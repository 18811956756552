import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Button from '../shared/Button';
import Modal from '../modals/Modal';

import { getHospitalsList } from '../../reducers/selectors';

import { caseConstants } from '../../constants';

const exportHeaders = [
  'Case ID', // 0
  'First Name',
  'Last Name',
  'DOB',
  'Gender',
  'Patient Age',
  'DOS',
  'Procedure',
  'Side',
  'PSI Yes/No',
  'Surgeon',
  'Hospital', // 10
  'Country',
  'State',
  'Xray (Y/N)',
  'CT (Y/N)',
  'MRI (Y/N)',
  'Dr Report (Y/N)',
  'PreOp HKA',
  'PreOp Varus/Valgus',
  'PreOp MPTA',
  'PreOp mLDFA', // 20
  'PreOp PPTA',
  'PreOp PDFA',
  'PreOp WBL',
  'Tibial Width',
  'Osteotomy Line Depth',
  'PostOp HKA',
  'PostOp Total Correction',
  'PostOp Correction PPTA',
  'PostOp WBL Intersection %',
  'Wedge Height', // 30
  'Usage 1',
  'Usage 2',
  'Usage 3',
  'Usage 4',
  'Usage 5',
  'Usage 6',
  'Usage 7',
  'Usage 8',
  'Usage 9',
  'Usage 10', // 40
  'Usage 11',
  'Usage 12',
  'Usage 13',
  'Usage 14',
  'Usage 15',
  'Usage 16',
  'Usage 17',
  'Usage 18',
  'Usage 19',
  'Usage 20', // 50
  'Xray (Y/N)',
  'Joint Registry Form (Y/N)',
  'Allograft Track Form',
  'Charge Sheet',
  'Case Notes',
];

const ExportModal = ({ open, onClose, cases, surgeons, users }) => {
  const hospitalList = useSelector(getHospitalsList);

  const { fileTypes } = caseConstants;

  const [exportData, setExportData] = useState([]);
  const [numberOfCases, setNumberOfCases] = useState(0);
  const [fromDate, setFromDate] = useState(moment().subtract(28, 'days'));
  const [toDate, setToDate] = useState(moment().add(28, 'days'));

  useEffect(() => {
    const filteredCases = cases.filter((item) => {
      const date = moment(item.date);

      return fromDate.isBefore(date) && toDate.isAfter(date) && !item.trainingMode;
    });
    setNumberOfCases(filteredCases.length);
    const preparedData = prepareDataForExport(filteredCases);

    setExportData(preparedData);
  }, [fromDate, toDate, cases]);

  const prepareDataForExport = (data) => {
    const preparedData = [];

    data.forEach((item) => {
      const exportCase = [];
      const {
        files = [],
        alignmentData = {},
        hardware = [],
        notes = {},
        procedureSide = '',
      } = item;
      const {
        firstName = '',
        lastName = '',
        gender = '',
        dateOfBirth = new Date(),
      } = item.patientInfo;

      const surgeon = surgeons.find((surgeon) => surgeon.id === item.surgeonId);
      const user = surgeon ? users.find((user) => surgeon.userId === user.uid) : null;
      const surgeonName = user ? `${user.firstName} ${user.lastName}` : '';

      const patientDate = moment(item.date).diff(moment(dateOfBirth), 'years') || '0';

      const xray = files.find((file) => file.type === fileTypes.xRay) ? 'Y' : 'N';
      const scan = files.find((file) => file.type === fileTypes.scan) ? 'Y' : 'N';
      const mri = files.find((file) => file.type === fileTypes.mri) ? 'Y' : 'N';
      const report = files.find((file) => file.type === fileTypes.report) ? 'Y' : 'N';
      const xray2 = files.filter((file) => file.type === fileTypes.xRay).length > 1 ? 'Y' : 'N';
      const aoa = files.find((file) => file.type === fileTypes.aoa) ? 'Y' : 'N';
      const allograft = files.find((file) => file.type === fileTypes.allograft) ? 'Y' : 'N';
      const chargedSheet = files.find((file) => file.type === fileTypes.chargedSheet) ? 'Y' : 'N';
      const caseNotes = notes && notes.step11 ? notes.step11 : '';
      const psi = !item.skipPSI ? 'Yes' : 'No';
      const hospitalUniq = hospitalList.find((hospital) => hospital?.id === item?.hospitalUniq?.id);

      exportCase[0] = item.formattedId || item.id;
      exportCase[1] = firstName;
      exportCase[2] = lastName;
      exportCase[3] = moment(dateOfBirth).format('DD/MM/YYYY');
      exportCase[4] = capitalize(gender);
      exportCase[5] = patientDate;

      exportCase[6] = moment(item.date).format('DD/MM/YYYY');

      exportCase[7] = item.procedureName || '';
      exportCase[8] = capitalize(procedureSide);
      exportCase[9] = psi;
      exportCase[10] = surgeonName;

      exportCase[11] = item.hospital || '';
      exportCase[12] = hospitalUniq?.country || '';
      exportCase[13] = hospitalUniq?.state || '';

      exportCase[14] = xray;
      exportCase[15] = scan;
      exportCase[16] = mri;
      exportCase[17] = report;

      exportCase[18] = alignmentData['Pre-Op_HKA'];
      exportCase[19] = alignmentData.type;
      exportCase[20] = alignmentData['MPTA'];
      exportCase[21] = alignmentData['mLDFA'];
      exportCase[22] = alignmentData['PPTA'];
      exportCase[23] = alignmentData['PDFA'];
      exportCase[24] = alignmentData['WBL'];
      exportCase[25] = alignmentData['Tibial_Width'];
      exportCase[26] = alignmentData['TW_Osteotomy'];
      exportCase[27] = alignmentData['HKA']; // Question
      exportCase[28] = alignmentData['Post_TS']; // Question
      exportCase[29] = alignmentData['Pre_TS'];
      exportCase[30] = alignmentData['%WBL']; // Question
      exportCase[31] = alignmentData['WH'];

      let index = 32;
      hardware.forEach((item) => {
        exportCase[index] = item.product;
        index++;
      });

      exportCase[52] = xray2;
      exportCase[53] = aoa;
      exportCase[54] = allograft;
      exportCase[55] = chargedSheet;

      exportCase[56] = caseNotes;

      for (let i = 16; i < 29; i++) {
        if (!exportCase[i]) {
          const value = exportCase[i] === null || exportCase[i] === undefined ? '' : 0;
          exportCase[i] = value;
        }
      }

      preparedData.push(exportCase);
    });

    return preparedData;
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') {
      return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <Modal open={open} onClose={onClose} title="Export Case Data" subtitle="Admin">
      <div className="d-flex direction-column space-between">
        <div style={{ padding: '32px 16px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="secondary">FROM DATE (Surgery date)</div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  fullWidth
                  id="date-picker"
                  value={fromDate}
                  onChange={(date) => setFromDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDate={toDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="secondary">TO DATE (Surgery date)</div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  fullWidth
                  id="date-picker"
                  value={toDate}
                  onChange={(date) => setToDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDate={fromDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <div className="d-flex p-t-lg">
            <span>Cases:</span>
            <span className="primary m-l-sm font-bold">{numberOfCases}</span>
          </div>
        </div>
        <div className="p-lg d-flex space-between border-top width-100">
          <div className="secondary pointer" onClick={onClose}>Close</div>
          <CSVLink
            style={{ textDecoration: 'none' }}
            data={exportData}
            headers={exportHeaders}
            filename={`ps_export_${fromDate.format('DDMMYY')}_${toDate.format('DDMMYY')}.csv`}
            target="_blank"
          >
            <Button text="Export" />
          </CSVLink>
        </div>
      </div>
    </Modal>
  );
};

export default ExportModal;
