import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';
import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

import { updateCase } from '../../../../../../actions/casesActions';

import { caseConstants } from '../../../../../../constants';

const Step9 = ({ onClose, activeCase, updateCase, toggleModal, userId }) => {
  const [date, setDate] = useState(moment());
  const [trackingNumber, setTrackingNumber] = useState('');
  const [courier, setCourier] = useState('');

  const [cocDocument, setCocDocument] = useState([]);
  const [uploadedCocDocument, setUploadedCocDocument] = useState([]);

  const handleSubmit = () => {
    if (!date) {
      return;
    }
    const now = moment().format();

    const filesData = activeCase.files && activeCase.files.length ? [...activeCase.files] : [];
    const { fileTypes, statuses } = caseConstants;

    cocDocument.forEach((file) => {
      filesData.push({
        id: `${activeCase.id}_${fileTypes.coc}_${file.name}`,
        name: file.name,
        type: fileTypes.coc,
        date: now,
        userId,
      });
    });

    const activity = [
      { ...caseConstants.caseNotifications[9].success, date: now, userId },
      ...activeCase.activity,
    ];
    const dispatchInfo = {
      ...activeCase.dispatchInfo,
      dispatchDate: date.format(),
      trackingNumber,
      courier,
    };

    updateCase({
      ...activeCase,
      files: filesData,
      step: 10,
      activity,
      completionDates: { ...activeCase.completionDates, step9: now },
      completedBy: { ...activeCase.completedBy, step9: userId },
      dispatchInfo,
      status: statuses.inProgress,
    }).then(() => onClose());
  };

  const handleUploadCocDocument = (files) => {
    const uploads = { ...uploadedCocDocument };
    files.forEach((file) => (uploads[file.name] = false));
    setUploadedCocDocument(uploads);
    setCocDocument([
      ...cocDocument,
      ...files.filter((file) => !cocDocument.map((coc) => coc.name).includes(file.name)),
    ]);
  };

  const handleRemoveCocDocument = (name) => {
    const uploads = { ...uploadedCocDocument };
    delete uploads[name];
    setUploadedCocDocument(uploads);
    setCocDocument(cocDocument.filter((file) => file.name !== name));
  };

  const onUploadCocDocumentComplete = (name, url) => {
    setUploadedCocDocument((prevState) => ({ ...prevState, [name]: true }));
  };

  const handleClose = () => {
    if (cocDocument.length) {
      toggleModal(true);
    } else {
      onClose();
    }
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">9</div>
          </div>
          <div className="modal-item-content">
            <div>Dispatch Confirmation</div>
            {activeCase.access ? (
              <div className="modal-item-block">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div className="field-container">
                      <div className="field-title">Dispatch date</div>
                      <div className="field-content">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            fullWidth
                            format="DD/MM/YYYY"
                            id="date-picker"
                            value={date}
                            onChange={(date) => setDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} />
                </Grid>
                <Grid container className="m-t-md" spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div className="field-container">
                      <div className="field-title">Tracking No.</div>
                      <div className="field-content">
                        <TextField
                          id="tracking-number"
                          type="text"
                          value={trackingNumber}
                          onChange={(e) => setTrackingNumber(e.target.value)}
                          fullWidth
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="field-container">
                      <div className="field-title">Courier</div>
                      <div className="field-content">
                        <TextField
                          id="courier"
                          type="text"
                          value={courier}
                          onChange={(e) => setCourier(e.target.value)}
                          fullWidth
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="block-header">Attach CoC Document</div>
                    <Dropzone
                      files={cocDocument}
                      onUpload={handleUploadCocDocument}
                      onRemove={handleRemoveCocDocument}
                      type="pdf"
                      icon="coc"
                      withProgress
                      path={`${activeCase.id}/${activeCase.id}`}
                      suffix="coc"
                      onUploadComplete={onUploadCocDocumentComplete}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={9} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={handleClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access}
        />
      </div>
    </div>
  );
};

Step9.propTypes = {
  activeCase: PropTypes.object,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase) => dispatch(updateCase(newCase, 9)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step9);
