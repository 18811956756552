import moment from 'moment';

import {
  LOAD_ACTIVITY_LIST,
  SET_ACTIVITY_LIST,
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  RESET_ACTIVITY,
} from './actionTypes';

import Firebase from '../firebase';
import { ACTIVITY_COLLECTION, ACTIVITY_SUB_COLLECTION } from '../firebase/collections';
import { api } from '../util';
import urls from '../constants/urls';

export const setActivityList = (activityList) => ({ type: SET_ACTIVITY_LIST, activityList });

export const fetchActivity = () => (dispatch, getState) => {
  const collection = Firebase.db.collection(ACTIVITY_COLLECTION);
  const state = getState();
  const uid = state.user.currentUser.uid;

  // dispatch({ type: LOAD_ACTIVITY_LIST });

  const date = moment().subtract(14, 'days');

  return collection
    .doc(uid)
    .collection(ACTIVITY_SUB_COLLECTION)
    .orderBy('date', 'desc')
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((activity) => dispatch(setActivityList(activity?.filter((item) => moment(item.date).isAfter(date)))));
};

export const createActivity = (uid, activity) => (dispatch, getState) => {
  const collection = Firebase.db.collection(ACTIVITY_COLLECTION);
  const docRef = collection.doc(uid);
  const state = getState();
  const notificationsSettings = state.settings?.notifications;

  dispatch({ type: LOAD_ACTIVITY_LIST });

  return docRef
    .collection(ACTIVITY_SUB_COLLECTION)
    .add(activity)
    .then(() => {
      dispatch({ type: CREATE_ACTIVITY, activity });
      if (notificationsSettings.active) {
        createPushNotification(uid, { title: activity?.title, body: activity?.text }).catch();
      }
      return dispatch(fetchActivity());
    })
    .catch((error) => console.log(error));
};

export const createPushNotification = (uid, notification) => {
  return api.post(urls.sendPushNotification, {
    uid,
    title: notification?.title || '',
    body: notification?.body || ''
  });
};

export const deleteActivity = (id, uid) => (dispatch, getState) => {
  const state = getState();
  const collection = Firebase.db.collection(ACTIVITY_COLLECTION);
  const userId = uid || state.user.currentUser.uid;
  const docRef = collection.doc(userId);

  dispatch({ type: LOAD_ACTIVITY_LIST });

  return docRef
    .collection(ACTIVITY_SUB_COLLECTION)
    .doc(id)
    .delete()
    .then(() => dispatch({ type: DELETE_ACTIVITY, id }));
};

export const resetActivity = () => ({ type: RESET_ACTIVITY });
