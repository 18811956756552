import React from 'react';
import PropTypes from 'prop-types';

import ManufacturerListItem from './ManufacturerListItem';

const ManufacturerList = ({
  manufacturers,
  onDelete,
  admins,
  users,
  onAddAdmin,
  onDeleteAdmin,
}) => {
  return (
    <div className="distributor-list">
      {manufacturers.map((manufacturer) => (
        <ManufacturerListItem
          key={manufacturer.id}
          {...manufacturer}
          onDelete={onDelete}
          admins={admins.filter((admin) => admin.distributor === manufacturer.id)}
          users={users}
          onAddAdmin={onAddAdmin}
          onDeleteAdmin={onDeleteAdmin}
        />
      ))}
    </div>
  );
};

ManufacturerList.propTypes = {
  manufacturers: PropTypes.array,
  admins: PropTypes.array,
  users: PropTypes.array,
  onDelete: PropTypes.func,
  onAddAdmin: PropTypes.func,
  onDeleteAdmin: PropTypes.func,
};

export default ManufacturerList;
