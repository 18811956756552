import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import imageIcon from '../../../../assets/image_icon.svg';
import pdfIcon from '../../../../assets/preop_icon.svg';
import videoIcon from '../../../../assets/block_icon.svg';

const DocumentsList = ({ documents, onDelete }) => {
  const getIcon = (name) => {
    if (name && name.includes('.pdf')) {
      return pdfIcon;
    }
    if (name && name.includes('.mp4')) {
      return videoIcon;
    }
    return imageIcon;
  };

  return (
    <div className="documents-list">
      {documents.map((document) => (
        <div key={document.id} className="d-flex">
          <div className="column" />
          <div className="documents-list-item">
            <div className="d-flex">
              <img src={getIcon(document.name)} alt="file-icon" />
              <a href={document.url} target="_blank" rel="noopener noreferrer">
                {document.name}
              </a>
            </div>
            <IconButton aria-label="delete" color="primary" onClick={() => onDelete(document.id)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
};

DocumentsList.propTypes = {
  documents: PropTypes.array,
  onDelete: PropTypes.func,
};

export default DocumentsList;
