import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const UserListItem = ({ uid, firstName, lastName, email, onDelete }) => {
  const [deleteMode, toggleDeleteMode] = useState(false);

  return (
    <div className="admin-modal-item list-item-container">
      <div className="d-flex w-100">
        <div className="column">
          <div className="modal-item-circle" />
        </div>
        <div className="modal-item-header-user">
          <div className="modal-item-content d-flex">
            <div>
              {firstName} {lastName}
            </div>
            <div className="user-email">{email}</div>
          </div>
          {deleteMode ? (
            <div className="d-flex">
              <div className="danger pointer m-l-sm" onClick={() => onDelete(uid)}>
                Delete
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleDeleteMode(false)}>
                Cancel
              </div>
            </div>
          ) : (
            <IconButton aria-label="delete" color="primary" onClick={() => toggleDeleteMode(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

UserListItem.propTypes = {
  uid: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  onDelete: PropTypes.func,
};

export default UserListItem;
