import React, { Fragment, useState } from 'react';
import moment from 'moment';

import { TextField, Grid } from '@material-ui/core';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';
import HardwareList from '../../HardwareList';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const AclStep5 = ({
  classes,
  onClose,
  activeCase,
  updateCase,
  products,
  caseNotifications,
  fileTypes,
  statuses,
  userId,
}) => {
  const hardware = activeCase.hardware ? activeCase.hardware.map((item) => ({ ...item, id: Math.random() })) : [];

  const [hardwareList, setHardwareList] = useState(hardware ? hardware : []);
  const [notes, setNotes] = useState('');
  const [sheets, setSheets] = useState([]);
  const [xRays, setXRays] = useState([]);
  const [allografts, setAllografts] = useState([]);

  const handleAddHardware = (product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setHardwareList(list);
    } else {
      setHardwareList([...hardwareList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setHardwareList(hardwareList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setHardwareList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setHardwareList(list);
  };

  const handleChange = (id, product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setHardwareList(list);
  };

  const handleSheetsUpload = (files) => {
    setSheets([...sheets, ...files.filter((file) => !sheets.map((sheet) => sheet.name).includes(file.name))]);
  };
  const handleXRaysUpload = (files) => {
    setXRays([...xRays, ...files.filter((file) => !xRays.map((xRay) => xRay.name).includes(file.name))]);
  };
  const handleAllograftsUpload = (files) => {
    setAllografts([
      ...allografts,
      ...files.filter((file) => !allografts.map((allograft) => allograft.name).includes(file.name)),
    ]);
  };

  const handleRemoveSheets = (name) => {
    setSheets(sheets.filter((file) => file.name !== name));
  };
  const handleRemoveXRays = (name) => {
    setXRays(xRays.filter((file) => file.name !== name));
  };
  const handleRemoveAllografts = (name) => {
    setAllografts(allografts.filter((file) => file.name !== name));
  };

  const handleSubmit = () => {
    const now = moment().format();
    const files = [...activeCase.files];
    const newFiles = [];

    sheets.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.chargedSheet}_${file.name}`,
        name: file.name,
        type: fileTypes.chargedSheet,
        date: now,
        userId,
      });
      newFiles.push(file);
    });
    xRays.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.xRay}_${file.name}`,
        name: file.name,
        type: fileTypes.xRay,
        date: now,
        userId,
      });
      newFiles.push(file);
    });
    allografts.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.allograft}_${file.name}`,
        name: file.name,
        type: fileTypes.allograft,
        date: now,
        userId,
      });
      newFiles.push(file);
    });

    const updatedCase = {
      ...activeCase,
      step: 6,
      activity: [{ ...caseNotifications[5].success, date: now, userId }, ...activeCase.activity],
      files,
      completionDates: { ...activeCase.completionDates, step5: now },
      completedBy: { ...activeCase.completedBy, step5: userId },
      hardware: hardwareList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category,
      })),
      notes: { ...activeCase.notes, step5: notes },
      status: statuses.inProgress,
    };

    updateCase(updatedCase, newFiles).then(() => onClose());
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">5</div>
          </div>
          <div className="modal-item-content">
            <div>Usage Report</div>
            {activeCase.access ? (
              <Fragment>
                <HardwareList
                  classes={classes}
                  hardwareList={hardwareList}
                  products={products}
                  onAdd={handleAddHardware}
                  onRemove={handleRemoveHardware}
                  onAmountChange={handleAmountChange}
                  onCategoryChange={handleCategoryChange}
                  onChange={handleChange}
                  title="Confirmed Hardware"
                  procedureFlow={activeCase.procedureFlow}
                />
                <div className="m-t-sm">
                  <TextField
                    id="notes"
                    type="text"
                    fullWidth
                    multiline
                    label="Usage Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
                <div className="m-t-lg">
                  <div className="modal-item-block p-t-md">
                    <div className="block-header">Upload Charge Sheet</div>
                    <Dropzone
                      files={sheets}
                      onUpload={handleSheetsUpload}
                      icon="image"
                      onRemove={handleRemoveSheets}
                    />
                  </div>
                  <div className="modal-item-block">
                    <div className="block-header">Attach X-ray(s)</div>
                    <Dropzone files={xRays} onUpload={handleXRaysUpload} icon="x-ray" onRemove={handleRemoveXRays} />
                  </div>
                  <div className="modal-item-block">
                    <div className="block-header">Allograft Tracking Form</div>
                    <Dropzone
                      files={allografts}
                      onUpload={handleAllograftsUpload}
                      icon="graft"
                      onRemove={handleRemoveAllografts}
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={5} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button text="Submit" color="primary" onClick={handleSubmit} disabled={!activeCase.access} />
      </div>
    </div>
  );
};
