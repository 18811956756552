import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';
import { caseConstants } from '../../../../../../constants';

const { fileStatuses, fileTypes, caseCMFExtendedNotifications, statuses } = caseConstants;

const CmfExtendedStep8 = (props) => {
  const {
    onClose,
    activeCase,
    updateCase,
    userId,
  } = props;

  const [preop, setPreop] = useState([]);
  const [manufacturingDate, setManufacturingDate] = useState(moment());

  const handleRemovePreop = (name) => {
    setPreop(preop.filter((file) => file.name !== name));
  };

  const handleSubmit = () => {
    const now = moment().format();

    const activity = [
      { ...caseCMFExtendedNotifications[8].success, date: now, userId },
      ...activeCase.activity,
    ];

    const files = [...activeCase.files];
    const newFiles = [];
    const preopFile = preop[0];

    if (preopFile) {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.conformanceReport}_${preopFile.name}`,
        name: preopFile.name,
        type: fileTypes.conformanceReport,
        status: fileStatuses.APPROVED,
        date: now,
        userId,
      });
      newFiles.push(preopFile);
    }

    updateCase(
      {
        ...activeCase,
        step: 9,
        activity,
        files,
        completionDates: { ...activeCase.completionDates, step8: now },
        completedBy: { ...activeCase.completedBy, step8: userId },
        status: statuses.inProgress,
        notes: {
          ...activeCase.notes,
        },
        manufacturingDate: manufacturingDate?.format()
      },
      newFiles
    ).then(() => onClose());
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">8</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Manufacturing Complete</div>
            {activeCase.access ? (
              <div>
                <div className="width-50">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      format="DD/MM/YYYY"
                      fullWidth
                      id="date-picker"
                      value={manufacturingDate}
                      onChange={(date) => setManufacturingDate(date)}
                      autoOk
                      disableFuture
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className="m-t-lg">
                  <div className="block-header">UPLOAD CONFORMANCE REPORT (.pdf)</div>
                  <Dropzone
                    files={preop}
                    onUpload={setPreop}
                    type="pdf"
                    icon="preop"
                    multiple={false}
                    onRemove={handleRemovePreop}
                  />
                </div>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={8} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access}
        />
      </div>
    </div>
  );
};

export default CmfExtendedStep8;