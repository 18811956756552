import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, CircularProgress, Tooltip } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Slider from '../../../../../shared/Slider';

import { procedureSides, alignmentDataTypes, procedureFlows } from '../../../../../../constants/cases';

const values = {
  dca: 'HKA (°)',
  tw: 'Tibial width (%)',
  rca: 'Correction angle (°)',
};

const ranges = {
  Valgus: {
    min: 30,
    max: 70,
  },
  Varus: {
    min: 30,
    max: 70,
  },
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    fontSize: '1rem',
    whiteSpace: 'pre-line !important',
  },
  active: {
    '& > div': {
      padding: '2px 8px !important',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 20,
      '& .alignment-data-value': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
  },
}));

const HtoCoronal = (props) => {
  const classes = useStyles();

  const {
    activeCase,
    DCA,
    tibialWidth,
    initialTibialWidth,
    RCA,
    handleDCAChange,
    handleTWChange,
    handleRCAChange,
    fujisawaLoading,
    DFPLoading,
    overallCorrectionLoading,
  } = props;

  const { alignmentData, procedureSide, procedureFlow } = activeCase;

  const [activeValue, setActiveValue] = useState(values.tw);

  const position = alignmentData.type === alignmentDataTypes.valgus ? (
    procedureFlow === procedureFlows.HTO_LATERAL ? 'close' : 'close' // For HTO Lateral we should use Varus for both (?)
  ) : (
    procedureFlow === procedureFlows.HTO_LATERAL ? 'close' : 'open'
  );
  const min = ranges[alignmentData.type].min || 30;
  const max = ranges[alignmentData.type].max || 70;

  const renderSlider = () => {
    switch (activeValue) {
      case values.dca:
        return (
          <Slider
            value={DCA}
            onChange={handleDCAChange}
            defaultValue={DCA}
            min={0}
            max={10}
            step={0.1}
            decimal
            disabled={fujisawaLoading}
          />
        );
      case values.tw:
        return (
          <Slider
            value={tibialWidth}
            onChange={handleTWChange}
            defaultValue={tibialWidth}
            min={min}
            max={max}
            step={0.5}
            decimal
            inverted={procedureSide === procedureSides.right}
          />
        );
      case values.rca:
        return (
          <Slider
            value={RCA}
            onChange={handleRCAChange}
            defaultValue={RCA}
            min={0}
            max={30}
            step={0.1}
            decimal
            disabled={fujisawaLoading}
          />
        );
      default:
        return null;
    }
  };

  const getImageSrc = () => {
    const url = procedureFlow === procedureFlows.HTO_LATERAL ? `/coronal_bone_images/HTO_lateral_${procedureSide}_${position}` : `/coronal_bone_images/${procedureSide}_${position}`;
    const min = ranges[alignmentData.type].min || 30;
    const max = ranges[alignmentData.type].max || 70;

    if (tibialWidth < min) {
      return `${url}/${min}_bone.png`;
    } else if (tibialWidth > max) {
      return `${url}/${max}_bone.png`;
    } else {
      return `${url}/${Math.floor(tibialWidth)}_bone.png`;
    }
  };

  const renderWBLArrow = () => {
    if (procedureSide === procedureSides.left) {
      if (initialTibialWidth === 0) {
        return <ArrowDown className="arrow" style={{ left: '-9%' }} />;
      } else if (initialTibialWidth < 0) {
        return <ArrowLeft className="arrow" style={{ left: '-6%', top: '-18px' }} />;
      } else if (initialTibialWidth > 100) {
        return <ArrowRight className="arrow" style={{ left: '89%', top: '-18px' }} />;
      }
    } else {
      if (initialTibialWidth === 0) {
        return <ArrowDown className="arrow" style={{ left: '91%' }} />;
      } else if (initialTibialWidth < 0) {
        return <ArrowRight className="arrow" style={{ left: '88%', top: '-18px' }} />;
      } else if (initialTibialWidth > 100) {
        return <ArrowLeft className="arrow" style={{ left: '-7%', top: '-18px' }} />;
      }
    }
  };

  const dcaLoading = fujisawaLoading || overallCorrectionLoading;
  const tibialWidthLoading = DFPLoading || overallCorrectionLoading;
  const correctionAngleLoading = fujisawaLoading || DFPLoading;

  const tooltip =
    'Select desired coronal correction angle by adjusting slider.\n' +
    '\n' +
    'By default mal-alignment is corrected to neutral with the weight bearing line passing through 50% of the tibial width.\n' +
    '\n' +
    'Image is an animated representation of the resultant deformity correction only and should not be referenced for any clinical decision making';

  return (
    <div className="modal-item-block flex-1 step-4-container margin-0">
      <div className="values-list">
        <div className="flex-1 m-r-md">
          <div className="block-header">Preoperative</div>
          <div className="alignment-data-item">
            <div>
              <div>
                HKA (°) '
                {alignmentData.type === alignmentDataTypes.valgus
                  ? alignmentDataTypes.valgus
                  : alignmentDataTypes.varus}
                '
              </div>
              <div>{alignmentData['Pre-Op_HKA']}</div>
            </div>
          </div>
          <div className="alignment-data-item">
            <div>
              <div>
                WBL <span className="secondary">(%)</span>
              </div>
              <div>{initialTibialWidth}</div>
            </div>
          </div>
          <div className="m-t-md">
            <Tooltip
              title={tooltip}
              placement="right"
              enterTouchDelay={50}
              classes={{ tooltip: classes.tooltip }}
              leaveTouchDelay={3000}
            >
              <InfoIcon color="primary" height={20} />
            </Tooltip>
          </div>
        </div>
        <div className="flex-1 m-l-md">
          <div className="block-header">Postoperative</div>
          <div
            className={`alignment-data-item pointer ${activeValue === values.dca ? 'active' : ''}`}
            // onClick={() => !dcaLoading && setActiveValue(values.dca)}
          >
            <div>
              <div>
                HKA (°) '
                {alignmentData.type === alignmentDataTypes.valgus
                  ? alignmentDataTypes.varus
                  : alignmentDataTypes.valgus}
                '
              </div>
              <div className="alignment-data-value">
                {dcaLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  `${alignmentData['Pre-Op_HKA'] > RCA ? '-' : ''}${DCA}`
                )}
              </div>
            </div>
          </div>
          <div
            className={`alignment-data-item pointer ${
              activeValue === values.tw ? classes.active : ''
            }`}
            onClick={() => !tibialWidthLoading && setActiveValue(values.tw)}
          >
            <div>
              <div>
                WBL Intersection<span className="secondary"> (%)</span>
              </div>
              <div className="alignment-data-value">
                {tibialWidthLoading ? <CircularProgress size={14} /> : tibialWidth}
              </div>
            </div>
          </div>
          <div
            className={`alignment-data-item pointer ${
              activeValue === values.rca ? classes.active : ''
            }`}
          >
            <div>
              <div style={{ whiteSpace: 'nowrap' }}>
                Correction Angle <span className="secondary">(°)</span>
              </div>
              <div className="alignment-data-value">
                {correctionAngleLoading ? <CircularProgress size={14} /> : RCA}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`image-container coronal ${procedureSide}_${position}`}>
        <div>
          <img src={getImageSrc()} alt="coronal" />
          <div className="arrow-slider-container">
            <div className="secondary font-size-xs m-r-sm">
              {procedureSide === procedureSides.right ? '100%' : '0'}
            </div>
            <div className="arrow-slider">
              <div>
                <ArrowDown
                  color="primary"
                  className="arrow"
                  style={{
                    left: `${
                      procedureSide === procedureSides.right
                        ? 100 - tibialWidth - 8
                        : tibialWidth - 4
                    }%`,
                    zIndex: 10,
                  }}
                />
                {initialTibialWidth >= 100 || initialTibialWidth <= 0 ? (
                  renderWBLArrow()
                ) : (
                  <ArrowDown
                    className="arrow"
                    style={{
                      left: `${
                        procedureSide === procedureSides.right
                          ? 100 - initialTibialWidth - 8
                          : initialTibialWidth - 4
                      }%`,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="secondary font-size-xs m-l-sm">
              {procedureSide === procedureSides.right ? '0' : '100%'}
            </div>
          </div>
        </div>
      </div>
      {renderSlider()}
      <div className="font-size-sm secondary text-center m-t-sm">Coronal view</div>
    </div>
  );
};

HtoCoronal.propTypes = {
  activeCase: PropTypes.object,
  DCA: PropTypes.number,
  tibialWidth: PropTypes.number,
  initialTibialWidth: PropTypes.number,
  RCA: PropTypes.number,
  handleDCAChange: PropTypes.func,
  handleTWChange: PropTypes.func,
  handleRCAChange: PropTypes.func,
  fujisawaLoading: PropTypes.bool,
  DFPLoading: PropTypes.bool,
  overallCorrectionLoading: PropTypes.bool,
};

export default HtoCoronal;
