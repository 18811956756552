import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Modal from '../../modals/Modal';

import { sortAlphabetically } from '../../../util/utils';

import { defaultLeadDays } from '../../../constants/cases';

const CaseDateModal = (props) => {
  const { open, onClose, selectedCase, onSubmit, hospitalList = [], loading, isAdmin, procedures = [] } = props;

  const [date, setDate] = useState(isAdmin ? moment() : moment().add(defaultLeadDays, 'days'));
  const [hospital, setHospital] = useState(null);

  const procedure = useMemo(() => procedures?.find((p) => p.id === selectedCase?.procedure), [selectedCase, procedures]);
  const leadDays = useMemo(() => (
    procedure && procedure?.leadDays > 0 ? procedure.leadDays + 10 : defaultLeadDays
  ), [procedure]);
  const startDate = useMemo(() => (
    isAdmin ? moment() : moment().add(leadDays, 'days')
  ), [leadDays, isAdmin]);

  useEffect(() => {
    if (startDate) {
      setDate(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (open && selectedCase) {
      setHospital(selectedCase?.hospitalUniq?.id)
    }
  }, [open, selectedCase]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={selectedCase?.formattedId || selectedCase?.id}
      subtitle="Case ID"
      submitButton="Save Changes"
      onSubmit={() => onSubmit(date, hospital)}
      size="md"
      autoSize
      closeButton="Cancel"
      disabled={loading}
      loading={loading}
    >
      <div className="d-flex direction-column space-between">
        <div className="p-lg width-100">
          <div className="secondary">Surgery Date</div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              fullWidth
              id="date-picker"
              value={date}
              onChange={(date) => setDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDate={!isAdmin ? startDate : undefined}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="p-l-lg p-r-lg p-b-lg width-100">
          <div className="secondary">Hospital</div>
          <Select
            value={hospital}
            fullWidth
            className="case-field-select"
            onChange={(e) => setHospital(e.target.value)}
          >
            {hospitalList.sort(sortAlphabetically('name')).map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default CaseDateModal;
