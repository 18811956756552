import React, { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Firebase from '../../firebase';
import Modal from '../modals/Modal';
import Iframe from '../shared/iframe';

const TermsModal = ({ year, open, onClose, onSubmit }) => {
  const [checked, setChecked] = useState(false);
  const [termsOfUse, setTermsOfUse] = React.useState('');

  Firebase.getDownloadLink('static/PS_terms_of_use.docx').then((response) =>
    setTermsOfUse(response)
  );

  return (
    <Modal
      size="md"
      fullWidth
      open={open}
      onClose={onClose}
      title={`Terms of Service ${year}`}
      subtitle="Personalised Surgery Pty Ltd"
      submitButton={onSubmit ? 'Accept' : null}
      onSubmit={onSubmit}
      disabled={!checked}
    >
      <div className="footer-modal__content">
        <div className="footer-modal__block">
          <Iframe source={termsOfUse} />
        </div>

        {onSubmit && (
          <div className="m-t-lg">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
              }
              label="I have read and agree to the Terms & Conditions"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TermsModal;
