import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import NewUser from '../NewUser';
import SubItemsUserList from '../SubItemsUserList';

const ManufacturerListItem = (props) => {
  const { id, name, address, state, postCode, onDelete, admins, users, onAddAdmin, onDeleteAdmin } =
    props;

  const [addMode, toggleMode] = useState(false);
  const [deleteMode, toggleDeleteMode] = useState(false);
  const [admin, setAdmin] = useState(null);

  const handleAddAdmin = () => {
    if (!admin) {
      return;
    }

    onAddAdmin(admin.uid, id);
    setAdmin(null);
    toggleMode(false);
  };

  const filteredUsers = users.filter(
    (user) => !~admins.map((admin) => admin.uid).indexOf(user.uid)
  );

  return (
    <div className="admin-modal-item list-item-container">
      <div className="list-item-main">
        <div className="column">
          <div className="modal-item-circle" />
        </div>
        <div className="modal-item-header">
          <div className="d-flex">
            <div className="m-r-md">{name}</div>
          </div>
          {deleteMode ? (
            <div className="d-flex">
              <div className="danger pointer m-l-sm" onClick={() => onDelete(id)}>
                Delete
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleDeleteMode(false)}>
                Cancel
              </div>
            </div>
          ) : (
            <IconButton aria-label="delete" color="primary" onClick={() => toggleDeleteMode(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="d-flex m-b-md">
        <div className="column" />
        <div className="flex-1">
          <div className="info-row">
            <div className="info-row-field">
              <div className="field-label">REGISTERED ADDRESS</div>
              <div className="field-value">{address || 'None'}</div>
            </div>
          </div>
          <div className="info-row">
            <div className="info-row-field">
              <div className="field-label">EMAIL</div>
              <div className="field-value">{state || 'None'}</div>
            </div>
            <div className="info-row-field">
              <div className="field-label">POST CODE</div>
              <div className="field-value">{postCode || 'None'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>{!admins || !admins.length ? 'NO USERS' : 'MANUFACTURER TEAM'}</div>
            <div className="primary pointer" onClick={() => toggleMode(true)}>
              Add Manufacturer Team
            </div>
          </div>
        </div>
        {admins && <SubItemsUserList users={admins} onDelete={onDeleteAdmin} />}
      </div>
      {addMode && (
        <NewUser
          userList={filteredUsers}
          isSubItem
          onChange={setAdmin}
          onSubmit={handleAddAdmin}
          onCancel={() => toggleMode(false)}
        />
      )}
    </div>
  );
};

ManufacturerListItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  users: PropTypes.array,
  admins: PropTypes.array,
  onDelete: PropTypes.func,
  onAddAdmin: PropTypes.func,
  onDeleteAdmin: PropTypes.func,
};

export default ManufacturerListItem;
