import {
  ADD_MANUFACTURER,
  DELETE_MANUFACTURER,
  LOAD_MANUFACTURERS,
  SET_MANUFACTURERS,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const manufacturersReducer = (state = initialState, action) => {
  const manufacturers = state.list.slice();

  switch (action.type) {
    case LOAD_MANUFACTURERS:
      return { ...state, loading: true };
    case SET_MANUFACTURERS:
      return { ...state, loading: false, list: action.manufacturers };
    case ADD_MANUFACTURER:
      const newManufacturer = {
        id: action.id,
        country: action.country,
        name: action.name,
        address: action.address,
        state: action.state,
        postCode: action.postCode,
      };

      return { ...state, list: [...manufacturers, newManufacturer], loading: false };
    case DELETE_MANUFACTURER:
      return {
        ...state,
        list: manufacturers.filter((item) => item.id !== action.id),
        loading: false,
      };
    default:
      return state;
  }
};

export default manufacturersReducer;
