import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import LoadScreen from '../../load-screen';

import { Step0, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9, Step10, Step11, Step12 } from './case-progress';
import CompletedCase from './case-progress/CompletedCase';

const useStyles = makeStyles((theme) => ({
  primary: { color: theme.palette.primary.main },
  pending: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: 'rgb(255, 244, 229)',
  },
}));

const CaseProgress = (props) => {
  const { activeCase, loading, onClose, toggleModal } = props;
  const classes = useStyles();

  const renderSteps = () => {
    if (!activeCase) {
      return <Step0 onClose={onClose} />;
    }

    switch (activeCase.step) {
      case 0:
        return <Step0 activeCase={activeCase} onClose={onClose} />;
      case 1:
        return <Step1 onClose={onClose} toggleModal={toggleModal} />;
      case 2:
        return <Step2 onClose={onClose} />;
      case 3:
        return <Step3 onClose={onClose} classes={classes} />;
      case 4:
        return <Step4 onClose={onClose} classes={classes} />;
      case 5:
        return <Step5 onClose={onClose} classes={classes} />;
      case 6:
        return <Step6 onClose={onClose} classes={classes} />;
      case 7:
        return <Step7 onClose={onClose} classes={classes} />;
      case 8:
        return <Step8 onClose={onClose} classes={classes} />;
      case 9:
        return <Step9 onClose={onClose} classes={classes} toggleModal={toggleModal} />;
      case 10:
        return <Step10 onClose={onClose} classes={classes} />;
      case 11:
        return <Step11 onClose={onClose} classes={classes} />;
      case 12:
        return <Step12 onClose={onClose} classes={classes} />;
      default:
        return <Step0 onClose={onClose} />;
    }
  };

  return (
    <div className="case-progress-container">
      {loading && <LoadScreen />}
      {renderSteps()}
    </div>
  );
};

CaseProgress.propTypes = {
  activeCase: PropTypes.object,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  toggleModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    loading: state.cases.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseProgress);
