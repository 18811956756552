import {
  LOAD_ACTIVITY_LIST,
  SET_ACTIVITY_LIST,
  CREATE_ACTIVITY,
  DELETE_ACTIVITY,
  RESET_ACTIVITY,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
  isLoaded: true,
};

const activityReducer = (state = initialState, action) => {
  const activityList = state.list.slice();

  switch (action.type) {
    case LOAD_ACTIVITY_LIST:
      return { ...state, loading: true };
    case SET_ACTIVITY_LIST:
      return { ...state, loading: false, list: action.activityList, isLoaded: true };
    case CREATE_ACTIVITY:
      return { ...state, loading: false };
    case DELETE_ACTIVITY:
      return {
        ...state,
        list: activityList.filter((item) => item.id !== action.id),
        loading: false,
      };
    case RESET_ACTIVITY:
      return { ...state, isLoaded: false };
    default:
      return state;
  }
};

export default activityReducer;
