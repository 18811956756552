import React from 'react';
import { connect } from 'react-redux';

import { caseConstants } from '../../../../../constants';

import OsteotomyStep9 from './osteotomy-step9/OsteotomyStep9';
import CmfExtendedStep9 from './cmf-extended-step9/CmfExtendedStep9';

const { procedureFlows } = caseConstants;

const Step9 = ({ classes, onClose, activeCase, userId }) => {
  const renderStep9 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep9
          classes={classes}
          activeCase={activeCase}
          onClose={onClose}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedStep9
          activeCase={activeCase}
          userId={userId}
          onClose={onClose}
        />
      );
    }
  };

  return renderStep9(activeCase.procedureFlow);
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    userId: state.user.currentUser.uid,
    products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step9);
