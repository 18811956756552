import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

const NewDistributor = ({ onCancel, onSubmit }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = () => {
    if (!name) {
      return;
    }

    onSubmit({ name, address, email, phone });
  };

  return (
    <div>
      <div className="d-flex new-item">
        <div className="column">
          <div className="modal-item-circle light" />
        </div>
        <div className="d-flex flex-1">
          <TextField
            id="new-distributor-name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="p-l-md primary pointer" onClick={handleSubmit}>
            Add
          </div>
          <div className="p-l-md secondary pointer" onClick={onCancel}>
            Cancel
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="column"></div>
        <div className="flex-1">
          <div className="d-flex">
            <TextField
              id="new-distributor-address"
              type="text"
              label="Address"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="d-flex">
            <div className="flex-1 m-r-md">
              <TextField
                id="new-distributor-email"
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex-1 m-l-md">
              <TextField
                id="new-distributor-phone"
                type="text"
                label="Phone"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewDistributor.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NewDistributor;
