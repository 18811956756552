import React from "react";
import moment from "moment";

import { getFlowsTheme } from '../../../util/utils';

const NoteListItem = ({ text, userId, users = [], date, external, procedureFlow }) => {
  const getUserName = (uid) => {
    const user = users?.find((u) => u.uid === uid);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };

  return (
    <div className="case-note">
      <div>
        <div className='d-flex'>
          <div className='font-bold'>{getUserName(userId)}</div>
          {external && (<span className='m-l-md' style={{ color: getFlowsTheme(procedureFlow)?.main }}>External Note</span>)}
        </div>
        {!!date && (
          <div className='secondary'>{`${moment(date).format('MMM Do YY')} | ${moment(date).format('HH:mm')}`}</div>
        )}
      </div>
      <div>
        {text}
      </div>
    </div>
  );
};

export default NoteListItem;
