import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import IndicatorCell from '../shared/table/IndicatorCell';
import DateCell from '../shared/table/DateCell';

import { caseConstants } from '../../constants';
import { utils } from '../../util';

const { numberOfAClSteps, numberOfSteps, numberOfCmfExtendedSteps, numberOfCmfSteps, numberOfCustomSteps, statuses, caseActions, caseAclActions, caseCmfActions, caseCmfExtendedActions, procedureFlows } = caseConstants;

const CasesTableMobile = ({ cases, onClick }) => {
  const getNumberFlowSteps = (flow) => {
    switch (flow) {
      case procedureFlows.CMF:
        return numberOfCmfSteps;
      case procedureFlows.CMF_EXTENDED:
        return numberOfCmfExtendedSteps;
      case procedureFlows.ONCOL:
        return numberOfCmfExtendedSteps;
      case procedureFlows.CUSTOM_EXTENDED:
        return numberOfCmfExtendedSteps;
      case procedureFlows.DOUBLE_LEVEL_OSTEOTOMY:
        return numberOfCmfExtendedSteps;
      case procedureFlows.ACL:
        return numberOfAClSteps;
      case procedureFlows.CUSTOM:
        return numberOfCustomSteps;
      default:
        return numberOfSteps;
    }
  };

  const getFlowActions = (flow) => {
    switch (flow) {
      case procedureFlows.CMF:
        return caseCmfActions;
      case procedureFlows.CMF_EXTENDED:
        return caseCmfExtendedActions;
      case procedureFlows.ONCOL:
        return caseCmfExtendedActions;
      case procedureFlows.CUSTOM_EXTENDED:
        return caseCmfExtendedActions;
      case procedureFlows.DOUBLE_LEVEL_OSTEOTOMY:
        return caseCmfExtendedActions;
      case procedureFlows.ACL:
        return caseAclActions;
      default:
        return caseActions;
    }
  };

  return (
    <div className="cases-table-mobile">
      {cases.map((item) => {
        const procedureFlow = item && item.procedureFlow;
        const numberFlowSteps = getNumberFlowSteps(procedureFlow);
        const actions = getFlowActions(procedureFlow);
        const action = procedureFlow === procedureFlows.ACL ? actions[item.step] : actions[item.step];
        const getFlowTheme = utils.getFlowsTheme(item?.procedureFlow, item.procedure).main;

        return (
          <div key={item.id} className="case-mobile-row" onClick={() => onClick(item)}>
            <div className="d-flex space-between">
              <div className="d-flex m-r-md flex-1">
                <IndicatorCell
                  value={item.access}
                  complete={item.step > numberFlowSteps}
                  rejected={item.status === statuses.rejected}
                  expiry={item?.createdAt ? item?.createdAt : false}
                  style={getFlowTheme}
                />
                <div className="m-l-md">{item?.formattedId || item.id}</div>
              </div>
              <div className="d-flex flex-1 flex-end">
                <DateCell date={item.date} />
              </div>
            </div>
            <div className="d-flex space-between m-t-sm">
              <div className="m-r-md flex-1">{item.hospital}</div>
              <div className="d-flex flex-end">{item.procedureName}</div>
            </div>
            <div className="d-flex space-between m-t-sm">
              <div style={{ color: getFlowTheme }} className={`m-r-md flex-1 ${item.access ? 'primary' : 'secondary'}`}>
                {action || ''}
              </div>
              <div className="d-flex flex-end">
                <span style={{ color: getFlowTheme }}>{item.step > numberFlowSteps ? numberFlowSteps : item.step}</span>
                /{numberFlowSteps}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

CasesTableMobile.propTypes = {
  cases: PropTypes.array,
  onClick: PropTypes.func,
};

export default memo(CasesTableMobile);
