import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { caseConstants } from '../../constants';
const { numberOfSteps, numberOfAClSteps, numberOfCmfSteps, numberOfCmfExtendedSteps, procedureFlows } = caseConstants;

const CasesSteps = (props) => {
  const { current, style, flow } = props;

  const styles = {
    new: {
      border: `1px solid ${style}`,
    },
    active: {
      background: '#fff',
      border: `2px solid ${style}`,
    },
    completed: {
      background: style,
    },
  };

  const steps = useMemo(() => {
    if (flow === procedureFlows.ACL) {
      return Array.from(Array(numberOfAClSteps).keys());
    }

    if (flow === procedureFlows.CMF) {
      return Array.from(Array(numberOfCmfSteps).keys());
    }

    if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return Array.from(Array(numberOfCmfExtendedSteps).keys());
    }

    return Array.from(Array(numberOfSteps).keys());
  }, []);

  return (
    <div className="case-steps-container">
      {steps.map((step) => (
        <div
          style={Object.assign(
            current === 0 && step === 0 && styles.new,
            current === step + 1 && styles.active,
            current > step + 1 && styles.completed
          )}
          key={step}
          className="case-step"
        />
      ))}
    </div>
  );
};

CasesSteps.propTypes = {
  current: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    current: state.cases.activeCase ? state.cases.activeCase.step : 0,
  };
};

export default connect(mapStateToProps)(CasesSteps);
