import { roleNames } from './userRoles';

const {
  GLOBAL_ADMIN,
  COUNTRY_ADMIN,
  DISTRIBUTOR_ADMIN,
  SURGEON,
  ENGINEER,
  SALES_REP,
  LOGISTICS,
  PRACTICE_MANAGER,
  MANUFACTURER,
  RADIOLOGY,
  REGISTRAR
} = roleNames;

const numberOfSteps = 11;
const numberOfAClSteps = 6;
const numberOfCmfSteps = 6;
const numberOfCmfExtendedSteps = 11;
const numberOfCustomSteps = 11;

const statuses = {
  inProgress: 'IN_PROGRESS',
  rejected: 'REJECTED',
  completed: 'COMPLETED',
  closed: 'CLOSED',
};

const stages = {
  singleStage: 'SINGLE_STAGE',
  twoStage: 'TWO_STAGE',
};

const hardwareCategories = [
  'Allograft',
  'Bone Wedge',
  'Plate',
  'Procedure Pack',
  'PSI',
  'Screw 1',
  'Screw 2',
  'Screw 3',
  'Screw 4',
  'Screw 5',
  'Screw 6',
  'Screw 7',
  'Screw 8',
  'Screw 9',
];

const caseNotifications = {
  0: {
    success: {
      type: 'notification',
      title: 'New Case Created',
      text: 'Please be advised that new case has been created & you have been assigned to it',
    },
  },
  1: {
    success: {
      type: 'activity',
      title: 'Scan(s) Awaiting Approval',
      text: 'New scans are now available for review and approval',
    },
    file: {
      type: 'file',
      title: 'New Case Scan(s) Uploaded',
      text: 'New Scan file(s) has been uploaded to the case',
    },
  },
  2: {
    success: {
      type: 'notification',
      title: 'Patient Scans Approved',
      text: 'Patient scans have been approved for use by engineer',
    },
    reject: {
      type: 'notification',
      title: 'Patient Scans Rejected',
      text: 'Patient scans have been rejected for use by engineer',
    },
  },
  3: {
    success: {
      type: 'activity',
      title: 'Alignment Data Uploaded',
      text: 'Engineer has uploaded alignment data for Surgeon review',
    },
  },
  4: {
    success: {
      type: 'activity',
      title: 'Surgeon Reviewed Alignment Data',
      text: 'Surgeon has reviewed the case alignment data and made adjustments where necessary',
    },
  },
  5: {
    success: {
      type: 'activity',
      title: 'Data Approved & Pre-Op Report Loaded',
      text: 'Engineer has reviewed any modifications and accepted the final alignment data',
    },
    reject: {
      type: 'notification',
      title: 'Wedge Measurements Rejected',
      text: 'Wedge measurements have been rejected by engineer',
    },
    file: {
      type: 'file',
      title: 'New File(s) Uploaded',
      text: 'New file(s) has been uploaded to the case',
    },
  },
  6: {
    success: {
      type: 'notification',
      title: 'Pre-op Plan Approved',
      text: 'Pre-op plan has been approved by surgeon',
    },
    reject: {
      type: 'notification',
      title: 'Pre-op Plan Rejected',
      text: 'Pre-op plan has been rejected by surgeon',
    },
  },
  7: {
    success: {
      type: 'notification',
      title: 'Files Approved For Manufacture',
      text: 'Print files have been approved by the manufacturer',
    },
    reject: {
      type: 'notification',
      title: 'Files Rejected For Manufacture',
      text: 'Print files have been rejected by the manufacturer',
    },
  },
  8: {
    success: {
      type: 'activity',
      title: 'PSI Manufacturing Completed',
      text: 'Manufacturing of the PSI block has been completed',
    },
  },
  9: {
    success: {
      type: 'activity',
      title: 'Manufacturer Has Dispatched PSI',
      text: 'Manufacturer has dispatched parts and adding shipping information to case',
    },
  },
  10: {
    success: {
      type: 'activity',
      title: 'Case Usage Has Been Reported',
      text: 'Sales Rep has confirmed the case usage and submitted the data',
    },
  },
  11: {
    success: {
      type: 'notification',
      title: 'Surgeon Has Closed Case',
      text: 'Surgeon has closed out the case and submitted any notes or associated images',
    },
  },
};

const caseACLNotifications = {
  0: {
    success: {
      type: 'notification',
      title: 'New Case Created',
      text: 'Please be advised that a new case has been created & you have been assigned to it',
    },
  },
  1: {
    success: {
      type: 'activity',
      title: 'Scan(s) Awaiting Approval',
      text: 'New scans are now available for review and approval',
    },
    file: {
      type: 'file',
      title: 'New Case Scan(s) Uploaded',
      text: 'New Scan file(s) has been uploaded to the case',
    },
  },
  2: {
    success: {
      type: 'notification',
      title: 'Patient Scans Approved',
      text: 'Patient scans have been approved for use by engineer',
    },
    reject: {
      type: 'notification',
      title: 'Patient Scans Rejected',
      text: 'Patient scans have been rejected for use by the case engineer',
    },
  },
  3: {
    success: {
      type: 'activity',
      title: 'Pre-Op Plan Uploaded',
      text: 'Engineer has reviewed patient data and uploaded a pre-op plan',
    },
    file: {
      type: 'file',
      title: 'Pre-Op Plan Uploaded',
      text: 'The design report has been uploaded to the case',
    },
  },
  4: {
    success: {
      type: 'notification',
      title: 'Pre-Op Plan Approved',
      text: 'Pre-Op Plan has been approved by surgeon',
    },
    reject: {
      type: 'notification',
      title: 'Pre-Op Plan Rejected',
      text: 'Pre-Op plan has been rejected by surgeon',
    },
  },
  5: {
    success: {
      type: 'activity',
      title: 'Case Usage Has Been Reported',
      text: 'Sales Rep has confirmed the case usage and submitted the data',
    },
  },
  6: {
    success: {
      type: 'notification',
      title: 'Case Closed',
      text: 'User has closed out the case and submitted any notes or associated images',
    },
  },
};

const caseCMFNotifications = {
  0: {
    success: {
      type: 'notification',
      title: 'New Case Created',
      text: 'Please be advised that a new case has been created & you have been assigned to it',
    },
  },
  1: {
    success: {
      type: 'activity',
      title: 'Scan(s) Awaiting Approval',
      text: 'New scans are now available for review and approval',
    },
    file: {
      type: 'file',
      title: 'New Case Scan(s) Uploaded',
      text: 'New Scan file(s) has been uploaded to the case',
    },
  },
  2: {
    success: {
      type: 'notification',
      title: 'Patient Scans Approved',
      text: 'Patient scans have been approved for use by engineer',
    },
    reject: {
      type: 'notification',
      title: 'Patient Scans Rejected',
      text: 'Patient scans have been rejected for use by the case engineer',
    },
  },
  3: {
    success: {
      type: 'activity',
      title: 'Design Report Uploaded',
      text: 'Engineer has reviewed patient data and uploaded a design report',
    },
    file: {
      type: 'file',
      title: 'Design Report Uploaded',
      text: 'The design report has been uploaded to the case',
    },
  },
  4: {
    success: {
      type: 'notification',
      title: 'Design Report Approved',
      text: 'Design report has been approved by surgeon',
    },
    reject: {
      type: 'notification',
      title: 'Pre-op Plan Rejected',
      text: 'Pre-op plan has been rejected by surgeon',
    },
  },
  5: {
    success: {
      type: 'activity',
      title: 'Case Usage Has Been Reported',
      text: 'Sales Rep has confirmed the case usage and submitted the data',
    },
  },
  6: {
    success: {
      type: 'notification',
      title: 'Case Closed',
      text: 'User has closed out the case and submitted any notes or associated images',
    },
  },
};

const caseCMFExtendedNotifications = {
  0: {
    success: {
      type: 'notification',
      title: 'New Case Created',
      text: 'Please be advised that a new case has been created & you have been assigned to it',
    },
  },
  1: {
    success: {
      type: 'activity',
      title: 'Scan(s) Awaiting Approval',
      text: 'New scans are now available for review and approval',
    },
    file: {
      type: 'file',
      title: 'New Case Scan(s) Uploaded',
      text: 'New Scan file(s) has been uploaded to the case',
    },
  },
  2: {
    success: {
      type: 'notification',
      title: 'Patient Scans Approved',
      text: 'Patient scans have been approved for use by engineer',
    },
    reject: {
      type: 'notification',
      title: 'Patient Scans Rejected',
      text: 'Patient scans have been rejected for use by the case engineer',
    },
  },
  3: {
    success: {
      type: 'activity',
      title: 'Plan Report Uploaded',
      text: 'Engineer has uploaded a plan report for Surgeon review',
    },
  },
  4: {
    success: {
      type: 'activity',
      title: 'Surgeon Approved Planning Report',
      text: 'Surgeon has reviewed the planning report and approved it',
    },
    reject: {
      type: 'notification',
      title: 'Planning Report Rejected',
      text: 'Planning report has been rejected by surgeon',
    },
  },
  5: {
    success: {
      type: 'activity',
      title: 'Design Report Uploaded',
      text: 'Engineer has reviewed any modifications and accepted the final alignment data',
    },
    file: {
      type: 'file',
      title: 'Design Report Uploaded',
      text: 'The design report has been uploaded to the case',
    },
  },
  6: {
    success: {
      type: 'notification',
      title: 'Design Report Approved',
      text: 'Design report has been approved by surgeon',
    },
    reject: {
      type: 'notification',
      title: 'Pre-op Plan Rejected',
      text: 'Pre-op plan has been rejected by surgeon',
    },
  },
  7: {
    success: {
      type: 'notification',
      title: 'Files Approved For Manufacture',
      text: 'Print files have been approved by the manufacturer',
    },
    reject: {
      type: 'notification',
      title: 'Files Rejected For Manufacture',
      text: 'Print files have been rejected by the manufacturer',
    },
  },
  8: {
    success: {
      type: 'activity',
      title: 'Manufacturing Completed',
      text: 'Manufacturing has been completed',
    },
  },
  9: {
    success: {
      type: 'activity',
      title: 'Manufacturer Has Dispatched Parts',
      text: 'Manufacturer has dispatched parts and adding shipping information to case',
    },
  },
  10: {
    success: {
      type: 'activity',
      title: 'Case Usage Has Been Reported',
      text: 'Sales Rep has confirmed the case usage and submitted the data',
    },
  },
  11: {
    success: {
      type: 'notification',
      title: 'Case Closed',
      text: 'User has closed out the case and submitted any notes or associated images',
    },
  },
};

const stepRestrictions = {
  0: [SURGEON, SALES_REP, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN, ENGINEER, REGISTRAR],
  1: [SURGEON, SALES_REP, PRACTICE_MANAGER, GLOBAL_ADMIN, RADIOLOGY, COUNTRY_ADMIN, ENGINEER, REGISTRAR],
  2: [GLOBAL_ADMIN, ENGINEER],
  3: [GLOBAL_ADMIN],
  4: [SURGEON, GLOBAL_ADMIN, REGISTRAR],
  5: [ENGINEER, GLOBAL_ADMIN],
  6: [SURGEON, GLOBAL_ADMIN, REGISTRAR],
  7: [MANUFACTURER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  8: [MANUFACTURER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  9: [MANUFACTURER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  10: [SALES_REP, GLOBAL_ADMIN, COUNTRY_ADMIN],
  11: [SURGEON, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN, REGISTRAR],
  12: [],
};

const cmfExtendedStepRestrictions = {
  0: [SURGEON, SALES_REP, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN, REGISTRAR, ENGINEER],
  1: [SURGEON, SALES_REP, PRACTICE_MANAGER, GLOBAL_ADMIN, RADIOLOGY, COUNTRY_ADMIN, REGISTRAR, ENGINEER],
  2: [ENGINEER, GLOBAL_ADMIN],
  3: [ENGINEER, GLOBAL_ADMIN, SURGEON, PRACTICE_MANAGER],
  4: [SURGEON, GLOBAL_ADMIN, REGISTRAR],
  5: [ENGINEER, GLOBAL_ADMIN],
  6: [SURGEON, GLOBAL_ADMIN, REGISTRAR],
  7: [MANUFACTURER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  8: [GLOBAL_ADMIN, COUNTRY_ADMIN],
  9: [GLOBAL_ADMIN, COUNTRY_ADMIN],
  10: [SALES_REP, GLOBAL_ADMIN, COUNTRY_ADMIN],
  11: [SURGEON, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN, REGISTRAR],
  12: [],
  13: [],
};

const aclStepRestrictions = {
  0: [SURGEON, SALES_REP, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN, ENGINEER, REGISTRAR],
  1: [SURGEON, SALES_REP, PRACTICE_MANAGER, GLOBAL_ADMIN, RADIOLOGY, COUNTRY_ADMIN, ENGINEER, REGISTRAR],
  2: [GLOBAL_ADMIN, ENGINEER],
  3: [GLOBAL_ADMIN, ENGINEER],
  4: [SURGEON, GLOBAL_ADMIN, REGISTRAR],
  5: [GLOBAL_ADMIN],
  6: [SURGEON, GLOBAL_ADMIN, REGISTRAR],
  7: [MANUFACTURER, GLOBAL_ADMIN],
};

const restrictionsInTrainingMode = {
  0: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  1: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
    RADIOLOGY,
  ],
  2: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  3: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  4: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  5: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  6: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  7: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  8: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  9: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  10: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  11: [
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    REGISTRAR,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  ],
  12: [],
};

const staticStepRestrictions = {
  0: [MANUFACTURER, LOGISTICS],
  1: [MANUFACTURER, LOGISTICS],
  2: [MANUFACTURER, LOGISTICS],
  3: [MANUFACTURER, LOGISTICS],
  4: [MANUFACTURER, LOGISTICS],
  5: [MANUFACTURER, LOGISTICS],
  6: [LOGISTICS],
  7: [LOGISTICS],
  8: [],
  9: [],
  10: [MANUFACTURER],
  11: [MANUFACTURER, LOGISTICS],
  12: [],
};

const fileTypes = {
  scan: 'scan',
  xRay: 'x-ray',
  preop: 'preop',
  block: 'block',
  image: 'image',
  chargedSheet: 'charged-sheet',
  mri: 'mri',
  report: 'report',
  aoa: 'aoa',
  allograft: 'allograft',
  coc: 'coc',
  planReport: 'plan-report',
  designReport: 'design-report',
  conformanceReport: 'conformance-report',
  manufacturing: 'manufacturing'
};

const fileRestrictions = {
  scan: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, SURGEON, REGISTRAR, COUNTRY_ADMIN],
  'x-ray': [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, SURGEON, REGISTRAR, COUNTRY_ADMIN],
  preop: [SURGEON, ENGINEER, PRACTICE_MANAGER, SALES_REP, GLOBAL_ADMIN, REGISTRAR, COUNTRY_ADMIN],
  block: [ENGINEER, PRACTICE_MANAGER, MANUFACTURER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  image: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  'charged-sheet': [SALES_REP, GLOBAL_ADMIN, COUNTRY_ADMIN],
  mri: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, SURGEON, REGISTRAR, COUNTRY_ADMIN],
  report: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, SURGEON, REGISTRAR, COUNTRY_ADMIN],
  aoa: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  allograft: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  coc: [GLOBAL_ADMIN, COUNTRY_ADMIN],
  [fileTypes.planReport]: [SALES_REP, ENGINEER, PRACTICE_MANAGER, SURGEON, GLOBAL_ADMIN, COUNTRY_ADMIN],
  [fileTypes.designReport]: [SALES_REP, ENGINEER, PRACTICE_MANAGER, SURGEON, GLOBAL_ADMIN, COUNTRY_ADMIN],
  [fileTypes.conformanceReport]: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN],
  [fileTypes.manufacturing]: [ENGINEER, PRACTICE_MANAGER, GLOBAL_ADMIN, COUNTRY_ADMIN],
};

// const fileRestrictions = {
//   "scan": [SURGEON, ENGINEER, PRACTICE_MANAGER, SALES_REP],
//   "x-ray": [SURGEON, ENGINEER, PRACTICE_MANAGER, SALES_REP],
//   "preop": [SURGEON, ENGINEER, PRACTICE_MANAGER],
//   "block": [SURGEON, ENGINEER, PRACTICE_MANAGER, SALES_REP, MANUFACTURER],
//   "image": [SURGEON, ENGINEER, PRACTICE_MANAGER, SALES_REP]
// };

const fileStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const calculationDataHTO = {
  tibia: [
    { field: 'TW', unity: 'mm' },
    { field: '%TW', unity: 'percents' },
    { field: 'JL_A', unity: 'mm' },
    { field: 'TW_JL_A', unity: 'degrees' },
    { field: 'TW_Osteotomy', unity: 'mm' },
    { field: 'T_MA', unity: 'mm', disabled: true },
    { field: 'R_%TW', unity: 'mm', disabled: true },
    { field: 'TW_T_MA', unity: 'mm', disabled: true },
  ],
  femur: [
    { field: 'JL_CoFH', unity: 'mm' },
    { field: 'TW_JL_CoFH', unity: 'degrees' },
    { field: 'F_MA', unity: 'mm', disabled: true },
    { field: 'TW_F_MA', unity: 'degrees', disabled: true },
  ],
  coronal: [
    { field: 'mLDFA', unity: 'degrees', min: 85, max: 90, label: 'Lateral Distal Femoral' },
    { field: 'MPTA', unity: 'degrees', min: 85, max: 90, label: 'Proximal Tibial' },
  ],
  sagittal: [
    { field: 'PDFA', unity: 'degrees', min: 79, max: 87, label: 'Posterior Distal Femoral' },
    { field: 'PPTA', unity: 'degrees', min: 6, max: 12, label: 'Tibial Slope' },
  ],
};

const calculationDataDFO = {
  tibia: [
    { field: 'TW', unity: 'mm' },
    { field: '%TW', unity: 'percents' },
    { field: 'JL_A', unity: 'mm' },
    { field: 'TW_JL_A', unity: 'degrees' },
    { field: 'FW_Osteotomy', unity: 'mm' },
    { field: 'T_MA', unity: 'mm', disabled: true },
    { field: 'R_%TW', unity: 'mm', disabled: true },
    { field: 'TW_T_MA', unity: 'mm', disabled: true },
  ],
  femur: [
    { field: 'JL_CoFH', unity: 'mm' },
    { field: 'TW_JL_CoFH', unity: 'degrees' },
    { field: 'F_MA', unity: 'mm', disabled: true },
    { field: 'TW_F_MA', unity: 'degrees', disabled: true },
  ],
  coronal: [
    { field: 'mLDFA', unity: 'degrees', min: 85, max: 90, label: 'Lateral Distal Femoral' },
    { field: 'MPTA', unity: 'degrees', min: 85, max: 90, label: 'Proximal Tibial' },
  ],
  sagittal: [
    { field: 'PDFA', unity: 'degrees', min: 79, max: 87, label: 'Posterior Distal Femoral' },
    { field: 'PPTA', unity: 'degrees', min: 77, max: 84, label: 'Tibial Slope' },
  ],
};

const validateCalculationData = {
  HKA: false,
  mLDFA: false,
  MPTA: false,
  PPTA: false,
  TW_Osteotomy: false,
  FW_Osteotomy: false,
  WBL: false,
  TW: false,
  JL_A: false,
  TW_JL_A: false,
  '%TW': false,
  JL_CoFH: false,
  TW_JL_CoFH: false,
  PDFA: false,
};

const alertMessages = {
  0: {
    success: 'New case successfully added',
  },
  1: {
    success: 'Scans have been successfully uploaded to case! If there are any issues we will contact you.',
  },
  2: {
    success: 'Patient scans approved',
    reject: 'Patient scans rejected',
  },
  3: {
    success: 'Alignment data successfully uploaded',
  },
  4: {
    success: 'Alignment data reviewed. Measurements submitted for review.',
  },
  5: {
    success: 'New files successfully uploaded',
    reject: 'Wedge measurements rejected',
  },
  6: {
    success: 'Pre-op plan approved',
    reject: 'Pre-op plan rejected',
  },
  7: {
    success: 'Files approved for manufacture',
    reject: 'Files rejected for manufacture',
  },
  8: {
    success: 'PSI Manufacturing Completed',
  },
  9: {
    success: 'PSI dispatched',
  },
  10: {
    success: 'Case usage confirmed',
  },
  11: {
    success: 'Case successfully completed',
  },
};

const alertAclMessages = {
  0: {
    success: 'New case successfully added',
  },
  1: {
    success: 'Scans have been successfully uploaded to case! If there are any issues we will contact you.',
  },
  2: {
    success: 'Patient scans approved',
    reject: 'Patient scans rejected',
  },
  3: {
    success: 'New files successfully uploaded',
    reject: 'Wedge measurements rejected',
  },
  4: {
    success: 'Pre-op plan approved',
    reject: 'Pre-op plan rejected',
  },
  5: {
    success: 'Case usage confirmed',
  },
  6: {
    success: 'Case successfully completed',
  },
};

const alertCmfMessages = {
  0: {
    success: 'New case successfully added',
  },
  1: {
    success: 'Scans have been successfully uploaded to case! If there are any issues we will contact you.',
  },
  2: {
    success: 'Patient scans approved',
    reject: 'Patient scans rejected',
  },
  3: {
    success: 'New files successfully uploaded',
    reject: 'Wedge measurements rejected',
  },
  4: {
    success: 'Pre-op plan approved',
    reject: 'Pre-op plan rejected',
  },
  5: {
    success: 'Case usage confirmed',
  },
  6: {
    success: 'Case successfully completed',
  },
};

const alertCmfExtendedMessages = {
  0: {
    success: 'New case successfully added',
  },
  1: {
    success: 'Scans have been successfully uploaded to case! If there are any issues we will contact you.',
  },
  2: {
    success: 'Patient scans approved',
    reject: 'Patient scans rejected',
  },
  3: {
    success: 'Success',
  },
  4: {
    success: 'Planning approved',
    reject: 'Planning rejected',
  },
  5: {
    success: 'Design report uploaded',
  },
  6: {
    success: 'Design report approved',
    reject: 'Design report rejected',
  },
  7: {
    success: 'Files approved for manufacture',
    reject: 'Files rejected for manufacture',
  },
  8: {
    success: 'Conformance report uploaded',
  },
  9: {
    success: 'Parts dispatched'
  },
  10: {
    success: 'Case usage confirmed'
  },
  11: {
    success: 'Post Op report completed',
  }
};

const caseActions = {
  0: 'New Case',
  1: 'Attach Scans & Documents',
  2: 'Approve Scans',
  3: 'Load Alignment',
  4: 'Review Alignment',
  5: 'Approve Alignment',
  6: 'Approve Pre-Op',
  7: 'Approve Files',
  8: 'Manufacturing Complete',
  9: 'Confirm Dispatch',
  10: 'Report Usage',
  11: 'Post Report',
  12: 'Completed',
};

const caseAclActions = {
  0: 'New Case',
  1: 'Attach Scans & Documents',
  2: 'Approve Scans',
  3: 'Attach Pre-Op Plan',
  4: 'Approve Pre-Op',
  5: 'Report Usage',
  6: 'Post Report',
  7: 'Completed',
};

const caseCmfActions = {
  0: 'New Case',
  1: 'Attach Scans & Documents',
  2: 'Approve Scans',
  3: 'Attach Pre-Op Plan',
  4: 'Approve Pre-Op',
  5: 'Report Usage',
  6: 'Post Report',
  7: 'Completed',
};

const caseCmfExtendedActions = {
  0: 'New Case',
  1: 'Attach Scans & Documents',
  2: 'Approve Scans',
  3: 'Planning',
  4: 'Approve Planning',
  5: 'Upload Design Report',
  6: 'Design Approval',
  7: 'Approve Files',
  8: 'Manufacturing Complete',
  9: 'Dispatch Confirmation',
  10: 'Usage Report',
  11: 'Post Report',
  12: 'Completed'
};

const procedureSides = {
  right: 'right',
  left: 'left',
};

const alignmentDataTypes = {
  varus: 'Varus',
  valgus: 'Valgus',
};

const selectedProcedureFlows = {
  HTO: 'HTO',
  DFO: 'DFO',
  Osteotomy: 'Osteotomy',
  ACL: 'ACL',
  CMF: 'CMF',
  CUSTOM: 'Custom',
  CUSTOM_EXTENDED: 'Custom Extended',
  ONCOL: 'ONCOL',
  DOUBLE_LEVEL_OSTEOTOMY: 'Double Level Osteotomy',
  CMF_EXTENDED: 'CMF Extended',
  CMF_GENERAL: 'CMF General'
};

const procedureFlows = {
  HTO: 'HTO',
  HTO_LATERAL: 'HTO Lateral',
  DFO_Lateral: 'DFO Lateral',
  DFO_Medial: 'DFO Medial',
  ACL: 'ACL',
  CMF: 'CMF',
  CUSTOM: 'Custom',
  ONCOL: 'ONCOL',
  DOUBLE_LEVEL_OSTEOTOMY: 'Double Level Osteotomy',
  CMF_EXTENDED: 'CMF Extended',
  CUSTOM_EXTENDED: 'Custom Extended'
};

const defaultLeadDays = 28;

export {
  numberOfSteps,
  numberOfAClSteps,
  numberOfCmfSteps,
  numberOfCmfExtendedSteps,
  numberOfCustomSteps,
  stages,
  caseNotifications,
  caseACLNotifications,
  caseCMFNotifications,
  caseCMFExtendedNotifications,
  fileStatuses,
  fileTypes,
  stepRestrictions,
  cmfExtendedStepRestrictions,
  aclStepRestrictions,
  restrictionsInTrainingMode,
  staticStepRestrictions,
  calculationDataHTO,
  calculationDataDFO,
  statuses,
  alertMessages,
  fileRestrictions,
  caseActions,
  caseAclActions,
  caseCmfActions,
  caseCmfExtendedActions,
  hardwareCategories,
  procedureSides,
  validateCalculationData,
  procedureFlows,
  selectedProcedureFlows,
  alignmentDataTypes,
  alertAclMessages,
  alertCmfMessages,
  alertCmfExtendedMessages,
  defaultLeadDays,
};
