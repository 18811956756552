import React, { useState } from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Modal from '../../modals/Modal';

const DatePickerModal = ({ open, onClose, onSubmit }) => {
  const [fromDate, setFromDate] = useState(moment().subtract(28, 'days'));
  const [toDate, setToDate] = useState(moment().add(28, 'days'));

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Filter by Date"
      submitButton="Submit"
      onSubmit={() => onSubmit(fromDate, toDate)}
    >
      <div className="d-flex direction-column space-between">
        <div style={{ padding: '32px 16px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <div className="secondary">FROM DATE (Surgery date)</div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  fullWidth
                  id="date-picker"
                  value={fromDate}
                  onChange={(date) => setFromDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDate={toDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="secondary">TO DATE (Surgery date)</div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  fullWidth
                  id="date-picker"
                  value={toDate}
                  onChange={(date) => setToDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDate={fromDate}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

export default DatePickerModal;
