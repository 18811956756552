import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import preopIcon from '../../../../../../assets/preop_icon.svg';

import { setActiveTab } from '../../../../../../actions/casesActions';

const OsteotomyStep6Static = ({ preop, goToFiles, notes }) => {
  if (preop) {
    return (
      <div className="case-static-container">
        <div className="d-flex m-b-md pointer" onClick={goToFiles}>
          <img src={preopIcon} alt="file-icon" />
          <div>{preop.name}</div>
          <div className={`status-indicator ${preop.status}`}>{preop.status}</div>
        </div>
        {notes && (
          <div className="m-t-md p-t-sm border-top">
            <div className="secondary">Notes:</div>
            <div>{notes}</div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

OsteotomyStep6Static.propTypes = {
  preop: PropTypes.object,
  goToFiles: PropTypes.func,
  notes: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    preop: state.cases.activeCase.files.find((file) => file.type === 'preop') || {},
    notes: state.cases.activeCase.notes && state.cases.activeCase.notes.step6,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep6Static);
