import {
  LOAD_SURGEONS,
  SET_SURGEONS,
  ADD_SURGEON,
  DISABLE_SURGEON,
  ENABLE_SURGEON,
  UPDATE_SURGEON,
  UPDATE_SURGEON_HOSPITALS,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const surgeonsReducer = (state = initialState, action) => {
  const surgeons = state.list.slice();
  const surgeon = surgeons.find((item) => item.id === action.id);
  const list = [...state.list];

  switch (action.type) {
    case LOAD_SURGEONS:
      return { ...state, loading: true };
    case SET_SURGEONS:
      return { ...state, loading: false, list: action.surgeons };
    case ADD_SURGEON:
      return {
        ...state,
        list: [
          ...state.list,
          {
            ...action.surgeon,
            distributor: action.distributor,
            hospitals: [],
            userId: action.userId,
          },
        ],
        loading: false,
      };
    case DISABLE_SURGEON:
      const disabled = list.find((s) => s.id === action.id);
      if (disabled) {
        disabled.disabled = true;
      }
      return {
        ...state,
        list,
        loading: false,
      };
    case ENABLE_SURGEON:
      const enabled = list.find((s) => s.id === action.id);
      if (enabled) {
        enabled.disabled = false;
      }
      return {
        ...state,
        list,
        loading: false,
      };
    case UPDATE_SURGEON:
      const { engineer, manufacturer, manager, salesRep, logistics, registrar } = action.surgeon;

      surgeon.engineer = engineer;
      surgeon.manufacturer = manufacturer;
      surgeon.manager = manager;
      surgeon.salesRep = salesRep;
      surgeon.logistics = logistics;
      surgeon.registrar = registrar;

      return { ...state, list: surgeons, loading: false };
    case UPDATE_SURGEON_HOSPITALS:
      surgeon.hospitals = action.hospitals;

      return { ...state, list: surgeons, loading: false };
    default:
      return state;
  }
};

export default surgeonsReducer;
