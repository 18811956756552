import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { TextField, Grid } from '@material-ui/core';

import { addProduct } from '../../../../../actions/adminActions';

const ProductsForm = ({ country, onCancel }) => {
  const dispatch = useDispatch();

  const [sku, setSku] = useState('');
  const [description, setDescription] = useState('');

  const handleSkuChange = (value) => setSku(value.trim());
  const handleDescriptionChange = (value) => setDescription(value);

  const handleSubmit = () => {
    if (!sku.length && !description.length) return;
    dispatch(addProduct({ country, sku, description }));
    setSku('');
    setDescription('');
  };

  const handleEnterSubmit = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className="products-container">
      <div className="d-flex">
        <div className="column" />

        <form className="products-form" onSubmit={handleSubmit} onKeyDown={handleEnterSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={5} xs={12}>
              <TextField
                fullWidth
                label="SKU"
                name="sku"
                placeholder="SKU"
                onChange={(e) => handleSkuChange(e.target.value)}
                value={sku}
              />
            </Grid>
            <Grid item sm={7} xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                placeholder="Description"
                onChange={(e) => handleDescriptionChange(e.target.value)}
                value={description}
              />
            </Grid>
          </Grid>
        </form>

        <div className="primary pointer p-l-md btn-sm" onClick={handleSubmit}>
          Add
        </div>

        <div className="secondary pointer p-l-md btn-sm" onClick={() => onCancel(false)}>
          Cancel
        </div>
      </div>
    </div>
  );
};

ProductsForm.propTypes = {
  country: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProductsForm;
