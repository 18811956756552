import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import FileItem from './FileItem';
import CircularProgress from '@material-ui/core/CircularProgress';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 20px',
  cursor: 'pointer',
  borderWidth: 2,
  borderRadius: 4,
  borderColor: '#b1b1b1',
  borderStyle: 'dashed',
  backgroundColor: '#f2f2f2',
  color: '#b1b1b1',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#ee9321',
};

const acceptStyle = {
  borderColor: '#ee9321',
};

const rejectStyle = {
  borderColor: '#d32f2f',
};

const Dropzone = (props) => {
  const {
    onUpload,
    files,
    type = 'image/jpeg, image/png, application/pdf',
    icon,
    multiple = true,
    disabled,
    text,
    hideFilesList,
    onRemove,
    withProgress,
    path,
    suffix,
    onUploadComplete,
    loading,
  } = props;
  const options = {
    onDrop: (acceptedFiles) => {
      onUpload(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    multiple,
    disabled: disabled || loading,
  };

  if (type === 'image') {
    options.accept = 'image/jpeg, image/png';
  }
  if (type === 'pdf') {
    options.accept = 'application/pdf';
  }
  if (type === 'csv') {
    options.accept = '.csv';
  }
  if (type === 'archive') {
    options.accept = ".zip,.rar,.7z,.gz";
  }
  if (type === 'zip') {
    options.accept = ".zip";
  }


  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone(options);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {
          loading ? (
            <CircularProgress size={24} />
          ) : (
            <div>{text || 'Drag and drop some files here or click to upload'}</div>
          )
        }
      </div>
      {!hideFilesList && (
        <aside>
          {files.map((file) => (
            <FileItem
              key={file.name}
              file={file}
              withProgress={withProgress}
              onRemove={onRemove}
              icon={icon}
              suffix={suffix}
              path={path}
              onUploadComplete={onUploadComplete}
            />
          ))}
        </aside>
      )}
    </div>
  );
};

Dropzone.propTypes = {
  onUpload: PropTypes.func,
  onRemove: PropTypes.func,
  onUploadComplete: PropTypes.func,
  files: PropTypes.array,
  type: PropTypes.oneOf(['image', 'pdf']),
  icon: PropTypes.oneOf([
    'x-ray',
    'scan',
    'preop',
    'block',
    'image',
    'aoa',
    'usage',
    'graft',
    'coc',
  ]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  hideFilesList: PropTypes.bool,
  withProgress: PropTypes.bool,
  text: PropTypes.string,
  path: PropTypes.string,
  suffix: PropTypes.string,
};

export default Dropzone;
