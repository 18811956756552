import React from "react";

const CaseNote = ({ onClose, caseNote }) => {
  return (
    <div className="case-export-container">
      <div className='p-lg'>
        <div className="modal-item-block">
          <div className="block-header">Case note</div>
          <div className="m-t-md secondary">
            {caseNote}
          </div>
        </div>
      </div>
      <div className="case-export-actions">
        <div className="secondary pointer" onClick={onClose}>
          Back
        </div>

      </div>
    </div>
  );
};

export default CaseNote;
