import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';

import { makeStyles, IconButton} from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import UploadIcon from '@material-ui/icons/Loop';
import BackIcon from '@material-ui/icons/ArrowBackOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import preopIcon from '../../../assets/preop_icon.svg';
import blockIcon from '../../../assets/block_icon.svg';
import imageIcon from '../../../assets/image_icon.svg';
import xRayIcon from '../../../assets/xray_icon.svg';
import ctIcon from '../../../assets/ct_icon.svg';
import mriIcon from '../../../assets/mri_icon.svg';
import usageIcon from '../../../assets/usage_icon.svg';
import aoaIcon from '../../../assets/aoa_icon.svg';
import graftIcon from '../../../assets/graft_icon.svg';

import Dropzone from '../../shared/dropzone/Dropzone';

import { changePreopFile, setActiveCase, setActiveTab, simpleUpdateCase, deleteCaseFile } from '../../../actions/casesActions';

import { caseConstants, userRoles } from '../../../constants';
import { roleNames } from '../../../constants/userRoles';
import AlertModal from '../AlertModal';

const { fileRestrictions, fileTypes, procedureFlows } = caseConstants;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  container: {
    width: '100%',
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const CaseFiles = ({
  activeCase,
  role,
  uploadFile,
  onClose,
  goToFiles,
  onBackClick,
  users = [],
  userId,
  updateCaseFiles,
  setActiveCase,
  deleteCaseFile
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { files } = activeCase;

  const [tabIndex, setTabIndex] = useState(2);
  const [fileType, setFileType] = useState('');
  const [uploading, setUploading] = useState(false);

  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const getIcon = (type) => {
    switch (type) {
      case fileTypes.xRay:
        return xRayIcon;
      case fileTypes.scan:
        return ctIcon;
      case fileTypes.preop:
        return preopIcon;
      case fileTypes.block:
        return blockIcon;
      case fileTypes.image:
        return imageIcon;
      case fileTypes.mri:
        return mriIcon;
      case fileTypes.aoa:
        return aoaIcon;
      case fileTypes.allograft:
        return graftIcon;
      case fileTypes.chargedSheet:
        return usageIcon;
      case fileTypes.coc:
        return preopIcon;
      default:
        return preopIcon;
    }
  };

  const getTypeLabel = (type) => {
    switch (type) {
      case fileTypes.chargedSheet:
        return 'Usage';
      case fileTypes.aoa:
        return 'AOA';
      case fileTypes.allograft:
        return 'Graft';
      case fileTypes.xRay:
        return 'Xray';
      case fileTypes.scan:
        return 'CT';
      case fileTypes.mri:
        return 'MRI';
      case fileTypes.report:
        return 'Report';
      case fileTypes.preop:
        return 'Preop';
      case fileTypes.block:
        return 'Block';
      case fileTypes.coc:
        return 'CoC';
      case fileTypes.image:
        return 'Image';
      case fileTypes.planReport:
        return 'Plan';
      case fileTypes.designReport:
        return 'Design';
      case fileTypes.conformanceReport:
        return 'Conf.';
      case fileTypes.manufacturing:
        return 'Manuf.';
      default:
        return type;
    }
  };

  const handleUpload = async ({ target }) => {
    const file = target.files[0];
    await uploadFile(activeCase, file);
    onClose();
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    dispatch(setActiveTab(newIndex));
  };

  const onUpload = async (newFiles) => {
    if (newFiles && newFiles[0]) {
      try {
        setUploading(true);
        const file = newFiles[0];
        const now = moment().format();
        const files = [...activeCase.files];
        files.unshift({
          id: `${activeCase.id}_${fileType}_${file.name}`,
          name: file.name,
          type: fileType,
          date: now,
          userId,
        });
        await updateCaseFiles({ ...activeCase, files }, [file]);
        await setActiveCase({ ...activeCase, files });
      } catch (err) {
        console.error(err);
      } finally {
        setUploading(false);
      }
    }
  };

  const tabs = [{ label: 'Process' }, { label: '', disabled: true }, { label: 'Files' }];

  const getUserName = (uid) => {
    const user = users?.find((u) => u.uid === uid);
    return user ? ` | ${user.firstName} ${user.lastName}` : '';
  };

  const onDeleteClick = (file) => {
    setSelectedFile(file);
    toggleDeleteModal(true);
  };

  const onDelete = async () => {
    try {
      if (selectedFile) {
        await deleteCaseFile(activeCase, selectedFile.id);
        toggleDeleteModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const downloadEnabled = (file) => (
    [procedureFlows.CMF_EXTENDED, procedureFlows.CMF, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && role === roleNames.SURGEON ? (
      file.type === fileTypes.designReport || file.type === fileTypes.planReport
    ) : (
      fileRestrictions[file.type] && fileRestrictions[file.type].includes(role)
    )
  );

  return (
    <div className={classes.container}>
      {/*{activeCase.step === 4 && ([procedureFlows.DFO_Lateral, procedureFlows.DFO_Medial, procedureFlows.HTO, procedureFlows.CUSTOM].includes(activeCase.procedureFlow)) && (*/}
      {/*  <AppBar position="static" color="default">*/}
      {/*    <Tabs*/}
      {/*      value={tabIndex}*/}
      {/*      onChange={handleTabChange}*/}
      {/*      indicatorColor="primary"*/}
      {/*      textColor="primary"*/}
      {/*      variant="fullWidth"*/}
      {/*    >*/}
      {/*      {tabs.map((tab, index) => (*/}
      {/*        <Tab*/}
      {/*          key={index}*/}
      {/*          label={typeof tab === 'object' ? tab.label : tab}*/}
      {/*          disabled={tab.disabled}*/}
      {/*          {...a11yProps(index)}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </Tabs>*/}
      {/*  </AppBar>*/}
      {/*)}*/}

      <div className="case-files-container">
        {activeCase && activeCase.files && activeCase.files.length ? (
          <div className="case-data-list">
            {files.map((item) => (
              <div key={`${item.name}-${item.type}-${item.date}`} className="data-list-item">
                <div className="d-flex flex-1">
                  <div className="d-flex direction-column align-center icon-file-container">
                    <img src={getIcon(item.type)} alt="file-icon" />
                    <div className="list-item-type">{getTypeLabel(item.type)}</div>
                  </div>
                  <div className="list-item-main">
                    <div className="list-item-title">{item.name}</div>
                    <div className="list-item-date">
                      {`${moment(item.date).format('MMM Do YY')} | ${moment(item.date).format('HH:mm')}${getUserName(item.userId)}`}
                    </div>
                  </div>
                  {item.status && (
                    <div className={`status-indicator ${item.status}`}>{item.status}</div>
                  )}
                </div>
                {item.type === fileTypes.preop && (role === roleNames.GLOBAL_ADMIN || role === roleNames.COUNTRY_ADMIN) && (
                  <div className="m-r-lg">
                    <input
                      className={classes.input}
                      id="icon-button-file"
                      type="file"
                      onChange={handleUpload}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton color="secondary" aria-label="upload pdf" component="span">
                        <UploadIcon />
                      </IconButton>
                    </label>
                  </div>
                )}
                {downloadEnabled(item) && (
                  <a href={item.downloadLink} target="_blank" rel="noopener noreferrer" download>
                    <IconButton edge="start" color="secondary" aria-label="menu">
                      <DownloadIcon />
                    </IconButton>
                  </a>
                )}
                {(role === userRoles.GLOBAL_ADMIN.name || role === userRoles.COUNTRY_ADMIN.name) && (
                  <IconButton edge="start" color="secondary" aria-label="delete" onClick={() => onDeleteClick(item)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="empty-list m-t-lg">
            <img src={xRayIcon} alt="scans-icon" />
            No Files
          </div>
        )}
      </div>

      <div className='p-lg'>
          <div className='m-b-lg d-flex' style={{ width: '50%' }}>
            <span className='font-bold m-r-lg'>File type:</span>
            <Select
              id="file-type-select"
              className="file-type-select"
              onChange={(e) => setFileType(e.target.value)}
              placeholder="File type"
              style={{ flex: 1 }}
            >
              {Object.values(fileTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {getTypeLabel(type)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Dropzone
            files={[]}
            onUpload={onUpload}
            icon="x-ray"
            loading={uploading}
            multiple={false}
            disabled={!fileType}
          />
        </div>

      <div className='pointer m-t-lg m-l-md p-md d-flex primary p-b-lg' onClick={onBackClick}
           style={{ width: 'fit-content' }}>
        <BackIcon />
        <span className='m-l-md'>Back to the case process</span>
      </div>

      <AlertModal
        open={deleteModalOpen}
        onClose={() => {
          toggleDeleteModal(false);
          selectedFile(null);
        }}
        onSubmit={onDelete}
        title="Delete File"
        text="You are about to delete this file. This process cannot be undone. Are you sure?"
        closeText="Cancel"
        submitText="Delete"
      />
    </div>
  );
};

CaseFiles.propTypes = {
  activeCase: PropTypes.object,
  role: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    role: state.user.currentUser.role,
    userId: state.user.currentUser.uid,
    users: state.users?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (activeCase, file) => dispatch(changePreopFile(activeCase, file)),
    onBackClick: () => dispatch(setActiveTab(0)),
    updateCaseFiles: (currCase, updateCaseFiles) => dispatch(simpleUpdateCase(currCase, updateCaseFiles)),
    setActiveCase: (activeCase) => dispatch(setActiveCase(activeCase)),
    deleteCaseFile: (activeCase, fileId) => dispatch(deleteCaseFile(activeCase, fileId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseFiles);
