import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import WarningIcon from '@material-ui/icons/Warning';
import { utils } from '../../../../../../util';

const DfoSummary = (props) => {
  const {
    classes,
    type,
    flow,
    HKA,
    initialTibialWidth,
    DCA,
    tibialWidth,
    RCA,
    initialPTS,
    PTS,
    WH,
    WL,
    wedgePosterior,
    wedgeAnterior,
    wedgeIncluding,
    measurementsRejected,
    notes,
  } = props;

  return (
    <Fragment>
      <div className="modal-item-block margin-0">
        {measurementsRejected && (
          <div className="alert warning m-b-lg">
            <div className="d-flex align-center">
              <WarningIcon color="primary" />
              <div>Measurements have been rejected by engineer</div>
            </div>
            {notes && <div className="m-t-md">Note: {notes}</div>}
          </div>
        )}
        <div className="d-flex align-start">
          <div className="flex-1 m-r-md">
            <div className="block-header">Preoperative</div>
            <div className="summary-item">
              <div>
                HKA <span className="secondary">(°)</span> '
                {utils.getPreoperativeHkaLabel(flow, type)}'
              </div>
              <div className="font-bold">{HKA}</div>
            </div>
            <div className="summary-item">
              <div>
                WBL <span className="secondary">(%)</span>
              </div>
              <div className="font-bold">{initialTibialWidth}</div>
            </div>
          </div>
          <div className="flex-1 m-l-md">
            <div className="block-header">Postoperative</div>
            <div className="summary-item">
              <div>
                HKA <span className="secondary">(°)</span> '
                {utils.getPostoperativeHkaLabel(flow, type)}'
              </div>
              <div className="font-bold">{DCA}</div>
            </div>
            <div className="summary-item">
              <div>
                WBL Intersection <span className="secondary">(%)</span>
              </div>
              <div className="font-bold">{tibialWidth}</div>
            </div>
            <div className="summary-item">
              <div>
                Correction Angle <span className="secondary">(°)</span>
              </div>
              <div className="font-bold">{RCA}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-item-block m-b-md">
        <div className="summary-title">Posterior Distal Femoral Angle</div>
        <div className="d-flex">
          <div className="flex-1 m-r-md">
            <div className="summary-item">
              <div>
                PDFA <span className="secondary">(°)</span>
              </div>
              <div className="font-bold">{initialPTS}</div>
            </div>
          </div>
          <div className="flex-1 m-l-md">
            <div className="summary-item">
              <div>
                PDFA <span className="secondary">(°)</span>
              </div>
              <div className="font-bold">{PTS}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-item-block m-b-md">
        <div className="summary-title">
          Wedge Measurements
          {wedgeIncluding &&
            (wedgeIncluding === 'Include' ? (
              <div className="status-indicator">
                <span className={classes.pending}>Include</span>
              </div>
            ) : (
              <div className="status-indicator rejected">Remove</div>
            ))}
        </div>
        <div className="summary-item">
          <div>
            Wedge Height <span className="secondary">(mm)</span>
          </div>
          <div className="font-bold">{WH}</div>
        </div>
        {type === 'Varus' && (
          <Fragment>
            <div className="summary-item">
              <div>
                Allograft DFO Wedge Posterior <span className="secondary">(mm)</span>
              </div>
              <div className="font-bold">
                {wedgePosterior === 'custom' ? 'Custom graft required' : wedgePosterior}
              </div>
            </div>
            <div className="summary-item">
              <div>
                Allograft DFO Wedge Anterior <span className="secondary">(mm)</span>
              </div>
              <div className="font-bold">
                {wedgeAnterior === 'custom' ? 'Custom graft required' : wedgeAnterior}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

DfoSummary.propTypes = {
  type: PropTypes.oneOf(['Varus', 'Valgus']),
  HKA: PropTypes.number,
  initialTibialWidth: PropTypes.number,
  DCA: PropTypes.number,
  tibialWidth: PropTypes.number,
  RCA: PropTypes.number,
  initialPTS: PropTypes.number,
  PTS: PropTypes.number,
  WH: PropTypes.number,
  wedgePosterior: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wedgeAnterior: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wedgeIncluding: PropTypes.oneOf(['Include', 'Remove']),
  measurementsRejected: PropTypes.bool,
  notes: PropTypes.string,
};

export default memo(DfoSummary);
