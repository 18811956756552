import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';

import logo from '../../assets/ps_icon_logo.svg';
import dashboardIcon from '../../assets/dashboard_icon.svg';
import dashboardIconActive from '../../assets/dashboard_icon_active.svg';
import casesIcon from '../../assets/cases_icon.svg';
import casesIconActive from '../../assets/cases_icon_active.svg';
import activityIcon from '../../assets/activity_icon.svg';
import activityIconActive from '../../assets/activity_icon_active.svg';
import calendarIcon from '../../assets/calendar_icon.svg';
import calendarIconActive from '../../assets/calendar_icon_active.svg';
import trainingIcon from '../../assets/training_icon.svg';
import trainingIconActive from '../../assets/training_icon_active.svg';
import newCaseIcon from '../../assets/add_icon.svg';
import adminIcon from '../../assets/admin_icon.svg';
import signOutIcon from '../../assets/sign_out.svg';
import documentsIcon from '../../assets/preop_icon.svg';

import RestrictComponent from '../shared/RestrictComponent';

import AdminModal from '../modals/admin';
import CaseModal from '../modals/case';
import TrainingModeModal from '../modals/TrainingModeModal';
import DocumentsModal from '../modals/documents-modal';
import CaseExportModal from '../modals/case/CaseExportModal';

import { withFirebase } from '../../firebase';

import { discardActiveCase, setActiveCase } from '../../actions/casesActions';
import {
  closeSidebar,
  turnTrainingModeOff,
  turnTrainingModeOn,
} from '../../actions/sidebarActions';

import { utils } from '../../util';

import { caseConstants, userRoles, routes } from '../../constants';

import './sidebar.scss';
import { procedureFlows } from '../../constants/cases';

const links = [
  {
    id: 0,
    label: 'Dashboard',
    path: '/dashboard',
    icon: dashboardIcon,
    activeIcon: dashboardIconActive,
  },
  {
    id: 1,
    label: 'Cases',
    path: '/cases',
    icon: casesIcon,
    activeIcon: casesIconActive,
    badge: 'requiredCases',
  },
  {
    id: 2,
    label: 'Activity',
    path: '/activity',
    icon: activityIcon,
    activeIcon: activityIconActive,
    badge: 'newActivities',
  },
  {
    id: 3,
    label: 'Calendar',
    path: '/calendar',
    icon: calendarIcon,
    activeIcon: calendarIconActive,
  },
];

const {
  GLOBAL_ADMIN,
  COUNTRY_ADMIN,
  DISTRIBUTOR_ADMIN,
  SURGEON,
  ENGINEER,
  SALES_REP,
  LOGISTICS,
  PRACTICE_MANAGER,
  MANUFACTURER,
  RADIOLOGY,
} = userRoles;

const Sidebar = (props) => {
  const {
    currentUser,
    firebase,
    discardActiveCase,
    closeSidebar,
    trainingMode,
    turnOff,
    turnOn,
    caseId,
    collapsed,
    setCollapsed,
    isMobile,
    cases = [],
    setActiveCase,
  } = props;

  const [adminModalIsOpen, setAdminModalState] = useState(false);
  const [caseModalIsOpen, setCaseModalState] = useState(false);
  const [trainingModalIsOpen, setTrainingModalState] = useState(false);
  const [trainingConfirmModalOpen, setTrainingConfirmModalState] = useState(false);
  const [documentsModalIsOpen, setDocumentsModalState] = useState(false);
  const [isCaseExportModalOpen, toggleCaseExportModal] = useState(false);

  const signOut = () => {
    firebase.doSignOut().then(() => {
      window.location = '/';
    });
  };

  const handleModalClose = (isFirstStep, selectedFlow) => {
    setCaseModalState(false);

    if (isFirstStep && typeof isFirstStep === "boolean") {
      if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(selectedFlow)) {
        setCaseModalState(true);
      } else {
        toggleCaseExportModal(true);
      }
    } else {
      discardActiveCase();
    }
  };

  const toggleTrainingMode = () => {
    if (trainingMode) {
      turnOff();
    } else {
      setTrainingModalState(true);
    }
  };

  const handleSubmit = () => {
    setTrainingModalState(false);
    turnOn();
  };

  const handleNewCaseClick = () => {
    if (trainingMode) {
      setTrainingConfirmModalState(true);
    } else {
      setCaseModalState(true);
    }
  };

  const displayUserRole = (role) => userRoles[role] && userRoles[role].label;

  const path = window.location.pathname;
  const dashboard = links[0];

  const onSkipClick = (id) => {
    toggleCaseExportModal(false);
    const item = cases?.find((c) => c.id === id);
    if (item) {
      setActiveCase(item);
      setCaseModalState(true);
    }
  };

  return (
    <div className="sidebar">
      {!isMobile && (
        <div className="sidebar-toggle" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ArrowRight /> : <ArrowLeft />}
        </div>
      )}
      {collapsed ? (
        <>
          <div className="sidebar-main sidebar-main__collapsed">
            <LinkContainer to="/">
              <img src={logo} alt="logo" className="sidebar-logo__minimized" />
            </LinkContainer>
            {currentUser.role && currentUser.emailVerified ? (
              <List>
                {currentUser.role === RADIOLOGY.name ? (
                  <LinkContainer to={dashboard.path} key={dashboard.id} onClick={closeSidebar} style={{ paddingRight: 0 }}>
                    <ListItem className={dashboard.path === path && 'active-item'}>
                      <ListItemIcon style={{ minWidth: 32 }}>
                        {dashboard.path === path ? (
                          <img
                            src={dashboard.activeIcon}
                            alt={`icon-${dashboard.id}-active`}
                            className="sidebar-item-icon"
                          />
                        ) : (
                          <img src={dashboard.icon} alt={`icon-${dashboard.id}`} className="sidebar-item-icon" />
                        )}
                      </ListItemIcon>
                    </ListItem>
                  </LinkContainer>
                ) : (
                  links.map((item) => (
                    <LinkContainer to={item.path} key={item.id} onClick={closeSidebar} style={{ paddingRight: 0 }}>
                      <ListItem className={item.path === path && 'active-item'}>
                        <ListItemIcon style={{ minWidth: 32 }}>
                          {item.path === path ? (
                            <img
                              src={item.activeIcon}
                              alt={`icon-${item.id}-active`}
                              className="sidebar-item-icon"
                            />
                          ) : (
                            <img src={item.icon} alt={`icon-${item.id}`} className="sidebar-item-icon" />
                          )}
                        </ListItemIcon>
                        {item.badge && props[item.badge] > 0 && (
                          <div className="sidebar-badge">{props[item.badge]}</div>
                        )}
                      </ListItem>
                    </LinkContainer>
                  ))
                )}
                {currentUser.role !== RADIOLOGY.name && (
                  <ListItem
                    onClick={toggleTrainingMode}
                    className={trainingMode ? 'active-training-item' : null}
                  >
                    <ListItemIcon style={{ minWidth: 32 }}>
                      <img
                        src={trainingMode ? trainingIconActive : trainingIcon}
                        alt="new-case-icon"
                        className="sidebar-item-icon"
                      />
                    </ListItemIcon>
                    {trainingMode && <div className="sidebar-training-badge">active</div>}
                  </ListItem>
                )}
                <ListItem onClick={() => setDocumentsModalState(true)}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <img
                      src={documentsIcon}
                      alt="documents-case-icon"
                      className="sidebar-item-icon"
                    />
                  </ListItemIcon>
                </ListItem>
                <RestrictComponent roles={[GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN]}>
                  <ListItem onClick={() => setAdminModalState(true)}>
                    <ListItemIcon style={{ minWidth: 32 }}>
                      <img src={adminIcon} alt="admin-icon" className="sidebar-item-icon" />
                    </ListItemIcon>
                  </ListItem>
                </RestrictComponent>
                <RestrictComponent roles={[GLOBAL_ADMIN]}>
                  <LinkContainer to={routes.USERS}>
                    <ListItem className={routes.USERS === path && 'active-item'}>
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <PeopleAltIcon className="sidebar-item-icon" fontSize="large" style={{ width: '30px', fill: '#a7a7a7' }} />
                      </ListItemIcon>
                    </ListItem>
                  </LinkContainer>
                </RestrictComponent>
                {trainingMode ? (
                  <RestrictComponent
                    roles={[
                      GLOBAL_ADMIN,
                      COUNTRY_ADMIN,
                      DISTRIBUTOR_ADMIN,
                      SURGEON,
                      ENGINEER,
                      SALES_REP,
                      LOGISTICS,
                      PRACTICE_MANAGER,
                      MANUFACTURER,
                    ]}
                  >
                    <ListItem onClick={handleNewCaseClick}>
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <img src={newCaseIcon} alt="new-case-icon" className="sidebar-item-icon" />
                      </ListItemIcon>
                    </ListItem>
                  </RestrictComponent>
                ) : (
                  <RestrictComponent excludeRoles={[MANUFACTURER, LOGISTICS, RADIOLOGY]}>
                    <ListItem onClick={handleNewCaseClick}>
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <img src={newCaseIcon} alt="new-case-icon" className="sidebar-item-icon" />
                      </ListItemIcon>
                    </ListItem>
                  </RestrictComponent>
                )}
              </List>
            ) : (
              <List>
                <ListItem className={dashboard.path === path && 'active-item'}>
                  <ListItemIcon>
                    <img src={dashboard.icon} alt="icon-dashboard" className="sidebar-item-icon" />
                  </ListItemIcon>
                  <ListItemText primary={dashboard.label} />
                </ListItem>
              </List>
            )}
          </div>
          <div className="user-info">
            <div className="user-name-container">
              <div className="secondary m-t-md">V3.1.0</div>
            </div>
            <div className="sign-out-container" onClick={signOut}>
              <img src={signOutIcon} alt="sign-out-icon" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`sidebar-main${isMobile ? ' sidebar-mobile' : ''}`}>
            <LinkContainer to="/">
              <img src={logo} alt="logo" className="sidebar-logo" />
            </LinkContainer>
            {currentUser.role && currentUser.emailVerified ? (
              <List>
                {currentUser.role === RADIOLOGY.name ? (
                  <LinkContainer to={dashboard.path} key={dashboard.id} onClick={closeSidebar}>
                    <ListItem className={dashboard.path === path && 'active-item'}>
                      <ListItemIcon>
                        {dashboard.path === path ? (
                          <img
                            src={dashboard.activeIcon}
                            alt={`icon-${dashboard.id}-active`}
                            className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`}
                          />
                        ) : (
                          <img src={dashboard.icon} alt={`icon-${dashboard.id}`} className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={dashboard.label} />
                      {dashboard.badge && props[dashboard.badge] > 0 && (
                        <div className="sidebar-badge">{props[dashboard.badge]}</div>
                      )}
                    </ListItem>
                  </LinkContainer>
                ) : (
                  links.map((item) => (
                    <LinkContainer to={item.path} key={item.id} onClick={closeSidebar}>
                      <ListItem className={item.path === path && 'active-item'}>
                        <ListItemIcon>
                          {item.path === path ? (
                            <img
                              src={item.activeIcon}
                              alt={`icon-${item.id}-active`}
                              className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`}
                            />
                          ) : (
                            <img src={item.icon} alt={`icon-${item.id}`} className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`} />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                        {item.badge && props[item.badge] > 0 && (
                          <div className="sidebar-badge">{props[item.badge]}</div>
                        )}
                      </ListItem>
                    </LinkContainer>
                  ))
                )}
                {currentUser.role !== RADIOLOGY.name && (
                  <ListItem
                    onClick={toggleTrainingMode}
                    className={trainingMode ? 'active-training-item' : null}
                  >
                    <ListItemIcon>
                      <img
                        src={trainingMode ? trainingIconActive : trainingIcon}
                        alt="new-case-icon"
                        className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Training" />
                    {trainingMode && <div className="sidebar-training-badge">active</div>}
                  </ListItem>
                )}
                <ListItem onClick={() => setDocumentsModalState(true)}>
                  <ListItemIcon>
                    <img
                      src={documentsIcon}
                      alt="documents-case-icon"
                      className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Documents" />
                </ListItem>
                <RestrictComponent roles={[GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN]}>
                  <ListItem onClick={() => setAdminModalState(true)}>
                    <ListItemIcon>
                      <img src={adminIcon} alt="admin-icon" className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`} />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                  </ListItem>
                </RestrictComponent>
                <RestrictComponent roles={[GLOBAL_ADMIN]}>
                  <LinkContainer to={routes.USERS} onClick={closeSidebar}>
                    <ListItem className={routes.USERS === path && 'active-item'}>
                      <ListItemIcon>
                        <PeopleAltIcon className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`} fontSize="large" style={{ width: isMobile ? '22px' : '30px', fill: '#a7a7a7' }} />
                      </ListItemIcon>
                      <ListItemText primary="Users" />
                    </ListItem>
                  </LinkContainer>
                </RestrictComponent>
                {trainingMode ? (
                  <RestrictComponent
                    roles={[
                      GLOBAL_ADMIN,
                      COUNTRY_ADMIN,
                      DISTRIBUTOR_ADMIN,
                      SURGEON,
                      ENGINEER,
                      SALES_REP,
                      LOGISTICS,
                      PRACTICE_MANAGER,
                      MANUFACTURER,
                    ]}
                  >
                    <ListItem onClick={handleNewCaseClick}>
                      <ListItemIcon>
                        <img src={newCaseIcon} alt="new-case-icon" className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`} />
                      </ListItemIcon>
                      <ListItemText primary="New Case" />
                    </ListItem>
                  </RestrictComponent>
                ) : (
                  <RestrictComponent excludeRoles={[MANUFACTURER, LOGISTICS, RADIOLOGY]}>
                    <ListItem onClick={handleNewCaseClick}>
                      <ListItemIcon>
                        <img src={newCaseIcon} alt="new-case-icon" className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`} />
                      </ListItemIcon>
                      <ListItemText primary="New Case" />
                    </ListItem>
                  </RestrictComponent>
                )}
              </List>
            ) : (
              <List>
                <ListItem className={dashboard.path === path && 'active-item'}>
                  <ListItemIcon>
                    <img src={dashboard.icon} alt="icon-dashboard" className={`sidebar-item-icon${isMobile ? ' icon-mobile' : ''}`} />
                  </ListItemIcon>
                  <ListItemText primary={dashboard.label} />
                </ListItem>
              </List>
            )}
          </div>
          <div className="user-info">
            <div className="user-name-container">
              <div className="user-name">
                {currentUser.role === 'Surgeon' && 'Dr. '} {currentUser.firstName}{' '}
                {currentUser.lastName}
              </div>
              <div className="user-role">{displayUserRole(currentUser.role)}</div>
              <div className="secondary m-t-md">V3.1.0</div>
            </div>
            <div className="sign-out-container" onClick={signOut}>
              <img src={signOutIcon} alt="sign-out-icon" />
            </div>
          </div>
        </>
      )}
      <RestrictComponent roles={[GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN]}>
        <AdminModal open={adminModalIsOpen} onClose={() => setAdminModalState(false)} />
      </RestrictComponent>
      <CaseModal open={caseModalIsOpen} onClose={handleModalClose} />
      <DocumentsModal open={documentsModalIsOpen} onClose={() => setDocumentsModalState(false)} />
      <TrainingModeModal
        open={trainingModalIsOpen}
        onClose={() => setTrainingModalState(false)}
        onSubmit={handleSubmit}
        title="Training Mode"
        text="Use training mode to step through test cases and better understand features & functionality of the app."
        additionalText="In training mode, only test cases will be visible. You'll need to deactivate this function to view real cases."
        submitText="Activate"
      />
      <TrainingModeModal
        open={trainingConfirmModalOpen}
        onClose={() => setTrainingConfirmModalState(false)}
        onSubmit={() => {
          setTrainingConfirmModalState(false);
          setCaseModalState(true);
        }}
        title="Training Mode Active"
        text="You are about to create a case for training purposes only. Please cancel and deactivate training if you wish to create an actual case."
        submitText="Proceed"
      />
      <CaseExportModal
        open={isCaseExportModalOpen}
        onClose={() => {
          toggleCaseExportModal(false);
          discardActiveCase();
        }}
        caseId={caseId}
        onSkipClick={onSkipClick}
      />
    </div>
  );
};

Sidebar.propTypes = {
  currentUser: PropTypes.object,
  requiredCases: PropTypes.number,
  newActivities: PropTypes.number,
  discardActiveCase: PropTypes.func,
  closeSidebar: PropTypes.func,
};

const mapStateToProps = (state) => {
  const cases = state.cases.list.filter(
    (item) => item.access && item.step <= caseConstants.numberOfSteps
  );
  const trainingMode = state.sidebar.trainingMode;

  const requiredCases = cases.filter((item) => !!item.trainingMode === trainingMode).length;

  return {
    currentUser: state.user.currentUser,
    requiredCases,
    newActivities: state.activity.list.filter((item) => utils.checkTimeDiff(item.date) < 24).length,
    trainingMode,
    caseId: state.cases?.activeCase?.id,
    cases: state?.cases?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    discardActiveCase: () => dispatch(discardActiveCase()),
    closeSidebar: () => dispatch(closeSidebar()),
    turnOff: () => dispatch(turnTrainingModeOff()),
    turnOn: () => dispatch(turnTrainingModeOn()),
    setActiveCase: (caseItem) => dispatch(setActiveCase(caseItem))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(withRouter(Sidebar)));
