const userRoles = {
  GLOBAL_ADMIN: { name: 'GLOBAL_ADMIN', label: 'Global Admin', field: 'globalAdmin' },
  COUNTRY_ADMIN: { name: 'COUNTRY_ADMIN', label: 'Country Admin', field: 'countryAdmin' },
  DISTRIBUTOR_ADMIN: {
    name: 'DISTRIBUTOR_ADMIN',
    label: 'Distributor Admin',
    field: 'distributorAdmin',
  },
  SURGEON: { name: 'SURGEON', label: 'Surgeon', field: 'surgeon' },
  ENGINEER: { name: 'ENGINEER', label: 'Engineer', field: 'engineer' },
  SALES_REP: { name: 'SALES_REP', label: 'Sales Rep', field: 'salesRep' },
  LOGISTICS: { name: 'LOGISTICS', label: 'Logistics', field: 'logistics' },
  PRACTICE_MANAGER: { name: 'PRACTICE_MANAGER', label: 'Practice Manager', field: 'manager' },
  MANUFACTURER: { name: 'MANUFACTURER', label: 'Manufacturer', field: 'manufacturer' },
  RADIOLOGY: { name: 'RADIOLOGY', label: 'Radiology', field: 'radiology' },
  REGISTRAR: { name: 'REGISTRAR', label: 'Registrar', field: 'registrar' }
};
const roleNames = {};

Object.keys(userRoles).forEach((key) => (roleNames[key] = userRoles[key].name));

export { roleNames };
export default userRoles;
