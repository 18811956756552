import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';
import Button from '../../../../../shared/Button';

import { updateCase } from '../../../../../../actions/casesActions';

import calendarIcon from '../../../../../../assets/calendar_icon.svg';

import { caseConstants } from '../../../../../../constants';

const OsteotomyStep8 = ({ onClose, activeCase, updateCase, userId }) => {
  const [date, setDate] = useState(moment());

  const handleSubmit = () => {
    if (!date) {
      return;
    }

    const now = moment().format();
    const activity = [
      { ...caseConstants.caseNotifications[8].success, date: now, userId },
      ...activeCase.activity,
    ];
    const dispatchInfo = {
      ...activeCase.dispatchInfo,
      blockManufacturingCompleted: date.format(),
    };

    updateCase({
      ...activeCase,
      step: 9,
      activity,
      completionDates: { ...activeCase.completionDates, step8: now },
      completedBy: { ...activeCase.completedBy, step8: userId },
      dispatchInfo,
      status: caseConstants.statuses.inProgress,
    }).then(() => onClose());
  };

  const blockDispatchDate = moment(activeCase.date).subtract(7, 'days');
  const difference = blockDispatchDate.diff(date, 'days');

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">8</div>
          </div>
          <div className="modal-item-content">
            <div>Manufacturing Complete</div>
            {activeCase.access ? (
              <div className="modal-item-block">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <div className="field-container">
                      <div className="secondary font-size-sm uppercase">
                        Block manufacturing completed
                      </div>
                      <div className="field-content">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            fullWidth
                            format="DD/MM/YYYY"
                            id="date-picker"
                            value={date}
                            onChange={(date) => setDate(date)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className="field-container m-t-lg">
                  <div className="secondary font-size-sm uppercase">Targeted surgery date</div>
                  <div className="d-flex align-end space-between flex-wrap">
                    <div className="d-flex m-b-sm m-r-sm">
                      <img src={calendarIcon} alt="calendar-icon" height={15} className="m-r-sm" />
                      {activeCase.date ? moment(activeCase.date).format('DD/MM/YYYY') : 'TBC'}
                    </div>
                    {difference > 0 && (
                      <div className="m-b-sm">
                        Block must be dispatched within{' '}
                        <span className="primary font-size-lg m-l-sm m-r-sm">{difference}</span>{' '}
                        day(s)
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={8} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access}
        />
      </div>
    </div>
  );
};

OsteotomyStep8.propTypes = {
  activeCase: PropTypes.object,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase) => dispatch(updateCase(newCase, 8)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep8);
