import React, { useState } from "react";

import Button from "../../shared/Button";
import { TextField } from '@material-ui/core';

const CaseExport = ({ onClose, onSubmit, loading }) => {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  const onPhoneChange = (e) => {
    const re = /^[\+\d]?(?:[\d-.\s()]*)$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  return (
    <div className="case-export-container">
      <div className='p-lg'>
        <div className="modal-item-block">
          <div className="block-header">Patient info</div>
          <div className="m-t-md secondary">
            Please add available patient information that will be added to this referral document
          </div>
          <div className="m-t-sm secondary">
            <span className="font-bold m-r-sm">NOTE:</span>
            <span>This data is not stored by the platform</span>
          </div>
          <div className="m-t-lg address-input">
            <div className="field-container">
              <div className="field-title">Patient address</div>
              <div className="field-content d-flex">
                <TextField
                  id="address"
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="m-t-lg phone-input">
            <div className="field-container">
              <div className="field-title">Patient phone number</div>
              <div className="field-content d-flex">
                <TextField
                  id="phone"
                  type="text"
                  value={phone}
                  onChange={onPhoneChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="case-export-actions">
        <div className="secondary pointer" onClick={onClose}>
          Back
        </div>
        <Button
          text="Generate"
          color="primary"
          onClick={() => onSubmit(address, phone)}
          disabled={!address || !phone || loading}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CaseExport;
