import {
  SET_CURRENT_USER,
  SIGN_OUT,
  LOAD_USER_DATA,
  UPDATE_USER_DATA,
} from '../actions/actionTypes';

const initialState = {
  isLoaded: false,
  loading: false,
  currentUser: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER_DATA:
      return { ...state, loading: true };
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.user, isLoaded: true, loading: false };
    case SIGN_OUT:
      return { ...state, currentUser: null };
    case UPDATE_USER_DATA:
      const updatedUser = { ...state.currentUser, ...action.user };

      return { ...state, currentUser: updatedUser, loading: false };
    default:
      return state;
  }
};

export default userReducer;
