import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const HospitalListItem = (props) => {
  const { id, name, address, state, postCode, onDelete } = props;

  const [deleteMode, toggleDeleteMode] = useState(false);

  return (
    <div className="admin-modal-item list-item-container">
      <div className="list-item-main">
        <div className="column">
          <div className="modal-item-circle" />
        </div>
        <div className="modal-item-header">
          <div className="d-flex">
            <div className="m-r-md">{name}</div>
          </div>
          {deleteMode ? (
            <div className="d-flex">
              <div className="danger pointer m-l-sm" onClick={() => onDelete(id)}>
                Delete
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleDeleteMode(false)}>
                Cancel
              </div>
            </div>
          ) : (
            <IconButton aria-label="delete" color="primary" onClick={() => toggleDeleteMode(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="d-flex m-b-md">
        <div className="column" />
        <div className="flex-1">
          <div className="info-row">
            <div className="info-row-field">
              <div className="field-label">REGISTERED ADDRESS</div>
              <div className="field-value">{address || 'None'}</div>
            </div>
          </div>
          <div className="info-row">
            <div className="info-row-field">
              <div className="field-label">STATE</div>
              <div className="field-value">{state || 'None'}</div>
            </div>
            <div className="info-row-field">
              <div className="field-label">POST CODE</div>
              <div className="field-value">{postCode || 'None'}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HospitalListItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  state: PropTypes.string,
  postCode: PropTypes.string,
  onDelete: PropTypes.func,
};

export default HospitalListItem;
