import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dropzone from '../../../shared/dropzone/Dropzone';

const DocumentsUpload = ({ onUploadComplete, onDelete }) => {
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleUpload = (_files) => {
    const uploads = { ...uploadedFiles };

    _files.forEach((file) => (uploads[file.name] = false));
    setUploadedFiles(uploads);
    setFiles([...files, ..._files.filter((file) => !files.map((f) => f.name).includes(file.name))]);
  };

  const handleRemove = (name) => {
    const uploads = { ...uploadedFiles };

    delete uploads[name];
    setUploadedFiles(uploads);
    setFiles(files.filter((file) => file.name !== name));

    onDelete(name);
  };

  const handleUploadComplete = async (name, url) => {
    setUploadedFiles((prevState) => ({ ...prevState, [name]: true }));

    const document = {
      name,
      url,
    };
    onUploadComplete(document);
  };

  return (
    <div className="p-md">
      <Dropzone
        files={files}
        onUpload={handleUpload}
        onRemove={handleRemove}
        icon="x-ray"
        withProgress
        path={`documents/`}
        onUploadComplete={handleUploadComplete}
      />
    </div>
  );
};

DocumentsUpload.propTypes = {
  documents: PropTypes.array,
  onDelete: PropTypes.func,
};

export default DocumentsUpload;
