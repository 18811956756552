import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

const values = [
  { key: 'D_CA', label: 'D_CA' },
  { key: 'Tibial_Width', label: 'Tibial Width' },
  { key: 'R_CA', label: 'R_CA' },
  { key: 'Post_TS', label: 'PTS' },
  { key: 'WH', label: 'Wedge Height' },
  { key: 'Wedge_posterior', label: 'Wedge Posterior' },
  { key: 'Wedge_anterior', label: 'Wedge Anterior' },
];

const OsteotomyStep4Static = ({ activeCase }) => {
  const { alignmentData } = activeCase;

  return (
    <div className="case-static-container">
      <Grid container spacing={1}>
        {values.map((value) => (
          <Grid key={value.key} item xs={6} sm={4} md={3}>
            <div className="d-flex">
              <div className="secondary">{value.label}:</div>
              <div className="m-l-sm">{alignmentData?.[value.key]}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

OsteotomyStep4Static.propTypes = {
  activeCase: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
  };
};

export default connect(mapStateToProps)(OsteotomyStep4Static);
