import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useDispatch } from 'react-redux';

import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

import { confirmPlanningMeeting } from '../../../../../../actions/casesActions';

const isWeekend = (date) => {
  const day = date.day();

  return day === 0 || day === 6;
};

export const CmfExtendedStep3 = (props) => {
  const {
    onClose,
    activeCase,
    updateCase,
    planReport,
    planReportRejected,
    fileStatuses,
    fileTypes,
    statuses,
    caseNotifications,
    userId,
  } = props;

  const dispatch = useDispatch();

  const {
    planning: {
      requestPlanning,
      requestPlanningDate,
      additionalEmails,
      planningConfirmed,
      meetingLink,
      planApproveRequired,
    } = {},
  } = activeCase;

  const [preop, setPreop] = useState([]);
  const [link, setLink] = useState(meetingLink || '');
  const [loading, setLoading] = useState(false);
  const [approveRequired, setApproveRequired] = useState(!!planApproveRequired || true);
  const [date, setDate] = useState(requestPlanningDate ? moment(requestPlanningDate) : moment());

  const handleRemovePreop = (name) => {
    setPreop(preop.filter((file) => file.name !== name));
  };

  const handleSubmit = () => {
    const now = moment().format();

    const activity = [
      { ...caseNotifications[3].success, date: now, userId },
      ...activeCase.activity,
    ];

    const files = [...activeCase.files];
    const newFiles = [];
    const preopFile = preop[0];

    if (preopFile) {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.planReport}_${preopFile.name}`,
        name: preopFile.name,
        type: fileTypes.planReport,
        status: fileStatuses.PENDING,
        date: now,
        userId,
      });
      newFiles.push(preopFile);
    }

    updateCase(
      {
        ...activeCase,
        planning: {
          ...activeCase?.planning,
          planApproveRequired: !!approveRequired
        },
        step: approveRequired ? 4 : 5,
        activity,
        files,
        completionDates: { ...activeCase.completionDates, step3: now },
        completedBy: { ...activeCase.completedBy, step3: userId },
        status: statuses.inProgress,
        notes: {
          ...activeCase.notes,
        },
      },
      newFiles
    ).then(() => onClose());
  };

  const confirmPlanning = async () => {
    setLoading(true);
    const planning = {
      requestPlanning,
      requestPlanningDate: date ? date.format() : requestPlanningDate,
      additionalEmails: additionalEmails || '',
      meetingLink: link || '',
      planningConfirmed: true
    };

    await dispatch(confirmPlanningMeeting(activeCase, planning));

    setLoading(false);
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">3</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Planning</div>
            {activeCase.access ? (
              <div>
                <div>
                  {requestPlanning && (
                    <>
                      <div className='d-flex'>
                        <div className='w-50'>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDateTimePicker
                              disabled={planningConfirmed}
                              shouldDisableDate={isWeekend}
                              variant="inline"
                              disablePast={!planningConfirmed}
                              format="DD/MM/YYYY  hh:mm a"
                              fullWidth
                              id="date-picker"
                              value={planningConfirmed ? requestPlanningDate : date}
                              onChange={setDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              ampm={false}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        {planningConfirmed ? (
                          <div className={`m-l-lg status-indicator approved`}>Confirmed</div>
                        ) : (
                          <div className={`m-l-lg status-indicator rejected`}>Requested</div>
                        )}
                      </div>
                      {!!additionalEmails?.length && (
                        <div className='m-t-lg'>
                          <div className="secondary font-size-sm m-b-sm">ADDITIONAL ATTENDEES</div>
                          {additionalEmails}
                        </div>
                      )}
                    </>
                  )}
                  <div className='m-t-lg'>
                    <div className="secondary font-size-sm m-b-sm">MEETING LINK</div>
                    <div className='d-flex w-100'>
                      <TextField
                        id="meeting_link"
                        type="text"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className='m-t-lg d-flex flex-end'>
                    {!planningConfirmed && (
                      <Button
                        text="Confirm Meeting"
                        color="primary"
                        onClick={confirmPlanning}
                        disabled={!link?.length || loading}
                        loading={loading}
                      />
                    )}
                    {planningConfirmed && !!link && !!meetingLink && (
                      link !== meetingLink ? (
                        <Button
                          text="Save Changes"
                          color="primary"
                          onClick={confirmPlanning}
                          disabled={!link?.length || loading}
                          loading={loading}
                        />
                      ) : (
                        <Button
                          onClick={() => window.open(link, "_blank")}
                          text="Join Meeting"
                          color="primary"
                        />
                      )
                    )}
                  </div>
                </div>

                <div className="m-t-lg">
                  <div className="modal-item-block m-b-lg">
                    <div className='d-flex space-between m-b-md'>
                      <div className='secondary uppercase font-bold font-size-sm'>Surgeon approval required</div>
                      <div className="radio-group-container">
                        <RadioGroup aria-label="approveRequired" name="approveRequired" value={approveRequired} onChange={(e) => setApproveRequired(e.target.value === 'true')}>
                          <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                          <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                        </RadioGroup>
                      </div>
                    </div>
                    {approveRequired && (
                      <>
                        <div className="block-header">UPLOAD PLAN REPORT (.pdf)</div>
                        {planReportRejected && (
                          <div className="alert warning m-b-md">
                            <div className="d-flex align-center">
                              <WarningIcon color="primary" />
                              <div>Plan report has been rejected</div>
                            </div>
                            {activeCase.notes && activeCase.notes.step4 && (
                              <div className="m-t-md">Note: {activeCase.notes.step4}</div>
                            )}
                          </div>
                        )}
                        <Dropzone
                          files={preop}
                          onUpload={setPreop}
                          type="pdf"
                          icon="preop"
                          multiple={false}
                          onRemove={handleRemovePreop}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={3} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access || (!planReport && approveRequired && !preop.length)}
        />
      </div>
    </div>
  );
};
