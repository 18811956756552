import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import userRoles from '../../../../constants/userRoles';

const { ENGINEER, MANUFACTURER, PRACTICE_MANAGER, SALES_REP, LOGISTICS, SURGEON, REGISTRAR, GLOBAL_ADMIN } = userRoles;

const NewSurgeon = ({ userList, surgeons, distributor, distributors = [], onCancel, onSubmit, country, currentUser }) => {
  const [surgeon, setSurgeon] = useState(null);
  const [engineer, setEngineer] = useState([]);
  const [manufacturer, setManufacturer] = useState(null);
  const [manager, setManager] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [logistic, setLogistic] = useState(null);
  const [registrar, setRegistrar] = useState([]);

  const isSurgeonPresent = useCallback(
    (uid) =>
      !!surgeons.find((surgeon) => surgeon.userId === uid && surgeon.distributor === distributor),
    [surgeons, distributor]
  );

  const users = useMemo(
    () =>
      orderBy(
        userList.filter(
          (user) => !user.role || (user.role === SURGEON.name && !isSurgeonPresent(user.uid))
        ), 'lastName'
      ),
    [userList, isSurgeonPresent]
  );

  const engineers = useMemo(
    () => orderBy(userList.filter((user) => user.role === ENGINEER.name), 'lastName'),
    [userList]
  );

  const manufacturers = useMemo(
    () => orderBy(userList.filter((user) => user.role === MANUFACTURER.name), 'lastName'),
    [userList]
  );

  const managers = useMemo(
    () => orderBy(
      userList
        .filter((user) => (
          user.role === PRACTICE_MANAGER.name && (
            currentUser.role === GLOBAL_ADMIN ||
            currentUser.administrationCountry === user.country ||
            currentUser.distributor === user.distributor ||
            currentUser.administrationCountry === distributors?.find((d) => d.id === user.distributor)?.country
          )
        )),
      'lastName'
    ),
    [currentUser, userList, distributors]
  );

  const salesReps = useMemo(
    () => orderBy(
      userList
        .filter((user) => (
          user.role === SALES_REP.name && (
            currentUser.role === GLOBAL_ADMIN ||
            currentUser.administrationCountry === user.country ||
            currentUser.distributor === user.distributor ||
            currentUser.administrationCountry === distributors?.find((d) => d.id === user.distributor)?.country
          )
        )),
      'lastName'
    ),
    [currentUser, userList, distributors]
  );

  const logistics = useMemo(
    () => orderBy(userList.filter((user) => user.role === LOGISTICS.name), 'lastName'),
    [userList]
  );

  const registrars = useMemo(
    () => orderBy(
      userList
        .filter((user) => (
          user.role === REGISTRAR.name && (
            currentUser.role === GLOBAL_ADMIN ||
            currentUser.administrationCountry === user.country ||
            currentUser.distributor === user.distributor ||
            currentUser.administrationCountry === distributors?.find((d) => d.id === user.distributor)?.country
          )
        )),
      'lastName'
    ),
    [currentUser, userList, distributors]
  );

  const handleSubmit = () => {
    if (!surgeon || !engineer.length || !manufacturer || !manager || !salesRep) {
      return;
    }

    const newSurgeon = {
      id: uuidv4(),
      userId: surgeon.uid,
      engineer: engineer.map((item) => item.uid),
      manufacturer: manufacturer.uid,
      manager: manager.map((item) => item.uid),
      salesRep: salesRep.map((item) => item.uid),
      logistics: logistic ? logistic.uid : null,
      registrar: registrar.map((item) => item.uid),
    };

    onSubmit(newSurgeon);
  };

  return (
    <div>
      <div className="admin-modal-item new-item">
        <div className="column">
          <div className="modal-item-circle light" />
        </div>
        <div className="modal-item-content d-flex">
          <Select
            id="user-select"
            className="user-select"
            value={surgeon}
            onChange={(e) => setSurgeon(e.target.value)}
          >
            {users.map((item) => (
              <MenuItem key={item.uid} value={item}>
                {item.firstName} {item.lastName}
              </MenuItem>
            ))}
          </Select>
          <div className="p-l-md primary pointer" onClick={handleSubmit}>
            Add
          </div>
          <div className="p-l-md secondary pointer" onClick={onCancel}>
            Cancel
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="column" />
        <div className="flex-1">
          <div className="select-item-row">
            <div className="select-item">
              <div className="select-label">Assigned engineer</div>
              <Select
                id="engineer-select"
                className="user-select"
                MenuProps={{
                  getContentAnchorEl: null,
                }}
                renderValue={(selected) => {
                  if (!selected.length) return '';
                  return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                }}
                multiple
                fullWidth
                value={engineer}
                onChange={(e) => setEngineer(e.target.value)}
              >
                {engineers.map((item) => (
                  <MenuItem value={item}>
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="select-item">
              <div className="select-label">Assigned manufacturer</div>
              <Select
                id="user-select"
                className="user-select"
                fullWidth
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
              >
                {manufacturers.map((item) => (
                  <MenuItem value={item}>
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className="select-item-row">
            <div className="select-item">
              <div className="select-label">Assigned manager</div>
              <Select
                id="manager-select"
                className="user-select"
                MenuProps={{
                  getContentAnchorEl: null,
                }}
                renderValue={(selected) => {
                  if (!selected.length) return '';
                  return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                }}
                multiple
                fullWidth
                value={manager}
                onChange={(e) => setManager(e.target.value)}
              >
                {managers.map((item) => (
                  <MenuItem value={item}>
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="select-item">
              <div className="select-label">Assigned sales rep</div>
              <Select
                id="sales-select"
                className="user-select"
                MenuProps={{
                  getContentAnchorEl: null,
                }}
                renderValue={(selected) => {
                  if (!selected.length) return '';
                  return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                }}
                multiple
                fullWidth
                value={salesRep}
                onChange={(e) => setSalesRep(e.target.value)}
              >
                {salesReps.map((item) => (
                  <MenuItem value={item}>
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="select-item-row">
            <div className="select-item">
              <div className="select-label">Assigned logistics</div>
              <Select
                id="logistics-select"
                className="user-select"
                fullWidth
                value={logistic}
                onChange={(e) => setLogistic(e.target.value)}
              >
                {logistics.map((item) => (
                  <MenuItem value={item}>
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="select-item">
              <div className="select-label">Assigned registrar</div>
              <Select
                id="sales-select"
                className="user-select"
                MenuProps={{
                  getContentAnchorEl: null,
                }}
                renderValue={(selected) => {
                  if (!selected.length) return '';
                  return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                }}
                multiple
                fullWidth
                value={registrar}
                onChange={(e) => setRegistrar(e.target.value)}
              >
                {registrars.map((item) => (
                  <MenuItem value={item}>
                    {item.firstName} {item.lastName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewSurgeon.propTypes = {
  userList: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NewSurgeon;
