import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({ value, onChange, list, className, onDelete }) => {
  const classes = useStyles();

  return (
    <Select
      labelId="mutiple-chip-label"
      id="mutiple-chip"
      className={className}
      multiple
      value={value}
      onChange={(e) => onChange(e.target.value)}
      input={<Input id="select-multiple-chip" />}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {selected.map((value) => (
            <Chip
              key={value}
              label={value}
              className={classes.chip}
              onDelete={() => onDelete(value)}
            />
          ))}
        </div>
      )}
      MenuProps={MenuProps}
    >
      {list.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};

MultiSelect.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  list: PropTypes.array,
};

export default memo(MultiSelect);
