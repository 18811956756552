import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { CSVLink } from 'react-csv';

import { RadioGroup, FormControlLabel, Radio, TextField, Grid } from '@material-ui/core';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';
import Button from '../../../../../shared/Button';

import blockIcon from '../../../../../../assets/block_icon.svg';
import calendarIcon from '../../../../../../assets/calendar_icon.svg';

import { setActiveTab, updateCase } from '../../../../../../actions/casesActions';

import { caseConstants } from '../../../../../../constants';

const { fileStatuses, caseNotifications, statuses, procedureFlows } = caseConstants;

const exportHeaders = ['Patient ID:', 'Surgery Date:', 'Required Date:', 'Category:', 'SKU:', 'Description:', 'QTY:'];

const OsteotomyStep7 = ({ classes, onClose, activeCase, updateCase, goToFiles, blocks, userId }) => {
  const [files, setFiles] = useState(blocks);
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState(moment());
  const [exportData, setExportData] = useState([]);

  const preparedDataForExport = (data = []) => {
    const preparedData = [];

    const caseId = activeCase?.id;
    const date = moment(activeCase?.date).format('DD/MM/YYYY');
    const requiredDate = moment(activeCase?.requiredDate).format('DD/MM/YYYY');
    const patientInfo = [caseId, date, requiredDate];
    preparedData.push(patientInfo);

    data.forEach((item) => {
      const exportData = [];

      const product = item?.product.split(' — ');

      const category = item?.category ? item.category : '';
      const sku = product?.length ? product[0] : '';
      const description = product.length ? product[1] : '';
      const amount = item?.amount ? item.amount : '';

      exportData[3] = category;
      exportData[4] = sku;
      exportData[5] = description;
      exportData[6] = amount;

      preparedData.push(exportData);
    });

    return preparedData;
  };

  useEffect(() => {
    const preparedData = preparedDataForExport(activeCase?.manufacturing);
    setExportData(preparedData);
  }, [activeCase?.manufacturing]);

  const handleSubmit = () => {
    if (files.some((file) => file.status === fileStatuses.REJECTED) && !notes?.length) {
      return;
    }

    const now = moment().format();
    const activity = [...activeCase.activity];
    const caseFiles = [...activeCase.files];

    let rejected = false;

    if (files.some((file) => file.status === fileStatuses.REJECTED)) {
      activity.unshift({ ...caseNotifications[7].reject, date: now, userId });
      rejected = true;
    } else if (files.some((file) => file.status === fileStatuses.APPROVED)) {
      activity.unshift({ ...caseNotifications[7].success, date: now, userId });
    }

    files.forEach((file) => {
      const caseFile = caseFiles.find((item) => item.id === file.id);

      caseFile.status = file.status;
    });

    updateCase(
      {
        ...activeCase,
        files: caseFiles,
        step: rejected ? 5 : 8,
        activity,
        completionDates: { ...activeCase.completionDates, step7: now },
        completedBy: { ...activeCase.completedBy, step7: userId },
        notes: { ...activeCase.notes, step7: notes },
        dispatchInfo: { targetedDispatchDate: date.format() },
        status: rejected ? statuses.rejected : statuses.inProgress,
      },
      rejected
    ).then(() => onClose());
  };

  const handleChange = (id, status) => {
    const updatedFiles = files.slice();
    const changedFile = updatedFiles.find((item) => item.id === id);

    changedFile.status = status;
    setFiles(updatedFiles);
  };

  const rejected = files.some((file) => file.status === fileStatuses.REJECTED);
  const disabled = rejected && !notes?.length;

  return (
    <>
      <div className="case-step-container">
        <div className="case-step-content">
          <div className="case-step-row">
            <div className="column">
              <div className="modal-item-circle">7</div>
            </div>
            <div className="modal-item-content">
              <div>Manufacturing Approval</div>
              {activeCase.access ? (
                <Fragment>
                  {!!files?.length && (
                    <div className="modal-item-block">
                      <div className="block-header">Manufacturing files</div>
                      {files.map((file) => (
                        <div key={file.id} className="block-file-list">
                          <div className="d-flex pointer" onClick={goToFiles}>
                            <img src={blockIcon} alt="block-icon" />
                            {file.name}
                          </div>
                          <div className="radio-group-container">
                            <RadioGroup
                              aria-label="block-radio"
                              name="block-radio"
                              value={file.status}
                              onChange={(e) => handleChange(file.id, e.target.value)}
                            >
                              <FormControlLabel
                                value={fileStatuses.APPROVED}
                                control={<Radio color="primary" />}
                                label="Approve"
                              />
                              <FormControlLabel
                                value={fileStatuses.REJECTED}
                                control={<Radio color="primary" />}
                                label="Reject"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {rejected && (
                    <TextField
                      id="notes"
                      type="text"
                      fullWidth
                      multiline
                      label="Notes"
                      placeholder="Add reason for reject here"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  )}

                  {!!activeCase?.manufacturing?.length && (
                    <div className="manufacturing-list-container">
                      <div className="manufacturing-list-header">
                        <div className="secondary uppercase font-size-sm">PSI Manufacturing</div>
                        <CSVLink
                          data={exportData}
                          headers={exportHeaders}
                          filename={`${activeCase.patientLastName}.csv`}
                          target="_blank"
                        >
                          <IconButton color="secondary">
                            <DownloadIcon color="primary" />
                          </IconButton>
                        </CSVLink>
                      </div>
                      <div className="manufacturing-list p-b-md m-b-md">
                        {activeCase?.manufacturing.map((manufacturing, index) => (
                          <Grid key={`${index}-${manufacturing.product}`} className="d-flex p-t-md p-b-md underline">
                            <Grid item xs={3}>{`${manufacturing.category}`}</Grid>
                            <Grid item xs={8}>{`${manufacturing.product}`}</Grid>
                            <Grid item xs={1} className="amout">{`${manufacturing.amount}`}</Grid>
                          </Grid>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="m-t-lg d-flex align-start">
                    <div className="flex-1">
                      <div className="secondary font-size-sm m-b-sm">TARGETED SURGERY DATE</div>
                      <div className="d-flex">
                        <img src={calendarIcon} alt="calendar-icon" height={15} className="m-r-sm" />
                        {activeCase.date ? moment(activeCase.date).format('DD/MM/YYYY') : 'TBC'}
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="secondary font-size-sm">TARGETED DISPATCH DATE</div>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="DD/MM/YYYY"
                          id="date-picker"
                          fullWidth
                          value={date}
                          onChange={(date) => setDate(date)}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <NoAccess />
              )}
            </div>
          </div>
          <CaseAccordion currentStep={7} />
        </div>
        <div className="case-modal-actions">
          <div className="secondary pointer" onClick={onClose}>
            Close
          </div>
          <Button text="Submit" color="primary" onClick={handleSubmit} disabled={!activeCase.access || disabled} />
        </div>
      </div>
    </>
  );
};

OsteotomyStep7.propTypes = {
  activeCase: PropTypes.object,
  blocks: PropTypes.array,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const blocks = JSON.parse(JSON.stringify(state.cases.activeCase.files.filter((file) => file.type === 'block')));

  blocks.forEach((file) => {
    file.status = fileStatuses.APPROVED;
  });

  return {
    activeCase: state.cases.activeCase,
    blocks,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, rejected) => dispatch(updateCase(newCase, 7, null, rejected)),
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep7);
