import {
  ADD_PROCEDURE,
  DELETE_PROCEDURE,
  LOAD_PROCEDURES,
  SET_PROCEDURES,
  TOGGLE_PROCEDURE,
  UPDATE_PRODUCTS,
  UPDATE_DOCUMENTS,
  UPDATE_FLOW,
  UPDATE_LEAD_DAYS,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const proceduresReducer = (state = initialState, action) => {
  const procedures = state.list.slice();
  const procedure = action.id ? procedures.find((item) => item.id === action.id) : null;

  switch (action.type) {
    case LOAD_PROCEDURES:
      return { ...state, loading: true };
    case SET_PROCEDURES:
      return { ...state, loading: false, list: action.procedures };
    case ADD_PROCEDURE:
      const newProcedure = {
        id: action.id,
        name: action.name,
        products: [],
        active: [],
        flow: null,
      };

      return { ...state, list: [...procedures, newProcedure], loading: false };
    case DELETE_PROCEDURE:
      return { ...state, list: procedures.filter((item) => item.id !== action.id), loading: false };
    case TOGGLE_PROCEDURE:
      procedure.active = action.active;

      return { ...state, list: procedures };
    case UPDATE_PRODUCTS:
      procedure.products = action.products;

      return { ...state, list: procedures };
    case UPDATE_DOCUMENTS:
      procedure.documents = action.documents;

      return { ...state, list: procedures };
    case UPDATE_FLOW:
      procedure.flow = action.flow;

      return { ...state, list: procedures };
    case UPDATE_LEAD_DAYS:
      procedure.leadDays = action.leadDays;

      return { ...state, list: procedures };
    default:
      return state;
  }
};

export default proceduresReducer;
