import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles, List, ListItem, ListItemText } from '@material-ui/core';

import { setSelectedFlow } from '../../../../../actions/casesActions';
import { procedureFlows, selectedProcedureFlows } from '../../../../../constants/cases';

import cmf_image from '../../../../../assets/step0/PS_CMF_Image.png';
import cmf_extended_image from '../../../../../assets/step0/PS_CMF_Extended_Image.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,

    '& .block-header': {
      width: '90%',
      marginTop: 10,
      marginBottom: 25,
    },
  },
}));

const StyledList = withStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))(List);

const StyledListItem = withStyles((theme) => ({
  root: {
    width: '90%',
    borderRadius: 20,
    marginBottom: 30,
    '&:last-child': {
      margin: 0,
    },
  },
}))(ListItem);

const StyledListItemText = withStyles((theme) => ({
  primary: {
    fontSize: '1.8rem',
    fontWeight: 700,
    letterSpacing: 1.5,
  },
  secondary: {
    fontSize: '0.92rem',
  },
}))(ListItemText);

export const Step0CMF = ({ selected, onChange, procedures = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleProcedureFlowClick = (event, flow) => {
    onChange(flow);
    dispatch(setSelectedFlow(flow));
  };

  return (
    <div className={classes.root}>
      <div className="block-header">Select Procedure</div>
      <StyledList component="nav" aria-label="procedures">
        {procedures.some((procedure) => procedure.flow === procedureFlows.CMF) && (
          <StyledListItem
            style={{ border: '2px solid #33CC99' }}
            button
            selected={selected === selectedProcedureFlows.CMF}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.CMF)}
          >
            <StyledListItemText primary="ORTHOG" secondary="Craniomaxillofacial" />
            <img src={cmf_image} alt="CMF" width="100" height="100" />
          </StyledListItem>
        )}

        {procedures.some((procedure) => procedure.flow === procedureFlows.CMF_EXTENDED) && (
          <StyledListItem
            style={{ border: '2px solid #33CC99' }}
            button
            selected={selected === selectedProcedureFlows.CMF_EXTENDED}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.CMF_EXTENDED)}
          >
            <StyledListItemText primary="RECON" secondary="Craniomaxillofacial" />
            <img src={cmf_extended_image} alt="CMF_EXTENDED" width="100" height="100" />
          </StyledListItem>
        )}
      </StyledList>
    </div>
  );
};
