const routes = {
  HOME: '/',
  SIGN_IN: '/',
  SIGN_UP: '/sign-up',
  DASHBOARD: '/dashboard',
  CASES: '/cases',
  ACTIVITY: '/activity',
  CALENDAR: '/calendar',
  USERS: '/users',
};

export default routes;
