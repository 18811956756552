import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles, List, ListItem, ListItemText } from '@material-ui/core';

import { setSelectedFlow } from '../../../../../actions/casesActions';
import { procedureFlows, selectedProcedureFlows } from '../../../../../constants/cases';

import hto_image from '../../../../../assets/step0/PS_HTO_Image.png';
import osteotomy_image from '../../../../../assets/step0/PS_Osteotomy_Image.png';
import acl_image from '../../../../../assets/step0/PS_ACL_Image.png';
import cmf_image from '../../../../../assets/step0/PS_CMF_Extended_Image.png';
import oncol_image from '../../../../../assets/step0/PS_ONCOL_Image.png';
import other_image from '../../../../../assets/step0/PS_Other_Image.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,

    '& .block-header': {
      width: '90%',
      marginTop: 10,
      marginBottom: 25,
    },
  },
}));

const StyledList = withStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))(List);

const StyledListItem = withStyles((theme) => ({
  root: {
    width: '90%',
    borderRadius: 20,
    marginBottom: 30,
    '&:last-child': {
      margin: 0,
    },
  },
}))(ListItem);

const StyledListItemText = withStyles((theme) => ({
  primary: {
    fontSize: '1.8rem',
    fontWeight: 700,
    letterSpacing: 1.5,
  },
  secondary: {
    fontSize: '0.92rem',
  },
}))(ListItemText);

export const Step0Procedure = ({ selected, onChange, procedures = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleProcedureFlowClick = (event, flow) => {
    onChange(flow);
    dispatch(setSelectedFlow(flow));
  };

  return (
    <div className={classes.root}>
      <div className="block-header">Select Procedure</div>
      <StyledList component="nav" aria-label="procedures">
        {procedures.some(
          (procedure) =>
            procedure.flow === procedureFlows.HTO ||
            procedure.flow === procedureFlows.DFO_Lateral ||
            procedure.flow === procedureFlows.DFO_Medial ||
            procedure.flow === procedureFlows.HTO_LATERAL ||
            procedure.flow === procedureFlows.DOUBLE_LEVEL_OSTEOTOMY
        ) && (
          <StyledListItem
            style={{ border: '2px solid #ee9321' }}
            button
            selected={selected === selectedProcedureFlows.Osteotomy}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.Osteotomy)}
          >
            <StyledListItemText primary="OSTEOTOMY" secondary="Osteotomies around the knee" />
            <img src={osteotomy_image} alt="Osteotomy" width="100" height="100" />
          </StyledListItem>
        )}

        {procedures.some((procedure) =>
          procedure.flow === procedureFlows.CUSTOM_EXTENDED || procedure.flow === procedureFlows.CUSTOM
        ) && (
          <StyledListItem
            style={{ border: '2px solid #217cee' }}
            button
            selected={selected === selectedProcedureFlows.CUSTOM_EXTENDED}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.CUSTOM_EXTENDED)}
          >
            <StyledListItemText primary="OTHER" secondary="Other Orthopaedic Procedures" />
            <img src={other_image} alt="OTHER" width="100" height="100" />
          </StyledListItem>
        )}

        {procedures.some((procedure) => procedure.flow === procedureFlows.ACL) && (
          <StyledListItem
            style={{ border: '2px solid #e11157' }}
            button
            selected={selected === selectedProcedureFlows.ACL}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.ACL)}
          >
            <StyledListItemText primary="ACL" secondary="Anterior Cruciate Ligament" />
            <img src={acl_image} alt="ACL" width="100" height="100" />
          </StyledListItem>
        )}

        {procedures.some((procedure) => [procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL].includes(procedure.flow)) && (
          <StyledListItem
            style={{ border: '2px solid #33CC99' }}
            button
            selected={selected === selectedProcedureFlows.CMF_EXTENDED}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.CMF_EXTENDED)}
          >
            <StyledListItemText primary="CMF" secondary="Craniomaxillofacial" />
            <img src={cmf_image} alt="CMF" width="100" height="100" />
          </StyledListItem>
        )}

        {/*{procedures.some((procedure) => procedure.flow === procedureFlows.CMF_EXTENDED) && (*/}
        {/*  <StyledListItem*/}
        {/*    style={{ border: '2px solid #33CC99' }}*/}
        {/*    button*/}
        {/*    selected={selected === selectedProcedureFlows.CMF_EXTENDED}*/}
        {/*    onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.CMF_EXTENDED)}*/}
        {/*  >*/}
        {/*    <StyledListItemText primary="CMF Extended" secondary="Craniomaxillofacial" />*/}
        {/*    <img src={cmf_image} alt="CMF_EXTENDED" width="100" height="100" />*/}
        {/*  </StyledListItem>*/}
        {/*)}*/}

        {procedures.some((procedure) =>
          procedure.flow === procedureFlows.ONCOL
        ) && (
          <StyledListItem
            style={{ border: '2px solid #7266FA' }}
            button
            selected={selected === selectedProcedureFlows.ONCOL}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.ONCOL)}
          >
            <StyledListItemText primary="ONCOL" secondary="Tumor Removal & Oncologic Reconstruction" />
            <img src={oncol_image} alt="ONCOL" width="100" height="100" />
          </StyledListItem>
        )}
      </StyledList>
    </div>
  );
};
