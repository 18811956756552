import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import SurgeonListItem from './SurgeonListItem';

const SurgeonList = ({
  surgeons,
  onDisable,
  onEnable,
  onUpdate,
  users,
  hospitals,
  onAddHospital,
  onDeleteHospital,
  country,
  currentUser,
  distributors,
}) => {
  const list = surgeons?.map((item) => {
    const surgeon = users.find((user) => user.uid === item.userId);

    return {
      ...item,
      surgeon,
    };
  });

  return (
    <div className="distributor-list">
      {orderBy(list, 'surgeon.lastName')?.map((surgeon) => (
        <SurgeonListItem
          key={surgeon.id}
          {...surgeon}
          onDisable={onDisable}
          onEnable={onEnable}
          onUpdate={onUpdate}
          users={users}
          hospitalList={hospitals}
          onAddHospital={onAddHospital}
          onDeleteHospital={onDeleteHospital}
          country={country}
          currentUser={currentUser}
          distributors={distributors}
        />
      ))}
    </div>
  );
};

SurgeonList.propTypes = {
  surgeons: PropTypes.array,
  users: PropTypes.array,
  hospitals: PropTypes.array,
  onDisable: PropTypes.func,
  onEnable: PropTypes.func,
  onUpdate: PropTypes.func,
  onAddHospital: PropTypes.func,
  onDeleteHospital: PropTypes.func,
};

export default SurgeonList;
