import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';

const SubItemsUserList = ({ users, onDelete }) => {
  return (
    <div className="sub-items-list">
      {users.map((admin) => (
        <div key={admin.uid} className="sub-item">
          <div className="d-flex w-100">
            <div className="column">
              <div className="sub-item-circle" />
            </div>
            <div className="d-flex">
              <div>
                {admin.firstName} {admin.lastName}
              </div>
              <div className="user-email">{admin.email}</div>
              <div className="delete-icon" onClick={() => onDelete(admin.uid)}>
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

SubItemsUserList.propTypes = {
  users: PropTypes.array,
  onRemove: PropTypes.func,
};

export default SubItemsUserList;
