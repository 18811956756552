import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';

import { firebaseConfig } from '../config';

import { USER_DATA_COLLECTION, USER_AUTHORIZATION_COLLECTION } from './collections';

import { newUserNotificationEmail } from '../util';
import { post } from '../util/api';

import urls from '../constants/urls';

class Firebase {

  constructor() {
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.messaging = null;
    this.timestamp = app.firestore.Timestamp;

    // console.log(app.messaging.isSupported());
    if (app.messaging.isSupported()) {
      this.messaging = app.messaging();
    }
  }

  doCreateUserWithEmailAndPassword = (email, password, userData) => {
    return this.auth.createUserWithEmailAndPassword(email, password).then(({ user }) => {
      this.doSendEmailVerification({ ...userData, email });
      this.saveUserData({ ...userData, email });
      this.setUserRole(null);

      newUserNotificationEmail(userData.firstName, userData.lastName, email);

      if (user) {
        return this.doGetCurrentUser();
      }
    });
  };

  doSignInWithEmailAndPassword = (email, password, checked) => {
    this.auth.setPersistence(app.auth.Auth.Persistence[checked ? 'LOCAL' : 'NONE']);

    return this.auth.signInWithEmailAndPassword(email, password).then((auth) => {
      if (auth.user) {
        return this.doGetCurrentUser();
      }
    });
  };

  doSignOut = () => this.auth.signOut();

  doSendEmailVerification = (userData) => {
    // this.auth.currentUser.sendEmailVerification();
    post(urls.sendVerification, userData);
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doReauthenticate = (currentPassword) => {
    const user = this.auth.currentUser;
    const cred = app.auth.EmailAuthProvider.credential(user.email, currentPassword || window.location.href);

    return user.reauthenticateWithCredential(cred);
  }

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  saveUserData = (userData) =>
    this.db
      .collection(USER_DATA_COLLECTION)
      .doc(this.auth.currentUser.uid)
      .set(userData)
      .then(() =>
        this.auth.currentUser.updateProfile({
          displayName: `${userData.firstName} ${userData.lastName}`,
        })
      );

  setUserRole = (role, uid) => {
    const id = uid || this.auth.currentUser.uid;

    return this.db.collection(USER_AUTHORIZATION_COLLECTION).doc(id).set({ role });
  };

  doGetCurrentUser = () =>
    new Promise((resolve, reject) => {
      const unsubscribe = this.auth.onAuthStateChanged((res) => {
        const user = res?.multiFactor?.user;
        if (user) {
          Promise.all([this.getCurrentUserData(), this.getCurrentUserRoles()]).then((responses) =>
            resolve({ ...user, ...responses[0], ...responses[1] })
          );
        } else {
          resolve(user);
        }
        unsubscribe();
      }, reject);
    }).catch(() => null);

  getCurrentUserData = () => {
    return this.db
      .collection(USER_DATA_COLLECTION)
      .doc(this.auth.currentUser.uid)
      .get()
      .then((doc) => doc.data())
      .catch((error) => console.log(error));
  };

  getCurrentUserRoles = () =>
    this.db
      .collection(USER_AUTHORIZATION_COLLECTION)
      .doc(this.auth.currentUser.uid)
      .get()
      .then((doc) => doc.data())
      .catch(() => {});

  getUserData = (uid) =>
    this.db
      .collection(USER_DATA_COLLECTION)
      .doc(uid)
      .get()
      .then((doc) => doc.data())
      .catch(() => {});

  getDownloadLink = (fileId) =>
    new Promise((resolve, reject) => {
      const storageRef = this.storage.ref();

      storageRef
        .child(fileId)
        .getDownloadURL()
        .then((url) => {
          resolve(url);
        })
        .catch((error) => {
          reject(error);
        });
    });

  getNotificationToken = async () => {
    if (this.messaging) {
      const currentToken = await this.messaging.getToken({ vapidKey: firebaseConfig.vapidKey });
      return currentToken;
    }
  };

  onMessageListener = () =>
    new Promise((resolve) => {
      if (this.messaging) {
        this.messaging.onMessage((payload) => {
          resolve(payload);
        });
      } else {
        resolve();
      }
    });
}

const instance = new Firebase();

export default instance;
