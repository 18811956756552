import React from 'react';
import { connect } from 'react-redux';

import { caseConstants } from '../../../../../constants';

import OsteotomyStep11 from './osteotomy-step11/OsteotomyStep11';
import CmfExtendedStep11 from './cmf-extended-step11/CmfExtendedStep11';

const {
  procedureFlows,
  fileTypes,
  statuses,
  caseCMFExtendedNotifications
} = caseConstants;

const Step11 = ({ classes, onClose, activeCase }) => {
  const renderStep11 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep11
          classes={classes}
          activeCase={activeCase}
          onClose={onClose}
        />
      );
    }

    if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedStep11
          onClose={onClose}
          activeCase={activeCase}
          classes={classes}
          fileTypes={fileTypes}
          caseNotifications={caseCMFExtendedNotifications}
          statuses={statuses}
        />
      );
    }
  };

  return renderStep11(activeCase.procedureFlow);
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    userId: state.user.currentUser.uid,
    products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step11);
