import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const ProcedureAddProduct = ({ newProduct, products, onChange, onCancel, onSubmit }) => {
  return (
    <div className="admin-modal-item new-item">
      <div className="column" />
      <div className="modal-item-content d-flex">
        <Autocomplete
          style={{ width: '100%', marginBottom: 8 }}
          id="size-small-standard"
          options={products}
          getOptionLabel={(option) => option ? `${option?.sku} — ${option?.description}` : ''}
          value={newProduct || null}
          onChange={(event, newValue) => {
            onChange(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" style={{ width: '100%' }} />
          )}
        />
        <div className="p-l-md primary pointer" onClick={onSubmit}>
          Add
        </div>
        <div className="p-l-md secondary pointer" onClick={onCancel}>
          Cancel
        </div>
      </div>
    </div>
  )
};

ProcedureAddProduct.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProcedureAddProduct;
