import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Modal from '../Modal';

import './case-modal.scss';
import TextField from '@material-ui/core/TextField';

const CaseFilterModal = ({
  open,
  onClose,
  filter,
  filterOptions,
  handleFilterModalSubmit,
  search,
  onSearchChange,
}) => {
  const [statuses, setStatuses] = useState(filter && filter.access ? filter.access : []);
  const [hospitals, setHospitals] = useState(filter && filter.hospital ? filter.hospital : []);
  const [surgeons, setSurgeons] = useState(filter && filter.surgeonName ? filter.surgeonName : []);
  const [procedures, setProcedures] = useState(
    filter && filter.procedureName ? filter.procedureName : []
  );
  const [countries, setCountries] = useState(filter && filter.country ? filter.country : []);
  const [actions, setActions] = useState(filter && filter.actions ? filter.actions : []);

  useEffect(() => {
    if (open && filter) {
      if (filter.access) {
        setStatuses(filter.access);
      }
      if (filter.hospital) {
        setHospitals(filter.hospital);
      }
      if (filter.procedureName) {
        setProcedures(filter.procedureName);
      }
      if (filter.surgeonName) {
        setSurgeons(filter.surgeonName);
      }
      if (filter.country) {
        setCountries(filter.country);
      }
      if (filter.action) {
        setActions(filter.action);
      }
    }
  }, [open]); // eslint-disable-line

  const handleStatusChange = (e, value) => {
    if (e.target.checked) {
      setStatuses([...statuses, value]);
    } else {
      setStatuses(statuses.filter((item) => item !== value));
    }
  };

  const handleHospitalChange = (e, value) => {
    if (e.target.checked) {
      setHospitals([...hospitals, value]);
    } else {
      setHospitals(hospitals.filter((item) => item !== value));
    }
  };

  const handleProcedureChange = (e, value) => {
    if (e.target.checked) {
      setProcedures([...procedures, value]);
    } else {
      setProcedures(procedures.filter((item) => item !== value));
    }
  };

  const handleActionsChange = (e, value) => {
    if (e.target.checked) {
      setActions([...actions, value]);
    } else {
      setActions(actions.filter((item) => item !== value));
    }
  };

  const handleSurgeonsChange = (e, value) => {
    if (e.target.checked) {
      setSurgeons([...surgeons, value]);
    } else {
      setSurgeons(surgeons.filter((item) => item !== value));
    }
  };

  const handleCountryChange = (e, value) => {
    if (e.target.checked) {
      setCountries([...countries, value]);
    } else {
      setCountries(countries.filter((item) => item !== value));
    }
  };

  const handleSubmit = () => {
    const newFilter = {
      access: statuses,
      hospital: hospitals,
      procedureName: procedures,
      surgeonName: surgeons,
      action: actions,
      country: countries,
    };

    handleFilterModalSubmit(newFilter);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Filter cases"
      size="lg"
      submitButton="Filter"
      onSubmit={handleSubmit}
    >
      <div className="p-lg filter-modal">
        <div className="p-b-md m-b-mb border-bottom">
          <h5>Case ID</h5>
          <TextField
            id="search"
            type="text"
            label="Search"
            variant="outlined"
            value={search}
            size="small"
            margin="dense"
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
        <div className="p-t-md p-b-md m-b-md border-bottom">
          <h5>Status</h5>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={statuses.includes('Open')}
                  onChange={(e) => handleStatusChange(e, 'Open')}
                  name="Open"
                  color="primary"
                />
              }
              label="Open"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={statuses.includes('Completed')}
                  onChange={(e) => handleStatusChange(e, 'Completed')}
                  name="Completed"
                  color="primary"
                />
              }
              label="Completed"
            />
          </div>
        </div>

        {filterOptions.hospital && !!filterOptions.hospital.length && (
          <div className="p-t-md p-b-md m-b-md border-bottom">
            <h5>Hospital</h5>
            {sortBy(filterOptions.hospital).map((option) => (
              <div key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hospitals.includes(option)}
                      onChange={(e) => handleHospitalChange(e, option)}
                      name={option}
                      color="primary"
                    />
                  }
                  label={option}
                />
              </div>
            ))}
          </div>
        )}

        {filterOptions.procedureName && !!filterOptions.procedureName.length && (
          <div className="p-t-md p-b-md m-b-md">
            <h5>Procedure</h5>
            {sortBy(filterOptions.procedureName).map((option) => (
              <div key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={procedures.includes(option)}
                      onChange={(e) => handleProcedureChange(e, option)}
                      name={option}
                      color="primary"
                    />
                  }
                  label={option}
                />
              </div>
            ))}
          </div>
        )}

        {filterOptions.surgeonName && !!filterOptions.surgeonName?.length && (
          <div className="p-t-md p-b-md m-b-md">
            <h5>Surgeon</h5>
            {sortBy(filterOptions.surgeonName).map((option) => (
              <div key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={surgeons.includes(option)}
                      onChange={(e) => handleSurgeonsChange(e, option)}
                      name={option}
                      color="primary"
                    />
                  }
                  label={option}
                />
              </div>
            ))}
          </div>
        )}

        {filterOptions.country && !!filterOptions.country?.length && (
          <div className="p-t-md p-b-md m-b-md">
            <h5>Country</h5>
            {sortBy(filterOptions.country)?.map((option) => (
              <div key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={countries.includes(option)}
                      onChange={(e) => handleCountryChange(e, option)}
                      name={option}
                      color="primary"
                    />
                  }
                  label={option}
                />
              </div>
            ))}
          </div>
        )}

        {filterOptions.action && !!filterOptions.action.length && (
          <div className="p-t-md p-b-md m-b-md">
            <h5>Action</h5>
            {sortBy(filterOptions.action).map((option) => (
              <div key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={actions.includes(option)}
                      onChange={(e) => handleActionsChange(e, option)}
                      name={option}
                      color="primary"
                    />
                  }
                  label={option}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

CaseFilterModal.propTypes = {
  search: PropTypes.string,
  open: PropTypes.bool.isRequired,
  filter: PropTypes.object,
  filterOptions: PropTypes.object,
  handleFilterModalSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onSearchChange: PropTypes.func,
};

export default CaseFilterModal;
