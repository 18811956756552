import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isAccessPermitted } from '../../util';

const RestrictComponent = ({ children, ...props }) => (isAccessPermitted(props) ? children : null);

RestrictComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  roles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  excludeRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  userRole: PropTypes.string,
  emailVerified: PropTypes.bool,
};

const mapStateToPros = (state) => ({
  userRole: state.user.currentUser.role,
  emailVerified: state.user.currentUser.emailVerified,
});

export default connect(mapStateToPros)(RestrictComponent);
