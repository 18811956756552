import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import OsteotomyStep1Admin from './OsteotomyStep1Admin';
import RestrictComponent from '../../../../../shared/RestrictComponent';

import xRayIcon from '../../../../../../assets/xray_icon.svg';
import scansIcon from '../../../../../../assets/ct_icon.svg';

import { setActiveTab } from '../../../../../../actions/casesActions';

import { userRoles } from '../../../../../../constants';


const fileTypes = ['scan', 'x-ray'];

const OsteotomyStep1Static = ({ activeCase, goToFiles }) => {
  const { files } = activeCase;

  return (
    <div className="case-static-container">
      {files
        .filter((file) => fileTypes.includes(file.type))
        .map((file) => (
          <div key={file.id} className="d-flex m-b-md pointer" onClick={goToFiles}>
            <img src={file.type === 'x-ray' ? xRayIcon : scansIcon} alt="file-icon" />
            <div>{file.name}</div>
            <div className="status-indicator">{file.type}</div>
          </div>
        ))}

      <RestrictComponent roles={[userRoles.GLOBAL_ADMIN, userRoles.ENGINEER, userRoles.COUNTRY_ADMIN]}>
        <OsteotomyStep1Admin activeCase={activeCase} />
      </RestrictComponent>
    </div>
  );
};

OsteotomyStep1Static.propTypes = {
  activeCase: PropTypes.object,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep1Static);
