import { apiUrl } from '../config';

const calculationUrl = `${apiUrl}/calculation`;

const urls = {
  calculation: {
    rtw: `${calculationUrl}/rtw`,
    tma: `${calculationUrl}/tma`,
    twtma: `${calculationUrl}/twtma`,
    fma: `${calculationUrl}/fma`,
    twfma: `${calculationUrl}/twfma`,
    fujisawa: `${calculationUrl}/fujisawa`,
    desiredFinishPoint: `${calculationUrl}/desired-finish-point`,
    overallCorrection: `${calculationUrl}/overall-correction`,
    wedgeMeasurements: `${calculationUrl}/wedge-measurements`,
  },
  email: `${apiUrl}/emailMessage`,
  newUserNotification: `${apiUrl}/newUserEmail`,
  exportCase: `${apiUrl}/cases/export`,
  verifyEmail: `${apiUrl}/verifyEmail`,
  createUser: `${apiUrl}/users/create`,
  updateUser: `${apiUrl}/users/update`,
  sendVerification: `${apiUrl}/users/send-verification`,
  disableUser: `${apiUrl}/users/disable-user`,
  sendSms: `${apiUrl}/cases/send-sms`,
  sendPushNotification: `${apiUrl}/cases/send-push-notification`,
  // sendPushNotification: `${apiUrl}/sendPushNotification`,
};

export default urls;
