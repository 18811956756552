import React from 'react';
import { connect } from 'react-redux';

import { updateCase } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';

import CmfExtendedCompletedCase from './cmf-extended-completed-case/CmfExtendedCompletedCase';
import CompletedCase from '../CompletedCase';

const { procedureFlows, fileStatuses, fileTypes, statuses, caseNotifications } = caseConstants;

const Step12 = ({ classes, onClose, activeCase, userId, updateCase, products }) => {
  const renderStep12 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <CompletedCase
          classes={classes}
          activeCase={activeCase}
          onClose={onClose}
        />
      );
    }

    if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedCompletedCase
          onClose={onClose}
          activeCase={activeCase}
          classes={classes}
        />
      );
    }
  };

  return renderStep12(activeCase.procedureFlow);
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    userId: state.user.currentUser.uid,
    products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, rejected) => dispatch(updateCase(newCase, 12, null, rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step12);
