import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@material-ui/core/TextField';

import NewUser from '../../NewUser';
import SubItemsUserList from '../../SubItemsUserList';
import DistributorAddProcedure from './DistributorAddProcedure';

const DistributorListItem = (props) => {
  const {
    id,
    name,
    address,
    email,
    phone,
    onDelete,
    onUpdate,
    admins,
    users,
    onAddAdmin,
    onDeleteAdmin,
    onAddProcedure,
    onDeleteProcedure,
    assignedProcedures = [],
    procedures,
  } = props;

  const [addMode, toggleMode] = useState(false);
  const [editMode, toggleEditMode] = useState(false);
  const [deleteMode, toggleDeleteMode] = useState(false);
  const [addProcedureMode, toggleProcedureMode] = useState(false);

  const [admin, setAdmin] = useState(null);
  const [distributorProcedures, setDistributorProcedures] = useState(null);

  const [newName, setNewName] = useState(name || '');

  const handleAddAdmin = () => {
    if (!admin) {
      return;
    }

    onAddAdmin(admin.uid, id);
    setAdmin(null);
    toggleMode(false);
  };

  const handleAddProcedure = () => {
    if (!distributorProcedures) {
      return;
    }

    onAddProcedure(id, [
      ...assignedProcedures,
      { id: distributorProcedures.id, name: distributorProcedures.name },
    ]);
    setDistributorProcedures(null);
    toggleProcedureMode(false);
  };

  const handleSave = () => {
    onUpdate(id, { name: newName })
      .then(() => toggleEditMode(false));
  };

  const filteredUsers = users.filter(
    (user) => !~admins.map((admin) => admin.uid).indexOf(user.uid)
  );

  const filteredAssignedProcedures = procedures.filter(
    (procedure) => !~assignedProcedures.map((assign) => assign.id).indexOf(procedure.id)
  );

  return (
    <div className="admin-modal-item list-item-container">
      <div className="list-item-main">
        <div className="column">
          <div className="modal-item-circle" />
        </div>
        <div className="modal-item-header">
          {editMode ? (
            <TextField
              id="new-distributor-name"
              type="text"
              fullWidth
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          ) : (
            <div className="d-flex">
              <div className="m-r-md">{name}</div>
            </div>
          )}

          {deleteMode && (
            <div className="d-flex">
              <div className="danger pointer m-l-sm" onClick={() => onDelete(id)}>
                Delete
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleDeleteMode(false)}>
                Cancel
              </div>
            </div>
          )}
          {editMode && (
            <div className="d-flex">
              <div className="primary pointer m-l-sm" onClick={handleSave}>
                Save
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleEditMode(false)}>
                Cancel
              </div>
            </div>
          )}

          {!editMode && !deleteMode && (
            <div className='d-flex'>
              <IconButton aria-label="edit" color="primary" onClick={() => toggleEditMode(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton aria-label="delete" color="primary" onClick={() => toggleDeleteMode(true)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex m-b-md">
        <div className="column" />
        <div className="flex-1">
          <div className="info-row">
            <div className="info-row-field">
              <div className="field-label">REGISTERED ADDRESS</div>
              <div className="field-value">{address || 'None'}</div>
            </div>
          </div>
          <div className="info-row">
            <div className="info-row-field">
              <div className="field-label">EMAIL</div>
              <div className="field-value">{email || 'None'}</div>
            </div>
            <div className="info-row-field">
              <div className="field-label">PHONE</div>
              <div className="field-value">{phone || 'None'}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>{(!assignedProcedures || !assignedProcedures.length) && 'NO'} PROCEDURES</div>
            <div className="primary pointer" onClick={() => toggleProcedureMode(true)}>
              Add Procedure
            </div>
          </div>
        </div>
        {assignedProcedures &&
          assignedProcedures.map((procedure) => (
            <div key={`key-${procedure.id}`} className="sub-item">
              <div className="column" />
              <div className="d-flex">
                {procedure.name}
                <div className="delete-icon" onClick={() => onDeleteProcedure(id, procedure)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          ))}
        {addProcedureMode && (
          <DistributorAddProcedure
            distributorProcedures={distributorProcedures}
            procedures={filteredAssignedProcedures}
            onChange={setDistributorProcedures}
            onSubmit={handleAddProcedure}
            onCancel={() => toggleProcedureMode(false)}
          />
        )}
      </div>

      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>{!admins || !admins.length ? 'NO' : 'DISTRIBUTOR'} ADMINS</div>
            <div className="primary pointer" onClick={() => toggleMode(true)}>
              Add Distributor Admin
            </div>
          </div>
        </div>
        {admins && <SubItemsUserList users={admins} onDelete={onDeleteAdmin} />}
      </div>
      {addMode && (
        <NewUser
          userList={filteredUsers}
          isSubItem
          onChange={setAdmin}
          onSubmit={handleAddAdmin}
          onCancel={() => toggleMode(false)}
        />
      )}
    </div>
  );
};

DistributorListItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  users: PropTypes.array,
  admins: PropTypes.array,
  onDelete: PropTypes.func,
  onAddAdmin: PropTypes.func,
  onDeleteAdmin: PropTypes.func,
};

export default DistributorListItem;
