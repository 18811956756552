import moment from 'moment';
import { addDays, subDays, isBefore, isAfter } from 'date-fns';

import {
  LOAD_CASES,
  SET_CASES,
  SET_ACTIVE_TAB,
  SET_SELECTED_FLOW,
  CREATE_CASE,
  UPDATE_CASE,
  SET_ACTIVE_CASE,
  DISCARD_ACTIVE_CASE,
  RESET_CASES,
  SET_CASE_NOTES,
  ADD_CASE_NOTE,
} from './actionTypes';
import Firebase from '../firebase';
import {
  CASES_COLLECTION,
  SURGEONS_COLLECTION,
  NOTES_SUB_COLLECTION,
} from '../firebase/collections';

import { fetchProcedures } from './adminActions';
import { createActivity } from './activityActions';
import { setNotification } from './notificationActions';

import { api, sendEmail } from '../util';

import { caseConstants, userRoles, emailTemplates } from '../constants';
import { roleNames } from '../constants/userRoles';
import { orderBy } from 'lodash';
import urls from '../constants/urls';
import { casesACLEmails, casesCMFEmails, casesCMFExtendedEmails } from '../constants/emailTemplates';

const {
  stepRestrictions,
  cmfExtendedStepRestrictions,
  aclStepRestrictions,
  restrictionsInTrainingMode,
  caseNotifications,
  caseACLNotifications,
  caseCMFNotifications,
  caseCMFExtendedNotifications,
  alertMessages,
  alertAclMessages,
  alertCmfMessages,
  alertCmfExtendedMessages,
  procedureFlows,
  numberOfSteps,
  fileStatuses,
  fileTypes,
  statuses,
} = caseConstants;
const { casesEmails} = emailTemplates;

const hasAccess = (currentUser, surgeon, step, flow, trainingMode = false) => {
  const { GLOBAL_ADMIN, COUNTRY_ADMIN } = roleNames;
  const restrictions = [procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)
    ? cmfExtendedStepRestrictions : (
      flow === procedureFlows.ACL ? aclStepRestrictions : stepRestrictions
    );

  const isAccess = trainingMode
    ? restrictionsInTrainingMode[step].includes(currentUser.role) || step > numberOfSteps
    : (restrictions[step].includes(currentUser.role) || step > numberOfSteps || currentUser?.engineerLevel === 1 || (restrictions[step].includes(currentUser.role) && step === 1))
    && (Object.values(surgeon)?.some((value) => (Array.isArray(value) && value?.includes(currentUser.uid)) || value === currentUser.uid) || currentUser.role === GLOBAL_ADMIN || currentUser.role === COUNTRY_ADMIN);

  if (currentUser?.engineerLevel === 3) {
    return (step > numberOfSteps || step === 0 || step === 1) && Object.values(surgeon)?.some((value) => value && value?.includes(currentUser.uid));
  }

  return isAccess;
};

export const setCases = (cases) => ({ type: SET_CASES, cases });

export const setActiveTab = (tab) => ({ type: SET_ACTIVE_TAB, tab });
export const setSelectedFlow = (flow) => ({ type: SET_SELECTED_FLOW, flow });

export const fetchCases = (withoutLoading) => (dispatch, getState) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  const state = getState();
  const uid = state.user.currentUser?.uid;
  const role = state.user.currentUser?.role;
  const country = state.user?.currentUser?.administrationCountry || null;
  const users = state.users.list;
  const distributors = state.distributors.list;

  if (!withoutLoading) {
    dispatch({ type: LOAD_CASES });
  }

  dispatch(fetchProcedures());

  return fetchSurgeons(uid, role)
    .then((res) => {
      const surgeonIds = res.map((item) => item.id);

      if (!surgeonIds.length) {
        return dispatch(setCases([]));
      }

      const query = new Promise((resolve, reject) => {
        const results = [];
        const surgeonQueries = surgeonIds.map((surgeonId) =>
          collection
            .where('surgeonId', '==', surgeonId)
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((doc) => {
                const result = { id: doc.id, ...doc.data() };

                results.push(result);
                return result;
              });
            })
        );
        Promise.all(surgeonQueries).then(() => {
          resolve(results?.filter((item) => !item.archived));
        });
      });

      query.then((cases) => {
        // const cases = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
        const promises = [];
        cases.forEach((item) => {
          promises.push(item.surgeon.get());
        });
        Promise.all(promises).then((surgeons) => {
          surgeons.forEach((surgeon, index) => {
            const currentCase = cases[index];
            const caseSurgeon = { id: surgeon.id, ...surgeon.data() };
            const surgeonUser = users.find((user) => user.uid === caseSurgeon?.userId);
            const surgeonName = surgeonUser ? `${surgeonUser?.firstName} ${surgeonUser?.lastName}` : '';
            const currentUser = state.user.currentUser;
            const isTrainingMode = currentCase.trainingMode;

            currentCase.surgeon = caseSurgeon;
            currentCase.surgeonName = surgeonName;
            currentCase.access = hasAccess(currentUser, caseSurgeon, currentCase.step, currentCase.procedureFlow, isTrainingMode);
          });

          const filteredCases = role === userRoles.COUNTRY_ADMIN.name ? cases?.filter((item) => {
            const distributor = distributors?.find((d) => d.id === item.distributorId);
            return distributor?.country === country;
          }) : cases;

          return dispatch(setCases(filteredCases.sort((a, b) => (a.date > b.date ? 1 : -1))));
        });
      });
    })
    .catch((err) => {
      dispatch(setCases([]));
      console.log(err);
    });
};

export const fetchCaseById = (caseId) => async (dispatch, getState) => {
  const state = getState();
  const users = state.users.list;
  const collection = Firebase.db.collection(CASES_COLLECTION);

  const doc = await collection
    .doc(caseId)
    .get();

  if (doc && doc.exists) {
    dispatch(fetchProcedures());

    const data = doc?.data();
    const surgeonSnap = await data?.surgeon?.get();
    const surgeon = surgeonSnap?.data();
    const caseSurgeon = users.find((user) => user.uid === surgeon?.userId);
    const surgeonName = caseSurgeon ? `${caseSurgeon?.firstName} ${caseSurgeon?.lastName}` : '';

    return {
      ...data,
      id: doc.id,
      surgeon,
      surgeonName,
    };
  } else {
    return null;
  }
};

export const fetchCasesByPatientData = (lastName, dateOfBirth) => async (dispatch, getState) => {
  const state = getState();
  const users = state.users.list;
  const collection = Firebase.db.collection(CASES_COLLECTION);

  dispatch(fetchProcedures());

  const snapshot = await collection
    .where('patientInfo.lastName', '==', lastName)
    .get();

  const promises = snapshot?.docs?.map(async (doc) => {
    const data = doc?.data();
    const surgeonSnap = await data?.surgeon?.get();
    const surgeon = surgeonSnap?.data();
    const caseSurgeon = users.find((user) => user.uid === surgeon?.userId);
    const surgeonName = caseSurgeon ? `${caseSurgeon?.firstName} ${caseSurgeon?.lastName}` : '';

    return {
      ...data,
      id: doc.id,
      surgeon,
      surgeonName,
    };
  });

  const cases = await Promise.all(promises);

  return cases?.filter((item) => {
    const dateBefore = subDays(new Date(dateOfBirth), 1);
    const dateAfter = addDays(new Date(dateOfBirth), 1);
    const date = new Date(moment(item?.patientInfo?.dateOfBirth).format('MM/DD/YYYY'));

    return isBefore(dateBefore, date) && isAfter(dateAfter, date);
  });
};

export const fetchRejectedCases = () => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser.uid;
  const users = state.users.list;
  const collection = Firebase.db.collection(CASES_COLLECTION);

  dispatch(fetchProcedures());

  const snapshot = await collection
    .where('uploadFilesUid', '==', userId)
    .where('status', '==', 'REJECTED')
    .get();

  const promises = snapshot?.docs?.map(async (doc) => {
    const data = doc?.data();
    const surgeonSnap = await data?.surgeon?.get();
    const surgeon = surgeonSnap?.data();
    const caseSurgeon = users.find((user) => user.uid === surgeon?.userId);
    const surgeonName = caseSurgeon ? `${caseSurgeon?.firstName} ${caseSurgeon?.lastName}` : '';

    return {
      ...data,
      id: doc.id,
      surgeon,
      surgeonName,
    };
  });

  const cases = await Promise.all(promises);
  return cases;
};

const fetchSurgeons = (uid, role) => {
  const collection = Firebase.db.collection(SURGEONS_COLLECTION);
  const { SURGEON, ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER, REGISTRAR } = roleNames;
  const userRole = userRoles[role];

  if ([ENGINEER, SALES_REP, LOGISTICS, MANUFACTURER, PRACTICE_MANAGER, REGISTRAR].includes(role) && userRole) {
    const opStr = [ENGINEER, SALES_REP, PRACTICE_MANAGER, REGISTRAR].includes(role) ? 'array-contains' : '==';
    return collection
      .where(userRole.field, opStr, uid)
      .get()
      .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  } else if (role === SURGEON) {
    return collection
      .where('userId', '==', uid)
      .get()
      .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  } else {
    return collection.get().then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  }
};

export const createCase = (uid, caseId, data, files) => (dispatch, getState) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  const storageRef = Firebase.storage.ref();
  const state = getState();
  const currentUser = state.user.currentUser;
  const users = state.users.list;
  const surgeons = state.surgeons.list;
  const procedures = state.procedures.list;

  const surgeon = surgeons.find((item) => item.id === data.surgeon);
  const step = data.skipPSI ? 10 : 1;
  const access = surgeon && hasAccess(currentUser, surgeon, step, data.procedureFlow);
  const trainingMode = state.sidebar.trainingMode;

  const surgeonUser = users?.find((u) => u.uid === surgeon?.userId || u.uid === data?.surgeon);
  const procedure = procedures?.find((p) => p.id === data.procedure);

  dispatch({ type: LOAD_CASES });

  return collection
    .doc(caseId)
    .set({
      ...data,
      userId: uid,
      distributorId: data.distributorId,
      step: step,
      surgeon: Firebase.db.doc(`${SURGEONS_COLLECTION}/${data.surgeon}`),
      surgeonId: data.surgeon,
      patientLastName: data.patientInfo.lastName,
      createdAt: moment().format('YYYY-MM-DD hh:mm A'),
    })
    .then(() => {
      const promises = [];
      files.forEach((file) => {
        const currentFile = data?.files.find((item) => item.name === file.name);
        const type = currentFile && currentFile.type;

        if (type) {
          const path = `${caseId}/${caseId}_${type}_${file.name}`;
          console.log(path);
          promises.push(storageRef.child(path).put(file));
        }
      });
      Promise.all(promises);

      let emails = casesEmails;
      if (data.procedureFlow === procedureFlows.ACL) {
        emails = casesACLEmails;
      } else if (data.procedureFlow === procedureFlows.CMF) {
        emails = casesCMFEmails;
      } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.CUSTOM_EXTENDED, procedureFlows.ONCOL, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(data.procedureFlow)) {
        emails = casesCMFExtendedEmails;
      }

      const email = emails[0].success;

      if (!trainingMode) {
        email.roles.forEach((role) => {
          const recipientId = role.name === roleNames.SURGEON ? surgeon.userId : surgeon[role.field];

          if (!recipientId) {
            return;
          }

          const recipient =
            typeof recipientId === 'string'
              ? users.find((user) => user.uid === recipientId)
              : recipientId.map((recipientItem) => users.find((userItem) => userItem.uid === recipientItem));

          if (typeof recipientId === 'string') {
            if (recipient && recipient.uid !== uid) {
            }

            dispatch(
              createActivity(recipient.uid, {
                ...caseNotifications[0].success,
                caseId,
                date: moment().format(),
                userId: currentUser?.uid || ''
              })
            );
          } else {
            recipient.forEach((item) => {
              if (item && item.uid !== uid && role.name !== roleNames.SURGEON) {
                dispatch(
                  sendCaseEmail(
                    item,
                    email.subject,
                    email.message({
                      caseId: caseId?.includes("_") ? caseId?.substring(0, caseId?.indexOf("_")) : caseId,
                      patientLastName: data.patientLastName || '',
                    }),
                    email.additionalText,
                    true,
                    [
                      {
                        key: 'CASE ID',
                        value: caseId?.includes("_") ? caseId?.substring(0, caseId?.indexOf("_")) : caseId,
                      },
                      { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
                      { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
                      { key: 'DOS', value: data.date ? moment(data.date).format('DD/MM/YY') : 'TBC' },
                      { key: 'HOSPITAL', value: data.hospital },
                      { key: 'PROCEDURE', value: procedure ? procedure.name : '' },
                    ],
                  ),
                );
              }

              dispatch(
                createActivity(item.uid, {
                  ...caseNotifications[0].success,
                  caseId,
                  date: moment().format(),
                  userId: currentUser?.uid || '',
                }),
              );
            });
          }
        });
      }
      dispatch(setNotification({ variant: 'success', vertical: 'top', message: alertMessages[0].success }));

      return dispatch({
        type: CREATE_CASE,
        case: {
          id: caseId,
          ...data,
          patientLastName: data.patientInfo.lastName,
          userId: uid,
          step: step,
          access,
          surgeon: surgeon || data.surgeon,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const clearRejectedFiles = (filesData, caseId) => {
  const storageRef = Firebase.storage.ref();
  const promises = [];
  if (filesData) {
    filesData.forEach((file) => {
      if (file.status === fileStatuses.REJECTED) {
        promises.push(storageRef.child(`${caseId}/${file.id}`).delete());
      }
    });
  }

  return Promise.all(promises).catch(() => {
    console.log('item removed');
  });
};

export const updateCaseAdmin = (id, data, previousData, files) => (dispatch, getState) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  const storageRef = Firebase.storage.ref();
  const state = getState();
  // const users = state.users.list;
  // const surgeon = users.find((user) => user.uid === previousData.surgeon.id);
  // const dateEmail = casesEmails['0'].updateDate;
  // const hospitalEmail = casesEmails['0'].updateHospital;

  const trainingMode = state.sidebar.trainingMode || previousData.trainingMode;

  if (!trainingMode) {
    dispatch(sendChangeDateHospitalEmail(id, data, previousData));
  }

  const doc = { ...data };
  if (doc.sortDate) {
    delete doc.sortDate;
  }

  return collection
    .doc(id)
    .update({ ...doc, surgeon: Firebase.db.doc(`${SURGEONS_COLLECTION}/${data.surgeon}`) })
    .then(() => {
      const promises = [];
      files.forEach((file) => {
        const currentFile = data?.files.find((item) => item.name === file.name);
        const type = currentFile && currentFile.type;

        if (type) {
          promises.push(storageRef.child(`${data.id}/${data.id}_${type}_${file.name}`).put(file));
        }
      });
      Promise.all(promises);

      return dispatch({ type: UPDATE_CASE, case: { ...data, surgeon: previousData.surgeon, isPaid: data.isPaid } })
    });
};

export const uploadFiles = (caseId, stepData) => (dispatch, getState) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  // const storageRef = Firebase.storage.ref();
  const state = getState();
  const currentUser = state.user.currentUser;
  const activeCase = state.cases?.activeCase;
  const users = state.users.list;
  const surgeon = state.cases.activeCase.surgeon;
  const isTrainingMode = state.cases.activeCase.trainingMode;
  const access = surgeon && hasAccess(currentUser, surgeon, 2, activeCase.procedureFlow, isTrainingMode);
  const filesData = stepData.files;

  const surgeonUser = users?.find((u) => u.uid === surgeon.userId);

  const promises = [];

  dispatch({ type: LOAD_CASES });

  // filesData.forEach(file => {
  //     if (file.status === caseConstants.fileStatuses.REJECTED) {
  //       promises.push(storageRef.child(`${caseId}/${file.id}`).delete());
  //     }
  //   });

  stepData.files = filesData.filter((file) => file.status !== fileStatuses.REJECTED);

  if (currentUser.role === roleNames.RADIOLOGY) {
    stepData.radiologyUser = currentUser.uid;
  }

  let notifications = caseNotifications;

  // if (activeCase.procedureFlow === procedureFlows.ACL) {
  //   notifications = caseACLNotifications;
  // } else if (activeCase.procedureFlow === procedureFlows.CMF) {
  //   notifications = caseCMFNotifications;
  // } else if (activeCase.procedureFlow === procedureFlows.CMF_EXTENDED) {
  //   notifications = caseCMFExtendedNotifications;
  // }

  const doc = { ...stepData };
  if (doc.sortDate) {
    delete doc.sortDate;
  }

  return Promise.all(promises)
    .then(() => {
      return collection
        .doc(caseId)
        .update({ ...doc, step: 2 })
        .then(() => {
          let emails = casesEmails;
          if (activeCase.procedureFlow === procedureFlows.ACL) {
            emails = casesACLEmails;
          } else if (activeCase.procedureFlow === procedureFlows.CMF) {
            emails = casesCMFEmails;
          } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
            emails = casesCMFExtendedEmails;
          }

          const email = emails[1].success;
          const recipients = surgeon.engineer.map((engineer) => users.find((user) => user.uid === engineer));

          if (!isTrainingMode) {
            recipients.forEach((recipient) => {
              if (recipient?.uid !== currentUser?.uid) {
                dispatch(sendCaseEmail(
                  recipient,
                  email.subject,
                  email.message({ caseId: caseId?.includes("_") ? caseId?.substring(0, caseId?.indexOf("_")) : caseId }),
                  email.additionalText,
                  true,
                  [
                    { key: 'CASE ID', value: caseId?.includes("_") ? caseId?.substring(0, caseId?.indexOf("_")) : caseId },
                    { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
                    { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
                  ]
                ));
              }
              dispatch(
                createActivity(recipient.uid, {
                  ...notifications[1].success,
                  caseId,
                  date: moment().format(),
                  userId: currentUser?.uid || ''
                })
              );
            });
          }

          dispatch(
            setNotification({
              variant: 'success',
              vertical: 'top',
              message: alertMessages[1].success,
            })
          );

          const files = stepData.files.slice();

          return dispatch({
            type: UPDATE_CASE,
            case: { id: caseId, ...stepData, step: 2, access, files },
          });
        });
    })
    .catch((error) => console.log(error));
};

export const updateCase = (activeCase, currentStep, newFiles, rejected) => (dispatch, getState) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  const state = getState();
  const currentUser = state.user.currentUser;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const isTrainingMode = activeCase.trainingMode;
  const access = surgeon && hasAccess(currentUser, surgeon, activeCase.step, activeCase.procedureFlow, isTrainingMode);
  const surgeonId = activeCase.surgeon.id || state.cases.activeCase.surgeon.id;
  const storageRef = Firebase.storage.ref();

  delete activeCase.access;
  if (activeCase.sortDate) {
    delete activeCase.sortDate;
  }

  const files = activeCase.files.slice();

  files.forEach((item) => {
    delete item.downloadLink;
  });
  activeCase.files = files;

  dispatch({ type: LOAD_CASES });

  if (newFiles && newFiles.length) {
    const promises = [];
    const { files } = activeCase;

    newFiles.forEach((file) => {
      const currentFile = activeCase.files.find((item) => item.name === file.name);
      const type = currentFile && currentFile.type;

      if (type) {
        promises.push(storageRef.child(`${activeCase.id}/${activeCase.id}_${type}_${file.name}`).put(file));
      }
    });
    files.forEach((file) => {
      if (file.status === fileStatuses.REJECTED) {
        storageRef.child(`${activeCase.id}/${file.id}`).delete();
      }
    });

    return Promise.all(promises).then(() => {
      return collection
        .doc(activeCase.id)
        .update({
          ...activeCase,
          files: files.filter((file) => file.status !== fileStatuses.REJECTED),
          surgeon: Firebase.db.doc(`${SURGEONS_COLLECTION}/${surgeonId}`),
          previousStep: currentStep
        })
        .then(() => {
          dispatch(triggerNotification(activeCase, currentStep, rejected, activeCase.uploadFilesUid, currentUser.uid));

          return dispatch({
            type: UPDATE_CASE,
            case: { ...activeCase, access },
          });
        });
    });
  }

  return collection
    .doc(activeCase.id)
    .update({
      ...activeCase,
      surgeon: Firebase.db.doc(`${SURGEONS_COLLECTION}/${surgeonId}`),
      updatedAt: moment().format('YYYY-MM-DD hh:mm A'),
    })
    .then(() => {
      dispatch(triggerNotification(activeCase, currentStep, rejected, activeCase.uploadFilesUid, currentUser.uid));

      return dispatch({
        type: UPDATE_CASE,
        case: { ...activeCase, access },
      });
    });
};

export const simpleUpdateCase = (activeCase, newFiles) => async (dispatch) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  const surgeonId = activeCase.surgeon.id;
  const access = activeCase.access;
  const storageRef = Firebase.storage.ref();

  delete activeCase.access;
  if (activeCase.sortDate) {
    delete activeCase.sortDate;
  }

  const files = activeCase.files.slice();

  files.forEach((item) => {
    delete item.downloadLink;
  });

  if (newFiles && newFiles.length) {
    const promises = [];
    newFiles.forEach((file) => {
      const currentFile = activeCase.files.find((item) => item.name === file.name);
      const type = currentFile && currentFile.type;

      if (type) {
        promises.push(storageRef.child(`${activeCase.id}/${activeCase.id}_${type}_${file.name}`).put(file));
      }
    });
    await Promise.all(promises);
  }

  files.forEach((file) => {
    if (!file.downloadLink) {
      const currentFile = activeCase.files.find((item) => item.name === file.name);
      const type = currentFile && currentFile.type;

      Firebase.getDownloadLink(`${activeCase.id}/${activeCase.id}_${type}_${file.name}`).then(
        (url) => (file.downloadLink = url)
      );
    }
  });
  activeCase.files = files;

  const doc = {
    ...activeCase,
    files,
    surgeon: Firebase.db.doc(`${SURGEONS_COLLECTION}/${surgeonId}`),
  };

  return collection
    .doc(activeCase.id)
    .update(doc)
    .then(() => dispatch({ type: SET_ACTIVE_CASE, case: { ...activeCase, access } }));
};

export const deleteCaseFile = (activeCase, fileId) => async (dispatch) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  const storageRef = Firebase.storage.ref();
  const files = activeCase.files.slice();

  storageRef.child(`${activeCase.id}/${fileId}`).delete();

  if (activeCase.sortDate) {
    delete activeCase.sortDate;
  }

  return collection
    .doc(activeCase.id)
    .set({ files: files?.filter((f) => f.id !== fileId) }, { merge: true })
    .then(() => (
      dispatch({
        type: UPDATE_CASE,
        case: { ...activeCase, files: files?.filter((f) => f.id !== fileId) },
      })
    ))
};

export const updateCaseDoc = (caseId, data) => async (dispatch) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);
  return collection
    .doc(caseId)
    .set(data, { merge: true });
};

export const requestCaseDateUpdate = (activeCase, date, hospital) => async (dispatch, getState) => {
  const state = getState();
  const collection = Firebase.db.collection(CASES_COLLECTION);

  const users = state.users.list;
  const surgeons = state.surgeons.list;
  const distributors = state.distributors.list;
  const currentUser = state.user.currentUser;

  const doc = {
    requestedSurgeryDate: {
      currentDate: activeCase.date,
      newDate: date?.format(),
      userId: currentUser.uid,
      userName: `${currentUser.firstName} ${currentUser.lastName}`
    }
  }

  if (hospital) {
    doc.requestedSurgeryDate = {
      ...doc.requestedSurgeryDate,
      currentHospital: activeCase.hospital,
      newHospital: hospital
    }
  }

  await collection.doc(activeCase.id).set(doc, { merge: true });

  const trainingMode = state.sidebar.trainingMode || activeCase.trainingMode;

  if (!trainingMode) {
    const distributor = distributors?.find((d) => d.id === activeCase.distributorId);
    const surgeon = surgeons.find((s) => s.id === activeCase.surgeon.id);
    const surgeonUser = users.find((u) => u.uid === surgeon?.userId);

    users?.forEach((user) => {
      if (
        user.role === userRoles.GLOBAL_ADMIN.name ||
        (user.role === userRoles.COUNTRY_ADMIN.name && user.administrationCountry === distributor.country) ||
        (user.role === userRoles.DISTRIBUTOR_ADMIN.name && user.distributor === distributor.id) ||
        (user.role === userRoles.ENGINEER.name && user.engineerLevel === 1 && surgeon?.engineer?.includes(user.uid))
      ) {
        const parameters = [
          { key: 'CASE ID', value: activeCase?.formattedId || activeCase?.id },
          { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
          { key: 'SURGEON', value: surgeon && surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
          {
            key: 'CURRENT DOS',
            value: activeCase.date && moment(activeCase.date) ? moment(activeCase.date).format('DD/MM/YY') : 'TBC',
          },
          { key: 'NEW DOS', value: date && moment(date) ? moment(date).format('DD/MM/YY') : 'TBC' },
          { key: 'HOSPITAL', value: activeCase.hospital },
        ];

        if (hospital && hospital?.name) {
          parameters.push({ key: 'NEW HOSPITAL', value: hospital?.name || '' })
        }

        dispatch(sendCaseEmail(
          user.email,
          'Request for case date change',
          'Request to change case date has been submitted.',
          `User Requesting change: ${currentUser.firstName} ${currentUser.lastName}.`,
          true,
          parameters
        ));

        dispatch(
          createActivity(user.uid, {
            type: 'notification',
            title: 'Case Date Change Requested',
            text: 'User has requested a change to the case date',
            caseId: activeCase.id,
            date: moment().format(),
            userId: currentUser.uid || ''
          })
        );
      }
    });
  }
};

export const approveRequestDateUpdate = (activeCase) => async (dispatch, getState) => {
  const state = getState();
  const collection = Firebase.db.collection(CASES_COLLECTION);

  const users = state.users.list;
  const surgeons = state.surgeons.list;

  const doc = {
    date: activeCase?.requestedSurgeryDate?.newDate,
    requestedSurgeryDate: null
  };
  const newHospital = activeCase?.requestedSurgeryDate?.newHospital;
  if (newHospital) {
    doc.hospital = newHospital?.name || '';
    doc.hospitalUniq = {
      id: newHospital?.id || '',
      name: newHospital?.name || ''
    };
  }

  await collection.doc(activeCase.id).set(doc, { merge: true });

  const trainingMode = state.sidebar.trainingMode || activeCase.trainingMode;

  if (!trainingMode) {
    const requestUser = users?.find((u) => u.uid === activeCase?.requestedSurgeryDate?.userId);
    const surgeon = surgeons.find((s) => s.id === activeCase.surgeon.id);
    const surgeonUser = users.find((u) => u.uid === surgeon?.userId);

    if (requestUser && requestUser.email) {
      const parameters = [
        { key: 'CASE ID', value: activeCase?.formattedId || activeCase?.id },
        { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
        { key: 'SURGEON', value: surgeon && surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
        {
          key: newHospital ? 'NEW HOSPITAL' : 'HOSPITAL',
          value: newHospital ? newHospital?.name : activeCase.hospital
        },
        { key: 'NEW DOS', value: doc.date && moment(doc.date) ? moment(doc.date).format('DD/MM/YY') : 'TBC' },
      ];

      dispatch(sendCaseEmail(
        requestUser.email,
        'Case Date Change Request: APPROVED',
        'Your request to change the surgery date of the following case has been: APPROVED.',
        'Should you have any questions relating to this change, please contact Personalised Surgery.',
        true,
        parameters
      ));
    }

    if (!trainingMode) {
      users?.forEach((user) => {
        if (
          (user.role === userRoles.ENGINEER.name && surgeon?.engineer?.includes(user.uid)) ||
          (user.role === userRoles.SALES_REP.name && surgeon?.salesRep?.includes(user.uid)) ||
          (user.role === userRoles.PRACTICE_MANAGER.name && surgeon?.manager?.includes(user.uid)) ||
          (user.role === userRoles.LOGISTICS.name && surgeon?.logistics === user.uid) ||
          (user.role === userRoles.MANUFACTURER.name && surgeon?.manufacturer === user.uid) ||
          (user.role === userRoles.REGISTRAR.name && surgeon?.registrar?.includes(user.uid))
        ) {
          const parameters = [
            { key: 'CASE NUMBER', value: activeCase?.formattedId || activeCase?.id },
            { key: 'PATIENT REFERENCE', value: activeCase.patientInfo?.lastName || '' },
            { key: 'SURGEON', value: surgeon && surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
            {
              key: newHospital ? 'NEW HOSPITAL' : 'HOSPITAL',
              value: newHospital ? newHospital?.name : activeCase.hospital
            },
            { key: 'NEW CASE DATE',  value: doc.date && moment(doc.date) ? moment(doc.date).format('DD/MM/YY') : 'TBC' },
          ];

          if (user.uid !== activeCase?.requestedSurgeryDate?.userId) {
            dispatch(sendCaseEmail(
              user.email,
              'Case Date Change Request: APPROVED',
              'A new requested surgery date for the following case has been approved.',
              `Should you have any questions relating to this change, please contact Personalised Surgery.`,
              true,
              parameters
            ));
          }
        }
      });
    }
  }
};

export const rejectRequestDateUpdate = (activeCase) => async (dispatch, getState) => {
  const state = getState();
  const collection = Firebase.db.collection(CASES_COLLECTION);

  const users = state.users.list;
  const surgeons = state.surgeons.list;

  const doc = {
    requestedSurgeryDate: null
  };
  await collection.doc(activeCase.id).set(doc, { merge: true });

  const trainingMode = state.sidebar.trainingMode || activeCase.trainingMode;

  if (!trainingMode) {
    const requestUser = users?.find((u) => u.uid === activeCase?.requestedSurgeryDate?.userId);
    const surgeon = surgeons.find((s) => s.id === activeCase.surgeon.id);
    const surgeonUser = users.find((u) => u.uid === surgeon?.userId);

    if (requestUser && requestUser.email) {
      const parameters = [
        { key: 'CASE ID', value: activeCase?.formattedId || activeCase?.id },
        { key: 'PATIENT SURNAME', value: activeCase.patientInfo?.lastName || '' },
        { key: 'SURGEON', value: surgeon && surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' },
        { key: 'HOSPITAL', value: activeCase.hospital },
        { key: 'CURRENT DOS', value: activeCase.date && moment(activeCase.date) ? moment(activeCase.date).format('DD/MM/YY') : 'TBC' },
      ];

      dispatch(sendCaseEmail(
        requestUser.email,
        'Case Date Change Request: REJECTED',
        'Your request to change the surgery date of the following case has been: REJECTED.',
        'Personalised Surgery Admin team have rejected this change for specific operational or logistics reasons. Please contact the team to discuss further.',
        true,
        parameters
      ));
    }
  }
};

export const sendChangeDateHospitalEmail = (id, data, previousData) => async (dispatch, getState) => {
  const state = getState();

  const users = state.users.list;
  const currentUser = state.user.currentUser;
  const surgeon = users.find((user) => user.uid === previousData.surgeon.id);

  let emails = casesEmails;
  if (data.procedureFlow === procedureFlows.ACL) {
    emails = casesACLEmails;
  } else if (data.procedureFlow === procedureFlows.CMF) {
    emails = casesCMFEmails;
  } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(data.procedureFlow)) {
    emails = casesCMFExtendedEmails;
  }

  const dateEmail = emails['0'].updateDate;
  const hospitalEmail = emails['0'].updateHospital;

  const trainingMode = state.sidebar.trainingMode || previousData.trainingMode;

  if (!trainingMode) {
    if (data.date !== previousData.date) {
      const recipients = [];
      const roles = dateEmail.roles.map((role) => role.field);
      for (let key in previousData.surgeon) {
        if (roles.includes(key) && key !== 'surgeon' && !!previousData.surgeon[key]) {
          if (typeof previousData.surgeon[key] === 'string') {
            recipients.push(users.find((user) => user.uid === previousData.surgeon[key]));
          } else {
            previousData.surgeon[key].forEach((recipient) =>
              recipients.push(users.find((user) => user.uid === recipient))
            );
          }
        }
      }

      const parameters = [procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(data.procedureFlow) ? [
        { key: 'CASE ID', value: id?.includes("_") ? id?.substring(0, id?.indexOf("_")) : id },
        { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
      ] : [
        { key: 'CASE ID', value: id?.includes("_") ? id?.substring(0, id?.indexOf("_")) : id },
        { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
        {
          key: 'OLD DOS',
          value: previousData.date && moment(previousData.date) ? moment(previousData.date).format('DD/MM/YY') : 'TBC',
        },
        { key: 'NEW DOS', value: data.date && moment(data.date) ? moment(data.date).format('DD/MM/YY') : 'TBC' },
      ];
      const dateEmailParams = {
        caseId: id?.includes("_") ? id?.substring(0, id?.indexOf("_")) : id,
        oldDate: previousData.date && moment(previousData.date) ? moment(previousData.date).format('DD/MM/YY') : 'TBC',
        newDate: moment(data.date).format('DD/MM/YY'),
      };

      recipients.forEach((recipient) => {
        if (recipient?.uid !== currentUser?.uid) {
          dispatch(sendCaseEmail(
            recipient,
            dateEmail.subject,
            dateEmail.message(dateEmailParams),
            dateEmail.additionalText(dateEmailParams),
            true,
            parameters
          ));
        }
      });
    }

    if (data.hospital !== previousData.hospital) {
      const recipients = [surgeon];
      const roles = hospitalEmail.roles.map((role) => role.field);
      for (let key in previousData.surgeon) {
        if (roles.includes(key) && key !== 'surgeon' && !!previousData.surgeon[key]) {
          if (typeof previousData.surgeon[key] === 'string') {
            recipients.push(users.find((user) => user.uid === previousData.surgeon[key]));
          } else {
            previousData.surgeon[key].forEach((recipient) =>
              recipients.push(users.find((user) => user.uid === recipient))
            );
          }
        }
      }

      const hospitalParameters = [procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(data.procedureFlow) ? [
        { key: 'CASE ID', value: id?.includes("_") ? id?.substring(0, id?.indexOf("_")) : id },
        { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
      ] : [
        { key: 'CASE ID', value: id?.includes("_") ? id?.substring(0, id?.indexOf("_")) : id },
        { key: 'PATIENT SURNAME', value: data.patientInfo?.lastName || '' },
        { key: 'DOS', value: data.date ? moment(data.date).format('DD/MM/YY') : 'TBC' },
        { key: 'OLD HOSPITAL', value: previousData.hospital },
        { key: 'NEW HOSPITAL', value: data.hospital }
      ];
      const hospitalEmailParams = {
        caseId: id?.includes("_") ? id?.substring(0, id?.indexOf("_")) : id,
        oldHospital: previousData.hospital,
        newHospital: data.hospital,
      };

      recipients.forEach((recipient) => {
        if (recipient?.uid !== currentUser?.uid) {
          dispatch(sendCaseEmail(
            recipient,
            hospitalEmail.subject,
            hospitalEmail.message(hospitalEmailParams),
            hospitalEmail.additionalText(hospitalEmailParams),
            true,
            hospitalParameters
          ));
        }
      });
    }
  }
};

export const changePreopFile = (activeCase, file) => async (dispatch, getState) => {
  const state = getState();
  const userId = state.user.currentUser.uid;

  const collection = Firebase.db.collection(CASES_COLLECTION);
  const surgeonId = activeCase.surgeon.id;
  const storageRef = Firebase.storage.ref();

  delete activeCase.access;
  if (activeCase.sortDate) {
    delete activeCase.sortDate;
  }

  const oldPreop = activeCase.files.find((item) => item.type === 'preop');
  const preopIndex = activeCase.files.findIndex((item) => item.type === 'preop');
  const files = activeCase.files.slice();

  files.forEach((item) => {
    delete item.downloadLink;
  });

  files[preopIndex] = {
    id: `${activeCase.id}_${fileTypes.preop}_${file.name}`,
    name: file.name,
    type: fileTypes.preop,
    status: fileStatuses.APPROVED,
    date: moment().format(),
    userId,
  };

  activeCase.files = files;

  await storageRef.child(`${activeCase.id}/${oldPreop.id}`).delete();
  await storageRef.child(`${activeCase.id}/${activeCase.id}_${fileTypes.preop}_${file.name}`).put(file);

  return collection
    .doc(activeCase.id)
    .update({
      ...activeCase,
      surgeon: Firebase.db.doc(`${SURGEONS_COLLECTION}/${surgeonId}`),
    })
    .then(() => {
      return dispatch(
        setNotification({
          variant: 'success',
          vertical: 'top',
          message: 'Pre op file has been changed',
        })
      );
    });
};

export const confirmPlanningMeeting = (activeCase, planning) => async (dispatch, getState) => {
  const state = getState();
  const currentUser = state.user.currentUser;
  const users = state.users.list;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const surgeonUser = users?.find((u) => u.uid === surgeon.userId);

  if (activeCase.sortDate) {
    delete activeCase.sortDate;
  }

  await dispatch(simpleUpdateCase({ ...activeCase, planning }));

  const email = casesCMFExtendedEmails[3].planningConfirmed;

  const caseId = activeCase?.id?.includes("_") ? activeCase?.id?.substring(0, activeCase?.id?.indexOf("_")) : activeCase?.id;
  const planningLink = planning.meetingLink || '';
  const planningDate = planning?.requestPlanningDate ? moment(activeCase?.planning?.requestPlanningDate).format('DD/MM/YYYY, h:mm a') : '';


  if (surgeonUser) {
    if (surgeonUser.uid !== currentUser?.uid) {
      await dispatch(sendCaseEmail(
        surgeonUser.email,
        email.subject(),
        email.message({ ...activeCase, caseId }),
        '',
        true,
        [
          { key: 'MEETING LINK', value: planningLink },
          { key: 'DATE/TIME', value: planningDate },
        ]
      ));
    }

    dispatch(
      createActivity(surgeonUser.uid, {
        type: 'activity',
        title: 'Planning Meeting Confirmed',
        text: 'A requested planning meeting has now been confirmed',
        caseId: activeCase.id,
        date: moment().format(),
        userId: currentUser?.uid || '',
      }),
    );
  }
};

const triggerNotification = (activeCase, currentStep, rejected, customRecipientId, userId) => (dispatch, getState) => {
  const state = getState();
  const users = state.users.list;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const procedures = state.procedures.list;
  const currentUser = state.user.currentUser;

  const surgeonUser = users?.find((u) => u.uid === surgeon.userId);
  const procedure = procedures?.find((p) => p.id === activeCase?.procedure);

  const type = rejected ? 'reject' : 'success';

  let notifications = caseNotifications;
  if (activeCase.procedureFlow === procedureFlows.ACL) {
    notifications = caseACLNotifications;
  } else if (activeCase.procedureFlow === procedureFlows.CMF) {
    notifications = caseCMFNotifications;
  } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
    notifications = caseCMFExtendedNotifications;
  }

  let emails = casesEmails;
  if (activeCase.procedureFlow === procedureFlows.ACL) {
    emails = casesACLEmails;
  } else if (activeCase.procedureFlow === procedureFlows.CMF) {
    emails = casesCMFEmails;
  } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
    emails = casesCMFExtendedEmails;
  }

  let email = ![procedureFlows.ACL, procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.CUSTOM_EXTENDED, procedureFlows.ONCOL, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && activeCase.step === 7 && currentStep === 5 ?
    casesEmails[5].manufacturer : emails[currentStep]?.[type];

  const parameters = email && email.parameters ? email.parameters?.map((key) => {
    if (key === 'caseId') {
      return { key: 'CASE ID', value: activeCase.formattedId || activeCase.id };
    }
    if (key === 'lastName') {
      return { key: 'PATIENT SURNAME', value: activeCase?.patientInfo?.lastName || '' };
    }
    if (key === 'date') {
      return { key: 'DOS', value: activeCase.date ? moment(activeCase.date).format('DD/MM/YY') : 'TBC' };
    }
    if (key === 'surgeon') {
      return { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' }}
    if (key === 'hospital') {
      return { key: 'HOSPITAL', value: activeCase.hospital };
    }
    if (key === 'procedure') {
      return { key: 'PROCEDURE', value: procedure ? procedure.name : '' };
    }
    if (key === 'planningDate') {
      return { key: 'DATE/TIME', value: activeCase?.planning?.requestPlanningDate ? moment(activeCase?.planning?.requestPlanningDate).format('DD/MM/YYYY, h:mm a') : '' };
    }
    if (key === 'planningLink') {
      return { key: 'MEETING LINK', value: activeCase?.planning?.meetingLink || '' };
    }
    if (key === 'note') {
      return { key: 'NOTE', value: activeCase?.notes?.[`step${currentStep}`] || '' };
    }
    return { key: '', value: '' };
  }) : [];

  if (!activeCase.trainingMode) {
    if (currentStep === 2 && customRecipientId && rejected) {
      const recipient = users.find((user) => user.uid === customRecipientId);

      if (recipient?.uid !== currentUser?.uid) {
        dispatch(
          sendCaseEmail(
            recipient,
            email.subject,
            email.message({
              ...activeCase,
              caseId: activeCase.formattedId || activeCase.id,
            }),
            email.additionalText,
            true,
            parameters
          )
        );
      }
      dispatch(
        createActivity(recipient.uid, {
          ...notifications[currentStep][type],
          caseId: activeCase.id,
          date: moment().format(),
          userId: userId || ''
        })
      );
    }

    if ([procedureFlows.ACL, procedureFlows.CMF].includes(activeCase.procedureFlow) && currentStep > 5) {
      email = null;
    }

    if (email && currentStep !== 2) {
      email.roles.forEach((role) => {
        const recipientId = role.name === roleNames.SURGEON ? surgeon.userId : surgeon[role.field];

        if (!recipientId) {
          return;
        }

        // if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && currentStep === 3 && activeCase.step === 5 && role.name === roleNames.SURGEON) {
        //   return; // Don't send email to surgeon in CMF Extended flow after step 3 if surgeon approve is not required
        // }

        if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && currentStep === 3 && !activeCase.planning?.planApproveRequired) {
          return; // Don't send email to surgeon in CMF Extended flow after step 3 if surgeon approve is not required
        }

        const recipient =
          typeof recipientId === 'string'
            ? users.find((user) => user.uid === recipientId)
            : recipientId.map((recipientItem) => users.find((userItem) => userItem.uid === recipientItem));

        if (typeof recipientId === 'string') {
          if (recipientId !== currentUser?.uid) {
            dispatch(
              sendCaseEmail(
                recipient,
                email.subject({ ...activeCase, recipient }),
                email.message({
                  ...activeCase,
                  recipient,
                  caseId: activeCase.formattedId || activeCase.id,
                }),
                email.additionalText({ ...activeCase, recipient }),
                true,
                parameters
              )
            );
          }
          dispatch(
            createActivity(recipient.uid, {
              ...notifications[currentStep][type],
              caseId: activeCase.id,
              date: moment().format(),
              userId: userId || ''
            })
          );
        } else {
          recipient.forEach((recipient) => {
            if (recipient?.uid !== currentUser?.uid) {
              dispatch(
                sendCaseEmail(
                  recipient,
                  email.subject({ ...activeCase, recipient }),
                  email.message({
                    ...activeCase,
                    recipient,
                    caseId: activeCase.formattedId || activeCase.id,
                  }),
                  email.additionalText({ ...activeCase, recipient }),
                  true,
                  parameters
                )
              );
            }
            dispatch(
              createActivity(recipient.uid, {
                ...notifications[currentStep][type],
                caseId: activeCase.id,
                date: moment().format(),
                userId: userId || ''
              })
            );
          });
        }
      });
    }
  }

  let messages;

  if (activeCase.procedureFlow === procedureFlows.ACL) {
    messages = alertAclMessages;
  } else if (activeCase.procedureFlow === procedureFlows.CMF) {
    messages = alertCmfMessages;
  } else if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
    messages = alertCmfExtendedMessages;
  } else {
    messages = alertMessages;
  }

  const message = messages[currentStep][type];

  if (message) {
    dispatch(
      setNotification({
        variant: rejected ? 'warning' : 'success',
        vertical: 'top',
        message,
      })
    );
  }
};

// const onNoteAdded = (activeCase, note) => (dispatch, getState) => {
//   const state = getState();
//   const userId = state.user.currentUser.uid;
//   const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
//   const users = state.users.list;
//
//   if (!activeCase.trainingMode) {
//     const recipients = [];
//
//     users?.forEach((user) => {
//       if ([roleNames.GLOBAL_ADMIN, roleNames.COUNTRY_ADMIN, roleNames.DISTRIBUTOR_ADMIN].includes(user.role)) {
//         recipients?.push(user.uid);
//       } else if (user.role === roleNames.SURGEON && surgeon.userId === user.uid) {
//         recipients?.push(user.uid);
//       } else {
//         const roleField = userRoles[ user.role ]?.field || '';
//         const recipientId = surgeon[ roleField ];
//
//         if (( typeof recipientId === 'string' && user.uid === recipientId ) || recipientId?.includes(user.uid)) {
//           recipients?.push(user.uid);
//         }
//       }
//     });
//
//     recipients?.forEach((recipient) => {
//       dispatch(
//         createActivity(recipient.uid, {
//           type: 'notification',
//           title: 'Note Added',
//           text: note,
//           caseId: activeCase.id,
//           date: moment().format(),
//           userId: userId || ''
//         })
//       );
//     });
//   }
// };

export const notifyUsers = (activeCase) => (dispatch, getState) => {
  if (!activeCase) {
    return;
  }

  const state = getState();
  const users = state.users.list;
  const procedures = state.procedures.list;
  const currentUser = state.user.currentUser;
  const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
  const type = activeCase.status === statuses.rejected ? 'reject' : 'success';
  const previousStep = activeCase.previousStep || activeCase.step - 1;

  const surgeonUser = users?.find((u) => u.uid === surgeon.userId);
  const procedure = procedures?.find((p) => p.id === activeCase?.procedure);

  let email = casesEmails[previousStep][type];
  const parameters = email && email.parameters ? email.parameters?.map((key) => {
    if (key === 'caseId') {
      return { key: 'CASE ID', value: activeCase.formattedId || activeCase.id };
    }
    if (key === 'lastName') {
      return { key: 'PATIENT SURNAME', value: activeCase?.patientInfo?.lastName || '' };
    }
    if (key === 'date') {
      return { key: 'DOS', value: activeCase.date ? moment(activeCase.date).format('DD/MM/YY') : 'TBC' };
    }
    if (key === 'surgeon') {
      return { key: 'SURGEON', value: surgeonUser ? `${surgeonUser.firstName} ${surgeonUser.lastName}` : '' }}
    if (key === 'hospital') {
      return { key: 'HOSPITAL', value: activeCase.hospital };
    }
    if (key === 'procedure') {
      return { key: 'PROCEDURE', value: procedure ? procedure.name : '' };
    }
    return { key: '', value: '' };
  }) : [];

  if (!activeCase.trainingMode) {
    if ([procedureFlows.ACL, procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && previousStep === 6) {
      email = null;
    }

    if (email) {
      email.roles.forEach((role) => {
        const recipientId = role.name === roleNames.SURGEON ? surgeon.userId : surgeon[role.field];

        if (!recipientId) {
          return;
        }

        const recipient =
          typeof recipientId === 'string'
            ? users.find((user) => user.uid === recipientId)
            : recipientId.map((recipientItem) => users.find((userItem) => userItem.uid === recipientItem));

        if (typeof recipientId === 'string') {
          if (recipientId !== currentUser?.uid) {
            dispatch(sendCaseEmail(
              recipient,
              email.subject({ ...activeCase, recipient }),
              email.message({
                ...activeCase,
                recipient,
                caseId: activeCase.formattedId || activeCase.id,
              }),
              email.additionalText({ ...activeCase, recipient }),
              true,
              parameters
            ));
          }
          if (recipient && recipient.phone) {
            const phone = recipient.phone || '';
            const text = email.message({ ...activeCase, recipient, caseId: activeCase.formattedId || activeCase.id }) || '';
            api.post(urls.sendSms, { phone, text, parameters });
          }
        } else {
          recipient.forEach((recipient) => {
            if (recipient?.uid !== currentUser?.uid) {
              dispatch(sendCaseEmail(
                recipient,
                email.subject({ ...activeCase, recipient }),
                email.message({
                  ...activeCase,
                  recipient,
                  caseId: activeCase.formattedId || activeCase.id,
                }),
                email.additionalText({ ...activeCase, recipient }),
                true
              ));
            }

            if (recipient && recipient.phone) {
              const phone = recipient.phone || '';
              const text = email.message({ ...activeCase, recipient, caseId: activeCase.formattedId || activeCase.id }) || '';
              api.post(urls.sendSms, { phone, text, parameters });
            }
          });
        }
      });
    }
  }

  dispatch(setNotification({ variant: 'success', vertical: 'bottom', message: 'Notifications were sent via SMS and email' }));
};

export const setActiveCase = (newCase) => (dispatch, getState) => {
  const state = getState();
  const procedures = state.procedures.list;

  if (!newCase.procedureFlow) {
    const procedureFlow = procedures.find((pr) => pr.id === newCase.procedure)?.flow;
    newCase.procedureFlow = procedureFlow;
  }

  dispatch({ type: SET_ACTIVE_CASE, case: newCase });
};

export const setActiveCaseById = (caseId) => (dispatch, getState) => {
  const state = getState();
  const cases = state.cases.list;
  const newCase = cases && !!cases.length && cases.find((item) => item.id === caseId);

  if (newCase) {
    return dispatch({ type: SET_ACTIVE_CASE, case: newCase });
  }
};

export const discardActiveCase = () => (dispatch) => {
  return setTimeout(() => dispatch({ type: DISCARD_ACTIVE_CASE }), 500);
};

export const resetCases = () => ({ type: RESET_CASES });

export const deleteCase = (caseId) => (dispatch) => {
  const collection = Firebase.db.collection(CASES_COLLECTION);

  return collection
    .doc(caseId)
    .set({ archived: true }, { merge: true })
    .then(() => dispatch(discardActiveCase()));
};

export const setNotes = (notes) => ({ type: SET_CASE_NOTES, notes });

export const createNote = (activeCase, note) => async (dispatch, getState) => {
  const state = getState();
  const caseId = activeCase.id;
  const uid = state.user.currentUser.uid;
  const users = state.users.list || [];
  const surgeons = state.surgeons.list || [];
  const collection = Firebase.db.collection(NOTES_SUB_COLLECTION(caseId));
  const doc = { ...note, userId: uid, date: moment().format() };
  const currentUser = state.user.currentUser;

  const docRef = await collection.add(doc);
  dispatch({ type: ADD_CASE_NOTE, id: docRef.id, note: doc });

  // Send email and create notification

  let recipients = [];
  const surgeonDoc = surgeons?.find((s) => s.id === activeCase.surgeonId);

  const engineers = typeof surgeonDoc.engineer === 'string' ? [surgeonDoc.engineer] : surgeonDoc.engineer;
  // const logistics = typeof surgeonDoc.logistics === 'string' ? [surgeonDoc.logistics] : surgeonDoc.logistics;
  // const manufacturers = typeof surgeonDoc.manufacturer === 'string' ? [surgeonDoc.manufacturer] : surgeonDoc.manufacturer;
  const salesReps = typeof surgeonDoc.salesRep === 'string' ? [surgeonDoc.salesRep] : surgeonDoc.salesRep;

  engineers?.forEach((uid) => {
    const user = users?.find((u) => u.uid === uid);
    if (user && user.email && !recipients?.map((r) => r.email)?.includes(user.email)) {
      recipients?.push(user);
    }
  });

  if (note?.external) {
    // logistics?.forEach((uid) => {
    //   const user = users?.find((u) => u.uid === uid);
    //   if (user && user.email && !recipients?.map((r) => r.email)?.includes(user.email)) {
    //     recipients?.push(user);
    //   }
    // });
    // manufacturers?.forEach((uid) => {
    //   const user = users?.find((u) => u.uid === uid);
    //   if (user && user.email && !recipients?.map((r) => r.email)?.includes(user.email)) {
    //     recipients?.push(user);
    //   }
    // });
    salesReps?.forEach((uid) => {
      const user = users?.find((u) => u.uid === uid);
      if (user && user.email && !recipients?.map((r) => r.email)?.includes(user.email)) {
        recipients?.push(user);
      }
    });

    // Add surgeon
    const surgeon = activeCase.surgeon || state.cases.activeCase.surgeon;
    const surgeonUser = users?.find((user) => user.uid === surgeon.userId);
    if (surgeonUser) {
      recipients?.push(surgeonUser);
    }
  }

  // Add admins
  // recipients = [...recipients, ...users?.filter((user) => [roleNames.GLOBAL_ADMIN, roleNames.COUNTRY_ADMIN, roleNames.DISTRIBUTOR_ADMIN].includes(user.role))];

  recipients = recipients?.filter((user) => user.uid !== currentUser.uid);

  Promise.all(recipients?.map((recipient) => (
    dispatch(sendCaseEmail(
      recipient.email,
      'New Case Note',
      `New note was added for the case ${activeCase?.formattedId || activeCase?.id}`,
      note.text
    ))
  )));
  Promise.all(recipients?.map((recipient) => (
    dispatch(
      createActivity(
        recipient.uid,
        {
          type: 'notification',
          title: 'New Case Note',
          text: note.text,
          caseId: activeCase.id,
          date: moment().format(),
          userId: uid || ''
        }
      )
    )
  )));
};

export const fetchNotes = (caseId) => (dispatch) => {
  return Firebase.db
    .collection(NOTES_SUB_COLLECTION(caseId))
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
    .then((notes) => {
      dispatch(setNotes(orderBy(notes, 'date')))
    });
};

const sendCaseEmail = (recipient, subject, message, additionalText, isCase, parameters) => async (dispatch, getState) => {
  const state = getState();
  const notificationsSettings = state.settings?.notifications;

  if (notificationsSettings.active) {
    return sendEmail(recipient, subject, message, additionalText, isCase, parameters)
  }
};
