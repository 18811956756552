import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import activityIcon from '../../../assets/activity_icon.svg';
import notificationIcon from '../../../assets/notification_icon.svg';
import fileIcon from '../../../assets/preop_icon.svg';

const CaseActivity = ({ activeCase, users = [] }) => {
  const getIcon = (type) => {
    switch (type) {
      case 'notification':
        return notificationIcon;
      case 'activity':
        return activityIcon;
      case 'file':
        return fileIcon;
      default:
        return notificationIcon;
    }
  };

  const getUserName = (uid) => {
    const user = users?.find((u) => u.uid === uid);
    return user ? ` | ${user.firstName} ${user.lastName}` : '';
  };

  return (
    <div className="case-activity-container">
      {activeCase && activeCase.activity && activeCase.activity.length ? (
        <div className="case-data-list">
          {activeCase.activity.map((item) => (
            <div key={`${item.title}-${item.date}`} className="data-list-item">
              <div className="d-flex flex-1">
                <img src={getIcon(item.type)} alt="activity-icon" />
                <div className="list-item-main">
                  <div className="list-item-title">{item.title}</div>
                  <div className="list-item-date">
                    {`${moment(item.date).format("MMM Do YY")} | ${moment(item.date).format("HH:mm")}${getUserName(item.userId)}`}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-list">
          <img src={activityIcon} alt="activity-icon" />
          No Activity
        </div>
      )}
    </div>
  );
};

CaseActivity.propTypes = {
  activeCase: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    users: state.users?.list || [],
  };
};

export default connect(mapStateToProps)(CaseActivity);
