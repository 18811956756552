import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '../Modal';
import CaseProgress from './CaseProgress';
import CaseActivity from './CaseActivity';
import CaseFiles from './CaseFiles';
import CaseNotes from './CaseNotes';
import CaseExport from './CaseExport';
import CaseNote from './CaseNote';
import AlertModal from '../AlertModal';

import {
  fetchUsers,
  fetchProcedures,
  fetchHospitals,
  fetchSurgeons,
  fetchDistributors,
} from '../../../actions/adminActions';
import { setActiveCase, setActiveTab, deleteCase, fetchNotes, notifyUsers, simpleUpdateCase } from '../../../actions/casesActions';

import { roleNames } from '../../../constants/userRoles';
import { caseConstants, urls } from '../../../constants';

import { withFirebase } from '../../../firebase';
import { getFile } from '../../../util/api';

import './case-modal.scss';

const { fileTypes, procedureFlows } = caseConstants;

const CaseModal = (props) => {
  const {
    open,
    fetchHospitals,
    fetchProcedures,
    fetchSurgeons,
    fetchDistributors,
    fetchUsers,
    fetchNotes,
    activeCase,
    activeTab,
    setActiveTab,
    setActiveCase,
    firebase,
    currentUser,
    deleteCase,
    trainingMode,
    selectedFlow,
    notifyUsers,
    simpleUpdateCase,
    onRequestDateClick,
    onTBCClick,
    onAdminApproveDateClick
  } = props;

  const [loading, setLoading] = useState(false);
  const [isOpen, toggleModal] = useState(false);

  const [tabModalOpen, toggleTabModal] = useState(false);
  const [nextTab, setNextTab] = useState(undefined);

  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [exportState, toggleExportState] = useState(false);
  const [noteState, toggleNoteState] = useState(false);
  const [exporting, toggleExporting] = useState(false);

  const [undoModalOpen, toggleUndoModal] = useState(false);

  const onClose = (isFirstStep, caseId) => {
    toggleExportState(false);
    props.onClose(isFirstStep, selectedFlow, caseId);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);

      const files = activeCase && activeCase.files ? activeCase.files.slice() : [];
      const downloadLinks = [];

      files.forEach((file) => {
        downloadLinks.push(firebase.getDownloadLink(`${activeCase.id}/${file.id}`));
      });

      const promises = [
        fetchUsers(),
        fetchProcedures(),
        fetchHospitals(),
        fetchSurgeons(),
        fetchDistributors(),
      ];

      Promise.all(promises).finally(() => setLoading(false));
      Promise.allSettled(downloadLinks)
        .then((links) => {
          files.forEach((file, index) => {
            const result = links[index];
            file.downloadLink = result?.status === "fulfilled" ? result.value : null;
          });

          const newCase = { ...activeCase, files };

          setActiveCase(newCase);
        })
        .then(() => fetchNotes(activeCase.id))
        .catch((err) => console.log(err));
    }
  }, [open]); // eslint-disable-line

  const handleClose = (isFirstStep) => {
    if (activeCase && (activeCase.step === 1 || activeCase.step === 9) && activeTab === 0) {
      toggleModal(true);
    } else {
      onClose(isFirstStep);
    }
  };

  const deleteAllFiles = () => {
    const storageRef = firebase.storage.ref();
    const promises = [];

    storageRef
      .child(caseId)
      .listAll()
      .then((data) => {
        const files = activeCase.files?.filter((file) => [fileTypes.xRay, fileTypes.scan, fileTypes.mri].includes(file.type)).map((file) => `${caseId}/${file.id}`);

        data.items.forEach((file) => {
          const path = file.location.path;

          if (files.includes(path) && activeCase.step === 1) {
            promises.push(storageRef.child(file.location.path).delete());
          }
        });
      });

    Promise.all(promises);
  };

  const deleteCocDocumenFile = () => {
    const storageRef = firebase.storage.ref();
    const promises = [];

    storageRef
      .child(caseId)
      .listAll()
      .then((data) => {
        data.items.forEach((file) => {
          const path = file.location.path;
          if (path.includes(`_${fileTypes.coc}_`)) {
            promises.push(storageRef.child(file.location.path).delete());
          }
        });
      });

    Promise.all(promises);
  };

  const closeCaseModal = async () => {
    if (activeCase.step === 1) {
      await deleteAllFiles();
    }

    if (activeCase.step === 9) {
      await deleteCocDocumenFile();
    }

    toggleModal(false);
    onClose();
  };

  const onDeleteCase = async () => {
    toggleDeleteModal(false);
    await deleteCase(activeCase.id);
    // deleteAllFiles();
    onClose();
  };

  const onExport = async (address, phoneNumber) => {
    toggleExporting(true);

    try {
      await getFile(urls.exportCase, { caseId, address, phoneNumber }, caseId);
      toggleExportState(false);
    } catch (err) {
      console.error(err);
    } finally {
      toggleExporting(false);
    }
  };

  const onRevert = async () => {
    try {
      if (activeCase?.step > 0) {
        const doc = {
          ...activeCase,
          step: activeCase?.step - 1
        }
        if ([procedureFlows.HTO, procedureFlows.HTO_LATERAL, procedureFlows.DFO_Lateral, procedureFlows.DFO_Medial].includes(activeCase?.procedureFlow) && activeCase.step === 3) {
          doc.alignmentData = {};
        }
        await simpleUpdateCase(doc);
        toggleUndoModal(false);
        handleClose();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderContent = () => {
    if (exportState) {
      return (
        <CaseExport
          onClose={() => toggleExportState(false)}
          onSubmit={onExport}
          loading={exporting}
        />
      );
    }

    if (noteState) {
      return (
        <CaseNote
          onClose={() => toggleNoteState(false)}
          caseNote={activeCase?.caseNote}
        />
      );
    }

    switch (activeTab) {
      case 0:
        return <CaseProgress onClose={onClose} toggleModal={toggleModal} />;
      case 1:
        return <CaseActivity onClose={onClose} />;
      case 2:
        return <CaseFiles onClose={onClose} />;
      case 3:
        return <CaseNotes onClose={onClose} />;
      default:
        return <CaseProgress />;
    }
  };

  const tabs = useMemo(() => {
    const result = [
      { label: 'Process' },
      { label: 'Activity', disabled: !activeCase || activeCase.step === 0 },
      { label: 'Files', disabled: !activeCase || activeCase.step === 0 },
      { label: 'Notes', disabled: !activeCase || activeCase.step === 0 },
    ];

    // if (currentUser.role !== roleNames.SURGEON && currentUser.role !== roleNames.PRACTICE_MANAGER) {
    //   result?.push({ label: 'Notes' });
    // }

    return result;
  }, [currentUser.role, activeCase]);
  const caseId = activeCase && activeCase.id;
  const noTabs =
    activeCase &&
    (
      // (
        // ((activeCase.step === 4 && ([procedureFlows.DFO_Lateral, procedureFlows.DFO_Medial, procedureFlows.HTO, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY, procedureFlows.ONCOL].includes(activeCase.procedureFlow)))
        // (activeCase.step === 1) && activeCase.access) ||
      !activeCase.step || exportState
    );
  const isAdmin = currentUser.role === roleNames.GLOBAL_ADMIN || currentUser.role === roleNames.COUNTRY_ADMIN;
  const isGlobalAdmin = currentUser.role === roleNames.GLOBAL_ADMIN;
  const isSeniorEngineer = currentUser.role === roleNames.ENGINEER && currentUser.engineerLevel === 1;

  const isTrainingModeTurnedOn = () => {
    if (activeCase) {
      return (
        (!activeCase.step && trainingMode) || (activeCase.step !== 0 && activeCase.trainingMode)
      );
    }
    return false;
  };

  const onTabChange = (value) => {
    if (activeCase.step === 1 && activeTab === 0) {
      setNextTab(value);
      toggleTabModal(true);
    } else {
      setActiveTab(value);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onTabChange={onTabChange}
      title={caseId ? (activeCase?.formattedId || activeCase?.id) : 'New Case'}
      subtitle={caseId && 'Case ID'}
      caseId={caseId}
      tabs={!noTabs ? tabs : null}
      activeTab={activeTab}
      size="lg"
      isCase
      isAdmin={isAdmin}
      isGlobalAdmin={isGlobalAdmin}
      isSeniorEngineer={isSeniorEngineer}
      onDeleteClick={() => toggleDeleteModal(true)}
      onExportClick={() => toggleExportState(true)}
      step={activeCase ? activeCase.step : null}
      trainingMode={isTrainingModeTurnedOn()}
      flow={activeCase?.procedureFlow ? activeCase?.procedureFlow : selectedFlow}
      withExport
      isPaid={activeCase?.procedureFlow === procedureFlows.ACL && !!activeCase.isPaid}
      caseNote={activeCase?.caseNote}
      onFilesClick={() => setActiveTab(2)}
      onCaseNoteClick={() => setActiveTab(3)}
      onNotifyUsersClick={() => notifyUsers(activeCase)}
      onUndoClick={() => toggleUndoModal(true)}
      onAdminApproveDateClick={onAdminApproveDateClick}
      onTBCClick={onTBCClick}
      onRequestDateClick={onRequestDateClick}
      activeCase={activeCase}
    >
      {loading ? (
        <div className="loading-container">
          <CircularProgress />
        </div>
      ) : (
        <div className={`case-modal-container ${noTabs && 'no-tabs'}`}>{renderContent()}</div>
      )}

      {activeCase && (
        <AlertModal
          open={isOpen}
          onClose={() => toggleModal(false)}
          onSubmit={closeCaseModal}
          title="Are you sure?"
          text="Closing this step will cancel all active uploads"
          submitText="Close"
        />
      )}
      {activeCase && (
        <AlertModal
          open={tabModalOpen}
          onClose={() => toggleTabModal(false)}
          onSubmit={() => {
            setActiveTab(nextTab);
            toggleTabModal(false);
            setNextTab(undefined);
            deleteAllFiles();
          }}
          title="Are you sure?"
          text="Changing the tab will cancel all active uploads"
          submitText="Submit"
        />
      )}
      {(isAdmin || isSeniorEngineer) && (
        <AlertModal
          open={deleteModalOpen}
          onClose={() => toggleDeleteModal(false)}
          onSubmit={onDeleteCase}
          title="Are you sure?"
          text="Deleting this case will also delete all related files"
          submitText="Delete"
        />
      )}
      {isAdmin && (
        <AlertModal
          open={undoModalOpen}
          onClose={() => toggleUndoModal(false)}
          onSubmit={onRevert}
          title="Go Back to Previous Step?"
          text="Are you sure you want revert this case back to the previous step, as all data and files for that step will be lost!"
          closeText="Cancel"
          submitText="Revert"
        />
      )}
    </Modal>
  );
};

CaseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  currentUser: PropTypes.object,
  activeCase: PropTypes.object,
  fetchUsers: PropTypes.func,
  fetchHospitals: PropTypes.func,
  fetchProcedures: PropTypes.func,
  fetchSurgeons: PropTypes.func,
  setActiveTab: PropTypes.func,
  setActiveCase: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    activeCase: state.cases.activeCase,
    activeTab: state.cases.activeTab,
    selectedFlow: state.cases.selectedFlow,
    trainingMode: state.sidebar.trainingMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    fetchHospitals: () => dispatch(fetchHospitals()),
    fetchProcedures: () => dispatch(fetchProcedures()),
    fetchSurgeons: () => dispatch(fetchSurgeons()),
    fetchDistributors: () => dispatch(fetchDistributors()),
    fetchNotes: (caseId) => dispatch(fetchNotes(caseId)),
    setActiveTab: (tab) => dispatch(setActiveTab(tab)),
    setActiveCase: (activeCase) => dispatch(setActiveCase(activeCase)),
    deleteCase: (caseId) => dispatch(deleteCase(caseId)),
    notifyUsers: (activeCase) => dispatch(notifyUsers(activeCase)),
    simpleUpdateCase: (data) => dispatch(simpleUpdateCase(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(CaseModal));
