import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Select, MenuItem, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { caseConstants } from '../../../../constants';

const HardwareList = ({
  classes,
  hardwareList = [],
  products = [],
  onAdd,
  onRemove,
  onAmountChange,
  onCategoryChange,
  onChange,
  title,
  procedureFlow
}) => {
  const filteredProducts = products.map((item) => `${item.sku} — ${item.description}`);

  const hardwareCategories = procedureFlow === caseConstants.procedureFlows.ACL ? ['Allograft', 'Procedure Pack'] :caseConstants.hardwareCategories;

  const [addMode, toggleAddMode] = useState(false);
  const [newHardware, setNewHardware] = useState(filteredProducts[0]);
  const [amount, setAmount] = useState(1);
  const [category, setCategory] = useState(hardwareCategories[0]);
  const [hasError, setHasError] = useState(false);

  const handleAdd = () => {
    if (!newHardware || !category || !amount) {
      setHasError(true);
      return;
    }

    const id = Math.random();
    const hardware = { id, product: newHardware, amount, category };

    onAdd(hardware);
    setAmount(1);
    setCategory('');
    toggleAddMode(false);
    setHasError(false);
  };

  return (
    <div className="hardware-list-container">
      <div className="hardware-list-header">
        <div className="secondary uppercase font-size-sm">{title}</div>
        <div className={`${classes.primary} font-size-sm pointer`} onClick={() => toggleAddMode(true)}>
          Add hardware
        </div>
      </div>
      <div className="hardware-list">
        {hardwareList.map((hardware) => (
          <div className="d-flex m-t-md" key={hardware?.id}>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={12}>
                <Select
                  className="category-select"
                  disabled={addMode}
                  fullWidth
                  value={hardware.category}
                  onChange={(e) => onCategoryChange(hardware.id, e.target.value)}
                >
                  {hardwareCategories.map((categoryItem) => (
                    <MenuItem key={categoryItem} value={categoryItem}>
                      {categoryItem}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item sm={7} xs={9}>
                <Select
                  id="user-select"
                  className="user-select"
                  fullWidth
                  disabled={addMode}
                  value={hardware.product}
                  onChange={(e) => onChange(hardware.id, e.target.value)}
                >
                  {filteredProducts.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  className="hardware-amount"
                  value={hardware.amount}
                  onChange={(e) => onAmountChange(hardware.id, e.target.value)}
                  inputProps={{ type: 'number', min: 1 }}
                />
              </Grid>

              <div className="d-flex flex-center" onClick={() => onRemove(hardware.id)}>
                <CloseIcon className="pointer" color="primary" fontSize="small" />
              </div>
            </Grid>
          </div>
        ))}
      </div>
      {addMode && (
        <div className="d-flex new-hardware">
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <Select
                error={hasError}
                className="category-select"
                value={category}
                label="category"
                fullWidth
                onChange={(e) => {
                  setCategory(e.target.value);
                  setHasError(false);
                }}
              >
                {hardwareCategories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item sm={6} xs={7}>
              <Select
                id="user-select"
                className="user-select"
                fullWidth
                value={newHardware}
                onChange={(e) => setNewHardware(e.target.value)}
              >
                {filteredProducts.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <TextField
                className="hardware-amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                inputProps={{ type: 'number', min: 1 }}
              />
            </Grid>
            <div className="d-flex">
              <div className={`${classes.primary} pointer m-r-md`} onClick={handleAdd}>
                Add
              </div>
              <div className="secondary pointer" onClick={() => toggleAddMode(false)}>
                Cancel
              </div>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
};

HardwareList.propTypes = {
  hardwareList: PropTypes.array,
  products: PropTypes.array,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onAmountChange: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

export default HardwareList;
