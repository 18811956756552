const firebaseConfig = {
  apiKey: 'AIzaSyBDpCj_98Hf7sDDdoKT5gviKXDZzsGxAyc',
  authDomain: 'personalised-surgery.firebaseapp.com',
  databaseURL: 'https://personalised-surgery.firebaseio.com',
  projectId: 'personalised-surgery',
  storageBucket: 'personalised-surgery.appspot.com',
  messagingSenderId: '123890557678',
  appId: '1:123890557678:web:841b56cc52a949986d89ff',
  measurementId: 'G-3FQ6WS03DN',
  // Push Notifications Key
  vapidKey: 'BF_oC_GuiVNadOt25iCzamfVc0plchliax-RSGoZI8mhyvujxFHt52qSAUm3-lnQkOwXCNjSnWvVFw13kDP2qx4', // TODO: Change to prod key
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyCFopEl54eNuTvQz25aNXY_RJm0Ma8d8U8',
  authDomain: 'ps-dev-environment.firebaseapp.com',
  projectId: 'ps-dev-environment',
  storageBucket: 'ps-dev-environment.appspot.com',
  messagingSenderId: '247146485673',
  appId: '1:247146485673:web:3045685be99ae513ed16b8',
  measurementId: 'G-6BEKZSX442',
  // Push Notifications Key
  vapidKey: 'BF_oC_GuiVNadOt25iCzamfVc0plchliax-RSGoZI8mhyvujxFHt52qSAUm3-lnQkOwXCNjSnWvVFw13kDP2qx4',
};

export default firebaseConfig;
