import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import HardwareList from '../../HardwareList';
import RestrictComponent from '../../../../../shared/RestrictComponent';

import blockIcon from '../../../../../../assets/block_icon.svg';
import preopIcon from '../../../../../../assets/preop_icon.svg';

import { setActiveTab, simpleUpdateCase } from '../../../../../../actions/casesActions';

import { userRoles } from '../../../../../../constants';
import { fileTypes } from '../../../../../../constants/cases';

const useStyles = makeStyles((theme) => ({
  primary: { color: theme.palette.primary.main },
}));

const CmfExtendedStep5Static = ({ activeCase, goToFiles, userRole, products, updateCase }) => {
  const { files, alignmentData, components, leftMandible, rightMandible, genioplasty } = activeCase;
  const classes = useStyles();
  const {
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  } = userRoles;

  const initialHardwareList = components ? components.map((item) => ({ ...item, id: Math.random() * Math.random() })) : [];
  // const initialLeftMandibleList = leftMandible
  //   ? leftMandible.map((item) => ({ ...item, id: Math.random() * Math.random() }))
  //   : [];
  // const initialRightMandibleList = rightMandible
  //   ? rightMandible.map((item) => ({ ...item, id: Math.random() * Math.random() }))
  //   : [];
  // const initialGenioplastyList = genioplasty
  //   ? genioplasty.map((item) => ({ ...item, id: Math.random() * Math.random() }))
  //   : [];

  const [maxillaList, setMaxillaList] = useState(initialHardwareList);
  // const [leftMandibleList, setLeftMandibleList] = useState(initialLeftMandibleList);
  // const [rightMandibleList, setRightMandibleList] = useState(initialRightMandibleList);
  // const [genioplastyList, setGenioplastyList] = useState(initialGenioplastyList);

  const [isEditMode, setEditMode] = useState(false);
  const [isEditLeftMandibleMode, setEditLeftMandibleMode] = useState(false);
  const [isEditRightMandibleMode, setEditRightMandibleMode] = useState(false);
  const [isEditGenioplastyMode, setEditGenioplastyMode] = useState(false);

  useEffect(() => {
    if (components) {
      setMaxillaList(components.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    }
    // if (leftMandible) {
    //   setLeftMandibleList(leftMandible.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    // }
    // if (rightMandible) {
    //   setRightMandibleList(rightMandible.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    // }
    // if (rightMandible) {
    //   setRightMandibleList(rightMandible.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    // }
  }, [components]);

  const notes = activeCase.notes && activeCase.notes.step5;

  const isPermission = !activeCase.trainingMode
    ? [GLOBAL_ADMIN, COUNTRY_ADMIN]
    : [
        GLOBAL_ADMIN,
        COUNTRY_ADMIN,
        DISTRIBUTOR_ADMIN,
        SURGEON,
        ENGINEER,
        SALES_REP,
        LOGISTICS,
        PRACTICE_MANAGER,
        MANUFACTURER,
      ];

  const onSave = () => {
    const updatedCase = {
      ...activeCase,
      components: maxillaList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      })),
      // leftMandible: leftMandibleList.map((item) => ({
      //   product: item.product,
      //   amount: item.amount,
      //   category: item.category || '',
      // })),
      // rightMandible: rightMandibleList.map((item) => ({
      //   product: item.product,
      //   amount: item.amount,
      //   category: item.category || '',
      // })),
      // genioplasty: genioplastyList.map((item) => ({
      //   product: item.product,
      //   amount: item.amount,
      //   category: item.category || '',
      // }))
    };

    updateCase(updatedCase);
    setEditMode(false);
    setEditLeftMandibleMode(false);
    setEditRightMandibleMode(false);
    setEditGenioplastyMode(false);
  };

  // Maxilla

  const handleAddHardware = (product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setMaxillaList(list);
    } else {
      setMaxillaList([...maxillaList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setMaxillaList(maxillaList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setMaxillaList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setMaxillaList(list);
  };

  const handleChange = (id, product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setMaxillaList(list);
  };

  return (
    <div className="case-static-container">
      <div className="m-b-md underline">
        {files
          .filter((file) => file.type === fileTypes.designReport)
          .map((file) => (
            <div key={file.id} className="d-flex m-b-md pointer" onClick={goToFiles}>
              <img src={file.type === 'block' ? blockIcon : preopIcon} alt="file-icon" />
              <div>{file.name}</div>
              <div className="status-indicator">{file.type}</div>
            </div>
          ))}
      </div>

      <div className="p-b-md m-b-md underline">
        <div className="m-b-sm d-flex">
          <div className="secondary">COMPONENTS</div>
          <RestrictComponent roles={isPermission}>
            {isEditMode ? (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={() => setEditMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={maxillaList}
              products={products}
              onAdd={handleAddHardware}
              onRemove={handleRemoveHardware}
              onAmountChange={handleAmountChange}
              onCategoryChange={handleCategoryChange}
              onChange={handleChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {maxillaList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      {notes && (
        <div className="m-t-md p-t-sm border-top">
          <div className="secondary">Notes:</div>
          <div>{notes}</div>
        </div>
      )}
    </div>
  );
};

CmfExtendedStep5Static.propTypes = {
  activeCase: PropTypes.object,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    products,
    userRole: state.user.currentUser.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
    updateCase: (updatedCase) => dispatch(simpleUpdateCase(updatedCase)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CmfExtendedStep5Static);
