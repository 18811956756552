import urls from '../constants/urls';
import { api } from '../util';

const sendEmail = async (recipient, subject, message, additionalText, isCase, parameters) => {
  try {
    const email = typeof recipient === 'object' && recipient ? recipient.email : recipient;
    if (email) {
      const emailData = {
        email,
        subject,
        message,
        additionalText,
        isCase,
        parameters,
      };
      await api.post(urls.email, emailData);
    }
  } catch (err) {
    console.error(err);
  }
};

const newUserNotificationEmail = (firstName, lastName, email) => {
  if (firstName && lastName && email) {
    api.post(urls.newUserNotification, { firstName, lastName, email });
  }
};

export default sendEmail;
export { newUserNotificationEmail };
