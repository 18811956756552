import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { TextField } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ProcedureAddDocument from './procedure-add-document';
import ProcedureAddProduct from './procedure-add-product';
import ProcedureAddFlow from './procedure-add-flow';

import { getProductsList } from '../../../../../reducers/selectors';

import { caseConstants } from '../../../../../constants';

const ProcedureListItem = (props) => {
  const {
    id,
    name,
    documents = [],
    active,
    onDelete,
    onToggle,
    onAddProduct,
    onDeleteProduct,
    onAddDocument,
    onDeleteDocument,
    country,
    allDocuments,
    onAddFlow,
    onDeleteFlow,
    flow,
    updateLeadDays,
    onUploadProducts,
  } = props;

  const products = orderBy(props?.products, 'sku');
  const { procedureFlows } = caseConstants;

  const productsList = useSelector(getProductsList);

  const [addProductMode, toggleProductMode] = useState(false);
  const [addDocumentMode, toggleDocumentMode] = useState(false);
  const [addFlowMode, toggleFlowMode] = useState(false);
  const [deleteMode, toggleDeleteMode] = useState(false);

  const [newProductOrDocument, setNewProductOrDocument] = useState(false);

  const [editLeadDays, toggleLeadDays] = useState(false);
  const [leadDays, setLeadDays] = useState(props.leadDays || 0);

  const handleToggle = (e) => {
    if (e.target.checked) {
      onToggle(id, [...active, country]);
    } else {
      onToggle(
        id,
        active.filter((item) => item !== country)
      );
    }
  };

  const handleAddFlow = () => {
    onAddFlow(id, newProductOrDocument);
    toggleFlowMode(false);
  };

  const handleAddProduct = () => {
    onAddProduct(id, newProductOrDocument);
    toggleProductMode(false);
  };

  const handleAddDocument = () => {
    onAddDocument(id, newProductOrDocument);
    toggleDocumentMode(false);
  };

  const filteredDocuments = allDocuments.filter(
    (doc) => !documents.map((d) => d.id).includes(doc.id)
  );

  const filteredProducts = productsList?.filter(
    (product) => !products?.map(({ id }) => id).includes(product.id) && product?.country === country
  );

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const filesUploaded = event.target.files;
    onUploadProducts(filesUploaded, id);
    hiddenFileInput.value = '';
  };

  return (
    <div className="admin-modal-item list-item-container">
      <div className="list-item-main">
        <div className="column">
          <div className="modal-item-circle" />
        </div>
        <div className="modal-item-header">
          <div className="d-flex">
            <div className="m-r-md">{name}</div>
            <Switch
              checked={active.includes(country)}
              onChange={handleToggle}
              value="checkedB"
              color="primary"
            />
          </div>
          {deleteMode ? (
            <div className="d-flex">
              <div className="danger pointer m-l-sm" onClick={() => onDelete(id)}>
                Delete
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleDeleteMode(false)}>
                Cancel
              </div>
            </div>
          ) : (
            <IconButton aria-label="delete" color="primary" onClick={() => toggleDeleteMode(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>

      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>CONFIGURATION</div>
            <div className="primary pointer" onClick={() => toggleFlowMode(true)}>
              Select Flow
            </div>
          </div>
        </div>
        {flow && (
          <div className="sub-item">
            <div className="column" />
            <div className="d-flex">
              {flow === procedureFlows.HTO ? 'HTO Medial' : flow}
              <div className="delete-icon" onClick={() => onDeleteFlow(id, flow)}>
                <CloseIcon />
              </div>
            </div>
          </div>
        )}
        {addFlowMode && (
          <ProcedureAddFlow
            newFlow={newProductOrDocument}
            flows={procedureFlows}
            onChange={setNewProductOrDocument}
            onSubmit={handleAddFlow}
            onCancel={() => toggleFlowMode(false)}
          />
        )}
      </div>

      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>LEAD DAYS</div>
            <div className="primary pointer" onClick={() => toggleLeadDays(true)}>
              {leadDays > 0 ? 'Edit Lead Days' : 'Add Lead Days'}
            </div>
          </div>
        </div>
        <div className="sub-item">
          <div className="column" />
          <div className="d-flex">
            {editLeadDays ? (
              <div className='d-flex'>
                <TextField
                  fullWidth
                  label=""
                  name="leadDays"
                  placeholder="Number of days"
                  onChange={(e) => setLeadDays(e.target.value)}
                  value={leadDays}
                  type='number'
                />
                <div className='d-flex m-l-md'>
                  <div
                    className="primary pointer m-l-sm"
                    onClick={() => {
                      updateLeadDays(id, Number(leadDays) || 0);
                      toggleLeadDays(false);
                    }}
                  >
                    Save
                  </div>
                  <div className="secondary pointer m-l-md" onClick={() => toggleLeadDays(false)}>
                    Cancel
                  </div>
                </div>
              </div>
            ) : (
              <div>{leadDays > 0 ? `${leadDays} day(s)` : ''}</div>
            )}
          </div>
        </div>
      </div>

      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>{(!products || !products.length) && 'NO'} PRODUCTS</div>
            <div className='d-flex align-center'>
              <div className='pointer p-l-sm p-r-sm m-r-lg d-flex' onClick={handleClick}>
                <CloudUploadIcon style={{ fontSize: 16 }} />
              </div>
              <div className="primary pointer" onClick={() => toggleProductMode(true)}>
                Add Product
              </div>
            </div>
          </div>
        </div>
        {products &&
          products.map((product) => (
            <div key={`key-${product.id}`} className="sub-item">
              <div className="column" />
              <div className="d-flex">
                {product?.sku} — {product?.description}
                <div className="delete-icon" onClick={() => onDeleteProduct(id, product)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          ))}
        {addProductMode && (
          <ProcedureAddProduct
            newProduct={newProductOrDocument}
            products={filteredProducts}
            onChange={setNewProductOrDocument}
            onSubmit={handleAddProduct}
            onCancel={() => toggleProductMode(false)}
          />
        )}
      </div>
      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>{(!documents || !documents.length) && 'NO'} DOCUMENTS</div>
            <div className="primary pointer" onClick={() => toggleDocumentMode(true)}>
              Add Document
            </div>
          </div>
        </div>
        {documents &&
          documents.map((document) => (
            <div key={`key-${document.id}`} className="sub-item">
              <div className="column" />
              <div className="d-flex">
                {document.name}
                <div className="delete-icon" onClick={() => onDeleteDocument(id, document.id)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          ))}
        {addDocumentMode && (
          <ProcedureAddDocument
            newDocument={newProductOrDocument}
            documents={filteredDocuments}
            onChange={setNewProductOrDocument}
            onSubmit={handleAddDocument}
            onCancel={() => toggleDocumentMode(false)}
          />
        )}
      </div>

      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display:'none' }}
        accept=".csv"
      />
    </div>
  );
};

ProcedureListItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  products: PropTypes.array,
  documents: PropTypes.array,
  active: PropTypes.array,
  country: PropTypes.string,
  onDelete: PropTypes.func,
  onAddProduct: PropTypes.func,
  onDeleteProduct: PropTypes.func,
  onAddDocument: PropTypes.func,
  onDeleteDocument: PropTypes.func,
  onToggle: PropTypes.func,
  allDocuments: PropTypes.array,
  onAddFlow: PropTypes.func,
  onDeleteFlow: PropTypes.func,
};

export default ProcedureListItem;
