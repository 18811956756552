import React, { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './load-screen.scss';

const LoadScreen = () => {
  return (
    <div className="load-screen">
      <CircularProgress />
    </div>
  );
};

export default memo(LoadScreen);
