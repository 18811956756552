import { LOAD_PRODUCTS, SET_PRODUCTS, ADD_PRODUCTS, DELETE_PRODUCTS } from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const productsReducer = (state = initialState, action) => {
  const products = state.list.slice();

  switch (action.type) {
    case LOAD_PRODUCTS:
      return { ...state, loading: true };
    case SET_PRODUCTS:
      return { ...state, loading: false, list: action.products };
    case ADD_PRODUCTS:
      const newProduct = {
        id: action.id,
        country: action.country,
        sku: action.sku,
        description: action.description,
      };

      return { ...state, list: [...products, newProduct], loading: false };
    case DELETE_PRODUCTS:
      return { ...state, list: products.filter((item) => item.id !== action.id), loading: false };
    default:
      return state;
  }
};

export default productsReducer;
