import React from 'react';
import PropTypes from 'prop-types';

import RadiologyListItem from './RadiologyListItem';

const RadiologyList = ({
  radiologyList,
  onDelete,
  admins,
  users,
  onAddAdmin,
  onDeleteAdmin,
}) => {
  return (
    <div className="distributor-list">
      {radiologyList.map((radiology) => (
        <RadiologyListItem
          key={radiology.id}
          {...radiology}
          onDelete={onDelete}
          admins={admins.filter((admin) => admin.distributor === radiology.id)}
          users={users}
          onAddAdmin={onAddAdmin}
          onDeleteAdmin={onDeleteAdmin}
        />
      ))}
    </div>
  );
};

RadiologyList.propTypes = {
  radiologyList: PropTypes.array,
  admins: PropTypes.array,
  users: PropTypes.array,
  onDelete: PropTypes.func,
  onAddAdmin: PropTypes.func,
  onDeleteAdmin: PropTypes.func,
};

export default RadiologyList;
