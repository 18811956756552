import React, { useState } from 'react';
import PropTypes from 'prop-types';

import InfoIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core';

import Slider from '../../../../../shared/Slider';
import AlertModal from '../../../../AlertModal';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    fontSize: '1rem',
    whiteSpace: 'pre-line !important',
  },
  active: {
    '& > div': {
      padding: '2px 8px !important',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 20,
      '& .alignment-data-value': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
  },
}));

const HtoSagittal = ({
  PTS,
  initialPTS,
  handlePTSChange,
  activeCase,
  skipModalOpen,
  toggleSkipModal,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { procedureSide } = activeCase;

  const [activeValue, setActiveValue] = useState('PTS');

  const min = initialPTS - 20;
  const max = initialPTS + 20;

  const tooltip =
    'Select desired sagittal correction angle by adjusting slider.\n' +
    '\n' +
    'By default, the tibial slope remains unchanged.\n' +
    '\n' +
    'Image is an animated representation of the resultant deformity correction only and should not be referenced for any clinical decision making';

  const getImageSrc = () => {
    const url = `/sagittal_bone_images/${procedureSide}`;

    if (PTS < min) {
      return `${url}/${min}_bone.png`;
    } else if (PTS > max) {
      return `${url}/${max}_bone.png`;
    } else if (PTS === initialPTS) {
      return `${url}/0_bone.png`;
    } else {
      return `${url}/${Math.floor(PTS - initialPTS)}_bone.png`;
    }
  };

  return (
    <>
      <div className="modal-item-block flex-1 step-4-container margin-0">
        <div className="values-list">
          <div className="flex-1 m-r-md">
            <div className="block-header">Preoperative</div>
            <div className="alignment-data-item">
              <div>
                <div>Tibial Slope (°)</div>
                <div>{initialPTS}</div>
              </div>
            </div>
            <div className="m-t-md">
              <Tooltip
                title={tooltip}
                placement="right"
                enterTouchDelay={50}
                classes={{ tooltip: classes.tooltip }}
                leaveTouchDelay={3000}
              >
                <InfoIcon color="primary" height={20} />
              </Tooltip>
            </div>
          </div>
          <div className="flex-1 m-l-md">
            <div className="block-header">Postoperative</div>
            <div
              className={`alignment-data-item pointer ${
                activeValue === 'PTS' ? classes.active : ''
              }`}
              onClick={() => setActiveValue('PTS')}
            >
              <div>
                <div>
                  Tibial Slope <span className="secondary">(°)</span>
                </div>
                <div className="alignment-data-value">{PTS}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="image-container sagittal">
          <img src={getImageSrc()} alt="sagittal-bone" />
        </div>
        <Slider
          value={PTS}
          onChange={handlePTSChange}
          defaultValue={PTS}
          min={min}
          max={max}
          step={0.5}
          decimal
          inverted={procedureSide === 'left'}
        />
        <div className="font-size-sm secondary text-center m-t-sm">Sagittal view</div>
      </div>

      <AlertModal
        open={skipModalOpen}
        onClose={() => toggleSkipModal(false)}
        onSubmit={() => {
          toggleSkipModal(false);
          handleSubmit();
        }}
        title="Do you need to adjust Tibial Slope?"
        text="To avoid delays in the processing of this case only make adjustments if necessary, otherwise continue to the summary screen"
        submitText="Continue"
        closeText="Adjust"
      />
    </>
  );
};

HtoSagittal.propTypes = {
  activeCase: PropTypes.object,
  PTS: PropTypes.number,
  initialPTS: PropTypes.number,
  handlePTSChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default HtoSagittal;
