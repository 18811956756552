import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AclStep4 from './acl-step4/AclStep4';
import CmfStep4 from './cmf-step4/CmfStep4';
import CmfExtendedStep4 from './cmf-extended-step4/CmfExtendedStep4';
import OsteotomyStep4 from './osteotomy-step4/OsteotomyStep4';

import { updateCase } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';

const Step4 = ({ onClose, activeCase, updateCase, userRole, classes, uid }) => {
  const { procedureFlows } = caseConstants;

  const renderStep4 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep4
          classes={classes}
          onClose={onClose}
          updateCase={updateCase}
          userRole={userRole}
          activeCase={activeCase}
          userId={uid}
        />
      );
    }

    if (flow === procedureFlows.ACL) {
      return <AclStep4 classes={classes} onClose={onClose} />;
    }

    if (flow === procedureFlows.CMF) {
      return <CmfStep4 classes={classes} onClose={onClose} />;
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return <CmfExtendedStep4 classes={classes} onClose={onClose} />
    }
  };

  return renderStep4(activeCase.procedureFlow);
};

Step4.propTypes = {
  activeCase: PropTypes.object,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    uid: state.user.currentUser.uid,
    activeCase: state.cases.activeCase,
    userRole: state.user.currentUser.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, rejected) => dispatch(updateCase(newCase, 4, [], rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step4);
