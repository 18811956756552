import React from 'react';
import PropTypes from 'prop-types';

import MuiSlider from '@material-ui/core/Slider';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

const StyledSlider = withStyles({
  track: {
    height: 6,
    borderRadius: 6,
  },
  rail: {
    height: 6,
    borderRadius: 6,
  },
  thumb: {
    height: 16,
    width: 16,
  },
  valueLabel: {
    left: '-50%',
  },
})(MuiSlider);

const Slider = (props) => {
  const {
    value,
    defaultValue = 0,
    min = 0,
    max = 10,
    step = 1,
    onChange,
    labelDisplay = true,
    decimal,
    inverted,
  } = props;

  const onMinusClick = () => {
    if (value <= min) {
      return;
    }

    if (value > max) {
      onChange(max);

      return;
    }

    if (value === 0) {
      onChange(value - step);

      return;
    }

    const remainder = ((value * 10) % (step * 10)) / 10;
    const diff = remainder === 0 ? step : remainder;

    const newValue = decimal ? (value * 10 - diff * 10) / 10 : value - diff;

    onChange(newValue);
  };

  const onPlusClick = () => {
    if (value >= max) {
      return;
    }

    if (value < min) {
      onChange(min);

      return;
    }

    if (value === 0) {
      onChange(value + step);

      return;
    }

    const remainder = ((value * 10) % (step * 10)) / 10;
    const diff = remainder === 0 ? step : step - remainder;

    const newValue = decimal ? (value * 10 + diff * 10) / 10 : value + diff;

    onChange(newValue);
  };

  const buttonMinus = (
    <IconButton
      aria-label="minus"
      color={value <= min ? 'secondary' : 'primary'}
      onClick={onMinusClick}
      style={{ marginTop: '4px', [inverted ? 'marginLeft' : 'marginRight']: '4px' }}
    >
      <Icon>remove</Icon>
    </IconButton>
  );

  const buttonPlus = (
    <IconButton
      aria-label="plus"
      color={value >= max ? 'secondary' : 'primary'}
      onClick={onPlusClick}
      style={{ marginTop: '4px', [inverted ? 'marginRight' : 'marginLeft']: '4px' }}
    >
      <Icon>add</Icon>
    </IconButton>
  );

  const formatValueLabel = (a) => {
    if (inverted) {
      return (a * -1).toString();
    }

    return a.toString();
  };

  return (
    <div className="d-flex" style={{ touchAction: 'manipulation' }}>
      {inverted ? buttonPlus : buttonMinus}
      <StyledSlider
        value={inverted ? -value : value}
        getAriaValueText={() => value.toString()}
        valueLabelFormat={formatValueLabel}
        defaultValue={defaultValue}
        step={step}
        onChange={(e, value) => onChange(inverted ? -value : value)}
        aria-labelledby="slider"
        valueLabelDisplay={labelDisplay ? 'on' : 'auto'}
        min={inverted ? -max : min}
        max={inverted ? -min : max}
        track={inverted ? 'inverted' : 'normal'}
      />
      {inverted ? buttonMinus : buttonPlus}
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  labelDisplay: PropTypes.bool,
  decimal: PropTypes.bool, // Need to avoid JavaScript floating-point arithmetic issues
};

export default Slider;
