import {
  ADD_RADIOLOGY,
  DELETE_RADIOLOGY,
  LOAD_RADIOLOGY,
  SET_RADIOLOGY,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const radiologyReducer = (state = initialState, action) => {
  const radiologyList = state.list.slice();

  switch (action.type) {
    case LOAD_RADIOLOGY:
      return { ...state, loading: true };
    case SET_RADIOLOGY:
      return { ...state, loading: false, list: action.radiologyList };
    case ADD_RADIOLOGY:
      const newItem = {
        id: action.id,
        country: action.country,
        name: action.name,
        address: action.address,
        state: action.state,
        postCode: action.postCode,
        phone: action.phone,
        email: action.email,
      };

      return { ...state, list: [...radiologyList, newItem], loading: false };
    case DELETE_RADIOLOGY:
      return {
        ...state,
        list: radiologyList.filter((item) => item.id !== action.id),
        loading: false,
      };
    default:
      return state;
  }
};

export default radiologyReducer;
