import moment from 'moment';
import theme from '../styles/theme';
import { alignmentDataTypes, procedureFlows, selectedProcedureFlows } from '../constants/cases';

const mergeArrayObjectsByField = (field, arrays) => {
  let result = arrays[0];
  for (let i = 1; i < arrays.length; i++) {
    result = result.map((item1) => ({
      ...item1,
      ...arrays[i].find((item2) => item2[field] === item1[field]),
    }));
  }
  return result;
};

const checkTimeDiff = (date) => {
  const currentTime = moment();
  const timeStore = moment(date);

  return currentTime.diff(timeStore, 'h');
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const sortAlphabetically = (sortBy) => (a, b) => {
  if (a[sortBy] > b[sortBy]) {
    return 1;
  } else if (a[sortBy] < b[sortBy]) {
    return -1;
  }
  return 0;
};

const getPreoperativeHkaLabel = (flow, type) => {
  // if (flow === procedureFlows.DFO_Lateral && type === alignmentDataTypes.varus) {
  //   return alignmentDataTypes.valgus;
  // }
  if (type === alignmentDataTypes.varus) {
    return alignmentDataTypes.varus;
  } else {
    return alignmentDataTypes.valgus;
  }
};

const getPostoperativeHkaLabel = (flow, type) => {
  // if (flow === procedureFlows.DFO_Lateral && type === alignmentDataTypes.varus) {
  //   return alignmentDataTypes.varus;
  // }
  if (type === alignmentDataTypes.varus) {
    return alignmentDataTypes.valgus;
  } else {
    return alignmentDataTypes.varus;
  }
};

const getFlowsTheme = (flow, procedure) => {
  if (
    (!flow && procedure) ||
    flow === procedureFlows.HTO ||
    flow === procedureFlows.DFO_Lateral ||
    flow === procedureFlows.DFO_Medial ||
    flow === selectedProcedureFlows.DFO ||
    flow === selectedProcedureFlows.Osteotomy ||
    flow === procedureFlows.HTO_LATERAL ||
    flow === procedureFlows.DOUBLE_LEVEL_OSTEOTOMY
  ) {
    return theme.palette.orange;
  }
  if (flow === procedureFlows.ACL) {
    return theme.palette.rubyDust;
  }
  if (flow === procedureFlows.CMF || flow === procedureFlows.CMF_EXTENDED || flow === selectedProcedureFlows.CMF_GENERAL) {
    return theme.palette.shamrock;
  }
  if (flow === procedureFlows.CUSTOM) {
    return theme.palette.primary;
  }
  if (flow === procedureFlows.CUSTOM_EXTENDED) {
    return theme.palette.primary;
  }
  if (flow === procedureFlows.ONCOL) {
    return theme.palette.purple;
  }
  return theme.palette.primary;
};

export {
  mergeArrayObjectsByField,
  checkTimeDiff,
  getKeyByValue,
  sortAlphabetically,
  getPreoperativeHkaLabel,
  getPostoperativeHkaLabel,
  getFlowsTheme,
};
