import React, { Fragment } from 'react';

import { RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core';

import mriIcon from '../../../../../../assets/mri_icon.svg';
import scansIcon from '../../../../../../assets/ct_icon.svg';

export const AclStep2 = ({ mri, goToFiles, handleChange, fileStatuses, scans, rejected, notes, setNotes }) => (
  <Fragment>
    {!!mri.length && (
      <div className="modal-item-block">
        <div className="block-header">Attached MRI</div>
        {mri.map((file) => (
          <div key={file.id} className="block-file-list">
            <div className="d-flex pointer" onClick={goToFiles}>
              <img src={mriIcon} alt="xray-icon" />
              {file.name}
            </div>
            <div className="radio-group-container">
              <RadioGroup
                aria-label="x-ray-radio"
                name="mri-radio"
                value={file.status}
                onChange={(e) => handleChange(file.id, e.target.value)}
              >
                <FormControlLabel value={fileStatuses.APPROVED} control={<Radio color="primary" />} label="Approve" />
                <FormControlLabel value={fileStatuses.REJECTED} control={<Radio color="primary" />} label="Reject" />
              </RadioGroup>
            </div>
          </div>
        ))}
      </div>
    )}
    {!!scans.length && (
      <div className="modal-item-block" style={{ marginBottom: 8 }}>
        <div className="block-header">Uploaded CT Scan(s)</div>
        {scans.map((file) => (
          <div key={file.id} className="block-file-list">
            <div className="d-flex align-items-center pointer" onClick={goToFiles}>
              <img src={scansIcon} alt="scans-icon" />
              {file.name}
            </div>
            <div className="radio-group-container">
              <RadioGroup
                aria-label="scans-radio"
                name="scans-radio"
                value={file.status}
                onChange={(e) => handleChange(file.id, e.target.value)}
              >
                <FormControlLabel value={fileStatuses.APPROVED} control={<Radio color="primary" />} label="Approve" />
                <FormControlLabel value={fileStatuses.REJECTED} control={<Radio color="primary" />} label="Reject" />
              </RadioGroup>
            </div>
          </div>
        ))}
      </div>
    )}
    {rejected && (
      <TextField
        id="notes"
        type="text"
        fullWidth
        autoFocus
        multiline
        label="Notes"
        placeholder="Add reason for reject here"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
    )}
  </Fragment>
);
