import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { caseConstants } from '../../../constants';

import './table.scss';

const { caseActions, caseAclActions, caseCmfActions, caseCmfExtendedActions, procedureFlows } = caseConstants;

const getAction = (procedureFlow, step) => {
  if (procedureFlow === procedureFlows.ACL) {
    return caseAclActions[step];
  }
  if (procedureFlow === procedureFlows.CMF) {
    return caseCmfActions[step];
  }
  if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(procedureFlow)) {
    return caseCmfExtendedActions[step];
  }
  return caseActions[step];
};

const CaseActionCell = ({ step, access, style, numberSteps, flow }) => {
  return (
    <div style={{ color: style }} className={`case-action-cell ${access && step <= numberSteps ? 'active' : ''}`}>
      {getAction(flow, step) || ''}
    </div>
  );
};

CaseActionCell.propTypes = {
  step: PropTypes.number,
  access: PropTypes.bool,
};

export default memo(CaseActionCell);
