import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { procedureFlows } from '../../../../../constants/cases';

const ProcedureAddFlow = ({ newFlow, flows, onChange, onCancel, onSubmit }) => (
  <div className="admin-modal-item new-item">
    <div className="column" />
    <div className="modal-item-content d-flex">
      <Select
        id="user-select"
        className="user-select"
        value={newFlow}
        onChange={(e) => onChange(e.target.value)}
      >
        {Object.values(flows)
          .sort()
          .map((flow, index) => (
            <MenuItem key={`${index}_${flow}`} value={flow}>
              {flow === procedureFlows.HTO ? 'HTO Medial' : flow}
            </MenuItem>
          ))}
      </Select>
      <div className="p-l-md primary pointer" onClick={onSubmit}>
        Save
      </div>
      <div className="p-l-md secondary pointer" onClick={onCancel}>
        Cancel
      </div>
    </div>
  </div>
);

ProcedureAddFlow.propTypes = {
  flows: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProcedureAddFlow;
