import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './table.scss';

const StepCell = ({ step, max, style }) => {
  return (
    <div className="step-cell">
      <span style={{ color: style }} className="current-step">
        {step > max ? max : step}
      </span>
      <span className="max-step"> / {max}</span>
    </div>
  );
};

StepCell.propTypes = {
  step: PropTypes.number.isRequired,
  max: PropTypes.number,
};

export default memo(StepCell);
