import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './table.scss';

const FileCell = ({ onClick, icon }) => {
  return (
    <div className="action-cell" onClick={onClick}>
      <img src={icon} alt="action-icon" />
    </div>
  );
};

FileCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

export default memo(FileCell);
