import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions/DialogActions';
import { withStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: { width: '450px' },
  primary: { color: theme.palette.primary.main },
}));

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #f2f2f2',
  },
}))(MuiDialogActions);

const ConfirmationModal = (props) => {
  const {
    open,
    onSubmit,
    onClose,
    title,
    text,
    closeText,
    submitText,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        {title || 'Are you sure?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-1">
          <div
            className={`${classes.primary} flex-1 p-md text-center pointer font-bold`}
            style={{ borderRight: '1px solid #f2f2f2' }}
            onClick={onClose}
          >
            {closeText || 'Cancel'}
          </div>
          <div
            className={`${classes.primary} flex-1 p-md text-center pointer font-bold`}
            onClick={onSubmit}
          >
            {submitText || 'Confirm'}
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
