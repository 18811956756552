import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import { MenuItem, Select } from '@material-ui/core';

import Modal from '../Modal';

import { fetchDocuments, fetchProcedures } from '../../../actions/adminActions';
import { getDocumentsList, getIsLoadingDocumentsList, getProceduresList, getIsLoadingProceduresList } from '../../../reducers/selectors';

import { procedureFlows } from '../../../constants/cases';

import imageIcon from '../../../assets/image_icon.svg';
import pdfIcon from '../../../assets/preop_icon.svg';
import videoIcon from '../../../assets/block_icon.svg';

import './documents-modal.scss';

const DocumentsModal = ({ open, onClose }) => {
  const isLoading = useSelector(getIsLoadingDocumentsList);
  const proceduresLoading = useSelector(getIsLoadingProceduresList);
  const documents = useSelector(getDocumentsList);
  const procedures = useSelector(getProceduresList);
  const dispatch = useDispatch();

  const [flow, setFlow] = useState('ALL');

  useEffect(() => {
    if (open) {
      dispatch(fetchDocuments());
      dispatch(fetchProcedures());
    }
  }, [open, dispatch]);

  const getIcon = (name) => {
    if (name && name.includes('.pdf')) {
      return pdfIcon;
    }
    if (name && name.includes('.mp4')) {
      return videoIcon;
    }
    return imageIcon;
  };

  const list = useMemo(() => {
    if (flow === 'ALL') {
      return documents;
    }
    const result = [];
    const proceduresList = procedures?.filter((p) => p.flow === flow);

    proceduresList?.forEach((item) => {
      item?.documents?.forEach((doc) => {
        if (!result?.map?.((d) => d.id)?.includes(doc.id)) {
          result?.push(doc);
        }
      })
    });

    return result;
  }, [flow, procedures, documents]);

  return (
    <Modal open={open} onClose={onClose} title="Documents" subtitle="PS" size="lg" autoSize>
      {(isLoading || proceduresLoading) && (
        <div className="loading-container">
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div className="modal-container">
          <div style={{ paddingTop: '16px', paddingLeft: '24px', paddingRight: '24px' }}>
            <div className="secondary">Select Procedure</div>
            <Select
              value={flow}
              fullWidth
              className="case-field-select"
              onChange={(e) => setFlow(e.target.value)}
            >
              <MenuItem value="ALL">
                All
              </MenuItem>
              {orderBy(Object.values(procedureFlows)).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </div>

          {list?.length ? (
            <div className="documents-list">
              {list.map((document) => (
                <div className="documents-list-item" key={document.id}>
                  <div className="d-flex">
                    <img src={getIcon(document.name)} alt="file-icon" />
                    <a href={document.url} target="_blank" rel="noopener noreferrer">
                      {document.name}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex flex-center secondary" style={{ padding: '24px' }}>
              No Documents
            </div>
          )}
          <div className="modal-actions">
            <div className="secondary pointer" onClick={onClose}>
              Close
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

DocumentsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default DocumentsModal;
