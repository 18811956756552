import React from 'react';
import { connect } from 'react-redux';

import { OsteotomyStep3 } from './osteotomy-step3/OsteotomyStep3';
import { AclStep3 } from './acl-step3/AclStep3';
import { CmfStep3 } from './cmf-step3/CmfStep3';
import { CmfExtendedStep3 } from './cmf-extended-step3/CmfExtendedStep3';
import { NoProcedureFlow } from '../../../../shared/NoProcedureFlow';

import { updateCase, simpleUpdateCase } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';

const {
  fileStatuses,
  fileTypes,
  procedureFlows,
  statuses,
  caseNotifications,
  caseACLNotifications,
  caseCMFNotifications,
  caseCMFExtendedNotifications,
} = caseConstants;

const Step3 = ({
  classes,
  onClose,
  activeCase,
  updateCase,
  trainingMode,
  products,
  preopPlan,
  preopRejected,
  blockFilesRejected,
  caseHardware,
  caseManufacturing,
  caseMaxilla,
  caseLeftMandible,
  caseRightMandible,
  caseGenioplasty,
  userId,
  planReport,
  planReportRejected,
  simpleUpdateCase,
}) => {
  const renderStep3 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep3
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          simpleUpdateCase={simpleUpdateCase}
          trainingMode={trainingMode}
          userId={userId}
        />
      );
    }

    if (flow === procedureFlows.ACL) {
      return (
        <AclStep3
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          products={products}
          preopPlan={preopPlan}
          preopRejected={preopRejected}
          blockFilesRejected={blockFilesRejected}
          caseHardware={caseHardware}
          caseManufacturing={caseManufacturing}
          fileStatuses={fileStatuses}
          fileTypes={fileTypes}
          statuses={statuses}
          caseNotifications={caseACLNotifications}
          userId={userId}
        />
      );
    }

    if (flow === procedureFlows.CMF) {
      return (
        <CmfStep3
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          products={products}
          preopPlan={preopPlan}
          preopRejected={preopRejected}
          blockFilesRejected={blockFilesRejected}
          caseMaxilla={caseMaxilla}
          caseLeftMandible={caseLeftMandible}
          caseRightMandible={caseRightMandible}
          caseGenioplasty={caseGenioplasty}
          fileStatuses={fileStatuses}
          fileTypes={fileTypes}
          statuses={statuses}
          caseNotifications={caseCMFNotifications}
          userId={userId}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedStep3
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          updateCase={updateCase}
          products={products}
          planReport={planReport}
          planReportRejected={planReportRejected}
          fileStatuses={fileStatuses}
          fileTypes={fileTypes}
          statuses={statuses}
          caseNotifications={caseCMFExtendedNotifications}
          userId={userId}
          simpleUpdateCase={simpleUpdateCase}
        />
      );
    }
  };

  return (
    <div className="case-step-container">
      {renderStep3(activeCase.procedureFlow)}

      {!activeCase.procedureFlow && <NoProcedureFlow procedureName={activeCase.procedureName} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];
  const preopPlan = state.cases.activeCase.files.find((file) => file.type === fileTypes.preop);
  const planReport = state.cases.activeCase.files.find((file) => file.type === fileTypes.planReport);
  const blockFiles = state.cases.activeCase.files.filter((file) => file.type === fileTypes.block);

  const caseHardware = state.cases.activeCase?.hardware;
  const caseManufacturing = state.cases.activeCase?.manufacturing;
  const caseMaxilla = state.cases.activeCase?.maxilla || [];
  const caseLeftMandible = state.cases.activeCase?.leftMandible || [];
  const caseRightMandible = state.cases.activeCase?.rightMandible || [];
  const caseGenioplasty = state.cases.activeCase?.genioplasty || [];

  return {
    activeCase,
    products,
    preopPlan: preopPlan && preopPlan.status !== fileStatuses.REJECTED ? preopPlan : null,
    planReport: planReport && planReport.status !== fileStatuses.REJECTED ? planReport : null,
    preopRejected: preopPlan && preopPlan.status === fileStatuses.REJECTED,
    planReportRejected: planReport && planReport.status === fileStatuses.REJECTED,
    blockFilesRejected: blockFiles && !!blockFiles.filter((file) => file.status === fileStatuses.REJECTED).length,
    caseHardware,
    caseManufacturing,
    caseMaxilla,
    caseLeftMandible,
    caseRightMandible,
    caseGenioplasty,
    trainingMode: state.sidebar.trainingMode,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, newFiles, rejected) => dispatch(updateCase(newCase, 3, newFiles, rejected)),
    simpleUpdateCase: (data) => dispatch(simpleUpdateCase(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
