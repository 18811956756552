import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';

const CmfExtendedStep9Static = ({ dispatchInfo }) => {
  return (
    <div className="case-static-container">
      <Grid container spacing={0}>
        <Grid item xs={12} md={4}>
          <div className="field-container">
            <div className="field-title">Dispatch date</div>
            <div className="field-value">
              {moment(dispatchInfo.dispatchDate).format('DD/MM/YYYY')}
            </div>
          </div>
        </Grid>
        {dispatchInfo.trackingNumber && (
          <Grid item xs={12} md={4}>
            <div className="field-container">
              <div className="field-title">Tracking No.</div>
              <div className="field-value">{dispatchInfo.trackingNumber}</div>
            </div>
          </Grid>
        )}
        {dispatchInfo.courier && (
          <Grid item xs={12} md={4}>
            <div className="field-container">
              <div className="field-title">Courier</div>
              <div className="field-value">{dispatchInfo.courier}</div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

CmfExtendedStep9Static.propTypes = {
  dispatchInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    dispatchInfo: state.cases.activeCase.dispatchInfo,
  };
};

export default connect(mapStateToProps)(CmfExtendedStep9Static);
