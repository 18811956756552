import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import Alert from '../../components/shared/Alert';

import { withFirebase } from '../../firebase';

import { setCurrentUser } from '../../actions/userActions';

import './sign-up-page.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import TermsModal from '../../components/app-footer/TermsModal';

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      passwordVerification: '',
      firstName: '',
      lastName: '',
      loading: false,
      error: null,
      warning: false,
      open: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { email, password, passwordVerification, firstName, lastName } = this.state;

    if (
      !email.length ||
      !password.length ||
      !passwordVerification.length ||
      !firstName.length ||
      !lastName.length
    ) {
      this.setState({ warning: true });
      return;
    }

    if (password !== passwordVerification) {
      this.setState({ error: 'Password does not match' });
      return;
    }

    this.setState({ open: true });
  };

  onSignUp = () => {
    const { firebase, setUser, history } = this.props;
    const { email, password, firstName, lastName } = this.state;

    this.setState({ open: false, error: null, loading: true });
    firebase
      .doCreateUserWithEmailAndPassword(email, password, { firstName, lastName })
      .then((user) => {
        setUser(user);
        history.push('/dashboard');
      })
      .catch((error) => this.setState({ error: error.message }))
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const {
      loading,
      error,
      warning,
      email,
      password,
      passwordVerification,
      showPassword,
      firstName,
      lastName,
    } = this.state;
    const year = new Date().getFullYear();

    return (
      <div className="sign-up-page">
        <AppHeader />
        <div className="sign-up-form-container">
          <h3>Sign Up</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="sign-up-form">
              <div className="form-group-container m-t-sm m-b-lg">
                <TextField
                  id="first-name"
                  type="text"
                  label="First name"
                  fullWidth
                  value={firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value, error: null })}
                />
                <TextField
                  id="last-name"
                  type="text"
                  label="Last name"
                  fullWidth
                  value={lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value, error: null })}
                />
              </div>
              <div className="form-group-container m-t-sm m-b-lg">
                <TextField
                  id="email"
                  type="text"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value, error: null })}
                />
              </div>
              <div className="form-group-container m-t-sm m-b-lg">
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showPassword: !showPassword })}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="form-group-container m-t-sm m-b-lg">
                <FormControl fullWidth>
                  <InputLabel htmlFor="password-verification">Confirm password</InputLabel>
                  <Input
                    id="password-verification"
                    type={showPassword ? 'text' : 'password'}
                    value={passwordVerification}
                    onChange={(e) => this.setState({ passwordVerification: e.target.value })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showPassword: !showPassword })}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="actions">
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                SIGN UP
                {loading && <CircularProgress size={24} className="button-processing-indicator" />}
              </Button>
            </div>
          </form>
        </div>
        <div />
        <Alert
          variant="error"
          message={error}
          open={!!error}
          onClose={() => this.setState({ error: null })}
        />
        <Alert
          variant="warning"
          message="All fields are required"
          open={warning}
          onClose={() => this.setState({ warning: false })}
        />
        <TermsModal
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          year={year}
          onSubmit={this.onSignUp}
        />
        <AppFooter link="sign-in" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setCurrentUser(user)),
  };
};

SignUpPage.propTypes = {
  setUser: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(withFirebase(withRouter(SignUpPage)));
