import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DateCell from './DateCell';
import StepCell from './StepCell';
import FileCell from './FileCell';
import IndicatorCell from './IndicatorCell';
import CaseActionCell from './CaseActionCell';
import HeaderCell from './HeaderCell';

import { caseConstants } from '../../../constants';
import { utils } from '../../../util';

const { fileTypes, statuses, numberOfSteps, numberOfAClSteps, numberOfCmfSteps, numberOfCmfExtendedSteps, procedureFlows } = caseConstants;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#b1b1b1',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    paddingTop: '6px',
    paddingBottom: '6px',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
  },
  body: {},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    cursor: 'pointer',
    '&:hover': {
      background: 'rgb(255, 244, 229)',
    },
    '&:last-child': {},
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    boxShadow: 'none',
  },
  table: {
    minWidth: 300,
  },
}));

const getNumberOfSteps = (procedureFlow) => {
  if (procedureFlow === procedureFlows.ACL) {
    return numberOfAClSteps;
  }
  if (procedureFlow === procedureFlows.CMF) {
    return numberOfCmfSteps;
  }
  if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(procedureFlow)) {
    return numberOfCmfExtendedSteps;
  }
  return numberOfSteps;
};

const renderCell = (column, row) => {
  const procedureFlow = row && row.procedureFlow;
  const numberFlowSteps = getNumberOfSteps(procedureFlow);
  const type = column.type;
  const value = row[column.field];
  const complete = row && row.step > numberFlowSteps;
  const rejected = row && row.status === statuses.rejected;
  const access = row && row.access;
  const createdAt = row?.createdAt ? row?.createdAt : false;
  const getFlowTheme = utils.getFlowsTheme(row?.procedureFlow, row.procedure).main;

  switch (type) {
    case 'default':
      if (column.formatter) {
        return column.formatter(value);
      }

      return value;
    case 'date':
      return <DateCell date={value} />;
    case 'step':
      const step = typeof value === 'object' ? value.currentStep : value;
      const max = typeof value === 'object' ? value.max : numberFlowSteps;

      return <StepCell step={step} max={max} style={getFlowTheme} />;
    case 'file':
      let files = [];

      if (row && row.files) {
        if (column.field === 'scan') {
          files = row.files.filter((item) => item.type === fileTypes.scan || item.type === fileTypes.xRay);
        } else {
          files = row.files.filter((item) => item.type === column.field);
        }
      }

      return !!files.length && <FileCell onClick={() => column.onClick(row)} icon={column.icon} />;
    case 'indicator':
      return (
        <IndicatorCell expiry={createdAt} value={value} complete={complete} rejected={rejected} style={getFlowTheme} />
      );
    case 'caseAction':
      const numberSteps = typeof value === 'object' ? value.max : numberFlowSteps;
      return (
        <CaseActionCell
          step={row.step}
          flow={procedureFlow}
          numberSteps={numberSteps}
          access={access}
          style={getFlowTheme}
        />
      );
    case 'custom':
      return column.formatter(value, row);
    default:
      return value;
  }
};

const TableComponent = (props) => {
  const {
    columns = [],
    rows = [],
    onRowClick = () => {},
    onFilterChange = () => {},
    currentFilter = {},
    filterOptions = {},
  } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <HeaderCell
                key={`header-cell-${column.title}`}
                title={column.title}
                fieldName={column.field}
                filterOptions={column.filter ? filterOptions[column.field] : null}
                selectedValues={column.filter && currentFilter[column.field] ? currentFilter[column.field] : []}
                onFilterChange={onFilterChange}
                isDate={column.field === 'date' && !!column.filter}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length ? (
            rows.map((row) => (
              <StyledTableRow key={row.id} onClick={() => onRowClick(row)}>
                {columns.map((column) => (
                  <StyledTableCell key={`cell-${column.title}`} component="th" scope="row">
                    {renderCell(column, row)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          ) : (
            <tr className="p-lg">
              <td className="secondary text-center p-lg font-size-lg" colSpan={columns.length}>
                Empty list
              </td>
            </tr>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  currentFilter: PropTypes.object,
  filterOptions: PropTypes.object,
  onRowClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  stickyTable: PropTypes.bool,
};

export default memo(TableComponent);
