import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

const NewRadiology = ({ onCancel, onSubmit }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [postCode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    if (!name || !address || !state || !postCode || !phone || !email) {
      return;
    }

    onSubmit({ name, address, state, postCode, phone, email });
  };

  return (
    <div>
      <div className="d-flex new-item">
        <div className="column">
          <div className="modal-item-circle light" />
        </div>
        <div className="d-flex flex-1">
          <TextField
            id="new-manufacturer-name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="p-l-md primary pointer" onClick={handleSubmit}>
            Add
          </div>
          <div className="p-l-md secondary pointer" onClick={onCancel}>
            Cancel
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="column"></div>
        <div className="flex-1">
          <div className="d-flex">
            <TextField
              id="new-manufacturer-address"
              type="text"
              label="Address"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="d-flex">
            <div className="flex-1 m-r-md">
              <TextField
                id="new-manufacturer-state"
                type="text"
                label="State"
                fullWidth
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="flex-1 m-l-md">
              <TextField
                id="new-manufacturer-post-code"
                type="text"
                label="Post code"
                fullWidth
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="flex-1 m-r-md">
              <TextField
                id="new-manufacturer-phone"
                type="text"
                label="Contact phone"
                fullWidth
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="flex-1 m-l-md">
              <TextField
                id="new-manufacturer-email"
                type="text"
                label="Email address"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewRadiology.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NewRadiology;
