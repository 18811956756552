import React, { memo } from 'react';
import PropTypes from 'prop-types';

import WarningIcon from '@material-ui/icons/Warning';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DoneIcon from '@material-ui/icons/Done';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

import { utils } from '../../../util';

import './table.scss';

const IndicatorCell = ({ value, complete, rejected, expiry, style }) => {
  const caseExpiry = utils.checkTimeDiff(expiry) <= 48;

  const renderIcon = () => {
    if (rejected) {
      return <WarningIcon style={{ color: style }} color={value ? 'primary' : 'secondary'} />;
    }
    if (complete) {
      return <DoneIcon style={{ color: style }} color={value ? 'primary' : 'secondary'} />;
    }
    if (caseExpiry) {
      return <NewReleasesIcon style={{ color: style }} color={value ? 'primary' : 'secondary'} />;
    }

    return (
      <FiberManualRecordOutlinedIcon
        style={{ color: style }}
        color={value ? 'primary' : 'secondary'}
      />
    );
  };

  return <div className="indicator-cell">{renderIcon()}</div>;
};

IndicatorCell.propTypes = {
  value: PropTypes.bool,
  complete: PropTypes.bool,
  rejected: PropTypes.bool,
};

export default memo(IndicatorCell);
