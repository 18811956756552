import { LOAD_USER_DATA, SET_CURRENT_USER, UPDATE_USER_DATA } from './actionTypes';
import Firebase from '../firebase';
import { USER_AUTHORIZATION_COLLECTION, USER_DATA_COLLECTION } from '../firebase/collections';

export const setCurrentUser = (user) => (dispatch) => {
  if (user && user.profileImage) {
    Firebase.getDownloadLink(`${user.uid}/profileImage/${user.profileImage}`).then((photoUrl) =>
      dispatch({ type: SET_CURRENT_USER, user: { ...user, photoUrl } })
    );
  }

  return dispatch({ type: SET_CURRENT_USER, user });
};

export const updateUserData = (uid, data, currentPassword, newPassword, profileImage) => async (dispatch) => {
  const collection = Firebase.db.collection(USER_DATA_COLLECTION);
  const storageRef = Firebase.storage.ref();

  dispatch({ type: LOAD_USER_DATA });

  if (currentPassword && newPassword) {
    await Firebase.doReauthenticate(currentPassword);
    await Firebase.doPasswordUpdate(newPassword);

    collection
      .doc(uid)
      .set({ adminPassword: false }, { merge: true })
      .then(() =>
        dispatch({
          type: UPDATE_USER_DATA,
          user: { ...data, adminPassword: false },
        })
      );
  }

  if (profileImage) {
    return storageRef
      .child(`${uid}/profileImage/${profileImage.name}`)
      .put(profileImage)
      .then(() => {
        return Firebase.getDownloadLink(`${uid}/profileImage/${profileImage.name}`).then(
          (photoUrl) => {
            return collection
              .doc(uid)
              .update({ ...data, profileImage: profileImage.name })
              .then(() =>
                dispatch({
                  type: UPDATE_USER_DATA,
                  user: { ...data, profileImage: profileImage.name, photoUrl },
                })
              );
          }
        );
      });
  }

  return collection
    .doc(uid)
    .update(data)
    .then(() => dispatch({ type: UPDATE_USER_DATA, user: { ...data } }));
};

export const savePushNotificationsToken = (token) => (dispatch, getState) => {
  const collection = Firebase.db.collection(USER_AUTHORIZATION_COLLECTION);
  const state = getState();
  const currentUser = state.user.currentUser;

  if (currentUser?.uid) {
    return collection
      .doc(currentUser.uid)
      .set({ pushNotificationsToken: token }, { merge: true })
  }
};
