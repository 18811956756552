import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions/DialogActions';
import { withStyles, makeStyles, TextField } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import Button from '../../shared/Button';

import { getFile } from '../../../util/api';

import { urls } from '../../../constants';

const useStyles = makeStyles(() => ({
  paper: { width: '450px' },
}));

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #f2f2f2',
  },
}))(MuiDialogActions);

const CaseExportModal = ({
  open,
  onClose,
  caseId,
  onSkipClick
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  const onPhoneChange = (e) => {
    const re = /^[\+\d]?(?:[\d-.\s()]*)$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      await getFile(urls.exportCase, { caseId, address, phoneNumber: phone }, caseId);
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ marginTop: '8px' }}>
        <div className="d-flex">
          <DescriptionIcon color="primary" />
          <span className="font-bold m-l-md">Referral Document</span>
        </div>
      </DialogTitle>
      <DialogContent style={{ marginBottom: 16 }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontWeight: 'bold', paddingTop: '8px' }}
        >
          Would you like to create a referral document for this case?
        </DialogContentText>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontWeight: 'bold', paddingTop: '8px' }}
        >
          If yes, please enter patient details below & click to Save a copy.
        </DialogContentText>

        <div className="m-t-lg" style={{ width: '100%' }}>
          <div className="field-container">
            <div className="secondary uppercase font-size-sm">Patient address</div>
            <div className="field-content d-flex">
              <TextField
                style={{ width: '100%' }}
                id="address"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex m-t-lg w-100 space-between align-end">
          <div className="field-container">
            <div className="secondary uppercase font-size-sm">Patient phone number</div>
            <div className="field-content d-flex">
              <TextField
                style={{ width: '100%' }}
                id="phone"
                type="text"
                value={phone}
                onChange={onPhoneChange}
              />
            </div>
          </div>
          <Button
            type="submit"
            text="Save Referral"
            onClick={onSubmit}
            loading={loading}
            disabled={loading || !address || !phone}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-1 p-l-lg p-r-lg p-t-md p-b-md">
          <div className="flex-1 p-t-md p-b-md secondary pointer font-bold" onClick={onClose}>
            Cancel
          </div>
          <div className="flex-1 p-t-md p-b-md primary pointer font-bold" style={{ textAlign: 'right' }} onClick={() => onSkipClick(caseId)}>
            Proceed to Upload Scans
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CaseExportModal;

