import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';

import { TextField } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import NoteListItem from './NoteListItem';
import Button from '../../shared/Button';

import { createNote } from '../../../actions/casesActions';

import { roleNames } from '../../../constants/userRoles';
import AlertModal from '../AlertModal';

const { GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN, ENGINEER } = roleNames;

const CaseNotes = ({ activeCase, users = [], createNote, userRole }) => {
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [isOpen, toggleModal] = useState(false);

  const containerRef = useRef(null);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const notes = useMemo(() => {
    const caseNotes = activeCase?.caseNotes && activeCase?.caseNotes?.length ?
      activeCase?.caseNotes?.filter((note) => [GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN, ENGINEER].includes(userRole) ? true : !!note.external) : [];
    if (activeCase?.caseNote) {
      return [...caseNotes, {
        id: `${activeCase.id}_case_note`,
        text: activeCase.caseNote,
        userId: activeCase.userId,
        date: activeCase?.completionDates?.step0,
      }];
    }
    return caseNotes;
  }, [activeCase, userRole]);

  const onSubmitClick = async () => {
    if (isExternal) {
      toggleModal(true);
    } else {
      await onSubmit();
    }
  };

  const onSubmit = async () => {
    try {
      toggleModal(false);
      setLoading(true);
      await createNote(activeCase, { text: newNote, external: [GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN, ENGINEER].includes(userRole) ? isExternal : true });
      setIsExternal(false);
      setNewNote('');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onKeyDown = async (e) => {
    if (e.keyCode === 13) {
      await onSubmit();
    }
  };

  return (
    <div className="case-notes-container">
      {notes && notes.length ? (
        <div className="case-notes-list" ref={containerRef}>
          {orderBy(notes, 'date', 'asc').map((item) => (
            <NoteListItem
              key={item.id}
              text={item.text}
              date={item.date}
              users={users}
              userId={item.userId}
              external={item.external}
              procedureFlow={activeCase.procedureFlow}
            />
          ))}
        </div>
      ) : (
        <div className="empty-list">

        </div>
      )}
      <div className="case-notes_new-note">
        <TextField
          id="new-note"
          type="text"
          placeholder="Type any case related notes here"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          variant="outlined"
          onKeyDown={onKeyDown}
        />
        <div className="case-notes_new-note-action">
          {[GLOBAL_ADMIN, COUNTRY_ADMIN, DISTRIBUTOR_ADMIN, ENGINEER].includes(userRole) ? (
            <FormControlLabel
              checked={isExternal}
              control={<Switch color="primary" />}
              label="Activate to send this note to all users"
              labelPlacement="start"
              style={{ marginLeft: 0 }}
              onChange={(e) => setIsExternal(e.target.checked)}
            />
          ) : (
            <div />
          )}

          <Button
            text="Add new note"
            type="submit"
            color="primary"
            onClick={onSubmitClick}
            disabled={!newNote.length || loading}
            loading={loading}
          />
        </div>
      </div>

      <AlertModal
        open={isOpen}
        onClose={() => toggleModal(false)}
        onSubmit={onSubmit}
        title="Confirm Visibility"
        text="This note will be visible to all case related users"
        submitText="Continue"
      />
    </div>
  );
};

CaseNotes.propTypes = {
  activeCase: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    users: state.users?.list || [],
    userRole: state.user.currentUser.role
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createNote: (caseId, note) => dispatch(createNote(caseId, note))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseNotes);
