import React, { Fragment } from 'react';

import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import WarningIcon from '@material-ui/icons/Warning';
import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';

import Dropzone from '../../../../../shared/dropzone/Dropzone';

import { procedureFlows } from '../../../../../../constants/cases';

const isWeekend = (date) => {
  const day = date.day();

  return day === 0 || day === 6;
};

export const CmfStep1 = ({
  activeCase,
  fileStatuses,
  rejectedFiles,
  date,
  setDate,
  xRays,
  handleXRaysUpload,
  handleRemoveXRay,
  onUploadXRayComplete,
  scans,
  handleScansUpload,
  handleRemoveScan,
  onUploadScanComplete,
  mris,
  handleMriUpload,
  handleRemoveMri,
  onUploadMriComplete,
  reports,
  handleReportUpload,
  handleRemoveReport,
  onUploadReportComplete,
  userRole,
  requestPlanning,
  setRequestPlanning,
  requestPlanningDate,
  setRequestPlanningDate,
  additionalEmails,
  setAdditionalEmails
}) => (
  <Fragment>
    {activeCase.files && activeCase.files.some((file) => file.status === fileStatuses.REJECTED) && (
      <div className="alert warning">
        <div className="d-flex align-center">
          <WarningIcon color="primary" />
          <div>
            {`Some of the scans have been rejected: 
                      ${rejectedFiles.map((file, index) => `${file.name} `)}`}
          </div>
        </div>
        {activeCase.notes && activeCase.notes.step2 && <div className="m-t-md">Note: {activeCase.notes.step2}</div>}
      </div>
    )}

    <div className="field-container m-t-lg">
      <div className="secondary font-size-sm uppercase">Targeted surgery date</div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          id="date-picker"
          value={date}
          onChange={(date) => setDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
    <div className="modal-item-block">
      <div className="block-header">
        UPLOAD CT/CBCT DICOM*
      </div>
      <Dropzone
        type="archive"
        files={scans}
        onUpload={handleScansUpload}
        onRemove={handleRemoveScan}
        icon="scan"
        withProgress
        path={`${activeCase.id}/${activeCase.id}`}
        suffix="scan"
        onUploadComplete={onUploadScanComplete}
      />
    </div>
    {activeCase.procedureFlow === procedureFlows.CMF && (
      <div className="modal-item-block">
        <div className="block-header">
          UPLOAD DENTAL MODELS
        </div>
        <Dropzone
          type='archive'
          files={mris}
          onUpload={handleMriUpload}
          onRemove={handleRemoveMri}
          icon="x-ray"
          withProgress
          path={`${activeCase.id}/${activeCase.id}`}
          suffix="mri"
          onUploadComplete={onUploadMriComplete}
        />
      </div>
    )}
    <div className="modal-item-block">
      <div className="block-header">UPLOAD ADDITIONAL FILES</div>
      <Dropzone
        files={xRays}
        onUpload={handleXRaysUpload}
        onRemove={handleRemoveXRay}
        icon="x-ray"
        withProgress
        path={`${activeCase.id}/${activeCase.id}`}
        suffix="x-ray"
        onUploadComplete={onUploadXRayComplete}
      />
    </div>
    {/*{userRole !== userRoles.RADIOLOGY.name && (*/}
    {/*  <div className="modal-item-block">*/}
    {/*    <div className="block-header">Surgeon consultation report</div>*/}
    {/*    <Dropzone*/}
    {/*      files={reports}*/}
    {/*      onUpload={handleReportUpload}*/}
    {/*      onRemove={handleRemoveReport}*/}
    {/*      icon="scan"*/}
    {/*      withProgress*/}
    {/*      path={`${activeCase.id}/${activeCase.id}`}*/}
    {/*      suffix="report"*/}
    {/*      onUploadComplete={onUploadReportComplete}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*)}*/}

    {[procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow) && (
      <div>
        <div className='d-flex space-between'>
          <div className='secondary uppercase font-bold font-size-sm'>Request planning meeting</div>
          <div className="radio-group-container">
            <RadioGroup aria-label="requestPlanning" name="requestPlanning" value={requestPlanning} onChange={(e) => setRequestPlanning(e.target.value === 'true')}>
              <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </div>
        </div>
        {requestPlanning && (
          <>
            <div style={{ width: '50%' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  shouldDisableDate={isWeekend}
                  disablePast
                  format="DD/MM/YYYY  hh:mm a"
                  fullWidth
                  id="date-picker"
                  value={requestPlanningDate}
                  onChange={(date) => setRequestPlanningDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  ampm={false}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className='m-t-lg'>
              <div className="secondary uppercase font-size-xs">Additional attendees (email addresses)</div>
              <div className='w-100'>
                <TextField
                  id="additionalEmails"
                  type="text"
                  value={additionalEmails}
                  onChange={(e) => setAdditionalEmails(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
          </>
        )}
      </div>
    )}
  </Fragment>
);
