import React, { Fragment, useState } from 'react';
import moment from 'moment';

import { TextField } from '@material-ui/core';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';
import HardwareList from '../../HardwareList';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const CmfStep5 = ({
  classes,
  onClose,
  activeCase,
  updateCase,
  products,
  caseNotifications,
  fileTypes,
  statuses,
  userId,
}) => {
  const maxilla = activeCase.maxilla ? activeCase.maxilla.map((item) => ({ ...item, id: Math.random() })) : [];
  const leftMandible = activeCase.leftMandible ? activeCase.leftMandible.map((item) => ({ ...item, id: Math.random() })) : [];
  const rightMandible = activeCase.rightMandible ? activeCase.rightMandible.map((item) => ({ ...item, id: Math.random() })) : [];
  const genioplasty = activeCase.genioplasty ? activeCase.genioplasty.map((item) => ({ ...item, id: Math.random() })) : [];

  const [maxillaList, setMaxillaList] = useState(maxilla ? maxilla : []);
  const [leftMandibleList, setLeftMandibleList] = useState(leftMandible ? leftMandible : []);
  const [rightMandibleList, setRightMandibleList] = useState(rightMandible ? rightMandible : []);
  const [genioplastyList, setGenioplastyListList] = useState(genioplasty ? genioplasty : []);

  const [notes, setNotes] = useState('');
  const [sheets, setSheets] = useState([]);
  const [xRays, setXRays] = useState([]);
  const [allografts, setAllografts] = useState([]);

  // Maxilla

  const handleAddMaxilla = (product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setMaxillaList(list);
    } else {
      setMaxillaList([...maxillaList, product]);
    }
  };

  const handleRemoveMaxilla = (id) => {
    setMaxillaList(maxillaList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setMaxillaList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setMaxillaList(list);
  };

  const handleChange = (id, product) => {
    const list = [...maxillaList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setMaxillaList(list);
  };

  // Left Mandible

  const handleAddLeftMandible = (product) => {
    const list = [...leftMandibleList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setLeftMandibleList(list);
    } else {
      setLeftMandibleList([...leftMandibleList, product]);
    }
  };

  const handleRemoveLeftMandible = (id) => {
    setLeftMandibleList(leftMandibleList.filter((item) => item.id !== id));
  };

  const handleAmountLeftMandibleChange = (id, amount) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setLeftMandibleList(list);
  };

  const handleCategoryLeftMandibleChange = (id, category) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setLeftMandibleList(list);
  };

  const handleLeftMandibleChange = (id, product) => {
    const list = [...leftMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setLeftMandibleList(list);
  };

  // Right Mandible

  const handleAddRightMandible = (product) => {
    const list = [...rightMandibleList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setRightMandibleList(list);
    } else {
      setRightMandibleList([...rightMandibleList, product]);
    }
  };

  const handleRemoveRightMandible = (id) => {
    setRightMandibleList(rightMandibleList.filter((item) => item.id !== id));
  };

  const handleAmountRightMandibleChange = (id, amount) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setRightMandibleList(list);
  };

  const handleCategoryRightMandibleChange = (id, category) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setRightMandibleList(list);
  };

  const handleRightMandibleChange = (id, product) => {
    const list = [...rightMandibleList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setRightMandibleList(list);
  };

  // Genioplasty

  const handleAddGenioplasty = (product) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setGenioplastyListList(list);
    } else {
      setGenioplastyListList([...genioplastyList, product]);
    }
  };

  const handleRemoveGenioplasty = (id) => {
    setGenioplastyListList(genioplastyList.filter((item) => item.id !== id));
  };

  const handleAmountGenioplastyChange = (id, amount) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setGenioplastyListList(list);
  };

  const handleCategoryGenioplastyChange = (id, category) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setGenioplastyListList(list);
  };

  const handleGenioplastyChange = (id, product) => {
    const list = [...genioplastyList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setGenioplastyListList(list);
  };

  const handleSheetsUpload = (files) => {
    setSheets([...sheets, ...files.filter((file) => !sheets.map((sheet) => sheet.name).includes(file.name))]);
  };
  const handleXRaysUpload = (files) => {
    setXRays([...xRays, ...files.filter((file) => !xRays.map((xRay) => xRay.name).includes(file.name))]);
  };
  const handleAllograftsUpload = (files) => {
    setAllografts([
      ...allografts,
      ...files.filter((file) => !allografts.map((allograft) => allograft.name).includes(file.name)),
    ]);
  };

  const handleRemoveSheets = (name) => {
    setSheets(sheets.filter((file) => file.name !== name));
  };
  const handleRemoveXRays = (name) => {
    setXRays(xRays.filter((file) => file.name !== name));
  };
  const handleRemoveAllografts = (name) => {
    setAllografts(allografts.filter((file) => file.name !== name));
  };

  const handleSubmit = () => {
    const now = moment().format();
    const files = [...activeCase.files];
    const newFiles = [];

    sheets.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.chargedSheet}_${file.name}`,
        name: file.name,
        type: fileTypes.chargedSheet,
        date: now,
        userId,
      });
      newFiles.push(file);
    });
    xRays.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.xRay}_${file.name}`,
        name: file.name,
        type: fileTypes.xRay,
        date: now,
        userId,
      });
      newFiles.push(file);
    });
    allografts.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.allograft}_${file.name}`,
        name: file.name,
        type: fileTypes.allograft,
        date: now,
        userId,
      });
      newFiles.push(file);
    });

    const updatedCase = {
      ...activeCase,
      step: 6,
      activity: [{ ...caseNotifications[5].success, date: now, userId }, ...activeCase.activity],
      files,
      completionDates: { ...activeCase.completionDates, step5: now },
      completedBy: { ...activeCase.completedBy, step5: userId },
      maxilla: maxillaList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category,
      })),
      leftMandible: leftMandibleList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category,
      })),
      rightMandible: rightMandibleList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category,
      })),
      genioplasty: genioplastyList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category,
      })),
      notes: { ...activeCase.notes, step5: notes },
      status: statuses.inProgress,
    };

    updateCase(updatedCase, newFiles).then(() => onClose());
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">5</div>
          </div>
          <div className="modal-item-content">
            <div>Usage Report</div>
            {activeCase.access ? (
              <Fragment>

                <div>
                  <HardwareList
                    classes={classes}
                    hardwareList={maxillaList}
                    products={products}
                    onAdd={handleAddMaxilla}
                    onRemove={handleRemoveMaxilla}
                    onAmountChange={handleAmountChange}
                    onCategoryChange={handleCategoryChange}
                    onChange={handleChange}
                    title="Maxilla"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div style={{ marginTop: 32, marginBottom: 32 }}>
                  <HardwareList
                    classes={classes}
                    hardwareList={leftMandibleList}
                    products={products}
                    onAdd={handleAddLeftMandible}
                    onRemove={handleRemoveLeftMandible}
                    onAmountChange={handleAmountLeftMandibleChange}
                    onCategoryChange={handleCategoryLeftMandibleChange}
                    onChange={handleLeftMandibleChange}
                    title="Left Mandible"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div style={{ marginTop: 32, marginBottom: 32 }}>
                  <HardwareList
                    classes={classes}
                    hardwareList={rightMandibleList}
                    products={products}
                    onAdd={handleAddRightMandible}
                    onRemove={handleRemoveRightMandible}
                    onAmountChange={handleAmountRightMandibleChange}
                    onCategoryChange={handleCategoryRightMandibleChange}
                    onChange={handleRightMandibleChange}
                    title="Right Mandible"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div style={{ marginTop: 32, marginBottom: 32 }}>
                  <HardwareList
                    classes={classes}
                    hardwareList={genioplastyList}
                    products={products}
                    onAdd={handleAddGenioplasty}
                    onRemove={handleRemoveGenioplasty}
                    onAmountChange={handleAmountGenioplastyChange}
                    onCategoryChange={handleCategoryGenioplastyChange}
                    onChange={handleGenioplastyChange}
                    title="Genioplasty"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>

                <div className="m-t-sm">
                  <TextField
                    id="notes"
                    type="text"
                    fullWidth
                    multiline
                    label="Usage Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
                <div className="m-t-lg">
                  <div className="modal-item-block p-t-md">
                    <div className="block-header">Upload Charge Sheet</div>
                    <Dropzone
                      files={sheets}
                      onUpload={handleSheetsUpload}
                      icon="image"
                      onRemove={handleRemoveSheets}
                    />
                  </div>
                  <div className="modal-item-block">
                    <div className="block-header">Attach X-ray(s)</div>
                    <Dropzone files={xRays} onUpload={handleXRaysUpload} icon="x-ray" onRemove={handleRemoveXRays} />
                  </div>
                  <div className="modal-item-block">
                    <div className="block-header">Allograft Tracking Form</div>
                    <Dropzone
                      files={allografts}
                      onUpload={handleAllograftsUpload}
                      icon="graft"
                      onRemove={handleRemoveAllografts}
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={5} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button text="Submit" color="primary" onClick={handleSubmit} disabled={!activeCase.access} />
      </div>
    </div>
  );
};
