import { SET_NOTIFICATION, CLOSE_NOTIFICATION } from '../actions/actionTypes';

const initialState = {
  open: false,
  message: '',
  variant: 'info',
  vertical: 'top',
  horizontal: 'center',
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { open: true, ...action.notification };
    case CLOSE_NOTIFICATION:
      return { open: false };
    default:
      return state;
  }
};

export default notificationReducer;
