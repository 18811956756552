import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';

import Autocomplete from './Autocomplete';

const UserSelect = ({ user, userList, onChange, disabled, value }) => {
  return (
    <div>
      <Hidden xsDown implementation="css">
        <Autocomplete
          onChange={(event, value) => onChange(value)}
          value={user ? user : value}
          options={userList.sort((a, b) => a.lastName.localeCompare(b.lastName))}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          groupBy={(option) => option.lastName[0].toUpperCase()}
          disabled={disabled}
        />
      </Hidden>
      <Hidden smUp implementation="css">
        <Select
          id="user-select"
          className="user-select"
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        >
          {userList.map((item) => (
            <MenuItem key={item.uid} value={item}>
              {item.firstName} {item.lastName}
            </MenuItem>
          ))}
        </Select>
      </Hidden>
    </div>
  );
};

UserSelect.propTypes = {
  user: PropTypes.object,
  userList: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(UserSelect);
