import React from 'react';
import PropTypes from 'prop-types';

import DistributorListItem from './DistributorListItem';

const DistributorList = ({
  distributors,
  procedures,
  onDelete,
  admins,
  users,
  onAddAdmin,
  onDeleteAdmin,
  onAddProcedure,
  onDeleteProcedure,
  onUpdateDistributor,
}) => {
  return (
    <div className="distributor-list">
      {distributors.map((distributor) => (
        <DistributorListItem
          key={distributor.id}
          {...distributor}
          procedures={procedures}
          onDelete={onDelete}
          onUpdate={onUpdateDistributor}
          admins={admins.filter((admin) => admin.distributor === distributor.id)}
          users={users}
          onAddAdmin={onAddAdmin}
          onDeleteAdmin={onDeleteAdmin}
          onAddProcedure={onAddProcedure}
          onDeleteProcedure={onDeleteProcedure}
        />
      ))}
    </div>
  );
};

DistributorList.propTypes = {
  distributors: PropTypes.array,
  admins: PropTypes.array,
  users: PropTypes.array,
  onDelete: PropTypes.func,
  onAddAdmin: PropTypes.func,
  onDeleteAdmin: PropTypes.func,
};

export default DistributorList;
