import React from 'react';
import PropTypes from 'prop-types';

import ProcedureListItem from './procedure-list-item';

import { sortAlphabetically } from '../../../../../util/utils';

const ProcedureList = (props) => {
  const {
    procedures,
    onDelete,
    onAddProduct,
    onDeleteProduct,
    onAddDocument,
    onDeleteDocument,
    onToggle,
    country,
    allDocuments,
    onAddFlow,
    onDeleteFlow,
    updateLeadDays,
    onUploadProducts,
  } = props;

  return (
    <div className="procedure-list">
      {procedures.sort(sortAlphabetically('name')).map((procedure) => (
        <ProcedureListItem
          key={procedure.id}
          {...procedure}
          country={country}
          onDelete={onDelete}
          onToggle={onToggle}
          onAddProduct={onAddProduct}
          onDeleteProduct={onDeleteProduct}
          onAddDocument={onAddDocument}
          onDeleteDocument={onDeleteDocument}
          allDocuments={allDocuments}
          onAddFlow={onAddFlow}
          onDeleteFlow={onDeleteFlow}
          updateLeadDays={updateLeadDays}
          onUploadProducts={onUploadProducts}
        />
      ))}
    </div>
  );
};

ProcedureList.propTypes = {
  procedures: PropTypes.array,
  country: PropTypes.string,
  onDelete: PropTypes.func,
  onToggle: PropTypes.func,
  onAddProduct: PropTypes.func,
  onDeleteProduct: PropTypes.func,
};

export default ProcedureList;
