import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Button from '../../../shared/Button';
import UserList from '../UserList';
import NewUser from '../NewUser';
import NewSurgeon from './NewSurgeon';
import SurgeonList from './SurgeonList';

import { userRoles } from '../../../../constants';

import {
  setRole,
  addSurgeon,
  disableSurgeon,
  enableSurgeon,
  updateHospitals,
  updateSurgeon,
} from '../../../../actions/adminActions';

const tabs = [
  { id: 0, label: 'Surgeon', buttonLabel: 'Surgeon' },
  { id: 1, label: 'Sales Rep', buttonLabel: 'Sales Rep' },
  { id: 2, label: 'Pr. Manager', buttonLabel: 'Pr. Manager' },
  { id: 3, label: 'Logistics', buttonLabel: 'Logistics' },
  { id: 4, label: 'Registrar', buttonLabel: 'Registrar' },
];

const DistributorAdminContainer = (props) => {
  const {
    onClose,
    currentUser,
    users,
    setRole,
    salesReps,
    managers,
    logistics,
    distributors,
    surgeons,
    registrars,
    hospitals,
    addSurgeon,
    disableSurgeon,
    enableSurgeon,
    updateHospitals,
    updateSurgeon,
    countryList,
  } = props;

  const [activeTab, setActiveTab] = useState(0);

  const [distributor, setDistributor] = useState(distributors.length ? distributors[0].id : null);
  const [country, setCountry] = useState('Australia');

  const [addMode, setAddMode] = useState(false);
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    if (currentUser.role === userRoles.COUNTRY_ADMIN.name) {
      setCountry(currentUser.administrationCountry);
      const countryDistributor = distributors?.find((d) => d.country === currentUser.administrationCountry);
      if (countryDistributor) {
        setDistributor(countryDistributor.id);
      }
    }
  }, [distributors, currentUser]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    setAddMode(false);
  };

  const handleAddSales = () => {
    if (!newUser) {
      return;
    }

    setRole(newUser.uid, userRoles.SALES_REP.name, null, distributor);
    setAddMode(false);
  };

  const handleAddManager = () => {
    if (!newUser) {
      return;
    }

    setRole(newUser.uid, userRoles.PRACTICE_MANAGER.name, null, distributor);
    setAddMode(false);
  };

  const handleAddLogistics = () => {
    if (!newUser) {
      return;
    }

    setRole(newUser.uid, userRoles.LOGISTICS.name, null, distributor);
    setAddMode(false);
  };

  const handleAddRegistrar = () => {
    if (!newUser) {
      return;
    }

    setRole(newUser.uid, userRoles.REGISTRAR.name, null, distributor);
    setAddMode(false);
  };

  const handleAddSurgeon = (surgeon) => {
    addSurgeon(surgeon, distributor);
    setAddMode(false);
  };

  const handleAddHospital = (id, hospitalId) => {
    const surgeon = surgeons.find((item) => item.id === id);
    const surgeonHospitals = surgeon.hospitals.slice();

    surgeonHospitals.push(hospitalId);
    updateHospitals(id, surgeonHospitals);
  };

  const handleDeleteHospital = (id, product) => {
    const surgeonHospitals = surgeons.find((item) => item.id === id).hospitals.slice();
    const newHospitals = surgeonHospitals.filter((item) => item !== product);

    updateHospitals(id, newHospitals);
  };

  const renderContent = (data) => {
    switch (activeTab) {
      case 0:
        return (
          <SurgeonList
            surgeons={data}
            onDisable={disableSurgeon}
            onEnable={enableSurgeon}
            onUpdate={updateSurgeon}
            users={users}
            hospitals={hospitals?.filter((h) => h.country === country)}
            onAddHospital={handleAddHospital}
            onDeleteHospital={handleDeleteHospital}
            country={country}
            currentUser={currentUser}
            distributors={distributors}
          />
        );
      case 1:
        return <UserList users={data} onDelete={(uid) => setRole(uid, null, null, null)} />;
      case 2:
        return <UserList users={data} onDelete={(uid) => setRole(uid, null, null, null)} />;
      case 3:
        return <UserList users={data} onDelete={(uid) => setRole(uid, null, null, null)} />;
      case 4:
        return <UserList users={data} onDelete={(uid) => setRole(uid, null, null, null)} />;
      default:
        return <div>Surgeon</div>;
    }
  };

  const renderInput = () => {
    switch (activeTab) {
      case 0:
        return (
          <NewSurgeon
            userList={userList}
            surgeons={surgeons}
            distributor={distributor}
            onSubmit={handleAddSurgeon}
            onCancel={() => setAddMode(false)}
            country={country}
            currentUser={currentUser}
            distributors={distributors}
          />
        );
      case 1:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={handleAddSales}
          />
        );
      case 2:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={handleAddManager}
          />
        );
      case 3:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={handleAddLogistics}
          />
        );
      case 4:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={handleAddRegistrar}
          />
        );
      default:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={handleAddLogistics}
          />
        );
    }
  };

  const getData = () => {
    switch (activeTab) {
      case 0:
        return surgeons;
      case 1:
        return salesReps;
      case 2:
        return managers;
      case 3:
        return logistics;
      case 4:
        return registrars;
      default:
        return users;
    }
  };

  const data = getData();
  const filteredData = data?.filter((item) => item.distributor === distributor);

  const filterUserList = (user) =>
    !~filteredData.map((admin) => admin.email).indexOf(user.email) && user.uid !== currentUser.uid;
  const userList = users.filter(filterUserList);

  const isDistributorAdmin = currentUser.userRole === userRoles.DISTRIBUTOR_ADMIN.name;
  const isCountryAdmin = currentUser.role === userRoles.COUNTRY_ADMIN.name;

  const distributorsList = useMemo(() => {
    if (!country) {
      return distributors;
    }
    return distributors.filter((d) => d.country === country) || [];
  }, [distributors, country]);

  return (
    <div className="admin-modal-container">
      <div className="admin-modal-content">
        <div className={`admin-modal-item header ${!filteredData.length && 'empty'}`}>
          <div className="column">
            <div className="modal-item-circle light" />
          </div>
          <div className="modal-item-content">
            <>
              <div className="item-content-header">Country</div>
              <Select
                id="country-select"
                disabled={isCountryAdmin}
                className="country-select"
                value={isCountryAdmin ? currentUser.administrationCountry : country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setDistributor(null);
                }}
              >
                {countryList.sort().map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <div className="m-t-lg item-content-header">Distributor</div>
              <Select
                disabled={isDistributorAdmin}
                id="country-select"
                className="country-select"
                value={isDistributorAdmin ? currentUser.distributor : distributor}
                onChange={(e) => setDistributor(e.target.value)}
              >
                {distributorsList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </>
            <div className="modal-tabs">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  className={`modal-tab ${activeTab === tab.id && 'active'}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  {tab.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        {renderContent(filteredData)}
        {addMode && renderInput()}
      </div>
      <div className="admin-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        {!addMode && (
          <Button
            text={`Add ${tabs[activeTab].buttonLabel}`}
            color="primary"
            onClick={() => setAddMode(true)}
          />
        )}
      </div>
    </div>
  );
};

DistributorAdminContainer.propTypes = {
  email: PropTypes.string,
  users: PropTypes.array,
  currentUser: PropTypes.object,
  salesReps: PropTypes.array,
  managers: PropTypes.array,
  logistics: PropTypes.array,
  distributors: PropTypes.array,
  surgeons: PropTypes.array,
  hospitals: PropTypes.array,
  onClose: PropTypes.func,
  setRole: PropTypes.func,
  addSurgeon: PropTypes.func,
  disableSurgeon: PropTypes.func,
  enableSurgeon: PropTypes.func,
  updateHospitals: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    users: state.users.list,
    salesReps: state.users.list.filter((item) => item.role === userRoles.SALES_REP.name),
    managers: state.users.list.filter((item) => item.role === userRoles.PRACTICE_MANAGER.name),
    logistics: state.users.list.filter((item) => item.role === userRoles.LOGISTICS.name),
    registrars: state.users.list.filter((item) => item.role === userRoles.REGISTRAR.name),
    distributors: state.distributors.list,
    hospitals: state.hospitals.list,
    surgeons: state.surgeons.list,
    countryList: state.countries.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRole: (uid, role, country, distributor) =>
      dispatch(setRole(uid, role, country, distributor)),
    addSurgeon: (surgeon, distributor) => dispatch(addSurgeon(surgeon, distributor)),
    disableSurgeon: (id, userId) => dispatch(disableSurgeon(id, userId)),
    enableSurgeon: (id, userId) => dispatch(enableSurgeon(id, userId)),
    updateSurgeon: (id, surgeon) => dispatch(updateSurgeon(id, surgeon)),
    updateHospitals: (id, hospitals) => dispatch(updateHospitals(id, hospitals)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributorAdminContainer);
