import {
  ADD_HOSPITAL,
  DELETE_HOSPITAL,
  LOAD_HOSPITALS,
  SET_HOSPITALS,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const hospitalsReducer = (state = initialState, action) => {
  const hospitals = state.list.slice();

  switch (action.type) {
    case LOAD_HOSPITALS:
      return { ...state, loading: true };
    case SET_HOSPITALS:
      return { ...state, loading: false, list: action.hospitals };
    case ADD_HOSPITAL:
      const newHospital = {
        id: action.id,
        country: action.country,
        name: action.name,
        address: action.address,
        state: action.state,
        postCode: action.postCode,
      };

      return { ...state, list: [...hospitals, newHospital], loading: false };
    case DELETE_HOSPITAL:
      return { ...state, list: hospitals.filter((item) => item.id !== action.id), loading: false };
    default:
      return state;
  }
};

export default hospitalsReducer;
