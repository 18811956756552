import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const ProcedureAddDocument = ({ newDocument, documents, onChange, onCancel, onSubmit }) => (
  <div className="admin-modal-item new-item">
    <div className="column" />
    <div className="modal-item-content d-flex">
      <Select
        id="user-select"
        className="user-select"
        value={newDocument}
        onChange={(e) => onChange(e.target.value)}
      >
        {documents?.map((document) => (
          <MenuItem key={document.id} value={document}>
            {document.name}
          </MenuItem>
        ))}

        {!documents.length && (
          <MenuItem disabled value={''}>
            None
          </MenuItem>
        )}
      </Select>
      <div className="p-l-md primary pointer" onClick={onSubmit}>
        Add
      </div>
      <div className="p-l-md secondary pointer" onClick={onCancel}>
        Cancel
      </div>
    </div>
  </div>
);

ProcedureAddDocument.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProcedureAddDocument;
