import {
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
  LOAD_DOCUMENTS,
  SET_DOCUMENTS,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const documentsReducer = (state = initialState, action) => {
  const documents = state.list.slice();

  switch (action.type) {
    case LOAD_DOCUMENTS:
      return { ...state, loading: true };
    case SET_DOCUMENTS:
      return { ...state, loading: false, list: action.documents };
    case ADD_DOCUMENT:
      const newDocument = {
        id: action.id,
        name: action.name,
        url: action.url,
      };

      return { ...state, list: [...documents, newDocument], loading: false };
    case DELETE_DOCUMENT:
      return { ...state, list: documents.filter((item) => item.id !== action.id), loading: false };
    default:
      return state;
  }
};

export default documentsReducer;
