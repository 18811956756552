import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HardwareList from '../../HardwareList';
import RestrictComponent from '../../../../../shared/RestrictComponent';

import blockIcon from '../../../../../../assets/block_icon.svg';
import preopIcon from '../../../../../../assets/preop_icon.svg';

import { setActiveTab, simpleUpdateCase } from '../../../../../../actions/casesActions';

import { userRoles } from '../../../../../../constants';
import { makeStyles } from '@material-ui/core';

const fileTypes = ['preop', 'block'];

const useStyles = makeStyles((theme) => ({
  primary: { color: theme.palette.primary.main },
}));

const AclStep3Static = ({ activeCase, goToFiles, userRole, products, updateCase }) => {
  const { files, alignmentData, hardware, manufacturing } = activeCase;
  const classes = useStyles();
  const {
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  } = userRoles;

  const initialHardwareList = hardware ? hardware.map((item) => ({ ...item, id: Math.random() * Math.random() })) : [];

  const initialManufacturingList = manufacturing
    ? manufacturing.map((item) => ({ ...item, id: Math.random() * Math.random() }))
    : [];

  const [hardwareList, setHardwareList] = useState(initialHardwareList);
  const [manufacturingList, setManufacturingList] = useState(initialManufacturingList);

  const [isEditMode, setEditMode] = useState(false);
  const [isEditManufacturingMode, setEditManufacturingMode] = useState(false);

  useEffect(() => {
    setHardwareList(hardware.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    if (manufacturing) {
      setManufacturingList(manufacturing.map((item) => ({ ...item, id: Math.random() * Math.random() })));
    }
  }, [hardware, manufacturing]);

  const notes = activeCase.notes && activeCase.notes.step5;

  const isPermission = !activeCase.trainingMode
    ? [GLOBAL_ADMIN, COUNTRY_ADMIN]
    : [
        GLOBAL_ADMIN,
        COUNTRY_ADMIN,
        DISTRIBUTOR_ADMIN,
        SURGEON,
        ENGINEER,
        SALES_REP,
        LOGISTICS,
        PRACTICE_MANAGER,
        MANUFACTURER,
      ];

  const onSave = () => {
    const updatedCase = {
      ...activeCase,
      hardware: hardwareList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      })),
      manufacturing: manufacturingList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      })),
    };

    updateCase(updatedCase);
    setEditMode(false);
    setEditManufacturingMode(false);
  };

  const handleAddHardware = (product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setHardwareList(list);
    } else {
      setHardwareList([...hardwareList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setHardwareList(hardwareList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setHardwareList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setHardwareList(list);
  };

  const handleChange = (id, product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setHardwareList(list);
  };

  const handleAddManufacturing = (product) => {
    const list = [...manufacturingList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setManufacturingList(list);
    } else {
      setManufacturingList([...manufacturingList, product]);
    }
  };

  const handleRemoveManufacturing = (id) => {
    setManufacturingList(manufacturingList.filter((item) => item.id !== id));
  };

  const handleAmountManufacturingChange = (id, amount) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setManufacturingList(list);
  };

  const handleCategoryManufacturingChange = (id, category) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setManufacturingList(list);
  };

  const handleManufacturingChange = (id, product) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setManufacturingList(list);
  };

  return (
    <div className="case-static-container">
      <div className="m-b-md underline">
        {files
          .filter((file) => fileTypes.includes(file.type))
          .map((file) => (
            <div key={file.id} className="d-flex m-b-md pointer" onClick={goToFiles}>
              <img src={file.type === 'block' ? blockIcon : preopIcon} alt="file-icon" />
              <div>{file.name}</div>
              <div className="status-indicator">{file.type}</div>
            </div>
          ))}
      </div>
      <div className="p-b-md m-b-md underline">
        <div className="m-b-sm d-flex">
          <div className="secondary">1 STAGE HARDWARE (SUGGESTED)</div>
          <RestrictComponent roles={isPermission}>
            {isEditMode ? (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={() => setEditMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={hardwareList}
              products={products}
              onAdd={handleAddHardware}
              onRemove={handleRemoveHardware}
              onAmountChange={handleAmountChange}
              onCategoryChange={handleCategoryChange}
              onChange={handleChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {hardwareList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <div className="m-b-sm d-flex">
          <div className="secondary">2 STAGE HARDWARE (SUGGESTED)</div>
          <RestrictComponent roles={isPermission}>
            {isEditManufacturingMode ? (
              <div className={`${classes.primary} m-l-sm pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm  pointer`} onClick={() => setEditManufacturingMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditManufacturingMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={manufacturingList}
              products={products}
              onAdd={handleAddManufacturing}
              onRemove={handleRemoveManufacturing}
              onAmountChange={handleAmountManufacturingChange}
              onCategoryChange={handleCategoryManufacturingChange}
              onChange={handleManufacturingChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {manufacturingList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>

      {notes && (
        <div className="m-t-md p-t-sm border-top">
          <div className="secondary">Notes:</div>
          <div>{notes}</div>
        </div>
      )}
    </div>
  );
};

AclStep3Static.propTypes = {
  activeCase: PropTypes.object,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    products,
    userRole: state.user.currentUser.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
    updateCase: (updatedCase) => dispatch(simpleUpdateCase(updatedCase)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AclStep3Static);
