import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';

import Dropzone from '../../../../../shared/dropzone/Dropzone';
import Button from '../../../../../shared/Button';

import { simpleUpdateCase } from '../../../../../../actions/casesActions';

import { fileStatuses, fileTypes } from '../../../../../../constants/cases';

const CmfStep1Admin = ({ activeCase, userId }) => {
  const [xRays, setXRays] = useState([]);
  const [scans, setScans] = useState([]);
  const [mris, setMris] = useState([]);
  const [reports, setReports] = useState([]);

  const [uploadedXRays, setUploadedXRays] = useState([]);
  const [uploadedScans, setUploadedScans] = useState([]);
  const [uploadedMris, setUploadedMris] = useState([]);
  const [uploadedReports, setUploadedReports] = useState([]);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleXRaysUpload = (files) => {
    const uploads = { ...uploadedXRays };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedXRays(uploads);
    setXRays([...xRays, ...files.filter((file) => !xRays.map((xRay) => xRay.name).includes(file.name))]);
  };
  const handleScansUpload = (files) => {
    const uploads = { ...uploadedScans };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedScans(uploads);
    setScans([...scans, ...files.filter((file) => !scans.map((scan) => scan.name).includes(file.name))]);
  };
  const handleMriUpload = (files) => {
    const uploads = { ...uploadedMris };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedMris(uploads);
    setMris([...mris, ...files.filter((file) => !mris.map((mri) => mri.name).includes(file.name))]);
  };
  const handleReportUpload = (files) => {
    const uploads = { ...uploadedReports };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedReports(uploads);
    setReports([...reports, ...files.filter((file) => !reports.map((report) => report.name).includes(file.name))]);
  };

  const handleRemoveXRay = (name) => {
    const uploads = { ...uploadedXRays };

    delete uploads[name];
    setUploadedXRays(uploads);
    setXRays(xRays.filter((file) => file.name !== name));
  };
  const handleRemoveScan = (name) => {
    const uploads = { ...uploadedScans };

    delete uploads[name];
    setUploadedScans(uploads);
    setScans(scans.filter((file) => file.name !== name));
  };
  const handleRemoveMri = (name) => {
    const uploads = { ...uploadedMris };

    delete uploads[name];
    setUploadedMris(uploads);
    setMris(mris.filter((file) => file.name !== name));
  };
  const handleRemoveReport = (name) => {
    const uploads = { ...uploadedMris };

    delete uploads[name];
    setUploadedReports(uploads);
    setReports(reports.filter((file) => file.name !== name));
  };

  const onUploadXRayComplete = (name, url) => {
    setUploadedXRays((prevState) => ({ ...prevState, [name]: true }));
  };
  const onUploadScanComplete = (name, url) => {
    setUploadedScans((prevState) => ({ ...prevState, [name]: true }));
  };
  const onUploadMriComplete = (name, url) => {
    setUploadedMris((prevState) => ({ ...prevState, [name]: true }));
  };
  const onUploadReportComplete = (name, url) => {
    setUploadedReports((prevState) => ({ ...prevState, [name]: true }));
  };

  const handleSubmit = () => {
    setLoading(true);
    const filesData = activeCase.files && activeCase.files.length ? [...activeCase.files] : [];
    const now = moment().format();

    xRays.forEach((file) => {
      filesData.push({
        id: `${activeCase.id}_${fileTypes.xRay}_${file.name}`,
        name: file.name,
        type: fileTypes.xRay,
        status: fileStatuses.APPROVED,
        date: now,
        userId,
      });
    });
    scans.forEach((file) => {
      filesData.push({
        id: `${activeCase.id}_${fileTypes.scan}_${file.name}`,
        name: file.name,
        type: fileTypes.scan,
        status: fileStatuses.APPROVED,
        date: now,
        userId,
      });
    });
    mris.forEach((file) => {
      filesData.push({
        id: `${activeCase.id}_${fileTypes.mri}_${file.name}`,
        name: file.name,
        type: fileTypes.mri,
        status: fileStatuses.APPROVED,
        date: now,
        userId,
      });
    });
    reports.forEach((file) => {
      filesData.push({
        id: `${activeCase.id}_${fileTypes.report}_${file.name}`,
        name: file.name,
        type: fileTypes.report,
        status: fileStatuses.APPROVED,
        date: now,
        userId,
      });
    });

    dispatch(simpleUpdateCase({ ...activeCase, files: filesData }))
      .then(() => {
        setXRays([]);
        setScans([]);
        setMris([]);
        setReports([]);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      <div className='m-t-lg font-bold secondary'>
        Upload additional files
      </div>
      <div className="modal-item-block m-t-md">
        <div className="block-header">
          Attach MRI (recommended) <span className="required" />
        </div>
        <Dropzone
          files={mris}
          onUpload={handleMriUpload}
          onRemove={handleRemoveMri}
          icon="x-ray"
          withProgress
          path={`${activeCase.id}/${activeCase.id}`}
          suffix="mri"
          onUploadComplete={onUploadMriComplete}
        />
      </div>
      <div className="modal-item-block m-t-lg">
        <div className="block-header">
          Upload CT Scan <span className="required" />
        </div>
        <Dropzone
          files={scans}
          onUpload={handleScansUpload}
          onRemove={handleRemoveScan}
          icon="scan"
          withProgress
          path={`${activeCase.id}/${activeCase.id}`}
          suffix="scan"
          onUploadComplete={onUploadScanComplete}
        />
      </div>
      <div className="modal-item-block m-t-lg">
        <div className="block-header">Attach XRAY</div>
        <Dropzone
          files={xRays}
          onUpload={handleXRaysUpload}
          onRemove={handleRemoveXRay}
          icon="x-ray"
          withProgress
          path={`${activeCase.id}/${activeCase.id}`}
          suffix="x-ray"
          onUploadComplete={onUploadXRayComplete}
        />
      </div>
      <div className="modal-item-block m-t-lg">
        <div className="block-header">Surgeon consultation report</div>
        <Dropzone
          files={reports}
          onUpload={handleReportUpload}
          onRemove={handleRemoveReport}
          icon="scan"
          withProgress
          path={`${activeCase.id}/${activeCase.id}`}
          suffix="report"
          onUploadComplete={onUploadReportComplete}
        />
      </div>

      <div className='m-t-lg'>
        <Button
          text="Attach Scans"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
          disabled={loading || (!xRays.length && !scans.length && !mris.length && !reports.length)}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.currentUser.uid,
  };
};

export default connect(mapStateToProps)(CmfStep1Admin);
