import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import NewHospital from './NewHospital';

import { roleNames } from '../../../../constants/userRoles';
import { orderBy } from 'lodash';

const { ENGINEER, MANUFACTURER, PRACTICE_MANAGER, SALES_REP, LOGISTICS, REGISTRAR, GLOBAL_ADMIN } = roleNames;

const SurgeonListItem = (props) => {
  const {
    id,
    engineer,
    manufacturer,
    manager,
    salesRep,
    logistics,
    registrar = [],
    hospitals,
    onDisable,
    onEnable,
    onUpdate,
    users,
    hospitalList,
    onAddHospital,
    onDeleteHospital,
    userId,
    country,
    currentUser,
    distributors = [],
    disabled,
  } = props;

  const [addMode, toggleMode] = useState(false);
  const [deleteMode, toggleDeleteMode] = useState(false);
  const [enableMode, toggleEnableMode] = useState(false);
  const [editMode, toggleEditMode] = useState(false);
  const [newHospital, setHospital] = useState(null);

  const [newManufacturer, setManufacturer] = useState(
    users.find((item) => item.uid === manufacturer)
  );
  const [newLogistics, setLogistics] = useState(users.find((item) => item.uid === logistics));
  const [newEngineer, setEngineer] = useState(
    typeof engineer === 'string'
      ? users.find((item) => item.uid === engineer)
      : engineer.map((userId) => users.find((item) => item.uid === userId)).filter((item) => item)
  );
  const [newManager, setManager] = useState(
    typeof manager === 'string'
      ? users.find((item) => item.uid === manager)
      : manager.map((userId) => users.find((item) => item.uid === userId)).filter((item) => item)
  );
  const [newSales, setSales] = useState(
    typeof salesRep === 'string'
      ? users.find((item) => item.uid === salesRep)
      : salesRep.map((userId) => users.find((item) => item.uid === userId)).filter((item) => item)
  );
  const [newRegistrar, setRegistrar] = useState(registrar?.map((userId) => users.find((item) => item.uid === userId)).filter((item) => item));

  const surgeon = users.find((user) => user.uid === userId);
  const surgeonHospitals = hospitals
    .map((hospitalId) => hospitalList.find((hospital) => hospital.id === hospitalId))
    .filter((item) => item);
  const filteredHospitals = hospitalList.filter((hospital) => !hospitals.includes(hospital.id));

  const engineers = useMemo(() => users.filter((user) => user.role === ENGINEER), [users]);

  const manufacturers = useMemo(() => users.filter((user) => user.role === MANUFACTURER), [users]);

  const managers = useMemo(() => orderBy(
    users
      .filter((user) => (
        user.role === PRACTICE_MANAGER && (
          currentUser.role === GLOBAL_ADMIN ||
          currentUser.administrationCountry === user.country ||
          currentUser.distributor === user.distributor ||
          currentUser.administrationCountry === distributors?.find((d) => d.id === user.distributor)?.country
        )
      )),
    'lastName'
  ),[currentUser, users, distributors]);

  const salesReps = useMemo(() => orderBy(
    users
      .filter((user) => (
        user.role === SALES_REP && (
          currentUser.role === GLOBAL_ADMIN ||
          currentUser.administrationCountry === user.country ||
          currentUser.distributor === user.distributor ||
          currentUser.administrationCountry === distributors?.find((d) => d.id === user.distributor)?.country
        )
      )),
    'lastName'
  ),[currentUser, users, distributors]);

  const logisticsList = useMemo(() => users.filter((user) => user.role === LOGISTICS), [users]);

  const registrarList = useMemo(() => orderBy(
    users
      .filter((user) => (
        user.role === REGISTRAR && (
          currentUser.role === GLOBAL_ADMIN ||
          currentUser.administrationCountry === user.country ||
          currentUser.distributor === user.distributor ||
          currentUser.administrationCountry === distributors?.find((d) => d.id === user.distributor)?.country
        )
      )),
    'lastName'
  ),[currentUser, users, distributors]);

  const handleSubmit = () => {
    onAddHospital(id, newHospital.id);
    toggleMode(false);
  };

  const getUser = (role) => {
    if (!role) return 'Not Assigned';

    if (typeof role === 'string') {
      const user = users.find((item) => item.uid === role);

      if (user) {
        return `${user.firstName} ${user.lastName}`;
      }

      return 'Not Assigned';
    } else {
      const usersList = role
        .map((userId) => users.find((item) => item.uid === userId))
        .filter((item) => item);
      return usersList.length
        ? usersList.map((item) => `${item.firstName} ${item.lastName}`).join(', ')
        : 'Not Assigned';
    }
  };

  const handleSave = () => {
    const updatedSurgeon = {
      engineer: newEngineer ? newEngineer.map((item) => item.uid) : null,
      manufacturer: newManufacturer ? newManufacturer.uid : null,
      manager: newManager ? newManager.map((item) => item.uid) : null,
      salesRep: newSales ? newSales.map((item) => item.uid) : null,
      logistics: newLogistics ? newLogistics.uid : null,
      registrar: newRegistrar ? newRegistrar.map((item) => item.uid) : null,
    };

    onUpdate(id, updatedSurgeon).then(() => {
      toggleEditMode(false);
    });
  };

  const onDisableClick = async (id, userId) => {
    await onDisable(id, userId);
    toggleDeleteMode(false);
  };

  const onEnableClick = async (id, userId) => {
    await onEnable(id, userId);
    toggleEnableMode(false);
  };

  return (
    <div className="admin-modal-item list-item-container">
      <div className="list-item-main">
        <div className="column">
          <div className="modal-item-circle" />
        </div>
        <div className="modal-item-header">
          <div className="d-flex">
            <div>{`Dr ${surgeon?.firstName} ${surgeon?.lastName}`}</div>
            <div className="user-email">{surgeon?.email}</div>
          </div>
          {deleteMode && (
            <div className="d-flex">
              <div className="danger pointer m-l-sm" onClick={() => onDisableClick(id, userId)}>
                Disable
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleDeleteMode(false)}>
                Cancel
              </div>
            </div>
          )}
          {enableMode && (
            <div className="d-flex">
              <div className="primary pointer m-l-sm" onClick={() => onEnableClick(id, userId)}>
                Enable
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleEnableMode(false)}>
                Cancel
              </div>
            </div>
          )}
          {editMode && (
            <div className="d-flex">
              <div className="primary pointer m-l-sm" onClick={handleSave}>
                Save
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleEditMode(false)}>
                Cancel
              </div>
            </div>
          )}
          {!deleteMode && !editMode && !enableMode && (
            <div className="d-flex">
              <IconButton aria-label="edit" color="primary" onClick={() => toggleEditMode(true)}>
                <EditIcon fontSize="small" />
              </IconButton>
              {disabled ? (
                <IconButton
                  aria-label="enable"
                  color="primary"
                  onClick={() => toggleEnableMode(true)}
                >
                  <CheckCircleIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="disable"
                  color="primary"
                  onClick={() => toggleDeleteMode(true)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex m-b-md font-size-xs">
        <div className="column" />
        {editMode ? (
          <div className="flex-1">
            <div className="select-item-row">
              <div className="select-item">
                <div className="select-label">Assigned engineer</div>
                <Select
                  id="engineer-select"
                  className="user-select"
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    if (!selected.length) return '';
                    return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                  }}
                  multiple
                  fullWidth
                  value={newEngineer}
                  onChange={(e) => setEngineer(e.target.value)}
                >
                  {engineers.map((item) => (
                    <MenuItem key={item.uid} value={item}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="select-item">
                <div className="select-label">Assigned manufacturer</div>
                <Select
                  id="manufacturer-select"
                  className="user-select"
                  fullWidth
                  value={newManufacturer}
                  onChange={(e) => setManufacturer(e.target.value)}
                >
                  {manufacturers.map((item) => (
                    <MenuItem key={item.uid} value={item}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="select-item-row">
              <div className="select-item">
                <div className="select-label">Assigned manager</div>
                <Select
                  id="manager-select"
                  className="user-select"
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    if (!selected.length) return '';
                    return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                  }}
                  multiple
                  fullWidth
                  value={newManager}
                  onChange={(e) => setManager(e.target.value)}
                >
                  {managers.map((item) => (
                    <MenuItem key={item.uid} value={item}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="select-item">
                <div className="select-label">Assigned sales rep</div>
                <Select
                  id="sales-select"
                  className="user-select"
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    if (!selected.length) return '';
                    return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                  }}
                  multiple
                  fullWidth
                  value={newSales}
                  onChange={(e) => setSales(e.target.value)}
                >
                  {salesReps.map((item) => (
                    <MenuItem key={item.uid} value={item}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="select-item-row">
              <div className="select-item">
                <div className="select-label">Assigned logistics</div>
                <Select
                  id="logistics-select"
                  className="user-select"
                  fullWidth
                  value={newLogistics}
                  onChange={(e) => setLogistics(e.target.value)}
                >
                  {logisticsList.map((item) => (
                    <MenuItem key={item.uid} value={item}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="select-item">
                <div className="select-label">Assigned registrar</div>
                <Select
                  id="sales-select"
                  className="user-select"
                  MenuProps={{
                    getContentAnchorEl: null,
                  }}
                  renderValue={(selected) => {
                    if (!selected.length) return '';
                    return selected.map((item) => `${item.firstName} ${item.lastName}`).join(', ');
                  }}
                  multiple
                  fullWidth
                  value={newRegistrar}
                  onChange={(e) => setRegistrar(e.target.value)}
                >
                  {registrarList.map((item) => (
                    <MenuItem key={item.uid} value={item}>
                      {item.firstName} {item.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-1">
            <div className="select-item-row">
              <div className="select-item">
                <div className="select-label">Assigned engineer</div>
                {getUser(engineer)}
              </div>
              <div className="select-item">
                <div className="select-label">Assigned manufacturer</div>
                {getUser(manufacturer)}
              </div>
            </div>
            <div className="select-item-row">
              <div className="select-item">
                <div className="select-label">Assigned manager</div>
                {getUser(manager)}
              </div>
              <div className="select-item">
                <div className="select-label">Assigned sales rep</div>
                {getUser(salesRep)}
              </div>
            </div>
            <div className="select-item-row">
              <div className="select-item">
                <div className="select-label">Assigned logistics</div>
                {getUser(logistics)}
              </div>
              <div className="select-item">
                <div className="select-label">Assigned registrar</div>
                {getUser(registrar)}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="sub-items-list">
        <div className="sub-item-header-container">
          <div className="column" />
          <div className="sub-item-header">
            <div>{!hospitals || !hospitals.length ? 'NO HOSPITALS' : 'ASSIGNED HOSPITALS'}</div>
            <div className="primary pointer" onClick={() => toggleMode(true)}>
              Add Hospital
            </div>
          </div>
        </div>
        {surgeonHospitals &&
          surgeonHospitals.map((hospital) => (
            <div key={`key-${hospital.id}`} className="sub-item">
              <div className="column">
                <div className="sub-item-circle" />
              </div>
              <div className="d-flex">
                {hospital.name}
                <div className="delete-icon" onClick={() => onDeleteHospital(id, hospital.id)}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          ))}
        {addMode && (
          <NewHospital
            newHospital={newHospital}
            hospitals={filteredHospitals}
            onChange={setHospital}
            onSubmit={handleSubmit}
            onCancel={() => toggleMode(false)}
          />
        )}
      </div>
    </div>
  );
};

SurgeonListItem.propTypes = {
  id: PropTypes.string,
  engineer: PropTypes.array,
  manufacturer: PropTypes.string,
  manager: PropTypes.array,
  salesRep: PropTypes.array,
  logistics: PropTypes.string,
  users: PropTypes.array,
  hospitals: PropTypes.array,
  hospitalList: PropTypes.array,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onAddHospital: PropTypes.func,
  onDeleteHospital: PropTypes.func,
};

export default SurgeonListItem;
