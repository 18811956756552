import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import HardwareList from '../../HardwareList';
import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';

import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const AclStep3 = (props) => {
  const {
    classes,
    onClose,
    activeCase,
    updateCase,
    products,
    preopPlan,
    preopRejected,
    blockFilesRejected,
    caseHardware,
    caseManufacturing,
    fileStatuses,
    fileTypes,
    statuses,
    caseNotifications,
    userId,
  } = props;

  const [preop, setPreop] = useState([]);
  const [hardwareList, setHardwareList] = useState(caseHardware ? caseHardware : []);
  const [manufacturingList, setManufacturingList] = useState(caseManufacturing ? caseManufacturing : []);

  const requiredDate = activeCase?.date ? moment(activeCase?.date).subtract(7, 'd') : moment();
  const [date, setDate] = useState(requiredDate);

  const handleRemovePreop = (name) => {
    setPreop(preop.filter((file) => file.name !== name));
  };

  const handleAddHardware = (product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setHardwareList(list);
    } else {
      setHardwareList([...hardwareList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setHardwareList(hardwareList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setHardwareList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setHardwareList(list);
  };

  const handleChange = (id, product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setHardwareList(list);
  };

  const handleAddManufacturing = (product) => {
    const list = [...manufacturingList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setManufacturingList(list);
    } else {
      setManufacturingList([...manufacturingList, product]);
    }
  };

  const handleRemoveManufacturing = (id) => {
    setManufacturingList(manufacturingList.filter((item) => item.id !== id));
  };

  const handleAmountManufacturingChange = (id, amount) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.amount = amount;
    setManufacturingList(list);
  };

  const handleCategoryManufacturingChange = (id, category) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.category = category;
    setManufacturingList(list);
  };

  const handleManufacturingChange = (id, product) => {
    const list = [...manufacturingList];
    const manufacturing = list.find((item) => item.id === id);

    manufacturing.product = product;
    setManufacturingList(list);
  };

  const handleSubmit = () => {
    const now = moment().format();

    const activity = [
      { ...caseNotifications[3].success, date: now, userId },
      { ...caseNotifications[3].file, date: now, userId },
      ...activeCase.activity,
    ];

    const files = [...activeCase.files];
    const newFiles = [];
    const preopFile = preop[0];

    if (preopFile) {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.preop}_${preopFile.name}`,
        name: preopFile.name,
        type: fileTypes.preop,
        status: fileStatuses.PENDING,
        date: now,
        userId,
      });
      newFiles.push(preopFile);
    }

    const approvedPreop = files.find((file) => file.type === fileTypes.preop && file.status === fileStatuses.APPROVED);

    updateCase(
      {
        ...activeCase,
        step: approvedPreop ? 5 : 4,
        activity,
        hardware: hardwareList.map((item) => ({
          product: item.product,
          amount: item.amount,
          category: item.category,
        })),
        manufacturing: manufacturingList.map((item) => ({
          product: item.product,
          amount: item.amount,
          category: item.category,
        })),
        files,
        completionDates: { ...activeCase.completionDates, step3: now },
        completedBy: { ...activeCase.completedBy, step3: userId },
        status: statuses.inProgress,
        notes: {
          ...activeCase.notes,
        },
        requiredDate: date.format(),
      },
      newFiles
    ).then(() => onClose());
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">3</div>
          </div>
          <div className="modal-item-content">
            <div className="m-b-lg">Attach Pre Op Plan</div>
            {activeCase.access ? (
              <div>
                <div className="m-t-lg m-b-md">
                  <div className="secondary font-size-sm m-b-sm">ATTACH PRE-OP PLAN (.pdf)</div>
                  {preopRejected && (
                    <div className="alert warning m-b-md">
                      <div className="d-flex align-center">
                        <WarningIcon color="primary" />
                        <div>Pre-Op plan has been rejected</div>
                      </div>
                      {activeCase.notes && activeCase.notes.step6 && (
                        <div className="m-t-md">Note: {activeCase.notes.step6}</div>
                      )}
                    </div>
                  )}
                  <Dropzone
                    files={preop}
                    onUpload={setPreop}
                    type="pdf"
                    icon="preop"
                    multiple={false}
                    disabled={blockFilesRejected}
                    onRemove={handleRemovePreop}
                  />
                </div>
                <div className="m-t-lg">
                  <HardwareList
                    classes={classes}
                    hardwareList={hardwareList}
                    products={products}
                    onAdd={handleAddHardware}
                    onRemove={handleRemoveHardware}
                    onAmountChange={handleAmountChange}
                    onCategoryChange={handleCategoryChange}
                    onChange={handleChange}
                    title="1 Stage Hardware (Suggested)"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>
                <div style={{ marginTop: 32, marginBottom: 32 }}>
                  <HardwareList
                    classes={classes}
                    hardwareList={manufacturingList}
                    products={products}
                    onAdd={handleAddManufacturing}
                    onRemove={handleRemoveManufacturing}
                    onAmountChange={handleAmountManufacturingChange}
                    onCategoryChange={handleCategoryManufacturingChange}
                    onChange={handleManufacturingChange}
                    title="2 Stage Hardware (Suggested)"
                    procedureFlow={activeCase.procedureFlow}
                  />
                </div>

                <div className="hardware-list-container">
                  <div className="hardware-list-header">
                    <div className="secondary font-size-sm">REQUIRED DATE</div>
                  </div>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="DD/MM/YYYY"
                      id="date-picker"
                      value={date}
                      onChange={(date) => setDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={3} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button
          text="Submit"
          color="primary"
          onClick={handleSubmit}
          disabled={!activeCase.access || (!preopPlan && !preop.length) || !hardwareList.length}
        />
      </div>
    </div>
  );
};
