import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { MenuItem, Select } from '@material-ui/core';

import { changeEngineerLevel } from '../../../../actions/adminActions';

const EngineerListItem = ({ uid, firstName, lastName, email, onDelete, engineerLevel = 1 }) => {
  const [deleteMode, toggleDeleteMode] = useState(false);
  const [, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onChange = async (value) => {
    try {
      setLoading(true);
      dispatch(changeEngineerLevel(uid, value));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-modal-item list-item-container">
      <div className="d-flex w-100">
        <div className="column">
          <div className="modal-item-circle" />
        </div>
        <div className="modal-item-header-user modal-item-header">
          <div className="modal-item-content d-flex">
            <div>
              {firstName} {lastName}
            </div>
            <div className="user-email">{email}</div>
          </div>
          {deleteMode ? (
            <div className="d-flex">
              <div className="danger pointer m-l-sm" onClick={() => onDelete(uid)}>
                Delete
              </div>
              <div className="secondary pointer m-l-md" onClick={() => toggleDeleteMode(false)}>
                Cancel
              </div>
            </div>
          ) : (
            <IconButton aria-label="delete" color="primary" onClick={() => toggleDeleteMode(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
      <div className="d-flex m-b-md">
        <div className="column" />
        <div className="flex-1">
          <div className="info-row">
            <div className="info-row-field">
              <div className="field-label">ENGINEER LEVEL</div>
              <div className='width-50'>
                <Select
                  className="level-select"
                  fullWidth
                  value={engineerLevel}
                  onChange={(e) => onChange(e.target.value)}
                >
                  <MenuItem key={1} value={1}>
                    Level 1 (Senior)
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    Level 2 (Junior)
                  </MenuItem>
                  <MenuItem key={2} value={3}>
                    Level 3 (Entry-Level)
                  </MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

EngineerListItem.propTypes = {
  uid: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  onDelete: PropTypes.func,
};

export default EngineerListItem;
