import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import DoneIcon from '@material-ui/icons/Done';

import CaseAccordion from '../../CaseAccordion';
import { userRoles } from '../../../../../../constants';

const CmfExtendedCompletedCase = ({ classes, onClose, activeCase, surgeons }) => {
  const { surgeon } = activeCase;

  const caseSurgeon = useMemo(
    () => surgeons.find((user) => user.uid === surgeon.id || user.uid === surgeon.userId),
    [surgeons, surgeon.id, surgeon.userId]
  );

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <DoneIcon color="primary" />
          </div>
          <div className="modal-item-content">
            <div className="font-size-bg">
              Case has been completed by
              <span className={classes.primary}>
                {' '}
                Dr. {caseSurgeon.firstName} {caseSurgeon.lastName}
              </span>
              <span className="secondary"> – {moment(activeCase?.step10Complete).format('DD/MM/YYYY')}</span>
            </div>
          </div>
        </div>
        <CaseAccordion currentStep={12} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;

  return {
    activeCase,
    surgeons: state.users.list.filter((user) => user.role === userRoles.SURGEON.name),
  };
};

export default connect(mapStateToProps, null)(CmfExtendedCompletedCase);
