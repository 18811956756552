import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import Step0StaticAdmin from './Step0StaticAdmin';
import RestrictComponent from '../../../../shared/RestrictComponent';

import { simpleUpdateCase } from '../../../../../actions/casesActions';

import { userRoles } from '../../../../../constants';
import Dropzone from '../../../../shared/dropzone/Dropzone';
import Button from '../../../../shared/Button';
import { fileTypes } from '../../../../../constants/cases';

const {
  GLOBAL_ADMIN,
  COUNTRY_ADMIN,
  DISTRIBUTOR_ADMIN,
  SURGEON,
  ENGINEER,
  SALES_REP,
  LOGISTICS,
  PRACTICE_MANAGER,
  MANUFACTURER,
  RADIOLOGY
} = userRoles;

const Step0Static = ({ activeCase, surgeons, procedure, distributors, userId }) => {
  const { surgeon, hospital, date, procedureSide, patientInfo, distributorId } = activeCase;
  const { firstName, lastName, dateOfBirth, gender } = patientInfo;

  const dispatch = useDispatch();

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const onUpload = (files) => {
    setReports([...reports, ...files.filter((file) => !reports.map((sheet) => sheet.name).includes(file.name))]);
  };

  const onRemove = (name) => {
    setReports(reports.filter((file) => file.name !== name));
  };

  const caseSurgeon = useMemo(
    () => surgeons?.find((user) => user.uid === surgeon?.id || user.uid === surgeon?.userId),
    [surgeons, surgeon]
  );
  const caseDistributor = useMemo(
    () => distributors.find((distributor) => distributor.id === distributorId),
    [distributors, distributorId]
  );

  const handleSubmit = async () => {
    const now = moment().format();
    const files = [...activeCase.files];
    const newFiles = [];

    reports.forEach((file) => {
      files?.unshift({
        id: `${activeCase.id}_${fileTypes.chargedSheet}_${file.name}`,
        name: file.name,
        type: fileTypes.chargedSheet,
        date: now,
        userId,
      });
      newFiles?.push(file);
    });

    setLoading(true);

    await dispatch(simpleUpdateCase({ ...activeCase, files }, newFiles));
    setLoading(false);
  };

  const isPermission = !activeCase.trainingMode
    ? [GLOBAL_ADMIN, COUNTRY_ADMIN]
    : [
        GLOBAL_ADMIN,
        COUNTRY_ADMIN,
        DISTRIBUTOR_ADMIN,
        SURGEON,
        ENGINEER,
        SALES_REP,
        LOGISTICS,
        PRACTICE_MANAGER,
        MANUFACTURER,
        RADIOLOGY
      ];

  return (
    <Fragment>
      {!activeCase.trainingMode && (
        <RestrictComponent excludeRoles={[GLOBAL_ADMIN, COUNTRY_ADMIN]}>
          <div className="case-static-container">
            <Grid container spacing={0}>
              {distributorId && (
                <Grid item xs={12} md={6}>
                  <div className="field-container">
                    <div className="field-title">Distributor</div>
                    <div className="field-value">
                      {caseDistributor && `${caseDistributor.name}`}
                    </div>
                  </div>
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Assigned surgeon</div>
                  <div className="field-value">
                    {caseSurgeon && `Dr ${caseSurgeon.firstName} ${caseSurgeon.lastName}`}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Hospital</div>
                  <div className="field-value">{hospital}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Procedure</div>
                  <div className="field-value">{procedure}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Targeted surgery date</div>
                  <div className="field-value">{date ? moment(date).format('DD/MM/YYYY') : 'TBC'}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Patient name</div>
                  <div className="field-value d-flex">{`${firstName} ${lastName}`}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Gender</div>
                  <div className="field-value">{gender.toUpperCase()}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Date of birth</div>
                  <div className="field-value">{moment(dateOfBirth).format('DD/MM/YYYY')}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="field-container">
                  <div className="field-title">Procedure side</div>
                  <div className="field-value">{procedureSide.toUpperCase()}</div>
                </div>
              </Grid>
            </Grid>

            <RestrictComponent roles={[ENGINEER, SALES_REP, PRACTICE_MANAGER]}>
              <div className='m-t-lg'>
                <div className="modal-item-block m-b-lg">
                  <div className="block-header">Surgeon consultation report</div>
                  <Dropzone
                    files={reports}
                    onUpload={onUpload}
                    icon="scan"
                    onRemove={onRemove}
                  />
                </div>
                <div className="case-modal-actions">
                  <div />
                  <Button
                    text="Upload"
                    loading={loading}
                    disabled={loading}
                    color="primary"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </RestrictComponent>
          </div>
        </RestrictComponent>
      )}
      <RestrictComponent roles={isPermission}>
        <Step0StaticAdmin activeCase={activeCase} />
      </RestrictComponent>
    </Fragment>
  );
};

Step0Static.propTypes = {
  activeCase: PropTypes.object,
  surgeons: PropTypes.array,
  procedure: PropTypes.string,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((item) => item.id === activeCase.procedure);
  const procedureName = procedure ? procedure.name : 'No procedure';

  return {
    activeCase: state.cases.activeCase,
    surgeons: state.users.list.filter((user) => user.role === userRoles.SURGEON.name),
    procedure: procedureName,
    distributors: state.distributors.list,
    userId: state.user.currentUser.uid,
  };
};

export default connect(mapStateToProps)(Step0Static);
