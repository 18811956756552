import React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';

export const LoadableContainer = (options) =>
  Loadable({
    loading: () => <CircularProgress style={{ margin: 16 }} />,
    delay: 200,
    timeout: 10,
    ...options,
  });

export default LoadableContainer;
