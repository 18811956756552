import { LOAD_COUNTRY_LIST, RECEIVE_COUNTRY_LIST } from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COUNTRY_LIST:
      return { ...state, loading: true };
    case RECEIVE_COUNTRY_LIST:
      return { ...state, loading: false, list: action.countries };
    default:
      return state;
  }
};

export default countriesReducer;
