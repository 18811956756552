import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import PlacesAutocomplete from '../../../google-api/PlacesAutocomplete';

const NewHospital = ({ onCancel, onSubmit }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [postCode, setPostCode] = useState('');

  const handleSubmit = () => {
    if (!name) {
      return;
    }

    onSubmit({ name, address, state, postCode });
  };

  return (
    <div>
      <div className="d-flex new-item">
        <div className="column">
          <div className="modal-item-circle light" />
        </div>
        <div className="d-flex flex-1">
          <TextField
            id="new-hospital-name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="p-l-md primary pointer" onClick={handleSubmit}>
            Add
          </div>
          <div className="p-l-md secondary pointer" onClick={onCancel}>
            Cancel
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="column" />
        <div className="flex-1">
          <div className="d-flex m-t-md">
            <PlacesAutocomplete label="Address" onChange={(value) => setAddress(value)} />
          </div>
          <div className="d-flex">
            <div className="flex-1 m-r-md">
              <TextField
                id="new-hospital-state"
                type="text"
                label="State"
                fullWidth
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="flex-1 m-l-md">
              <TextField
                id="new-hospital-post-code"
                type="text"
                label="Post code"
                fullWidth
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewHospital.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NewHospital;
