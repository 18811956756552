import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';

import CaseAccordion from '../../CaseAccordion';
import NoAccess from '../../NoAccess';
import Button from '../../../../../shared/Button';
import Dropzone from '../../../../../shared/dropzone/Dropzone';

import { updateCase } from '../../../../../../actions/casesActions';
import { setNotification } from '../../../../../../actions/notificationActions';

const CmfExtendedStep11 = ({ onClose, activeCase, updateCase, fileTypes, caseNotifications, statuses, userId }) => {
  const [notes, setNotes] = useState('');
  const [images, setImages] = useState([]);

  const handleImagesUpload = (files) => {
    setImages([...images, ...files.filter((file) => !images.map((image) => image.name).includes(file.name))]);
  };

  const handleRemove = (name) => {
    setImages(images.filter((file) => file.name !== name));
  };

  const handleSubmit = () => {
    const now = moment().format();

    const files = [...activeCase.files];
    const newFiles = [];

    images.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.image}_${file.name}`,
        name: file.name,
        type: fileTypes.image,
        date: now,
        userId,
      });
      newFiles.push(file);
    });

    updateCase(
      {
        ...activeCase,
        activity: [{ ...caseNotifications[11].success, date: now, userId }, ...activeCase.activity],
        step: 12,
        files,
        completionDates: { ...activeCase.completionDates, step11: now },
        completedBy: { ...activeCase.completedBy, step11: userId },
        notes: { ...activeCase.notes, step11: notes },
        status: statuses.completed,
      },
      newFiles
    ).then(() => onClose());
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">11</div>
          </div>
          <div className="modal-item-content">
            <div>Post-Op Report</div>
            {activeCase.access ? (
              <Fragment>
                <TextField
                  id="notes"
                  type="text"
                  fullWidth
                  multiline
                  label="Case Notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
                <div className="m-t-lg m-b-md">
                  <div className="secondary font-size-sm m-b-sm">ATTACH IMAGES</div>
                  <Dropzone files={images} onUpload={handleImagesUpload} type="image" onRemove={handleRemove} />
                </div>
              </Fragment>
            ) : (
              <NoAccess />
            )}
          </div>
        </div>
        <CaseAccordion currentStep={11} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button text="Submit" color="primary" onClick={handleSubmit} disabled={!activeCase.access} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, newFiles) => dispatch(updateCase(newCase, 11, newFiles)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CmfExtendedStep11);
