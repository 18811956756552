import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles, List, ListItem, ListItemText } from '@material-ui/core';

import { setSelectedFlow } from '../../../../../actions/casesActions';
import { procedureFlows, selectedProcedureFlows } from '../../../../../constants/cases';

import hto_image from '../../../../../assets/step0/PS_HTO_Image.png';
import dfo_image from '../../../../../assets/step0/PS_DFO_Image.png';
import double_level_image from '../../../../../assets/step0/PS_double_level.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,

    '& .block-header': {
      width: '90%',
      marginTop: 10,
      marginBottom: 25,
    },
  },
}));

const StyledList = withStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))(List);

const StyledListItem = withStyles((theme) => ({
  root: {
    width: '90%',
    borderRadius: 20,
    marginBottom: 30,
    '&:last-child': {
      margin: 0,
    },
  },
}))(ListItem);

const StyledListItemText = withStyles((theme) => ({
  primary: {
    fontSize: '1.8rem',
    fontWeight: 700,
    letterSpacing: 1.5,
  },
  secondary: {
    fontSize: '0.92rem',
  },
}))(ListItemText);

export const Step0Osteotomy = ({ selected, onChange, procedures = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleProcedureFlowClick = (event, flow) => {
    onChange(flow);
    dispatch(setSelectedFlow(flow));
  };

  return (
    <div className={classes.root}>
      <div className="block-header">Select Procedure</div>
      <StyledList component="nav" aria-label="procedures">
        {procedures.some((procedure) =>
          procedure.flow === procedureFlows.HTO ||
          procedure.flow === procedureFlows.HTO_LATERAL
        ) && (
          <StyledListItem
            style={{ border: '2px solid #ee9321' }}
            button
            selected={selected === selectedProcedureFlows.HTO}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.HTO)}
          >
            <StyledListItemText primary="HTO" secondary="High Tibial Osteotomy" />
            <img src={hto_image} alt="HTO" width="100" height="100" />
          </StyledListItem>
        )}

        {procedures.some(
          (procedure) =>
            procedure.flow === procedureFlows.DFO_Lateral ||
            procedure.flow === procedureFlows.DFO_Medial
        ) && (
          <StyledListItem
            style={{ border: '2px solid #ee9321' }}
            button
            selected={selected === selectedProcedureFlows.DFO}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.DFO)}
          >
            <StyledListItemText primary="DFO" secondary="Distal Femoral Osteotomy" />
            <img src={dfo_image} alt="DFO" width="100" height="100" />
          </StyledListItem>
        )}

        {procedures.some((procedure) => procedure.flow === procedureFlows.DOUBLE_LEVEL_OSTEOTOMY) && (
          <StyledListItem
            style={{ border: '2px solid #ee9321' }}
            button
            selected={selected === selectedProcedureFlows.DOUBLE_LEVEL_OSTEOTOMY}
            onClick={(event) => handleProcedureFlowClick(event, selectedProcedureFlows.DOUBLE_LEVEL_OSTEOTOMY)}
          >
            <StyledListItemText primary="DOUBLE LEVEL OSTEOTOMY" secondary="Combination HTO & DFO Procedure" />
            <img src={double_level_image} alt="DOUBLE LEVEL OSTEOTOMY" width="100" height="100" />
          </StyledListItem>
        )}
      </StyledList>
    </div>
  );
};
