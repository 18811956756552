import {
  ADD_DISTRIBUTOR,
  DELETE_DISTRIBUTOR,
  LOAD_DISTRIBUTORS,
  SET_DISTRIBUTORS,
  UPDATE_DISTRIBUTOR_PROCEDURE,
  UPDATE_DISTRIBUTOR,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  loading: false,
};

const distributorsReducer = (state = initialState, action) => {
  const distributors = state.list.slice();
  const distributor = distributors.find((item) => item.id === action.id);

  switch (action.type) {
    case LOAD_DISTRIBUTORS:
      return { ...state, loading: true };
    case SET_DISTRIBUTORS:
      return { ...state, loading: false, list: action.distributors };
    case ADD_DISTRIBUTOR:
      const newDistributor = {
        id: action.id,
        country: action.country,
        name: action.name,
        address: action.address,
        email: action.email,
        phone: action.phone,
      };

      return { ...state, list: [...distributors, newDistributor], loading: false };
    case UPDATE_DISTRIBUTOR:
      const updatedDistributor = {
        ...distributor,
        ...action.distributor
      };
      const index = distributors.findIndex((item) => item.id === action.id);
      distributors[index] = updatedDistributor;

      return { ...state, list: distributors, loading: false };
    case DELETE_DISTRIBUTOR:
      return {
        ...state,
        list: distributors.filter((item) => item.id !== action.id),
        loading: false,
      };
    case UPDATE_DISTRIBUTOR_PROCEDURE:
      distributor.assignedProcedures = action.assignedProcedures;

      return { ...state, list: distributors, loading: false };
    default:
      return state;
  }
};

export default distributorsReducer;
