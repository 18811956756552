import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import ResumeIcon from '@material-ui/icons/PlayArrow';
import SuccessIcon from '@material-ui/icons/Check';

import xRay from '../../../assets/scans_icon.svg';
import preopIcon from '../../../assets/preop_icon.svg';
import blockIcon from '../../../assets/block_icon.svg';
import imageIcon from '../../../assets/image_icon.svg';
import aoaIcon from '../../../assets/aoa_icon.svg';
import usageIcon from '../../../assets/usage_icon.svg';
import graftIcon from '../../../assets/graft_icon.svg';

import { withFirebase } from '../../../firebase';

class FileItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'running',
      progress: 0,
      showPassword: false,
      checked: false,
      loading: false,
      error: false,
      isOpen: false,
      resetPasswordError: null,
      resetPasswordSuccess: false,
    };
  }

  uploadTask = null;

  componentDidMount() {
    const { withProgress } = this.props;

    if (withProgress) {
      const { firebase, file, path, suffix, onUploadComplete } = this.props;
      const storageRef = firebase.storage.ref();
      let child = file.name;

      if (path && !suffix) {
        child = `${path}_${file.name}`;
      }
      if (path && suffix) {
        child = `${path}_${suffix}_${file.name}`;
      }

      this.uploadTask = storageRef.child(child).put(file);
      this.uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          this.setState({ progress });

          switch (snapshot.state) {
            case 'paused':
              this.setState({ status: 'paused' });
              break;
            case 'running':
              this.setState({ status: 'running' });
              break;
            default:
              this.setState({ status: 'running' });
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.setState({ status: 'success' });
          this.uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadUrl) => onUploadComplete(file.name, downloadUrl));
        }
      );
    }
  }

  // componentWillUnmount() {
  //   const { status } = this.state;
  //
  //   if (status === "success") {
  //     this.uploadTask.snapshot.ref.delete();
  //   } else {
  //     this.uploadTask.cancel();
  //   }
  // }

  getIcon = (icon) => {
    switch (icon) {
      case 'x-ray':
        return xRay;
      case 'scan':
        return preopIcon;
      case 'preop':
        return preopIcon;
      case 'block':
        return blockIcon;
      case 'image':
        return imageIcon;
      case 'aoa':
        return aoaIcon;
      case 'usage':
        return usageIcon;
      case 'graft':
        return graftIcon;
      default:
        return preopIcon;
    }
  };

  onPause = () => {
    this.uploadTask.pause();
  };

  onResume = () => {
    this.uploadTask.resume();
  };

  onCancel = () => {
    const { withProgress, onRemove, file } = this.props;

    if (withProgress) {
      const { status } = this.state;

      if (status === 'success') {
        this.uploadTask.snapshot.ref.delete();
      } else {
        this.uploadTask.cancel();
      }
    }

    onRemove(file.name);
  };

  renderButton = () => {
    const { status } = this.state;

    switch (status) {
      case 'running':
        return (
          <IconButton aria-label="pause" color="secondary" onClick={this.onPause} size="small">
            <PauseIcon fontSize="small" />
          </IconButton>
        );
      case 'paused':
        return (
          <IconButton aria-label="resume" color="secondary" onClick={this.onResume} size="small">
            <ResumeIcon fontSize="small" />
          </IconButton>
        );
      case 'success':
        return (
          <IconButton aria-label="success" color="primary" size="small">
            <SuccessIcon fontSize="small" />
          </IconButton>
        );
      default:
        return (
          <IconButton aria-label="resume" color="secondary" onClick={this.onResume} size="small">
            <ResumeIcon fontSize="small" />
          </IconButton>
        );
    }
  };

  render() {
    const { file, withProgress, icon } = this.props;
    const { progress } = this.state;
    const { name } = file;

    return (
      <div className="file-item">
        <div key={name} className="d-flex space-between m-t-md">
          <div className="flex-1">
            <div className="d-flex space-between align-end">
              <div className="d-flex flex-1">
                <img src={this.getIcon(icon)} alt="file-icon" height={20} className="m-r-md" />
                {name}
              </div>
              {withProgress && (
                <div>
                  <div className="d-flex flex-end w-100">{Math.round(progress)}%</div>
                </div>
              )}
            </div>
            {withProgress && (
              <div className="d-flex align-center flex-center flex-1 m-t-sm">
                <LinearProgress variant="determinate" value={progress} style={{ width: '100%' }} />
              </div>
            )}
          </div>
          <div className="d-flex m-l-md">
            {withProgress && this.renderButton()}
            <IconButton aria-label="delete" color="secondary" onClick={this.onCancel} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

FileItem.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string,
  suffix: PropTypes.string,
  onRemove: PropTypes.func,
  onUploadComplete: PropTypes.func,
  withProgress: PropTypes.bool,
  icon: PropTypes.oneOf([
    'x-ray',
    'scan',
    'preop',
    'block',
    'image',
    'aoa',
    'usage',
    'graft',
    'coc',
  ]),
};

export default withFirebase(FileItem);
