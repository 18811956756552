import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import Alert from '../../components/shared/Alert';
import ForgotPasswordModal from '../../components/modals/ForgotPassword';

import { withFirebase } from '../../firebase';

import { setCurrentUser } from '../../actions/userActions';

import './login-page.scss';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      showPassword: false,
      checked: false,
      loading: false,
      error: false,
      isOpen: false,
      resetPasswordError: null,
      resetPasswordSuccess: false,
      disabledAccountError: false,
    };
  }

  handleSubmit = (e) => {
    const { firebase, setUser, history } = this.props;
    const { email, password, checked } = this.state;

    e.preventDefault();
    e.stopPropagation();

    if (!email.length || !password.length) {
      return;
    }

    this.setState({ error: false, loading: true });
    firebase
      .doSignInWithEmailAndPassword(email, password, checked)
      .then((user) => {
        setUser(user);
        history.push('/dashboard');
      })
      .catch((err) => {
        if (err && err.code === 'auth/user-disabled') {
          this.setState({ disabledAccountError: true })
        } else {
          this.setState({ error: true });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  resetPassword = (email) => {
    const { firebase } = this.props;

    this.setState({ isOpen: false });
    firebase
      .doPasswordReset(email)
      .then(() => this.setState({ resetPasswordSuccess: true }))
      .catch((err) => this.setState({ resetPasswordError: err.message }));
  };

  render() {
    const {
      email,
      password,
      showPassword,
      loading,
      error,
      checked,
      isOpen,
      resetPasswordError,
      resetPasswordSuccess,
      disabledAccountError,
    } = this.state;

    return (
      <div className="login-page">
        <AppHeader />
        <div className="login-form-container">
          <h3>Sign In</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="login-form">
              <div className="form-group-container m-b-lg">
                <TextField
                  id="email"
                  type="email"
                  label="Email address"
                  fullWidth
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value, error: false })}
                />
              </div>
              <div className="form-group-container m-t-sm m-b-lg">
                <FormControl fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showPassword: !showPassword })}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="actions">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={() => this.setState({ checked: !checked })}
                  />
                }
                label="Keep me signed in"
              />
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                SIGN IN
                {loading && <CircularProgress size={24} className="button-processing-indicator" />}
              </Button>
            </div>
            <div className="primary pointer m-t-lg" onClick={() => this.setState({ isOpen: true })}>
              Forgot password?
            </div>
          </form>
          {/*<LinkContainer to={routes.SIGN_UP}>*/}
          {/*  <div className="primary pointer m-t-lg text-center">*/}
          {/*    Create new account*/}
          {/*  </div>*/}
          {/*</LinkContainer>*/}
        </div>
        <Alert
          variant="error"
          message="Invalid email or password"
          open={error}
          onClose={() => this.setState({ error: false })}
        />
        <Alert
          variant="error"
          message="Your account has been disabled by an administrator"
          open={disabledAccountError}
          onClose={() => this.setState({ disabledAccountError: false })}
        />
        <Alert
          variant="error"
          message={resetPasswordError}
          open={!!resetPasswordError}
          onClose={() => this.setState({ resetPasswordError: null })}
        />
        <Alert
          variant="success"
          message="Please follow instructions that we sent you"
          open={resetPasswordSuccess}
          onClose={() => this.setState({ resetPasswordSuccess: false })}
        />
        <ForgotPasswordModal
          open={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          onSubmit={this.resetPassword}
        />
        <AppFooter link="sign-up" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setCurrentUser(user)),
  };
};

LoginPage.propTypes = {
  setUser: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(withFirebase(withRouter(LoginPage)));
