import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { Step0Procedure } from './Step0Procedure';
import { Step0Osteotomy } from './Step0Osteotomy';
import { Step0CMF } from './Step0CMF';
import { Step0CaseInfo } from './Step0CaseInfo';

import Button from '../../../../shared/Button';
import Alert from '../../../../shared/Alert';

import { createCase } from '../../../../../actions/casesActions';
import { setNotification } from '../../../../../actions/notificationActions';

import { userRoles, caseConstants } from '../../../../../constants';

import { selectedProcedureFlows, procedureFlows, fileTypes, defaultLeadDays } from '../../../../../constants/cases';

const { GLOBAL_ADMIN, COUNTRY_ADMIN } = userRoles;

const getProcedureFlow = (flow) => {
  switch (flow) {
    case procedureFlows.HTO:
      return 'H';
    case procedureFlows.HTO_LATERAL:
      return 'HL';
    case procedureFlows.DFO_Medial:
      return 'DM';
    case procedureFlows.DFO_Lateral:
      return 'DL';
    case procedureFlows.ACL:
      return 'A';
    case procedureFlows.CMF:
      return 'C';
    case procedureFlows.CMF_EXTENDED:
      return 'CE';
    case procedureFlows.CUSTOM:
      return 'CM';
    case procedureFlows.DOUBLE_LEVEL_OSTEOTOMY:
      return 'DLO';
    case procedureFlows.ONCOL:
      return 'ON';
    case procedureFlows.CUSTOM_EXTENDED:
      return 'CE';
    default:
      return '';
  }
};

const Step0 = (props) => {
  const {
    uid,
    userRole,
    createCase,
    surgeons,
    hospitals,
    procedures,
    onClose,
    surgeonList,
    currentSurgeon,
    distributors,
    trainingMode,
    currentDistributor,
    selectedFlow,
  } = props;

  const isAdmin = userRole === GLOBAL_ADMIN.name || userRole === COUNTRY_ADMIN.name;

  const [view, setView] = useState(0);
  const [selectedProcedureFlow, setSelectedProcedureFlow] = useState(null);
  const [surgeon, setSurgeon] = useState(currentSurgeon || null);
  const [distributor, setDistributor] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [procedure, setProcedure] = useState(null);
  const [date, setDate] = useState(isAdmin ? moment() : moment().add(defaultLeadDays, 'days'));
  const [dateTBC, setDateTBC] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('male');
  const [psi, setPsi] = useState('yes');
  const [dateOfBirth, setDateOfBirth] = useState(moment('Jan 1, 1990'));
  const [procedureSide, setProcedureSide] = useState('left');
  const [caseNote, setCaseNote] = useState('');

  const [reports, setReports] = useState([]);

  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);

  const leadDays = useMemo(() => (
    procedure && procedure?.leadDays > 0 ? procedure.leadDays : defaultLeadDays
  ), [procedure]);
  const startDate = useMemo(() => (
    isAdmin ? moment() : moment().add(leadDays, 'days')
  ), [leadDays, isAdmin]);

  useEffect(() => {
    if (startDate) {
      setDate(startDate);
    }
  }, [startDate]);

  const documentList = procedure && procedure.documents ? procedure.documents : [];

  const checkMissingFields = () => {
    return (
      !distributor ||
      !surgeon ||
      !hospital ||
      !procedure ||
      !firstName ||
      !lastName ||
      !gender ||
      !dateOfBirth ||
      !procedureSide
    );
  };

  const generateId = (flow) => {
    const lastNameCode = lastName.substr(0, 3);
    const firstNameCode = firstName.substr(0, 1);
    const surgeonCode = `${surgeon.firstName[0]}${surgeon.lastName[0]}`;
    const dateCode = dateOfBirth.format('DDMMYY');
    const procedureFlow = getProcedureFlow(flow);
    const procedureCode = procedureSide[0];

    return `${lastNameCode}${firstNameCode}${surgeonCode}${dateCode}${procedureFlow}${procedureCode}`.toUpperCase();
  };

  useEffect(() => {
    const startDate = isAdmin || [selectedProcedureFlows.ACL, selectedProcedureFlows.CMF, selectedProcedureFlows.CMF_EXTENDED, selectedProcedureFlows.ONCOL].includes(selectedFlow) ? moment() : moment().add(leadDays, 'days');
    setDate(startDate);
  }, [leadDays, selectedFlow]);

  const filteredDistributors = useMemo(() => {
    return distributors?.filter((distributor) =>
      !!distributor?.assignedProcedures?.find((procedure) => {
        const _procedure = procedures.find((p) => p.id === procedure.id);
        return _procedure?.flow === selectedFlow || _procedure?.flow?.includes(selectedFlow);
      })
    );
  }, [distributors, selectedFlow, procedures]);

  useEffect(() => {
    if (currentSurgeon && filteredDistributors?.length === 1) {
      setDistributor(filteredDistributors[0]);
    }
  }, [filteredDistributors]);

  const onUpload = (files) => {
    setReports([...reports, ...files.filter((file) => !reports.map((sheet) => sheet.name).includes(file.name))]);
  };

  const onRemove = (name) => {
    setReports(reports.filter((file) => file.name !== name));
  };

  const handleSubmit = () => {
    if (checkMissingFields()) {
      setError(true);

      return;
    }

    // const minDate = selectedFlow !== selectedProcedureFlows.ACL || selectedFlow !== selectedProcedureFlows.CMF ? moment().add(27, 'days') : null;
    const minDate = moment().add(leadDays - 1, 'days');

    if ((!isAdmin && date.isBefore(minDate) && psi === 'yes' && !dateTBC)) {
      return;
    }

    const now = moment().format();
    const formattedId = generateId(procedure.flow);
    const suffix = uuidv4()?.substr(0, 4)?.toUpperCase();
    const caseId = `${formattedId}_${suffix}`;
    const newActivity = { ...caseConstants.caseNotifications[0].success, date: now, userId: uid };

    const _surgeon = surgeonList.find((s) => s.userId === surgeon.uid && s.distributor === distributor.id);

    if (!_surgeon) {
      return;
    }

    const skipPSI = psi === 'no';

    const files = [];
    const newFiles = [];

    reports.forEach((file) => {
      files?.unshift({
        id: `${caseId}_${fileTypes.report}_${file.name}`,
        name: file.name,
        type: fileTypes.report,
        date: now,
        userId: uid,
      });
      newFiles?.push(file);
    });

    const stepData = {
      surgeon: _surgeon.id,
      distributorId: distributor.id,
      hospital: hospital.name,
      hospitalUniq: { id: hospital.id, name: hospital.name },
      procedure: procedure.id,
      date: date && !dateTBC ? date.format() : null,
      procedureSide,
      procedureFlow: procedure.flow || '',
      patientInfo: {
        firstName,
        lastName,
        gender,
        dateOfBirth: dateOfBirth.format('ll'),
      },
      completionDates: { step0: now },
      completedBy: { step0: uid },
      activity: [newActivity],
      status: caseConstants.statuses.inProgress,
      trainingMode,
      skipPSI,
      files,
      caseNote,
      formattedId,
    };

    createCase(uid, caseId, stepData, newFiles).then(() => onClose(true, caseId));
  };

  const renderView = (view) => {
    switch (view) {
      case 0:
        return (
          <Step0Procedure
            selected={selectedProcedureFlow}
            onChange={setSelectedProcedureFlow}
            procedures={procedures}
          />
        );
      case 1:
        return (
          <Step0Osteotomy
            procedures={procedures}
            selected={selectedProcedureFlow}
            onChange={setSelectedProcedureFlow}
          />
        );
      case 2:
        return (
          <Step0CaseInfo
            distributor={distributor}
            distributors={filteredDistributors}
            setDistributor={setDistributor}
            selectedFlow={selectedFlow}
            currentSurgeon={currentSurgeon}
            surgeons={surgeons}
            surgeon={surgeon}
            setSurgeon={setSurgeon}
            surgeonList={surgeonList}
            procedures={procedures}
            procedure={procedure}
            setProcedure={setProcedure}
            hospitals={hospitals}
            hospital={hospital}
            setHospital={setHospital}
            documentList={documentList}
            date={date}
            setDate={setDate}
            isAdmin={isAdmin}
            psi={psi}
            setPsi={setPsi}
            startDate={startDate}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            gender={gender}
            setGender={setGender}
            dateOfBirth={dateOfBirth}
            setDateOfBirth={setDateOfBirth}
            procedureSide={procedureSide}
            setProcedureSide={setProcedureSide}
            checked={checked}
            setChecked={setChecked}
            reports={reports}
            caseNote={caseNote}
            setCaseNote={setCaseNote}
            onUpload={onUpload}
            onRemove={onRemove}
            dateTBC={dateTBC}
            setDateTBC={setDateTBC}
            leadDays={leadDays}
          />
        );
      default:
        return (
          <Step0Procedure
            procedures={procedures}
            selected={selectedProcedureFlow}
            onChange={setSelectedProcedureFlow}
          />
        );
    }
  };

  const handleContinue = () => {
    if (selectedFlow === selectedProcedureFlows.Osteotomy) {
      setView(view + 1);
      setSelectedProcedureFlow(null);

      // if (selectedFlow === selectedProcedureFlows.Osteotomy) {
      //   setSelectedProcedureFlow(null);
      // }
      // if (selectedFlow === selectedProcedureFlows.CMF_GENERAL) {
      //   setSelectedProcedureFlow(selectedProcedureFlows.CMF);
      // }
    } else {
      setView(2);
      setSelectedProcedureFlow(null);
    }
  };

  const handleBack = () => {
    if ([selectedProcedureFlows.HTO, selectedProcedureFlows.DFO].includes(selectedFlow)) {
      setView(view - 1);
    } else {
      setView(0);
    }

    setSurgeon(currentSurgeon || null);
    setDistributor(currentDistributor || null);
    setSelectedProcedureFlow(null);
    setHospital(null);
    setProcedure(null);
    setFirstName('');
    setLastName('');
  };

  const isDisabled = () => {
    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(selectedFlow)) {
      return (!!documentList.length && !checked) || (!caseNote && !reports.length);
    } else {
      return !!documentList.length && !checked;
    }
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">{renderView(view)}</div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>

        <div className="step-0-buttons">
          {view !== 0 && <Button text="Back" type="secondary" color="primary" onClick={handleBack} />}

          {view === 2 ? (
            <Button
              text="Add Case"
              type="submit"
              color="primary"
              onClick={handleSubmit}
              disabled={isDisabled()}
            />
          ) : (
            <Button text="Continue" color="primary" disabled={!selectedProcedureFlow} onClick={handleContinue} />
          )}
        </div>
      </div>
      <Alert variant="warning" message="All fields are required" open={error} onClose={() => setError(false)} />
    </div>
  );
};

Step0.propTypes = {
  onClose: PropTypes.func,
  uid: PropTypes.string,
  userRole: PropTypes.string,
  surgeons: PropTypes.array,
  hospitals: PropTypes.array,
  procedures: PropTypes.array,
  createCase: PropTypes.func,
};

const mapStateToProps = (state) => {
  const uid = state.user.currentUser.uid;
  const userRole = state.user.currentUser.role;
  const administrationCountry = state.user.currentUser.administrationCountry;
  const hospitals = state.hospitals.list;
  const surgeonList = state.surgeons.list?.filter((s) => !s.disabled);
  const distributors = state.distributors.list;
  const procedures = state.procedures.list;

  let surgeons = state.users.list.filter((user) => user.role === userRoles.SURGEON.name);
  const currentSurgeon = surgeonList.find((item) => item.userId === uid);
  const currentSurgeons = surgeonList.filter((item) => item.userId === uid);
  const surgeon = userRole === userRoles.SURGEON.name && surgeons.find((user) => user.uid === uid);

  const currentDistributor = currentSurgeon
    ? distributors.find((distributor) => distributor.id === currentSurgeon.distributor)
    : null;

  let distributorsList =
    surgeon && currentSurgeon
      ? distributors.filter((item) => {
        return surgeonList.filter((s) => s.userId === uid).some((s) => s.distributor === item.id);
      })
      : distributors;

  let hospitalList =
    surgeon && currentSurgeon
      ? hospitals.filter((item) => {
          return surgeonList.filter((s) => s.userId === uid).some((i) => i.hospitals.includes(item.id));
        })
      : hospitals;

  if (userRole === userRoles.SALES_REP.name) {
    const filteredSurgeons = surgeonList.filter((item) => item.salesRep.includes(uid));
    surgeons = state.users.list.filter((user) => filteredSurgeons?.map((item) => item.userId)?.includes(user.uid));
    distributorsList = distributorsList?.filter((distributor) => !!filteredSurgeons.find((surgeon) => surgeon.distributor === distributor.id))
  }

  if (userRole === userRoles.PRACTICE_MANAGER.name) {
    const filteredSurgeons = surgeonList.filter((item) => item.manager.includes(uid));
    surgeons = state.users.list.filter((user) => filteredSurgeons?.map((item) => item.userId)?.includes(user.uid));
    distributorsList = distributorsList?.filter((distributor) => !!filteredSurgeons.find((surgeon) => surgeon.distributor === distributor.id))
  }

  if (userRole === userRoles.REGISTRAR.name) {
    const filteredSurgeons = surgeonList.filter((item) => item?.registrar?.includes(uid));
    surgeons = state.users.list.filter((user) => filteredSurgeons?.map((item) => item.userId)?.includes(user.uid));
    distributorsList = distributorsList?.filter((distributor) => !!filteredSurgeons.find((surgeon) => surgeon.distributor === distributor.id))
  }

  if (userRole === userRoles.ENGINEER.name) {
    const filteredSurgeons = surgeonList.filter((item) => item?.engineer?.includes(uid));
    surgeons = state.users.list.filter((user) => filteredSurgeons?.map((item) => item.userId)?.includes(user.uid));
    distributorsList = distributorsList?.filter((distributor) => !!filteredSurgeons.find((surgeon) => surgeon.distributor === distributor.id))
  }

  if (userRole === userRoles.COUNTRY_ADMIN.name) {
    distributorsList = distributorsList?.filter((distributor) => distributor.country === administrationCountry);
    hospitalList = hospitalList?.filter((hospital) => hospital.country === administrationCountry);
  }

  const proceduresList =
    (surgeon && currentSurgeon) || [userRoles.SALES_REP.name, userRoles.PRACTICE_MANAGER.name, userRoles.REGISTRAR.name, userRoles.ENGINEER.name]?.includes(userRole)
      ? procedures.filter((item) => {
          return distributorsList.some((d) => d.assignedProcedures.map((p) => p.id).includes(item.id));
        })
      : procedures;

  return {
    uid,
    userRole,
    surgeons,
    distributors: distributorsList,
    currentDistributor,
    hospitals: hospitalList,
    procedures: proceduresList,
    surgeonList: state.surgeons.list?.filter((s) => !s.disabled),
    currentSurgeon: surgeon,
    currentSurgeons,
    trainingMode: state.sidebar.trainingMode,
    documents: state.documents.list,
    selectedFlow: state.cases.selectedFlow,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCase: (uid, caseId, stepData, files) => dispatch(createCase(uid, caseId, stepData, files)),
    setNotification: (notification) => dispatch(setNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step0);
