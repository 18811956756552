import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import AppPage from './pages/app';
import LoginPage from './pages/login';
import SignUpPage from './pages/sign-up';

import LoadScreen from './components/load-screen';

import { setCurrentUser } from './actions/userActions';

import { withFirebase } from './firebase';

import { routes } from './constants';

class App extends React.Component {
  componentDidMount() {
    const { setUser, firebase } = this.props;

    firebase
      .doGetCurrentUser()
      .then((res) => setUser(res))
      .catch(() => setUser(null));
  }

  render() {
    const { currentUser, isLoaded } = this.props;

    return (
      <div className="app">
        <Fragment>
          <Switch>
            {isLoaded && !currentUser && <Route path={routes.SIGN_UP} component={SignUpPage} />}
            {isLoaded &&
              (currentUser ? <Route component={AppPage} /> : <Route component={LoginPage} />)}
          </Switch>
          {!isLoaded && <LoadScreen />}
        </Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    isLoaded: state.user.isLoaded,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setCurrentUser(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(App));
