import React, { memo } from 'react';

import logo from '../../assets/ps_icon_logo_black.svg';

import './app-header.scss';

const AppHeader = () => {
  return (
    <div className="app-header">
      <img src={logo} alt="app-header-logo" />
    </div>
  );
};

export default memo(AppHeader);
