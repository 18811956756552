import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import UserListItem from './UserListItem';

const UserList = ({ users, onDelete }) => {
  const list = orderBy(users, 'lastName');
  return (
    <div>
      {list.map((admin) => (
        <UserListItem key={admin.uid} {...admin} onDelete={onDelete} />
      ))}
    </div>
  );
};

UserList.propTypes = {
  users: PropTypes.array,
  onDelete: PropTypes.func,
};

export default UserList;
