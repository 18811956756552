export const VARUS_DATASET = {
  HKA: 175,
  WBL: 25,
  'Pre-Op_HKA': 5,
  TW: 85,
  '%TW': 50,
  JL_A: 441.3,
  TW_JL_A: 89.5,
  TW_Osteotomy: 73.6,
  JL_CoFH: 493,
  TW_JL_CoFH: 85.1,
  LPFA: 90,
  mLDFA: 90.4,
  MPTA: 85.1,
  LDTA: 90,
  PPFA: 90,
  ANSA: 170,
  PDFA: 83,
  PPTA: 82,
  ADTA: 80,
};

export const VALGUS_DATASET = {
  HKA: 176,
  WBL: 40,
  'Pre-Op_HKA': 4,
  TW: 71.2,
  '%TW': 50,
  JL_A: 384,
  TW_JL_A: 84.6,
  TW_Osteotomy: 65,
  JL_CoFH: 458,
  TW_JL_CoFH: 81.6,
  LPFA: 150,
  mLDFA: 86,
  MPTA: 90,
  LDTA: 90,
  PPFA: 90,
  ANSA: 170,
  PDFA: 83,
  PPTA: 82,
  ADTA: 80,
};
