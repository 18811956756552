const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const get = (url) => fetch(url).then((res) => res.json());

export const post = (url, data) => {
  return fetch(url, { method: 'POST', headers, body: JSON.stringify(data) }).then((res) => res.json());
};

export const remove = (url) => fetch(url, { method: 'DELETE' }).then((res) => res.json());

export const put = (url, data) => {
  return fetch(url, { method: 'PUT', headers, body: JSON.stringify(data) }).then((res) => res.json());
};

export const getFile = (url, data, fileName = 'filename', type = 'pdf') => fetch(url, {
  method: 'POST',
  headers,
  body: JSON.stringify(data)
})
  .catch((error) => console.log(error))
  .then((res) => res.blob())
  .then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.${type}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
