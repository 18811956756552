import React from 'react';

import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.danger,
    textAlign: 'center',
    alignSelf: 'center',
  },
}));

export const NoProcedureFlow = ({ procedureName }) => {
  const classes = useStyles();
  return (
    <Grid className="flex-center height-100" container>
      <Grid className={classes.root} item xs={10}>
        Please, select procedure flow for the <strong>{procedureName}</strong> in the Admin panel
      </Grid>
    </Grid>
  );
};
