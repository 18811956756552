import React, { memo } from 'react';
import PropTypes from 'prop-types';

import noAccessIcon from '../../../../assets/no_access_icon.svg';

const NoAccess = ({ text, small }) => (
  <div className={`cases-no-access ${small ? 'small' : ''}`}>
    {text ? (
      <div>{text}</div>
    ) : (
      <img src={noAccessIcon} alt="no-access" height={small ? 30 : 100} />
    )}
  </div>
);

NoAccess.propTypes = {
  text: PropTypes.string,
  small: PropTypes.bool,
};

export default memo(NoAccess);
