import user from './user';
import users from './users';
import sidebar from './sidebar';
import countries from './countries';
import procedures from './procedures';
import distributors from './distributors';
import hospitals from './hospitals';
import documents from './documents';
import manufacturers from './manufacturers';
import surgeons from './surgeons';
import cases from './cases';
import notification from './notification';
import activity from './activity';
import products from './products';
import radiology from './radiology';
import settings from './settings';

const reducers = {
  user,
  users,
  sidebar,
  countries,
  procedures,
  distributors,
  hospitals,
  manufacturers,
  surgeons,
  cases,
  notification,
  activity,
  documents,
  products,
  radiology,
  settings,
};

export default reducers;
