import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import blockIcon from '../../../../../../assets/block_icon.svg';

import { setActiveTab } from '../../../../../../actions/casesActions';

const OsteotomyStep11Static = ({ files, goToFiles, notes }) => {
  return files.length > 0 ? (
    <div className="case-static-container">
      {files.map((file) => (
        <div key={file.id} className="d-flex m-b-md pointer" onClick={goToFiles}>
          <img src={blockIcon} alt="file-icon" />
          <div>{file.name}</div>
        </div>
      ))}
      {notes && (
        <div className="m-t-md p-t-sm border-top">
          <div className="secondary">Notes:</div>
          <div>{notes}</div>
        </div>
      )}
    </div>
  ) : (
    <div className="secondary">No files attached</div>
  );
};

OsteotomyStep11Static.propTypes = {
  files: PropTypes.array,
  goToFiles: PropTypes.func,
  notes: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    files: state.cases.activeCase.files.filter((file) => file.type === 'image'),
    notes: state.cases.activeCase.notes && state.cases.activeCase.notes.step11,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep11Static);
