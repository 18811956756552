import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Loadable from '../../components/loadable';
import Sidebar from '../../components/sidebar';
import Alert from '../../components/shared/Alert';
import LoadScreen from '../../components/load-screen';

import { toggleSidebar } from '../../actions/sidebarActions';
import { closeNotification } from '../../actions/notificationActions';
import { fetchCases } from '../../actions/casesActions';
import { fetchActivity } from '../../actions/activityActions';
import { fetchUsers, fetchDistributors, fetchCountryList, fetchSettings } from '../../actions/adminActions';
import { savePushNotificationsToken } from '../../actions/userActions';

import { withFirebase, collections } from '../../firebase';

import { routes, userRoles } from '../../constants';

const DashboardPage = Loadable({
  loader: () => import('../dashboard'),
});

const CasesPage = Loadable({
  loader: () => import('../cases'),
});

const ActivityPage = Loadable({
  loader: () => import('../activity'),
});

const CalendarPage = Loadable({
  loader: () => import('../calendar'),
});

const UsersPage = Loadable({
  loader: () => import('../users'),
});

const drawerWidth = 260;
const drawerWidthCollapsed = 106;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerCollapsed: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthCollapsed,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperCollapsed: {
    width: drawerWidthCollapsed,
  },
  content: {
    flexGrow: 1,
    // [theme.breakpoints.up("sm")]: {
    //   padding: theme.spacing(3)
    // },
    // [theme.breakpoints.down("xs")]: {
    //   padding: theme.spacing(3)
    // }
  },
}));

const AppPage = (props) => {
  const {
    container,
    mobileOpen,
    toggleSidebar,
    notification,
    closeNotification,
    fetchCases,
    fetchActivity,
    firebase,
    fetchUsers,
    userRole,
    users,
    fetchDistributors,
    fetchCountryList,
    fetchSettings,
    saveToken,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const [notificationMessage, setNotificationMessage] = useState('');

  const fetchData = async () => {
    if (!users || !users?.length) {
      await fetchUsers();
    }
    await fetchDistributors();
    if (userRole !== userRoles.RADIOLOGY.name) {
      await fetchCases(true);
    }
    await fetchActivity(true);
    await fetchCountryList();
  };

  useEffect(() => {
    const unsubscribe = firebase.db
      .collection(collections.CASES_COLLECTION)
      .onSnapshot(fetchData, (e) => console.log(e));
    const promises = [fetchActivity(), fetchUsers(), fetchSettings()];

    if (userRole !== userRoles.RADIOLOGY.name) {
      promises.push(fetchCases());
    }

    setLoading(true);
    Promise.all(promises).then(() => setLoading(false));

    requestPermission();

    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // console.log(navigator.userAgent);
    // if (!isSafari) {
    //   requestPermission();
    // }

    firebase.onMessageListener().then((payload) => {
      console.log(payload);
      setNotificationMessage(payload?.data?.body);
    });

    return () => {
      unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const requestPermission = () => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted');
          firebase.getNotificationToken().then(saveToken)
        } else {
          console.warn('Notification permission denied');
        }
      });
    } else {
      if ('safari' in window && 'pushNotification' in window.safari) {
        // Safari on iOS
        window.safari.pushNotification.requestPermission().then((permission) => {
          console.log(permission);
          if (permission === 'granted') {
            console.log('Notification permission granted');
            firebase.getNotificationToken().then(saveToken)
          } else {
            console.warn('Notification permission denied');
          }
        });
      } else {
        console.log('Notification API is not enabled')
      }
    }
  };

  return (
    <Fragment>
      {loading && <LoadScreen />}

      <div className={classes.root}>
        <nav className={collapsed ? classes.drawerCollapsed : classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={toggleSidebar}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Sidebar isMobile />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: collapsed ? classes.drawerPaperCollapsed : classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Switch>
            <Route path={routes.DASHBOARD} component={DashboardPage} />
            <Route path={routes.CASES} component={CasesPage} />
            <Route path={routes.ACTIVITY} component={ActivityPage} />
            <Route path={routes.CALENDAR} component={CalendarPage} />
            {userRole === userRoles.GLOBAL_ADMIN.name && (
              <Route path={routes.USERS} component={UsersPage} />
            )}
            <Redirect to={routes.DASHBOARD} />
          </Switch>
        </main>
      </div>

      {notification && notification.open && <Alert {...notification} onClose={closeNotification} />}

      <Alert
        variant="warning"
        message={notificationMessage}
        vertical='bottom'
        horizontal='center'
        open={!!notificationMessage}
        onClose={() => setNotificationMessage('')}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users.list,
    mobileOpen: state.sidebar.mobileOpen,
    userRole: state.user.currentUser.role,
    notification: state.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleSidebar: () => dispatch(toggleSidebar()),
    closeNotification: () => dispatch(closeNotification()),
    fetchCases: (withoutLoading) => dispatch(fetchCases(withoutLoading)),
    fetchActivity: (withoutLoading) => dispatch(fetchActivity(withoutLoading)),
    fetchUsers: () => dispatch(fetchUsers()),
    fetchDistributors: () => dispatch(fetchDistributors()),
    fetchCountryList: () => dispatch(fetchCountryList()),
    fetchSettings: () => dispatch(fetchSettings()),
    saveToken: (token) => dispatch(savePushNotificationsToken(token)),
  };
};

AppPage.propTypes = {
  mobileOpen: PropTypes.bool,
  userRole: PropTypes.string,
  notification: PropTypes.object,
  toggleSidebar: PropTypes.func,
  closeNotification: PropTypes.func,
  fetchCases: PropTypes.func,
  fetchActivity: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(AppPage));
