import React from 'react';
import { connect } from 'react-redux';

import { updateCase } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';

import OsteotomyStep7 from './osteotomy-step7/OsteotomyStep7';
import AclCompletedCase from './acl-completed-case/AclCompletedCase';
import CmfCompletedCase from './cmf-completed-case/CmfCompletedCase';
import CmfExtendedStep7 from './cmf-extended-step7/CmfExtendedStep7';

const { procedureFlows } = caseConstants;

const Step7 = ({ classes, onClose, activeCase, userId, updateCase }) => {
  const renderStep7 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep7
          classes={classes}
          activeCase={activeCase}
          onClose={onClose}
        />
      );
    }

    if (flow === procedureFlows.ACL) {
      return (
        <AclCompletedCase
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
        />
      );
    }

    if (flow === procedureFlows.CMF) {
      return (
        <CmfCompletedCase
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedStep7
          classes={classes}
          activeCase={activeCase}
          onClose={onClose}
        />
      );
    }
  };

  return renderStep7(activeCase.procedureFlow);
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, files) => dispatch(updateCase(newCase, 7, files)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step7);
