import React from 'react';
import PropTypes from 'prop-types';

import HospitalListItem from './HospitalListItem';

const HospitalList = ({ hospitals, onDelete }) => {
  return (
    <div className="distributor-list">
      {hospitals.map((hospital) => (
        <HospitalListItem key={hospital.id} {...hospital} onDelete={onDelete} />
      ))}
    </div>
  );
};

HospitalList.propTypes = {
  hospitals: PropTypes.array,
  onDelete: PropTypes.func,
};

export default HospitalList;
