import {
  TOGGLE_SIDEBAR,
  CLOSE_SIDEBAR,
  TRAINING_MODE_OFF,
  TRAINING_MODE_ON,
} from '../actions/actionTypes';

const initialState = {
  mobileOpen: false,
  trainingMode: false,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, mobileOpen: !state.mobileOpen };
    case CLOSE_SIDEBAR:
      return { ...state, mobileOpen: false };
    case TRAINING_MODE_OFF:
      return { ...state, trainingMode: false };
    case TRAINING_MODE_ON:
      return { ...state, trainingMode: true };
    default:
      return state;
  }
};

export default sidebarReducer;
