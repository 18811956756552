import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#217cee',
      light: '#0066d4',
      dark: '#1D6FD6',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    secondary: {
      main: '#b1b1b1',
      light: '#f2f2f2',
      dark: '#333333',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    orange: {
      main: '#ee9321',
      light: '#ee9321',
      dark: '#e2820a',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    rubyDust: {
      main: '#e11157',
      light: '#E42867',
      dark: '#CA0F4E',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    shamrock: {
      main: '#33CC99',
      light: '#5CD6AD',
      dark: '#29A37A',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
    purple: {
      main: '#7266FA',
      light: '#7266FA',
      dark: '#7266FA',
      contrastText: '#ffffff',
      danger: '#d32f2f',
    },
  },
  typography: {
    fontFamily: `"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 20,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '6px 48px',
        boxShadow: 'none !important',
        '&:hover': {
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
        },
        '&:active': {
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
        },
        '&:focus': {
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important',
        },
      },
    },
    MuiFormGroup: {
      root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > *': { flex: 1 },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.35)',
      },
    },
    MuiBadge: {
      root: {
        borderRadius: '50%',
        width: '35%',
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#b1b1b1',
        fontSize: '0.9rem',
        marginLeft: 4,
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: 'Source Sans Pro, Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
        lineHeight: 1.5,
        height: '100%',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
    },
    MuiTab: {
      wrapper: {
        alignItems: 'flex-start',
        textTransform: 'capitalize',
        paddingLeft: 8,
      },
    },
  },
});

export default theme;
