export const getVarusRightBreakpoints = (theme) => ({
  position: 'absolute',
  [theme.breakpoints.between('xs', 'sm')]: {
    top: '142px !important',
    left: '-7px !important',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    top: '160px !important',
    left: '-8px !important',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    top: '195px !important',
    left: '-8px !important',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    top: '294px !important',
    left: '-14px !important',
  },
  [theme.breakpoints.up('xl')]: {
    top: '381px !important',
    left: '-20px !important',
  },
});

export const getVarusLeftBreakpoints = (theme) => ({
  position: 'absolute',
  [theme.breakpoints.between('xs', 'sm')]: {
    top: '137px !important',
    left: '10px !important',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    top: '154px !important',
    left: '11px !important',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    top: '186px !important',
    left: '11px !important',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    top: '280px !important',
    left: '18px !important',
  },
  [theme.breakpoints.up('xl')]: {
    top: '359px !important',
    left: '25px !important',
  },
});

export const getValgusRightBreakpoints = (theme) => ({
  position: 'absolute',
  [theme.breakpoints.between('xs', 'sm')]: {
    top: '141px !important',
    left: '-10px !important',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    top: '161px !important',
    left: '-13px !important',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    top: '190px !important',
    left: '-12px !important',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    top: '285px !important',
    left: '-20px !important',
  },
  [theme.breakpoints.up('xl')]: {
    top: '371px !important',
    left: '-28px !important',
  },
});

export const getValgusLeftBreakpoints = (theme) => ({
  position: 'absolute',
  [theme.breakpoints.between('xs', 'sm')]: {
    top: '127px !important',
    left: '5px !important',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    top: '147px !important',
    left: '6px !important',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    top: '175px !important',
    left: '7px !important',
  },

  [theme.breakpoints.between('lg', 'xl')]: {
    top: '264px !important',
    left: '12px !important',
  },
  [theme.breakpoints.up('xl')]: {
    top: '341px !important',
    left: '16px !important',
  },
});
