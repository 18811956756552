import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import { ProductsItem } from './products-item';

const ProductsList = ({ products, country, onDelete }) => {
  const renderProductsList = products?.filter((product) => product?.country === country);

  return (
    <Grid item>
      {!!renderProductsList.length && (
        <div className="d-flex secondary">
          <div className="column" />
          <Grid item xs={4}>
            SKU
          </Grid>
          <Grid item xs={6}>
            DESCRIPTION
          </Grid>
        </div>
      )}

      {renderProductsList &&
        renderProductsList?.map((product) => (
          <div key={product?.id} className="d-flex">
            <div className="column" />
            <ProductsItem product={product} onDelete={onDelete} />
          </div>
        ))}
    </Grid>
  );
};

ProductsList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    })
  ).isRequired,
  country: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ProductsList;
