import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { Grid, makeStyles } from '@material-ui/core';

import HardwareList from '../../HardwareList';
import Dropzone from '../../../../../shared/dropzone/Dropzone';
import RestrictComponent from '../../../../../shared/RestrictComponent';

import { simpleUpdateCase } from '../../../../../../actions/casesActions';

import { userRoles } from '../../../../../../constants';
import { caseConstants } from '../../../../../../constants';

const { fileTypes } = caseConstants;

const useStyles = makeStyles((theme) => ({
  primary: { color: theme.palette.primary.main },
}));

const OsteotomyStep10Static = ({
  hardware,
  notes,
  surgicalInfo,
  userRole,
  updateCase,
  products,
  activeCase,
  updateCaseFiles,
  userId,
}) => {
  const classes = useStyles();
  const {
    GLOBAL_ADMIN,
    COUNTRY_ADMIN,
    DISTRIBUTOR_ADMIN,
    SURGEON,
    ENGINEER,
    SALES_REP,
    LOGISTICS,
    PRACTICE_MANAGER,
    MANUFACTURER,
  } = userRoles;

  const initialList = hardware ? hardware.map((item) => ({ ...item, id: Math.random() * Math.random() })) : [];

  const [hardwareList, setHardwareList] = useState(initialList);
  const [isEditMode, setEditMode] = useState(false);
  const [isEditFilesMode, setEditFilesMode] = useState(false);

  const [sheets, setSheets] = useState([]);
  const [xRays, setXRays] = useState([]);
  const [aoas, setAoas] = useState([]);
  const [allografts, setAllografts] = useState([]);

  useEffect(() => {
    setHardwareList(hardware.map((item) => ({ ...item, id: Math.random() * Math.random() })));
  }, [hardware]);

  const isPermission = !activeCase.trainingMode
    ? [GLOBAL_ADMIN, COUNTRY_ADMIN]
    : [
        GLOBAL_ADMIN,
        COUNTRY_ADMIN,
        DISTRIBUTOR_ADMIN,
        SURGEON,
        ENGINEER,
        SALES_REP,
        LOGISTICS,
        PRACTICE_MANAGER,
        MANUFACTURER,
      ];

  const onSave = () => {
    const updatedCase = {
      ...activeCase,
      hardware: hardwareList.map((item) => ({
        product: item.product,
        amount: item.amount,
        category: item.category || '',
      })),
    };

    updateCase(updatedCase);
    setEditMode(false);
  };

  const handleAddHardware = (product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.product === product.product && item.category === product.category);

    if (hardware) {
      hardware.amount += Number(product.amount);
      setHardwareList(list);
    } else {
      setHardwareList([...hardwareList, product]);
    }
  };

  const handleRemoveHardware = (id) => {
    setHardwareList(hardwareList.filter((item) => item.id !== id));
  };

  const handleAmountChange = (id, amount) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.amount = amount;
    setHardwareList(list);
  };

  const handleCategoryChange = (id, category) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.category = category;
    setHardwareList(list);
  };

  const handleChange = (id, product) => {
    const list = [...hardwareList];
    const hardware = list.find((item) => item.id === id);

    hardware.product = product;
    setHardwareList(list);
  };

  const handleSheetsUpload = (files) => {
    setSheets([...sheets, ...files.filter((file) => !sheets.map((sheet) => sheet.name).includes(file.name))]);
  };
  const handleXRaysUpload = (files) => {
    setXRays([...xRays, ...files.filter((file) => !xRays.map((xRay) => xRay.name).includes(file.name))]);
  };
  const handleAoasUpload = (files) => {
    setAoas([...aoas, ...files.filter((file) => !aoas.map((aoa) => aoa.name).includes(file.name))]);
  };
  const handleAllograftsUpload = (files) => {
    setAllografts([
      ...allografts,
      ...files.filter((file) => !allografts.map((allograft) => allograft.name).includes(file.name)),
    ]);
  };
  const handleRemoveSheets = (name) => {
    setSheets(sheets.filter((file) => file.name !== name));
  };
  const handleRemoveXRays = (name) => {
    setXRays(xRays.filter((file) => file.name !== name));
  };
  const handleRemoveAoas = (name) => {
    setAoas(aoas.filter((file) => file.name !== name));
  };
  const handleRemoveAllografts = (name) => {
    setAllografts(allografts.filter((file) => file.name !== name));
  };

  const onSaveNewFiles = () => {
    const now = moment().format();
    const files = [...activeCase.files];
    const newFiles = [];

    const updatedCase = {
      ...activeCase,
      files,
    };

    sheets.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.chargedSheet}_${file.name}`,
        name: file.name,
        type: fileTypes.chargedSheet,
        date: now,
        userId,
      });
      newFiles.push(file);
    });
    xRays.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.xRay}_${file.name}`,
        name: file.name,
        type: fileTypes.xRay,
        date: now,
        userId,
      });
      newFiles.push(file);
    });
    aoas.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.aoa}_${file.name}`,
        name: file.name,
        type: fileTypes.aoa,
        date: now,
        userId,
      });
      newFiles.push(file);
    });
    allografts.forEach((file) => {
      files.unshift({
        id: `${activeCase.id}_${fileTypes.allograft}_${file.name}`,
        name: file.name,
        type: fileTypes.allograft,
        date: now,
        userId,
      });
      newFiles.push(file);
    });

    if (newFiles.length) {
      updateCaseFiles(updatedCase, newFiles);
    }

    setEditFilesMode(false);
  };

  return (
    <div className="case-static-container">
      {surgicalInfo && (
        <div className="m-b-md">
          <div className="d-flex">
            <div className="secondary font-size-sm m-r-sm">Pre-operative Alignment:</div>
            <div>{surgicalInfo.preOpAlignment}°</div>
          </div>
          <div className="d-flex">
            <div className="secondary font-size-sm m-r-sm">Post-operative Alignment:</div>
            <div>{surgicalInfo.postOpAlignment}°</div>
          </div>
          <div className="d-flex">
            <div className="secondary font-size-sm m-r-sm">Wedge Height:</div>
            <div>{surgicalInfo.wedgeHeight} mm</div>
          </div>
        </div>
      )}
      <div className="p-b-md m-b-md underline">
        <div className="m-b-sm d-flex">
          <div className="secondary">CONFIRMED HARDWARE</div>
          <RestrictComponent roles={isPermission}>
            {isEditMode ? (
              <div className={`${classes.primary} m-l-sm pointer`} onClick={onSave}>
                Save
              </div>
            ) : (
              <div className={`${classes.primary} m-l-sm pointer`} onClick={() => setEditMode(true)}>
                Edit
              </div>
            )}
          </RestrictComponent>
        </div>
        {isEditMode ? (
          <div>
            <HardwareList
              classes={classes}
              hardwareList={hardwareList}
              products={products}
              onAdd={handleAddHardware}
              onRemove={handleRemoveHardware}
              onAmountChange={handleAmountChange}
              onCategoryChange={handleCategoryChange}
              onChange={handleChange}
              procedureFlow={activeCase.procedureFlow}
            />
          </div>
        ) : (
          <div>
            {hardwareList.map((item, index) => (
              <div key={`${index}-${item.product}`} className="d-flex">
                <div>
                  {`${index + 1}.`} <span className="secondary">{item.category}</span> {item.product}
                </div>
                <div className="secondary m-l-sm">x {item.amount}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      {notes && (
        <div className="m-t-md p-t-sm border-top">
          <div className="secondary">Notes:</div>
          <div>{notes}</div>
        </div>
      )}

      <div className="m-b-sm d-flex">
        <div className="secondary uppercase">Upload additional files</div>
        <RestrictComponent roles={isPermission}>
          {isEditFilesMode ? (
            <div className={`${classes.primary} m-l-sm pointer`} onClick={onSaveNewFiles}>
              Save
            </div>
          ) : (
            <div className={`${classes.primary} m-l-sm pointer`} onClick={() => setEditFilesMode(true)}>
              Edit
            </div>
          )}
        </RestrictComponent>
      </div>
      {isEditFilesMode && (
        <div className="m-t-lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <div className="block-header">Upload Charge Sheet</div>
              <Dropzone
                files={sheets}
                onUpload={handleSheetsUpload}
                type="image"
                icon="image"
                onRemove={handleRemoveSheets}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="block-header">Attach X-ray(s)</div>
              <Dropzone
                files={xRays}
                onUpload={handleXRaysUpload}
                type="image"
                icon="x-ray"
                onRemove={handleRemoveXRays}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="block-header">AOA registry (green form)</div>
              <Dropzone files={aoas} onUpload={handleAoasUpload} type="image" icon="aoa" onRemove={handleRemoveAoas} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="block-header">Allograft Tracking Form</div>
              <Dropzone
                files={allografts}
                onUpload={handleAllograftsUpload}
                type="image"
                icon="graft"
                onRemove={handleRemoveAllografts}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

OsteotomyStep10Static.propTypes = {
  hardware: PropTypes.array,
  notes: PropTypes.string,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    hardware: state.cases.activeCase.hardware,
    notes: state.cases.activeCase.notes && state.cases.activeCase.notes.step10,
    surgicalInfo: state.cases.activeCase.surgicalInfo,
    userRole: state.user.currentUser.role,
    userId: state.user.currentUser.uid,
    products,
    activeCase,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (updatedCase) => dispatch(simpleUpdateCase(updatedCase)),
    updateCaseFiles: (currCase, updateCaseFiles) => dispatch(simpleUpdateCase(currCase, updateCaseFiles)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OsteotomyStep10Static);
