import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

import Step0Static from './step0/Step0Static';
import OsteotomyStep1Static from './step1/osteotomy-step1/OsteotomyStep1Static';
import AclStep1Static from './step1/acl-step1/AclStep1Static';
import CmfStep1Static from './step1/cmf-step1/CmfStep1Static';
import OsteotomyStep2Static from './step2/osteotomy-step2/OsteotomyStep2Static';
import AclStep2Static from './step2/acl-step2/AclStep2Static';
import CmfStep2Static from './step2/cmf-step2/CmfStep2Static';
import OsteotomyStep3Static from './step3/osteotomy-step3/OsteotomyStep3Static';
import AclStep3Static from './step3/acl-step3/AclStep3Static';
import CmfStep3Static from './step3/cmf-step3/CmfStep3Static';
import CmfExtendedStep3Static from './step3/cmf-extended-step3/CmfExtendedStep3Static';
import OsteotomyStep4Static from './step4/osteotomy-step4/OsteotomyStep4Static';
import AclStep4Static from './step4/acl-step4/AclStep4Static';
import CmfStep4Static from './step4/cmf-step4/CmfStep4Static';
import CmfExtendedStep4Static from './step4/cmf-extended-step4/CmfExtendedStep4Static';
import OsteotomyStep5Static from './step5/osteotomy-step5/OsteotomyStep5Static';
import AclStep5Static from './step5/acl-step5/AclStep5Static';
import CmfStep5Static from './step5/cmf-step5/CmfStep5Static';
import CmfExtendedStep5Static from './step5/cmf-extended-step-5/CmfExtendedStep5Static';
import OsteotomyStep6Static from './step6/osteotomy-step6/OsteotomyStep6Static';
import AclStep6Static from './step6/acl-step6/AclStep6Static';
import CmfStep6Static from './step6/cmf-step6/CmfStep6Static';
import CmfExtendedStep6Static from './step6/cmf-extended-step6/CmfExtendedStep6Static';
import Step7Static from './step7/osteotomy-step7/OsteotomyStep7Static';
import CmfExtendedStep7Static from './step7/cmf-extended-step7/CmfExtendedStep7Static';
import Step8Static from './step8/osteotomy-step8/OsteotomyStep8Static';
import CmfExtendedStep8Static from './step8/cmf-extended-step8/CmfExtendedStep8Static';
import Step9Static from './step9/osteotomy-step9/OsteotomyStep9Static';
import CmfExtendedStep9Static from './step9/cmf-extended-step9/CmfExtendedStep9Static';
import Step10Static from './step10/osteotomy-step10/OsteotomyStep10Static';
import CmfExtendedStep10Static from './step10/cmf-extended-step10/CmfExtendedStep10Static';
import Step11Static from './step11/osteotomy-step11/OsteotomyStep11Static';
import CmfExtendedStep11Static from './step11/cmf-extended-step11/CmfExtendedStep11Static';

import NoAccess from './NoAccess';

import { caseConstants } from '../../../../constants';

const ExpansionPanel = withStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    color: theme.palette.primary.main,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    border: 'none',
    minHeight: 24,
    '&$expanded': {
      minHeight: 24,
    },
    padding: 0,
  },
  content: {
    '&$expanded': {
      minHeight: 24,
      margin: '4px 0',
    },
  },
  expanded: {
    minHeight: 24,
  },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: 0,
    color: '#000',
  },
})(MuiExpansionPanelDetails);

const { staticStepRestrictions, procedureFlows } = caseConstants;

const noAccessText = 'You do not have permission to access this information';

const CaseAccordion = ({ activeCase, currentStep, role, onClose = () => {}, users = [] }) => {
  const osteotomySteps = [
    {
      index: 11,
      component: <Step11Static />,
      title: 'Post-Op Report',
    },
    {
      index: 10,
      component: <Step10Static />,
      title: 'Usage Report',
    },
    {
      index: 9,
      component: <Step9Static />,
      title: 'Dispatch Confirmation',
    },
    {
      index: 8,
      component: <Step8Static />,
      title: 'Manufacturing Complete',
    },
    {
      index: 7,
      component: <Step7Static />,
      title: 'Manufacturing Approval',
    },
    {
      index: 6,
      component: <OsteotomyStep6Static />,
      title: 'Pre-Op Plan Approval',
    },
    {
      index: 5,
      component: <OsteotomyStep5Static />,
      title: 'Engineer Approval',
    },
    {
      index: 4,
      component: <OsteotomyStep4Static />,
      title: 'Surgeon Measurement Review',
    },
    {
      index: 3,
      component: <OsteotomyStep3Static onClose={onClose} />,
      title: 'Alignment Data',
    },
    {
      index: 2,
      component: <OsteotomyStep2Static />,
      title: 'Approve Scans',
    },
    {
      index: 1,
      component: <OsteotomyStep1Static />,
      title: 'Attach Scans & Documents',
    },
    {
      index: 0,
      component: <Step0Static />,
      title: 'Case Opened',
    },
  ];

  const aclSteps = [
    {
      index: 6,
      component: <AclStep6Static />,
      title: 'Post-Op Report',
    },
    {
      index: 5,
      component: <AclStep5Static />,
      title: 'Usage Report',
    },
    {
      index: 4,
      component: <AclStep4Static />,
      title: 'Pre-Op Plan Approval',
    },
    {
      index: 3,
      component: <AclStep3Static />,
      title: 'Engineer Approval',
    },
    {
      index: 2,
      component: <AclStep2Static />,
      title: 'Approve Scans',
    },
    {
      index: 1,
      component: <AclStep1Static />,
      title: 'Attach Scans & Documents',
    },
    {
      index: 0,
      component: <Step0Static />,
      title: 'Case Opened',
    },
  ];

  const cmfSteps = [
    {
      index: 6,
      component: <CmfStep6Static />,
      title: 'Post-Op Report',
    },
    {
      index: 5,
      component: <CmfStep5Static />,
      title: 'Usage Report',
    },
    {
      index: 4,
      component: <CmfStep4Static />,
      title: 'Pre-Op Plan Approval',
    },
    {
      index: 3,
      component: <CmfStep3Static />,
      title: 'Engineer Approval',
    },
    {
      index: 2,
      component: <CmfStep2Static />,
      title: 'Approve Scans',
    },
    {
      index: 1,
      component: <CmfStep1Static />,
      title: 'Attach Scans & Documents',
    },
    {
      index: 0,
      component: <Step0Static />,
      title: 'Case Opened',
    },
  ];

  const cmfExtendedSteps = [
    {
      index: 11,
      component: <CmfExtendedStep10Static />,
      title: 'Post-Op Report',
    },
    {
      index: 10,
      component: <CmfExtendedStep10Static />,
      title: 'Usage Report',
    },
    {
      index: 9,
      component: <CmfExtendedStep9Static />,
      title: 'Dispatch Confirmation',
    },
    {
      index: 8,
      component: <CmfExtendedStep8Static />,
      title: 'Manufacturing Complete',
    },
    {
      index: 7,
      component: <CmfExtendedStep7Static />,
      title: 'Manufacturing Approval',
    },
    {
      index: 6,
      component: <CmfExtendedStep6Static />,
      title: 'Design Approval',
    },
    {
      index: 5,
      component: <CmfExtendedStep5Static />,
      title: 'Upload Design Report',
    },
    {
      index: 4,
      component: <CmfExtendedStep4Static />,
      title: 'Planning Approval',
    },
    {
      index: 3,
      component: <CmfExtendedStep3Static />,
      title: 'Planning',
    },
    {
      index: 2,
      component: <CmfStep2Static />,
      title: 'Approve Scans',
    },
    {
      index: 1,
      component: <CmfStep1Static />,
      title: 'Attach Scans & Documents',
    },
    {
      index: 0,
      component: <Step0Static />,
      title: 'Case Opened',
    },
  ];

  const customSteps = [
    {
      index: 11,
      component: <Step11Static />,
      title: 'Post-Op Report',
    },
    {
      index: 10,
      component: <Step10Static />,
      title: 'Usage Report',
    },
    {
      index: 9,
      component: <Step9Static />,
      title: 'Dispatch Confirmation',
    },
    {
      index: 8,
      component: <Step8Static />,
      title: 'Manufacturing Complete',
    },
    {
      index: 7,
      component: <Step7Static />,
      title: 'Manufacturing Approval',
    },
    {
      index: 6,
      component: <OsteotomyStep6Static />,
      title: 'Pre-Op Plan Approval',
    },
    {
      index: 5,
      component: <OsteotomyStep5Static />,
      title: 'Engineer Approval',
    },
    {
      index: 2,
      component: <OsteotomyStep2Static />,
      title: 'Approve Scans',
    },
    {
      index: 1,
      component: <OsteotomyStep1Static />,
      title: 'Attach Scans & Documents',
    },
    {
      index: 0,
      component: <Step0Static />,
      title: 'Case Opened',
    },
  ];

  const getStaticStepsByFlow = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.HTO_LATERAL, procedureFlows.DFO_Lateral, procedureFlows.DFO_Medial].includes(flow)) {
      return osteotomySteps;
    }

    if (flow === procedureFlows.ACL) {
      return aclSteps;
    }

    if (flow === procedureFlows.CMF) {
      return cmfSteps;
    }

    if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return cmfExtendedSteps;
    }

    if (flow === procedureFlows.CUSTOM) {
      return customSteps;
    }
  };

  const getUserName = (step) => {
    if (!activeCase || !activeCase.completedBy) {
      return '';
    }
    const uid = activeCase.completedBy[`step${step}`];
    const user = users?.find((u) => u.uid === uid);
    return user ? ` | ${user.firstName} ${user.lastName}` : '';
  };

  return (
    <div className="case-accordion">
      {getStaticStepsByFlow(activeCase.procedureFlow)
        ?.filter((step) => {
          if (activeCase.skipPSI) {
            return step.index === 0 || step.index > 9;
          }
          return true;
        })
        ?.map(
          (step) =>
            currentStep > step.index && (
              <ExpansionPanel key={step.index}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <div className="accordion-step-header">
                    <div className="column">
                      {/*<div className="accordion-step-circle">{step.index}</div>*/}
                      <Badge badgeContent={step.index} color="primary" overlap="circle" showZero />
                    </div>
                    {/*<div className="accordion-title">{step.title}</div>*/}
                    <div>{step.title}</div>
                    <div className="accordion-title-date">
                      {`${moment(activeCase.completionDates[`step${step.index}`]).format("MMM Do YY")} | ${moment(activeCase.completionDates[`step${step.index}`]).format("HH:mm")}${getUserName(step.index)}`}
                    </div>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {staticStepRestrictions[step.index].includes(role) ? (
                    <NoAccess small text={noAccessText} />
                  ) : (
                    <div className="d-flex width-100">
                      <div className="column" />
                      {step.component}
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
        )}
    </div>
  );
};

CaseAccordion.propTypes = {
  activeCase: PropTypes.object,
  currentStep: PropTypes.number,
  role: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    role: state.user.currentUser.role,
    users: state.users?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseAccordion);
