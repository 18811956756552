import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { OsteotomyStep2 } from './osteotomy-step2/OsteotomyStep2';
import { AclStep2 } from './acl-step2/AclStep2';
import { CmfStep2 } from './cmf-step2/CmfStep2';

import CaseAccordion from '../CaseAccordion';
import NoAccess from '../NoAccess';
import Button from '../../../../shared/Button';

import { updateCase, setActiveTab } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';
import { caseCMFExtendedNotifications, fileStatuses } from '../../../../../constants/cases';

const Step2 = ({ onClose, activeCase, caseFiles, updateCase, goToFiles, userId }) => {
  const [files, setFiles] = useState(caseFiles);
  const [notes, setNotes] = useState('');

  const {
    fileStatuses,
    caseNotifications,
    caseACLNotifications,
    caseCMFNotifications,
    statuses,
    fileTypes,
    caseActions,
    procedureFlows,
  } = caseConstants;

  const notifications = useMemo(() => {
    switch (activeCase.procedureFlow) {
      case procedureFlows.ACL:
        return caseACLNotifications;
      case procedureFlows.CMF:
        return caseCMFNotifications;
      case procedureFlows.CMF_EXTENDED:
        return caseCMFExtendedNotifications;
      case procedureFlows.ONCOL:
        return caseCMFExtendedNotifications;
      case procedureFlows.CUSTOM_EXTENDED:
        return caseCMFExtendedNotifications;
      case procedureFlows.DOUBLE_LEVEL_OSTEOTOMY:
        return caseCMFExtendedNotifications;
      default:
        return caseNotifications;
    }
  }, [activeCase]);

  const handleChange = (id, status) => {
    const updatedFiles = files.slice();
    const changedFile = updatedFiles.find((item) => item.id === id);

    changedFile.status = status;
    setFiles(updatedFiles);
  };

  const handleSubmit = () => {
    if (files.some((file) => file.status === fileStatuses.REJECTED) && !notes.length) {
      return;
    }

    const now = moment().format();
    const activity = [...activeCase.activity];

    let rejected = false;

    if (files.some((file) => file.status === fileStatuses.REJECTED)) {
      activity.unshift({ ...notifications[2].reject, date: now, userId });
      rejected = true;
    } else if (files.some((file) => file.status === fileStatuses.APPROVED)) {
      activity.unshift({ ...notifications[2].success, date: now, userId });
    }

    updateCase(
      {
        ...activeCase,
        files,
        step: rejected ? 1 : (activeCase.procedureFlow === procedureFlows.CUSTOM ? 5 : 3),
        activity,
        notes: {
          step2: notes,
        },
        completionDates: { ...activeCase.completionDates, step2: now },
        completedBy: { ...activeCase.completedBy, step2: userId },
        status: rejected ? statuses.rejected : statuses.inProgress,
      },
      rejected
    ).then(() => onClose());
  };

  const xRays = files.filter((file) => file.type === fileTypes.xRay);
  const scans = files.filter((file) => file.type === fileTypes.scan);
  const mri = files.filter((file) => file.type === fileTypes.mri);

  const rejected = files.some((file) => file.status === fileStatuses.REJECTED);
  const disabled = rejected && !notes.length;

  const renderApproveScans = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep2
          xRays={xRays}
          goToFiles={goToFiles}
          handleChange={handleChange}
          fileStatuses={fileStatuses}
          scans={scans}
          rejected={rejected}
          notes={notes}
          setNotes={setNotes}
        />
      );
    }

    if (flow === procedureFlows.ACL) {
      return (
        <AclStep2
          mri={mri}
          goToFiles={goToFiles}
          handleChange={handleChange}
          fileStatuses={fileStatuses}
          scans={scans}
          rejected={rejected}
          notes={notes}
          setNotes={setNotes}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfStep2
          mri={mri}
          goToFiles={goToFiles}
          handleChange={handleChange}
          fileStatuses={fileStatuses}
          scans={scans}
          rejected={rejected}
          notes={notes}
          setNotes={setNotes}
        />
      );
    }
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">2</div>
          </div>
          <div className="modal-item-content">
            <div>{caseActions['2']}</div>
            {activeCase.access ? renderApproveScans(activeCase.procedureFlow) : <NoAccess />}
          </div>
        </div>
        <CaseAccordion currentStep={2} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        <Button text="Submit" color="primary" onClick={handleSubmit} disabled={!activeCase.access || disabled} />
      </div>
    </div>
  );
};

Step2.propTypes = {
  activeCase: PropTypes.object,
  caseFiles: PropTypes.array,
  onClose: PropTypes.func,
  updateCase: PropTypes.func,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const caseFiles = JSON.parse(JSON.stringify(state.cases.activeCase.files));

  caseFiles.forEach((file) => {
    file.status = fileStatuses.APPROVED;
  });

  return {
    activeCase: state.cases.activeCase,
    caseFiles,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, rejected) => dispatch(updateCase(newCase, 2, null, rejected)),
    goToFiles: () => dispatch(setActiveTab(2)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
