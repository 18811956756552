import React, { memo } from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledButton = withStyles((theme) => ({
  root: {
    padding: '4px 44px',
    minWidth: '200px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.main,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
}))(MuiButton);

const StyledCancelButton = withStyles((theme) => ({
  root: {
    padding: '4px 0',
    textTransform: 'capitalize',
    color: '#b1b1b1',
    '&:hover': {},
    '&:active': {},
    '&:focus': {},
  },
}))(MuiButton);

const StyledOutlinedButton = withStyles((theme) => ({
  root: {
    padding: '2px 44px',
    minWidth: '200px',
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      borderColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
}))(MuiButton);

const Button = ({ type, text, color, onClick, disabled, loading, outlined }) => {
  switch (type) {
    case 'submit':
      return (
        <StyledButton onClick={onClick} color={color || 'primary'} disabled={disabled}>
          {text}
          {loading && <CircularProgress size={24} className="button-processing-indicator" />}
        </StyledButton>
      );
    case 'cancel':
      return <StyledCancelButton onClick={onClick}>{text || 'Cancel'}</StyledCancelButton>;
    case 'secondary':
      return (
        <StyledOutlinedButton
          onClick={onClick}
          color={color || 'primary'}
          variant="outlined"
          disabled={disabled}
        >
          {text}
          {loading && <CircularProgress size={24} className="button-processing-indicator" />}
        </StyledOutlinedButton>
      );
    default:
      return (
        <StyledButton
          onClick={onClick}
          color={color || 'primary'}
          variant={outlined ? 'outlined' : 'text'}
          disabled={disabled}
        >
          {text}
          {loading && <CircularProgress size={24} className="button-processing-indicator" />}
        </StyledButton>
      );
  }
};

Button.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  outlined: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'cancel', 'secondary']),
};

export default memo(Button);
