import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import preopIcon from '../../../../../../assets/preop_icon.svg';

import { setActiveTab, simpleUpdateCase } from '../../../../../../actions/casesActions';

import { fileTypes } from '../../../../../../constants/cases';

const CmfExtendedStep8Static = ({ activeCase, goToFiles }) => {
  const { files } = activeCase;

  return (
    <div className="case-static-container">
      <div className="">
        {files
          .filter((file) => file.type === fileTypes.conformanceReport)
          .map((file) => (
            <div key={file.id} className="d-flex m-b-md pointer" onClick={goToFiles}>
              <img src={preopIcon} alt="file-icon" />
              <div>{file.name}</div>
              <div className="status-indicator">{file.type}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

CmfExtendedStep8Static.propTypes = {
  activeCase: PropTypes.object,
  goToFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  const activeCase = state.cases.activeCase;
  const procedure = state.procedures.list.find((procedure) => procedure.id === activeCase.procedure);
  const products = procedure ? procedure.products : [];

  return {
    activeCase,
    products,
    userRole: state.user.currentUser.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToFiles: () => dispatch(setActiveTab(2)),
    updateCase: (updatedCase) => dispatch(simpleUpdateCase(updatedCase)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CmfExtendedStep8Static);
