import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const NewHospital = ({ newHospital, hospitals = [], onChange, onCancel, onSubmit }) => {
  const list = orderBy(hospitals, [hospital => hospital.name.toLowerCase()], 'asc');

  return (
    <div className="admin-modal-item new-item">
      <div className="column">
        <div className="sub-item-circle-new" />
      </div>
      <div className="modal-item-content d-flex">
        <Select
          id="user-select"
          className="user-select"
          value={newHospital}
          onChange={(e) => onChange(e.target.value)}
        >
          {list.map((hospital) => (
            <MenuItem value={hospital}>{hospital.name}</MenuItem>
          ))}
        </Select>
        <div className="p-l-md primary pointer" onClick={onSubmit}>
          Add
        </div>
        <div className="p-l-md secondary pointer" onClick={onCancel}>
          Cancel
        </div>
      </div>
    </div>
  );
};

NewHospital.propTypes = {
  newHospital: PropTypes.object,
  hospitals: PropTypes.array,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default NewHospital;
