import React, { Fragment } from 'react';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import WarningIcon from '@material-ui/icons/Warning';

import Dropzone from '../../../../../shared/dropzone/Dropzone';

export const AclStep1 = ({
  activeCase,
  fileStatuses,
  rejectedFiles,
  date,
  setDate,
  xRays,
  handleXRaysUpload,
  handleRemoveXRay,
  onUploadXRayComplete,
  scans,
  handleScansUpload,
  handleRemoveScan,
  onUploadScanComplete,
  mris,
  handleMriUpload,
  handleRemoveMri,
  onUploadMriComplete,
  reports,
  handleReportUpload,
  handleRemoveReport,
  onUploadReportComplete,
  userRole,
}) => (
  <Fragment>
    {activeCase.files && activeCase.files.some((file) => file.status === fileStatuses.REJECTED) && (
      <div className="alert warning">
        <div className="d-flex align-center">
          <WarningIcon color="primary" />
          <div>
            {`Some of the scans have been rejected: 
                      ${rejectedFiles.map((file, index) => `${file.name} `)}`}
          </div>
        </div>
        {activeCase.notes && activeCase.notes.step2 && <div className="m-t-md">Note: {activeCase.notes.step2}</div>}
      </div>
    )}

    <div className="field-container m-t-lg">
      <div className="secondary font-size-sm uppercase">Targeted surgery date</div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="DD/MM/YYYY"
          id="date-picker"
          value={date}
          onChange={(date) => setDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
    <div className="modal-item-block">
      <div className="block-header">
        Attach MRI (recommended)
      </div>
      <Dropzone
        type='archive'
        files={mris}
        onUpload={handleMriUpload}
        onRemove={handleRemoveMri}
        icon="x-ray"
        withProgress
        path={`${activeCase.id}/${activeCase.id}`}
        suffix="mri"
        onUploadComplete={onUploadMriComplete}
      />
    </div>
    <div className="modal-item-block">
      <div className="block-header">
        Upload CT Scan
      </div>
      <Dropzone
        type='archive'
        files={scans}
        onUpload={handleScansUpload}
        onRemove={handleRemoveScan}
        icon="scan"
        withProgress
        path={`${activeCase.id}/${activeCase.id}`}
        suffix="scan"
        onUploadComplete={onUploadScanComplete}
      />
    </div>
    <div className="modal-item-block">
      <div className="block-header">Attach XRAY</div>
      <Dropzone
        type='archive'
        files={xRays}
        onUpload={handleXRaysUpload}
        onRemove={handleRemoveXRay}
        icon="x-ray"
        withProgress
        path={`${activeCase.id}/${activeCase.id}`}
        suffix="x-ray"
        onUploadComplete={onUploadXRayComplete}
      />
    </div>
    {/*{userRole !== userRoles.RADIOLOGY.name && (*/}
    {/*  <div className="modal-item-block">*/}
    {/*    <div className="block-header">Surgeon consultation report</div>*/}
    {/*    <Dropzone*/}
    {/*      files={reports}*/}
    {/*      onUpload={handleReportUpload}*/}
    {/*      onRemove={handleRemoveReport}*/}
    {/*      icon="scan"*/}
    {/*      withProgress*/}
    {/*      path={`${activeCase.id}/${activeCase.id}`}*/}
    {/*      suffix="report"*/}
    {/*      onUploadComplete={onUploadReportComplete}*/}
    {/*    />*/}
    {/*  </div>*/}
    {/*)}*/}
  </Fragment>
);
