export const USER_DATA_COLLECTION = 'userData';
export const USER_AUTHORIZATION_COLLECTION = 'userAuthorization';

export const PROCEDURES_COLLECTION = 'procedures';
export const DISTRIBUTORS_COLLECTION = 'distributors';
export const HOSPITALS_COLLECTION = 'hospitals';
export const MANUFACTURERS_COLLECTION = 'manufacturers';
export const RADIOLOGY_COLLECTION = 'radiology';
export const SURGEONS_COLLECTION = 'surgeons';
export const CONSTANTS_COLLECTION = 'constants';
export const DOCUMENTS_COLLECTION = 'documents';

export const CASES_COLLECTION = 'cases';
export const NOTES_SUB_COLLECTION = (caseId) => `cases/${caseId}/notes`;

export const ACTIVITY_COLLECTION = 'userActivity';
export const ACTIVITY_SUB_COLLECTION = 'activityList';

// Constant documents

export const COUNTRIES_DOCUMENT = 'countries';

export const PRODUCTS_COLLECTION = 'products';

export const SETTINGS_COLLECTION = 'settings';
