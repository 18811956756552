import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

const CalculationInput = ({
  value,
  label,
  unit,
  onChange,
  disabled,
  min = 0,
  max = 1000,
  calculating,
}) => {
  const getUnit = () => {
    switch (unit) {
      case 'degrees':
        return '°';
      case 'mm':
        return 'mm';
      case 'percents':
        return '%';
      default:
        return 'mm';
    }
  };

  const error = value < min || value > max;
  const loading = calculating && calculating[label];

  return (
    <FormControl className="calculation-input" error={error} fullWidth>
      <Input
        id={`calculation-input-${label}`}
        aria-describedby={`calculation-input-${label}`}
        type="number"
        disabled={disabled}
        value={!loading && value}
        fullWidth
        onChange={(e) => onChange(e.target.value)}
        inputProps={{ min, max }}
        startAdornment={
          <InputAdornment position="start">
            <div className="d-flex">
              <div className={!unit ? 'calculation-input-label' : undefined}>{label}</div>
              {unit && <div className="m-l-sm secondary">({getUnit()})</div>}
            </div>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {loading && <CircularProgress size={24} />}
          </InputAdornment>
        }
      />
      {/*{ error && <FormHelperText id={`calculation-input-${label}-error`}>Error</FormHelperText>}*/}
    </FormControl>
  );
};

CalculationInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  unit: PropTypes.oneOf(['mm', 'degrees', 'percents']),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  calculating: PropTypes.object,
};

export default CalculationInput;
