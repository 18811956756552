import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';

const OsteotomyStep8Static = ({ dispatchInfo }) => {
  return (
    <div className="case-static-container">
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <div className="field-container">
            <div className="field-title">Manufacturing completed date</div>
            <div className="field-value">{moment(dispatchInfo?.blockManufacturingCompleted).format('DD/MM/YYYY')}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

OsteotomyStep8Static.propTypes = {
  dispatchInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    dispatchInfo: state.cases.activeCase.dispatchInfo,
  };
};

export default connect(mapStateToProps)(OsteotomyStep8Static);
