import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import Papa from 'papaparse';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Button from '../../../shared/Button';
import NewUser from '../NewUser';
import DistributorList from './distributor/DistributorList';
import HospitalList from './HospitalList';
import ManufacturerList from './ManufacturerList';
import RadiologyList from './RadiologyList';
import NewDistributor from './NewDistributor';
import NewHospital from './NewHospital';
import NewManufacturer from './NewManufacturer';
import NewRadiology from './NewRadiology';
import EngineersList from './EngineersList';
import Dropzone from '../../../shared/dropzone/Dropzone';

import { userRoles } from '../../../../constants';

import {
  setRole,
  addDistributor,
  deleteDistributor,
  addHospital,
  fetchHospitals,
  deleteHospital,
  addManufacturer,
  deleteManufacturer,
  addRadiology,
  deleteRadiology,
  updateAssignedProcedures,
  updateDistributor,
} from '../../../../actions/adminActions';

const tabs = [
  { id: 0, label: 'Distributors', buttonLabel: 'Distributor', countryAdmin: false },
  { id: 1, label: 'Hospitals', buttonLabel: 'Hospital', countryAdmin: true },
  { id: 2, label: 'Manufacturers', buttonLabel: 'Manufacturer', countryAdmin: false },
  { id: 3, label: 'Engineers', buttonLabel: 'Engineer', countryAdmin: false },
  { id: 4, label: 'Radiology', buttonLabel: 'Radiology', countryAdmin: true },
];

const CountryAdminContainer = (props) => {
  const {
    onClose,
    users,
    countryList,
    setRole,
    currentUser,
    engineers,
    distributorAdmins,
    manufactureUsers,
    radiologyUsers,
    distributors,
    deleteDistributor,
    addDistributor,
    hospitals,
    addHospital,
    deleteHospital,
    manufacturers,
    radiologyList,
    addManufacturer,
    deleteManufacturer,
    addRadiology,
    deleteRadiology,
    procedures,
    updateAssignedProcedures,
    updateDistributor,
  } = props;

  const [activeTab, setActiveTab] = useState(currentUser.role === userRoles.GLOBAL_ADMIN.name ? 0 : 1);

  const [country, setCountry] = useState('Australia');
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const [addMode, setAddMode] = useState(false);
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    if (currentUser.role === userRoles.COUNTRY_ADMIN.name) {
      setCountry(currentUser.administrationCountry);
    }
  }, [currentUser]);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    setAddMode(false);
  };

  const addNewUser = () => {
    if (!newUser) {
      return;
    }

    setRole(newUser.uid, userRoles.ENGINEER.name, country);
    setAddMode(false);
  };

  const handleAddDistributor = (distributor) => {
    addDistributor({ ...distributor, country });
    setAddMode(false);
  };

  const handleAddHospital = (hospital) => {
    addHospital({ ...hospital, country });
    setAddMode(false);
  };

  const handleAddManufacturer = (manufacturer) => {
    addManufacturer({ ...manufacturer, country });
    setAddMode(false);
  };

  const handleAddRadiology = (radiology) => {
    addRadiology({ ...radiology, country });
    setAddMode(false);
  };

  const handleAddProcedure = (id, procedure) => {
    updateAssignedProcedures(id, procedure);
  };

  const handleDeleteProcedure = (id, procedure) => {
    const assignedProcedures = distributors
      .find((item) => item.id === id)
      .assignedProcedures.slice();
    const updateProcedures = assignedProcedures.filter((item) => item.id !== procedure.id);

    updateAssignedProcedures(id, updateProcedures);
  };

  const onUpload = (files) => {
    Papa.parse(files[0], {
      header: false,
      skipEmptyLines: true,
      complete: async (results) => {
        if (results && results.data) {
          await uploadHospitals(results.data);
        }
      },
    });
  };

  const uploadHospitals = async (list) => {
    try {
      setLoading(true);
      for (const hospital of list) {
        const name = hospital[0] || '';
        const street = hospital[1] || '';
        const city = hospital[2] || '';
        const country = hospital[3] || '';
        const state = hospital[4] || '';
        const postCode = hospital[5] || '';

        const address = `${street}, ${city}, ${country}`;

        if (name && !hospitals?.find((h) => h.name === name)) {
          await addHospital({
            name,
            address,
            state,
            postCode,
            country: 'Australia'
          });
        }

        await fetchHospitals();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = (data) => {
    switch (activeTab) {
      case 0:
        return (
          <DistributorList
            distributors={data}
            procedures={procedures}
            onDelete={(id) => deleteDistributor(id)}
            onUpdateDistributor={updateDistributor}
            admins={distributorAdmins}
            users={users.filter((user) => user.uid !== currentUser.uid)}
            onAddAdmin={(uid, distributor) =>
              setRole(uid, userRoles.DISTRIBUTOR_ADMIN.name, country, distributor)
            }
            onDeleteAdmin={(uid) => setRole(uid, null, null, null)}
            onAddProcedure={handleAddProcedure}
            onDeleteProcedure={handleDeleteProcedure}
          />
        );
      case 1:
        return <HospitalList
          hospitals={orderBy(data, [hospital => hospital.name.toLowerCase()], 'asc')}
          onDelete={(id) => deleteHospital(id)}
        />;
      case 2:
        return (
          <ManufacturerList
            manufacturers={data}
            onDelete={(id) => deleteManufacturer(id)}
            admins={manufactureUsers}
            users={users.filter((user) => user.uid !== currentUser.uid)}
            onAddAdmin={(uid, manufacturer) =>
              setRole(uid, userRoles.MANUFACTURER.name, country, manufacturer)
            }
            onDeleteAdmin={(uid) => setRole(uid, null, null, null)}
          />
        );
      case 3:
        return <EngineersList users={data} onDelete={(uid) => setRole(uid, null, null, null)} />;
      case 4:
        return (
          <RadiologyList
            radiologyList={data}
            onDelete={(id) => deleteRadiology(id)}
            admins={radiologyUsers}
            users={users.filter((user) => user.uid !== currentUser.uid)}
            onAddAdmin={(uid, radiology) =>
              setRole(uid, userRoles.RADIOLOGY.name, country, radiology)
            }
            onDeleteAdmin={(uid) => setRole(uid, null, null, null)}
          />
        );
      default:
        return <div>Distributors</div>;
    }
  };

  const renderInput = () => {
    switch (activeTab) {
      case 0:
        return (
          <NewDistributor onCancel={() => setAddMode(false)} onSubmit={handleAddDistributor} />
        );
      case 1:
        return <NewHospital onCancel={() => setAddMode(false)} onSubmit={handleAddHospital} />;
      case 2:
        return (
          <NewManufacturer onCancel={() => setAddMode(false)} onSubmit={handleAddManufacturer} />
        );
      case 4:
        return (
          <NewRadiology onCancel={() => setAddMode(false)} onSubmit={handleAddRadiology} />
        );
      default:
        return (
          <NewUser
            newUser={newUser}
            userList={userList}
            onChange={setNewUser}
            onCancel={() => setAddMode(false)}
            onSubmit={addNewUser}
          />
        );
    }
  };

  const getData = () => {
    switch (activeTab) {
      case 0:
        return distributors;
      case 1:
        return hospitals;
      case 2:
        return manufacturers;
      case 4:
        return radiologyList;
      default:
        return distributors;
    }
  };

  const filteredEngineers = engineers.filter((item) => item.administrationCountry === country);
  const data = getData();

  const filteredData =
    activeTab === 3 ? filteredEngineers : data.filter((item) => item.country === country);

  const filterUserList = (user) =>
    !~filteredEngineers.map((admin) => admin.email).indexOf(user.email) &&
    user.uid !== currentUser.uid;
  const userList = users.filter(filterUserList);

  const isCountryAdmin = currentUser.role === userRoles.COUNTRY_ADMIN.name;

  return (
    <div className="admin-modal-container">
      <div className="admin-modal-content">
        <div className={`admin-modal-item header ${!filteredData.length && 'empty'}`}>
          <div className="column">
            <div className="modal-item-circle light" />
          </div>
          <div className="modal-item-content">
            <div className="item-content-header">Country</div>
            <Select
              id="country-select"
              disabled={isCountryAdmin}
              className="country-select"
              value={isCountryAdmin ? currentUser.administrationCountry : country}
              onChange={(e) => setCountry(e.target.value)}
            >
              {countryList.sort().map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <div className="modal-tabs">
              {tabs
                ?.filter((tab) => (currentUser.role === userRoles.COUNTRY_ADMIN.name && tab.countryAdmin) || currentUser.role === userRoles.GLOBAL_ADMIN.name)
                ?.map((tab) => (
                  <div
                    key={tab.id}
                    className={`modal-tab ${activeTab === tab.id && 'active'}`}
                    onClick={() => handleTabChange(tab.id)}
                  >
                    {tab.label}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        {country === 'Australia' && activeTab === 1 && (
          <div className="modal-item-block m-t-md m-b-md">
            <div className="block-header">
              Upload Hospitals
            </div>
            <Dropzone
              multiple={false}
              files={file}
              onUpload={onUpload}
              type="csv"
              text="Drag and drop .csv file here or click to upload"
              hideFilesList
              loading={loading}
            />
            <div className='m-t-sm'>
              <span className='font-bold'>Format:</span>
              <span className='m-l-sm'>Name, Street, City, Country, State, Postcode</span>
            </div>
          </div>
        )}
        {renderContent(filteredData)}
        {addMode && renderInput()}
      </div>
      <div className="admin-modal-actions">
        <div className="secondary pointer" onClick={onClose}>
          Close
        </div>
        {!addMode && (
          <Button
            text={`Add ${tabs[activeTab].buttonLabel}`}
            color="primary"
            onClick={() => setAddMode(true)}
          />
        )}
      </div>
    </div>
  );
};

CountryAdminContainer.propTypes = {
  currentUser: PropTypes.object,
  email: PropTypes.string,
  users: PropTypes.array,
  engineers: PropTypes.array,
  distributorAdmins: PropTypes.array,
  distributors: PropTypes.array,
  hospitals: PropTypes.array,
  manufacturers: PropTypes.array,
  countryList: PropTypes.array,
  onClose: PropTypes.func,
  updateCountries: PropTypes.func,
  addDistributor: PropTypes.func,
  deleteDistributor: PropTypes.func,
  addHospital: PropTypes.func,
  deleteHospital: PropTypes.func,
  addManufacturer: PropTypes.func,
  deleteManufacturer: PropTypes.func,
  setRole: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    users: state.users.list,
    engineers: state.users.list.filter((item) => item.role === userRoles.ENGINEER.name),
    distributorAdmins: state.users.list.filter(
      (item) => item.role === userRoles.DISTRIBUTOR_ADMIN.name
    ),
    manufactureUsers: state.users.list.filter((item) => item.role === userRoles.MANUFACTURER.name),
    radiologyUsers: state.users.list.filter((item) => item.role === userRoles.RADIOLOGY.name),
    distributors: state.distributors.list,
    hospitals: state.hospitals.list,
    manufacturers: state.manufacturers.list,
    radiologyList: state.radiology.list,
    countryList: state.countries.list,
    procedures: state.procedures.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRole: (uid, role, country, distributor) =>
      dispatch(setRole(uid, role, country, distributor)),
    addDistributor: (distributor) => dispatch(addDistributor(distributor)),
    deleteDistributor: (id) => dispatch(deleteDistributor(id)),
    updateDistributor: (id, data) => dispatch(updateDistributor(id, data)),
    addHospital: (hospital) => dispatch(addHospital(hospital)),
    deleteHospital: (id) => dispatch(deleteHospital(id)),
    addManufacturer: (manufacturer) => dispatch(addManufacturer(manufacturer)),
    deleteManufacturer: (id) => dispatch(deleteManufacturer(id)),
    addRadiology: (radiology) => dispatch(addRadiology(radiology)),
    deleteRadiology: (id) => dispatch(deleteRadiology(id)),
    updateAssignedProcedures: (id, procedure) => dispatch(updateAssignedProcedures(id, procedure)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryAdminContainer);
