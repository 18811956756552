import React from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { utils } from '../../../../../util';

const DistributorAddProcedure = ({
  distributorProcedures,
  procedures,
  onChange,
  onCancel,
  onSubmit,
}) => (
  <div className="admin-modal-item new-item">
    <div className="column" />
    <div className="modal-item-content d-flex">
      <Select
        id="user-select"
        className="user-select"
        value={distributorProcedures}
        onChange={(e) => onChange(e.target.value)}
      >
        {procedures?.sort(utils.sortAlphabetically('name')).map((procedure) => (
          <MenuItem key={procedure.id} value={procedure}>
            {procedure.name}
          </MenuItem>
        ))}

        {!procedures.length && (
          <MenuItem disabled value={''}>
            None
          </MenuItem>
        )}
      </Select>
      <div className="p-l-md primary pointer" onClick={onSubmit}>
        Add
      </div>
      <div className="p-l-md secondary pointer" onClick={onCancel}>
        Cancel
      </div>
    </div>
  </div>
);

export default DistributorAddProcedure;
