import React from 'react';

import Firebase from '../../firebase';
import Modal from '../modals/Modal';
import Iframe from '../shared/iframe';

const PolicyModal = ({ year, open, onClose }) => {
  const [policy, setPolicy] = React.useState('');

  Firebase.getDownloadLink('static/PS_privacy_policy.docx').then((response) => setPolicy(response));

  return (
    <Modal
      size="md"
      fullWidth
      open={open}
      onClose={onClose}
      title={`Privacy Policy ${year}`}
      subtitle="Personalised Surgery Pty Ltd"
    >
      <div className="footer-modal__content">
        <div className="footer-modal__block">
          <Iframe source={policy} />
        </div>
      </div>
    </Modal>
  );
};

export default PolicyModal;
