import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

import EngineerListItem from './EngineerListItem';

const EngineersList = ({ users, onDelete }) => {
  const list = orderBy(users, 'lastName');
  return (
    <div>
      {list.map((admin) => (
        <EngineerListItem key={admin.uid} {...admin} onDelete={onDelete} />
      ))}
    </div>
  );
};

EngineersList.propTypes = {
  users: PropTypes.array,
  onDelete: PropTypes.func,
};

export default EngineersList;
