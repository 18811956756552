import { LOAD_SETTINGS, UPDATE_SETTINGS } from '../actions/actionTypes';

const initialState = {
  notifications: { active: true },
  loading: false,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SETTINGS:
      const notificationsSettings = action?.settings?.find((item) => item.id === 'notifications');
      return { ...state, notifications: { active: notificationsSettings ? notificationsSettings.active : true } };
    case UPDATE_SETTINGS:
      return { ...state, notifications: action.settings?.notifications || { active: true }  };
    default:
      return state;
  }
};

export default settingsReducer;
