import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions/DialogActions';
import { withStyles, makeStyles } from '@material-ui/core';

import Button from '../shared/Button';

import trainingIcon from '../../assets/training_icon_active.svg';

const useStyles = makeStyles(() => ({
  paper: { width: '450px' },
}));

const DialogActions = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #f2f2f2',
  },
}))(MuiDialogActions);

const TrainingModeModal = ({
  open,
  title,
  text,
  additionalText,
  onSubmit,
  onClose,
  submitText,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && (
        <DialogTitle id="alert-dialog-title" style={{ marginTop: '8px' }}>
          <img
            src={trainingIcon}
            alt="new-case-icon"
            style={{ width: '24px', marginRight: '16px' }}
          />
          <span className="font-bold">{title}</span>
        </DialogTitle>
      )}
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontWeight: 'bold', paddingTop: '16px' }}
        >
          {text}
        </DialogContentText>
        {additionalText && (
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontWeight: 'bold', paddingBottom: '16px', paddingTop: '8px' }}
          >
            {additionalText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <div className="d-flex flex-1 p-l-lg p-r-lg p-t-md p-b-md">
          <div className="flex-1 p-t-md p-b-md secondary pointer font-bold" onClick={onClose}>
            Cancel
          </div>
          <Button type="submit" text={submitText || 'Activate'} onClick={onSubmit} />
        </div>
      </DialogActions>
    </Dialog>
  );
};

TrainingModeModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default TrainingModeModal;
