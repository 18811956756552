import React from 'react';
import { connect } from 'react-redux';

import { updateCase } from '../../../../../actions/casesActions';

import { caseConstants } from '../../../../../constants';
import { roleNames } from '../../../../../constants/userRoles';

import { OsteotomyStep6 } from './osteotomy-step6/OsteotomyStep6';
import AclStep6 from './acl-step6/AclStep6';
import CmfStep6 from './cmf-step6/CmfStep6';
import CmfExtendedStep6 from './cmf-extended-step6/CmfExtendedStep6';

const {
  fileTypes,
  caseNotifications,
  caseACLNotifications,
  caseCMFNotifications,
  statuses,
  fileStatuses,
  procedureFlows,
} = caseConstants;

const { GLOBAL_ADMIN } = roleNames;

const Step6 = ({ classes, onClose, activeCase, preop, updateCase, userRole, userId }) => {
  const renderStep6 = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep6
          classes={classes}
          activeCase={activeCase}
          onClose={onClose}
          preop={preop}
          updateCase={updateCase}
          userRole={userRole}
          fileStatuses={fileStatuses}
          caseNotifications={caseNotifications}
          statuses={statuses}
          admin={GLOBAL_ADMIN}
          userId={userId}
        />
      );
    }

    if (flow === procedureFlows.ACL) {
      return (
        <AclStep6
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          fileTypes={fileTypes}
          caseNotifications={caseACLNotifications}
          statuses={statuses}
        />
      );
    }

    if (flow === procedureFlows.CMF) {
      return (
        <CmfStep6
          classes={classes}
          onClose={onClose}
          activeCase={activeCase}
          fileTypes={fileTypes}
          caseNotifications={caseCMFNotifications}
          statuses={statuses}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfExtendedStep6
          classes={classes}
          onClose={onClose}
        />
      );
    }
  };

  return renderStep6(activeCase.procedureFlow);
};

const mapStateToProps = (state) => {
  return {
    activeCase: state.cases.activeCase,
    preop: state.cases.activeCase.files.find((file) => file.type === 'preop'),
    users: state.users.list,
    userRole: state.user.currentUser.role,
    userId: state.user.currentUser.uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCase: (newCase, rejected) => dispatch(updateCase(newCase, 6, null, rejected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step6);
