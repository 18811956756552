import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Modal from './Modal';

const ForgotPasswordModal = ({ open, onClose, onSubmit }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    if (e.keyCode === 13) {
      setEmail('');
      onSubmit(email);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setEmail('');
        onClose();
      }}
      onSubmit={() => onSubmit(email)}
      title="Forgot your password?"
      disabled={!email.length}
      size="xs"
      autoSize
    >
      <div className="p-lg">
        <div className="secondary m-b-sm">
          Enter your email and we'll send you details on how to reset your password.
        </div>
        <TextField
          id="email"
          type="email"
          label="Email address"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleSubmit}
        />
        <div className="actions m-t-lg d-flex flex-end">
          <Button
            type="submit"
            onClick={() => onSubmit(email)}
            variant="contained"
            color="primary"
            disabled={!email.length}
          >
            Reset password
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ForgotPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ForgotPasswordModal;
