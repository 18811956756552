import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { OsteotomyStep1 } from './osteotomy-step1/OsteotomyStep1';
import { AclStep1 } from './acl-step1/AclStep1';
import { CmfStep1 } from './cmf-step1/CmfStep1';

import CaseAccordion from '../CaseAccordion';
import NoAccess from '../NoAccess';
import Button from '../../../../shared/Button';
import Alert from '../../../../shared/Alert';

import { uploadFiles, clearRejectedFiles } from '../../../../../actions/casesActions';
import { caseConstants, userRoles } from '../../../../../constants';

import { withFirebase } from '../../../../../firebase';

const Step1 = (props) => {
  const { onClose, uploadFiles, activeCase, toggleModal, uid, userRole, procedures, firebase } = props;
  const { fileStatuses, fileTypes, caseActions, caseNotifications, statuses, procedureFlows, defaultLeadDays } = caseConstants;

  useEffect(() => {
    clearRejectedFiles(activeCase.files, activeCase.id);
  }, [activeCase.files, activeCase.id]);

  const rejectedFiles =
    activeCase && activeCase.files ? activeCase.files.filter((file) => file.status === fileStatuses.REJECTED) : [];
  const isAdmin = userRole === userRoles.GLOBAL_ADMIN.name || userRole === userRoles.COUNTRY_ADMIN.name || userRole === userRoles.RADIOLOGY.name;

  const [xRays, setXRays] = useState([]);
  const [scans, setScans] = useState([]);
  const [mris, setMris] = useState([]);
  const [reports, setReports] = useState([]);

  const [uploadedXRays, setUploadedXRays] = useState([]);
  const [uploadedScans, setUploadedScans] = useState([]);
  const [uploadedMris, setUploadedMris] = useState([]);
  const [uploadedReports, setUploadedReports] = useState([]);

  const [date, setDate] = useState(activeCase.date ? moment(activeCase.date) : null);
  const [alert, toggleAlert] = useState('');

  const [requestPlanning, setRequestPlanning] = useState(activeCase.procedureFlow !== procedureFlows.CMF_EXTENDED);
  const [requestPlanningDate, setRequestPlanningDate] = useState(activeCase?.planning?.requestPlanningDate ? moment(activeCase?.planning?.requestPlanningDate) : moment().add(1, 'days'));
  const [additionalEmails, setAdditionalEmails] = useState(activeCase?.planning?.additionalEmails || '');

  const leadDays = useMemo(() => {
    const procedure = procedures?.find((p) => p.id === activeCase.procedure);
    return procedure && procedure?.leadDays > 0 ? procedure.leadDays : defaultLeadDays;
  }, [procedures, activeCase.procedure, defaultLeadDays]);

  const startDate = useMemo(() => (
    isAdmin ? false : moment().add(leadDays, 'days')
  ), [leadDays, isAdmin]);

  const handleSubmit = async () => {
    // const minDate = activeCase.procedureFlow !== procedureFlows.ACL ? moment().add(27, 'days') : null;
    const minDate = moment().add(leadDays - 1, 'days');

    if (!isAdmin && date && date.isBefore(minDate)) {
      return;
    }

    const filesData = activeCase.files && activeCase.files.length ? [...activeCase.files] : [];
    const now = moment().format();

    for (const file of xRays) {
      try {
        const id = `${activeCase.id}_${fileTypes.xRay}_${file.name}`;
        const downloadUrl = await firebase.getDownloadLink(`${activeCase.id}/${id}`);

        if (!downloadUrl) {
          return;
        }

        filesData.push({
          id,
          name: file.name,
          type: fileTypes.xRay,
          date: now,
          userId: uid,
        });
      } catch (err) {
        setXRays([...xRays].filter((f) => f.name !== file.name));
        toggleAlert(`${file.name} was not uploaded correctly. Please try again.`);
        return;
      }
    }

    for (const file of scans) {
      try {
        const id = `${activeCase.id}_${fileTypes.scan}_${file.name}`;
        const downloadUrl = await firebase.getDownloadLink(`${activeCase.id}/${id}`);

        if (!downloadUrl) {
          return;
        }

        filesData.push({
          id,
          name: file.name,
          type: fileTypes.scan,
          date: now,
          userId: uid,
        });
      } catch (err) {
        setScans([...scans].filter((f) => f.name !== file.name));
        toggleAlert(`${file.name} was not uploaded correctly. Please try again.`);
        return;
      }
    }

    for (const file of mris) {
      try {
        const id = `${activeCase.id}_${fileTypes.mri}_${file.name}`;
        const downloadUrl = await firebase.getDownloadLink(`${activeCase.id}/${id}`);

        if (!downloadUrl) {
          return;
        }

        filesData.push({
          id,
          name: file.name,
          type: fileTypes.mri,
          date: now,
          userId: uid,
        });
      } catch (err) {
        setMris([...mris].filter((f) => f.name !== file.name));
        toggleAlert(`${file.name} was not uploaded correctly. Please try again.`);
        return;
      }
    }

    // reports.forEach((file) => {
    //   filesData.push({
    //     id: `${activeCase.id}_${fileTypes.report}_${file.name}`,
    //     name: file.name,
    //     type: fileTypes.report,
    //     date: now,
    //   });
    // });

    const newActivity = { ...caseNotifications[1].success, date: now, userId: uid };
    const fileNotification = { ...caseNotifications[1].file, date: now, userId: uid };

    const stepData = {
      files: filesData,
      completionDates: { ...activeCase.completionDates, step1: now },
      completedBy: { ...activeCase.completedBy, step1: uid, },
      activity: [newActivity, fileNotification, ...activeCase.activity],
      status: statuses.inProgress,
      date: date ? date.format() : null,
      uploadFilesUid: uid,
    };

    if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(activeCase.procedureFlow)) {
      stepData.planning = {
        requestPlanning: !!requestPlanning,
        requestPlanningDate: requestPlanningDate && !!requestPlanning ? requestPlanningDate?.format() : null,
        additionalEmails: additionalEmails || '',
      };
    }

    uploadFiles(activeCase.id, stepData).then(() => onClose());
  };

  const handleXRaysUpload = (files) => {
    const uploads = { ...uploadedXRays };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedXRays(uploads);
    setXRays([...xRays, ...files.filter((file) => !xRays.map((xRay) => xRay.name).includes(file.name))]);
  };
  const handleScansUpload = (files) => {
    const uploads = { ...uploadedScans };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedScans(uploads);
    setScans([...scans, ...files.filter((file) => !scans.map((scan) => scan.name).includes(file.name))]);
  };
  const handleMriUpload = (files) => {
    const uploads = { ...uploadedMris };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedMris(uploads);
    setMris([...mris, ...files.filter((file) => !mris.map((mri) => mri.name).includes(file.name))]);
  };
  const handleReportUpload = (files) => {
    const uploads = { ...uploadedReports };

    files.forEach((file) => (uploads[file.name] = false));
    setUploadedReports(uploads);
    setReports([...reports, ...files.filter((file) => !reports.map((report) => report.name).includes(file.name))]);
  };

  const handleRemoveXRay = (name) => {
    const uploads = { ...uploadedXRays };

    delete uploads[name];
    setUploadedXRays(uploads);
    setXRays(xRays.filter((file) => file.name !== name));
  };
  const handleRemoveScan = (name) => {
    const uploads = { ...uploadedScans };

    delete uploads[name];
    setUploadedScans(uploads);
    setScans(scans.filter((file) => file.name !== name));
  };
  const handleRemoveMri = (name) => {
    const uploads = { ...uploadedMris };

    delete uploads[name];
    setUploadedMris(uploads);
    setMris(mris.filter((file) => file.name !== name));
  };
  const handleRemoveReport = (name) => {
    const uploads = { ...uploadedMris };

    delete uploads[name];
    setUploadedReports(uploads);
    setReports(reports.filter((file) => file.name !== name));
  };

  const onUploadXRayComplete = (name, url) => {
    setUploadedXRays((prevState) => ({ ...prevState, [name]: true }));
  };
  const onUploadScanComplete = (name, url) => {
    setUploadedScans((prevState) => ({ ...prevState, [name]: true }));
  };
  const onUploadMriComplete = (name, url) => {
    setUploadedMris((prevState) => ({ ...prevState, [name]: true }));
  };
  const onUploadReportComplete = (name, url) => {
    setUploadedReports((prevState) => ({ ...prevState, [name]: true }));
  };

  const checkUploadedFiles = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        xRays.every((file) => uploadedXRays[file.name] === true) &&
        scans.every((file) => uploadedScans[file.name] === true)
      );
    }

    if ([procedureFlows.ACL, procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.CUSTOM_EXTENDED, procedureFlows.ONCOL, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        mris.every((file) => uploadedMris[file.name] === true) &&
        scans.every((file) => uploadedScans[file.name] === true)
      );
    }
  };

  const checkRejectedFiles = (type) =>
    activeCase.files?.filter((file) => file.status !== fileStatuses.REJECTED && file.type === type).length;

  const handleClose = () => {
    if (xRays.length || scans.length || mris.length) {
      toggleModal(true);
    } else {
      onClose();
    }
  };

  const isOverdue = (day) => {
    const surgeryDate = day || moment(activeCase.date);

    return !isAdmin && surgeryDate.diff(moment(), 'days') < leadDays - 1;
  };

  const disabled = (flow) => {
    let condition = false;

    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      condition = (
        (!xRays.length && !checkRejectedFiles(fileTypes.xRay)) || (!scans.length && !checkRejectedFiles(fileTypes.scan))
      );
    }

    if (flow === procedureFlows.ACL) {
      condition = (
        (!mris.length && !checkRejectedFiles(fileTypes.mri)) && (!scans.length && !checkRejectedFiles(fileTypes.scan))
      );
    }

    if (flow === procedureFlows.CMF) {
      condition = !scans.length && !checkRejectedFiles(fileTypes.scan);
    }

    if ([procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      condition = (!scans.length && !checkRejectedFiles(fileTypes.scan)) || (requestPlanning && !requestPlanningDate);
    }

    return condition || !activeCase.access || !checkUploadedFiles(flow) || isOverdue(date);
  };

  const renderAttachedFiles = (flow) => {
    if ([procedureFlows.HTO, procedureFlows.DFO_Medial, procedureFlows.DFO_Lateral, procedureFlows.CUSTOM, procedureFlows.HTO_LATERAL].includes(flow)) {
      return (
        <OsteotomyStep1
          caseActions={caseActions}
          activeCase={activeCase}
          fileStatuses={fileStatuses}
          rejectedFiles={rejectedFiles}
          isOverdue={isOverdue}
          date={date}
          setDate={setDate}
          startDate={startDate}
          isAdmin={isAdmin}
          xRays={xRays}
          handleXRaysUpload={handleXRaysUpload}
          handleRemoveXRay={handleRemoveXRay}
          onUploadXRayComplete={onUploadXRayComplete}
          scans={scans}
          handleScansUpload={handleScansUpload}
          handleRemoveScan={handleRemoveScan}
          onUploadScanComplete={onUploadScanComplete}
          mris={mris}
          handleMriUpload={handleMriUpload}
          handleRemoveMri={handleRemoveMri}
          onUploadMriComplete={onUploadMriComplete}
          reports={reports}
          handleReportUpload={handleReportUpload}
          handleRemoveReport={handleRemoveReport}
          onUploadReportComplete={onUploadReportComplete}
          userRole={userRole}
          leadDays={leadDays}
        />
      );
    }

    if (flow === procedureFlows.ACL) {
      return (
        <AclStep1
          activeCase={activeCase}
          fileStatuses={fileStatuses}
          rejectedFiles={rejectedFiles}
          isOverdue={isOverdue}
          date={date}
          setDate={setDate}
          startDate={startDate}
          isAdmin={isAdmin}
          xRays={xRays}
          handleXRaysUpload={handleXRaysUpload}
          handleRemoveXRay={handleRemoveXRay}
          onUploadXRayComplete={onUploadXRayComplete}
          scans={scans}
          handleScansUpload={handleScansUpload}
          handleRemoveScan={handleRemoveScan}
          onUploadScanComplete={onUploadScanComplete}
          mris={mris}
          handleMriUpload={handleMriUpload}
          handleRemoveMri={handleRemoveMri}
          onUploadMriComplete={onUploadMriComplete}
          reports={reports}
          handleReportUpload={handleReportUpload}
          handleRemoveReport={handleRemoveReport}
          onUploadReportComplete={onUploadReportComplete}
          userRole={userRole}
          leadDays={leadDays}
        />
      );
    }

    if ([procedureFlows.CMF, procedureFlows.CMF_EXTENDED, procedureFlows.ONCOL, procedureFlows.CUSTOM_EXTENDED, procedureFlows.DOUBLE_LEVEL_OSTEOTOMY].includes(flow)) {
      return (
        <CmfStep1
          activeCase={activeCase}
          fileStatuses={fileStatuses}
          rejectedFiles={rejectedFiles}
          isOverdue={isOverdue}
          date={date}
          setDate={setDate}
          startDate={startDate}
          isAdmin={isAdmin}
          xRays={xRays}
          handleXRaysUpload={handleXRaysUpload}
          handleRemoveXRay={handleRemoveXRay}
          onUploadXRayComplete={onUploadXRayComplete}
          scans={scans}
          handleScansUpload={handleScansUpload}
          handleRemoveScan={handleRemoveScan}
          onUploadScanComplete={onUploadScanComplete}
          mris={mris}
          handleMriUpload={handleMriUpload}
          handleRemoveMri={handleRemoveMri}
          onUploadMriComplete={onUploadMriComplete}
          reports={reports}
          handleReportUpload={handleReportUpload}
          handleRemoveReport={handleRemoveReport}
          onUploadReportComplete={onUploadReportComplete}
          userRole={userRole}
          leadDays={leadDays}
          requestPlanning={requestPlanning}
          setRequestPlanning={setRequestPlanning}
          requestPlanningDate={requestPlanningDate}
          setRequestPlanningDate={setRequestPlanningDate}
          additionalEmails={additionalEmails}
          setAdditionalEmails={setAdditionalEmails}
        />
      );
    }
  };

  return (
    <div className="case-step-container">
      <div className="case-step-content">
        <div className="case-step-row">
          <div className="column">
            <div className="modal-item-circle">1</div>
          </div>
          <div className="modal-item-content">
            <div>{caseActions['1']}</div>
            {activeCase.access ? renderAttachedFiles(activeCase.procedureFlow) : <NoAccess />}
          </div>
        </div>
        <CaseAccordion currentStep={1} />
      </div>
      <div className="case-modal-actions">
        <div className="secondary pointer" onClick={handleClose}>
          Close
        </div>
        <Button
          text="Attach Scans"
          color="primary"
          disabled={disabled(activeCase.procedureFlow)}
          onClick={handleSubmit}
        />
      </div>

      <Alert
        variant="error"
        message={alert}
        open={!!alert}
        onClose={() => toggleAlert('')}
      />
    </div>
  );
};

Step1.propTypes = {
  onClose: PropTypes.func,
  activeCase: PropTypes.object,
  uploadFiles: PropTypes.func,
  toggleModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    uid: state.user.currentUser.uid,
    activeCase: state.cases.activeCase,
    userRole: state.user.currentUser.role,
    procedures: state.procedures.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFiles: (caseId, stepData) => dispatch(uploadFiles(caseId, stepData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withFirebase(Step1));
