import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MenuItem, Select } from '@material-ui/core';

import Modal from '../../modals/Modal';

import { sortAlphabetically } from '../../../util/utils';
import Hidden from '@material-ui/core/Hidden';
import Button from '../../shared/Button';

const CaseRequestDateHospitalModal = (props) => {
  const { open, onClose, selectedCase, onSubmit, loading, hospitals = [], surgeons = [] } = props;

  const [date, setDate] = useState(moment());
  const [hospital, setHospital] = useState(null);

  useEffect(() => {
    if (open && selectedCase?.hospital) {
      const currentHospital = hospitals?.find((h) => h.id === selectedCase?.hospitalUniq?.id);
      if (currentHospital) {
        setHospital(currentHospital);
      }
    }
  }, [open, hospitals, selectedCase]);

  const list = useMemo(() => (
    hospitals?.filter((h) => {
      const surgeon = surgeons?.find((s) => s.id === selectedCase?.surgeonId);
      return surgeon?.hospitals?.includes(h.id);
    })
  ), [hospitals, surgeons, selectedCase]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Request Surgery Date"
      subtitle={selectedCase?.formattedId || selectedCase?.id}
      onSubmit={() => onSubmit(date, hospital)}
      size="md"
      disabled={loading}
      loading={loading}
      autoSize
    >
      <div className="d-flex direction-column space-between">
        <Hidden smDown implementation="css">
          <div className="p-lg font-bold" style={{ maxWidth: 400 }}>
            Please request a surgery date and update the hospital if required for review and approval by an admin
          </div>
        </Hidden>

        <Hidden mdUp implementation="css">
          <div className='p-lg'>
            Please request a surgery date and update the hospital if required for review and approval by an admin
          </div>
        </Hidden>
        <div className="p-lg width-100">
          <div className="secondary">Requested Surgery Date</div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/YYYY"
              fullWidth
              id="date-picker"
              value={date}
              onChange={(date) => setDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDate={moment()}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="p-lg width-100">
          <div className="secondary">Hospital</div>
          <Select
            value={hospital}
            fullWidth
            className="case-field-select"
            onChange={(e) => setHospital(e.target.value)}
          >
            {list.sort(sortAlphabetically('name')).map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="d-flex w-100 space-between p-lg m-t-lg">
        <div className="secondary pointer" onClick={onClose}>
          Cancel
        </div>
        <Button text="Request Date" color="primary" onClick={() => onSubmit(date, hospital)} />
      </div>
    </Modal>
  );
};

export default CaseRequestDateHospitalModal;
