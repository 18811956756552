/* eslint-disable */

const local = 'http://localhost:5000/ps-dev-environment/us-central1';

const dev = 'https://us-central1-ps-dev-environment.cloudfunctions.net';
const prod = 'https://us-central1-personalised-surgery.cloudfunctions.net';

const apiUrl = prod;

export default apiUrl;
