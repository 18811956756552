import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import dateIcon from '../../../assets/calendar_icon.svg';

import './table.scss';

const DateCell = ({ date }) => {
  return (
    <div className="date-cell">
      {/*<img src={dateIcon} alt="date-icon" />*/}
      {date ? moment(date).format('DD/MM/YYYY') : 'TBC'}
    </div>
  );
};

DateCell.propTypes = {
  date: PropTypes.string.isRequired,
};

export default memo(DateCell);
