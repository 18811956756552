import React from 'react';

const Iframe = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <iframe
        src={`https://docs.google.com/viewer?embedded=true&url=${encodeURI(source)}`}
        title="Terms of Service and Privacy Policy "
        width="100%"
        height="700"
      ></iframe>
    </div>
  );
};

export default Iframe;
