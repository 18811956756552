import React from 'react';
import PropTypes from 'prop-types';

import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export const ProductsItem = ({ product, onDelete }) => (
  <>
    <Grid item xs={4}>
      {product?.sku}
    </Grid>
    <Grid item xs={6}>
      {product?.description}
    </Grid>
    <Grid item>
      <IconButton aria-label="delete" color="primary" onClick={() => onDelete(product.id)}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Grid>
  </>
);

ProductsItem.propTypes = {
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};
